import React from "react";
import Table, { TableData } from "../Table";
import drilldownTableEmptyState from "~/assets/drilldownTableEmptyState.png";
import Button from "../Button";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import Divider from "../Divider";
import Typography from "../Typography";

const CollapsibleTable = ({
  title,
  headers,
  data,
  loadingState,
  isExpanded,
  toggleExpanded,
}: {
  title?: string;
  headers: React.ReactNode[];
  data: TableData[];
  loadingState?: boolean;
  isExpanded?: boolean;
  toggleExpanded?: () => void;
}) => {
  return (
    <>
      {title && (
        <div className="flex flex-row items-center justify-between py-4 ">
          <Button
            fill="clear"
            onClick={toggleExpanded}
            className="!w-auto !p-0 !text-neutral-800"
            disabled={data.length === 0}
            id={`${title}-expand-button`}
          >
            <Typography
              size="sm"
              color={data.length ? undefined : "disabled"}
              weight="semibold"
            >
              {title}
            </Typography>
          </Button>
          <Button
            fill="clear"
            onClick={toggleExpanded}
            className={`!w-auto !p-2 ${
              data.length ? "!text-neutral-800" : "!text-neutral-100"
            }`}
            disabled={data.length === 0}
          >
            {isExpanded ? (
              <ChevronUpIcon className="h-4 w-4 ml-1" />
            ) : (
              <ChevronDownIcon className="h-4 w-4 ml-1" />
            )}
          </Button>
        </div>
      )}
      <div
        className={
          title && (!isExpanded || (isExpanded && !data.length)) ? "hidden" : ""
        }
      >
        <Table
          tableClassName="table-fixed"
          headers={headers}
          data={data}
          emptyState={
            <div className="flex items-center justify-center pb-6">
              <img src={drilldownTableEmptyState} alt="No data present" />
            </div>
          }
          loadingState={{
            isLoading: loadingState ?? false,
            skeletonRows: 20,
            widths: loadingState ? ["1/4", "1/4", "1/4", "1/4"] : undefined,
          }}
        />
      </div>
      {!isExpanded && <Divider />}
    </>
  );
};

export default CollapsibleTable;
