import React, { useEffect } from "react";
import Modal from "~/components/Modal";
import { capitalCase } from "change-case";
import { useSelector } from "react-redux";
import { State } from "~/store";
import date from "~/utils/dates/date";
import request from "~/utils/request";
import permissionsCheck from "~/utils/permissionsCheck";
import PositionForm from "../PositionForm";
import usePositionFormState from "../PositionForm/usePositionFormState";
import { Position } from "../headcount.types";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  selectedPositions?: Position[];
}
const ModifyMultiplePositionsContainer = ({
  isOpen,
  onClose,
  selectedPositions,
}: Props): React.ReactNode => {
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const {
    permissions: { role, departmentAccessList },
  } = useSelector((state: State) => state.user);
  const isAdmin = permissionsCheck(role, departmentAccessList);
  const {
    errorMessage,
    setErrorMessage,
    jobTitle,
    setJobTitle,
    startDateState,
    setStartDateState,
    employmentType,
    setEmploymentType,
    selectedPaymentType,
    setSelectedPaymentType,
    paymentAmountState,
    setPaymentAmountState,
    currencyTypeState,
    setCurrencyTypeState,
    selectDepartment,
    setSelectDepartment,
    managerState,
    setManagerState,
    changeDescription,
    setChangeDescription,
    expectedHoursState,
    setExpectedHoursState,
    resetFormState,
    createNewScenario,
  } = usePositionFormState();

  const { inEditMode: inScenarioEditMode, activeScenarioUuid } = useSelector(
    (state: State) => state.scenario,
  );

  useEffect(() => {
    if (selectedPositions && selectedPositions.length > 0) {
      const commonValues: {
        jobTitle: string | null;
        managerUuid: string | null;
        departmentUuid: string | null;
        employmentType: string | null;
        paymentUnit: string | null;
        compensationRate: number | null;
        expectedWeeklyHours: number | null;
      } = {
        jobTitle: selectedPositions[0].current.title,
        managerUuid: selectedPositions[0].current.managerUuid,
        departmentUuid: selectedPositions[0].current.groupUuid,
        employmentType: selectedPositions[0].current.employmentType,
        paymentUnit: selectedPositions[0].current.paymentUnit,
        compensationRate: selectedPositions[0].current.compensationRate,
        expectedWeeklyHours: selectedPositions[0].current.expectedWeeklyHours,
      };

      selectedPositions.forEach((position) => {
        if (position.current.title !== commonValues.jobTitle)
          commonValues.jobTitle = null;
        if (position.current.managerUuid !== commonValues.managerUuid)
          commonValues.managerUuid = null;
        if (position.current.groupUuid !== commonValues.departmentUuid)
          commonValues.departmentUuid = null;
        if (position.current.employmentType !== commonValues.employmentType)
          commonValues.employmentType = null;
        if (position.current.paymentUnit !== commonValues.paymentUnit)
          commonValues.paymentUnit = null;
        if (position.current.compensationRate !== commonValues.compensationRate)
          commonValues.compensationRate = null;
        if (
          position.current.expectedWeeklyHours !==
          commonValues.expectedWeeklyHours
        )
          commonValues.expectedWeeklyHours = null;
      });

      setJobTitle({
        ...jobTitle,
        value: commonValues.jobTitle || "",
        valid: true,
      });

      setEmploymentType({
        ...employmentType,
        selected: commonValues.employmentType
          ? {
              label: capitalCase(commonValues.employmentType),
              value: commonValues.employmentType,
            }
          : undefined,
        valid: true,
      });

      setSelectedPaymentType({
        ...selectedPaymentType,
        selected: commonValues.paymentUnit
          ? {
              label: capitalCase(commonValues.paymentUnit),
              value: commonValues.paymentUnit,
            }
          : undefined,
        valid: true,
      });

      setPaymentAmountState({
        ...paymentAmountState,
        value: commonValues.compensationRate
          ? (commonValues.compensationRate / 100).toString()
          : "",
        valid: true,
      });

      setCurrencyTypeState({
        ...currencyTypeState,
        selected: commonValues.paymentUnit
          ? {
              label: capitalCase(commonValues.paymentUnit),
              value: commonValues.paymentUnit,
            }
          : undefined,
        valid: true,
      });

      setSelectDepartment((prevState) => ({
        ...prevState,
        selected: commonValues.departmentUuid
          ? {
              label: selectedPositions[0].currentDepartment?.name,
              value: selectedPositions[0].currentDepartment?.uuid,
            }
          : undefined,
        valid: true,
      }));

      setManagerState({
        ...managerState,
        selected: {
          label: selectedPositions[0].currentManager
            ? `${selectedPositions[0].currentManager.firstName} ${selectedPositions[0].currentManager.lastName}`
            : null,
          value: selectedPositions[0].currentManager?.uuid ?? null,
        },
        valid: true,
      });

      setExpectedHoursState({
        ...expectedHoursState,
        value: commonValues.expectedWeeklyHours?.toString() || "",
        valid: true,
      });
    }
  }, [selectedPositions]);
  const attemptModifyPosition = async () => {
    setErrorMessage("");
    try {
      let titleChanged = false;
      let managerChanged = false;
      let departmentChanged = false;
      let compensationDataChanged = false;
      let expectedHoursChanged = false;

      let titleValid = true;
      if (jobTitle.value) {
        titleChanged = true;
        titleValid = jobTitle.valid;
      }

      let managerValid = true;
      if (managerState.selected?.value) {
        managerChanged = true;
        managerValid = managerState.valid;
      }

      let departmentValid = true;
      if (selectDepartment.selected?.value) {
        departmentChanged = true;
        departmentValid = selectDepartment.valid;
      }

      let compensationDataValid = true;
      const anyCompensationValuesModified =
        employmentType.selected?.value ||
        selectedPaymentType.selected?.value ||
        paymentAmountState.value;
      if (anyCompensationValuesModified) {
        compensationDataChanged = true;
        compensationDataValid =
          employmentType.valid &&
          selectedPaymentType.valid &&
          paymentAmountState.valid;
      }

      let expectedHoursValid = true;
      if (selectedPaymentType.selected?.value === "SALARY") {
        expectedHoursChanged = true;
        expectedHoursValid = expectedHoursState.valid;
      }

      if (
        startDateState.value.startDate &&
        changeDescription.value &&
        compensationDataValid &&
        titleValid &&
        managerValid &&
        departmentValid &&
        expectedHoursValid
      ) {
        const promises = selectedPositions?.map((position) => {
          const { positionUuid } = position.current;
          if (!startDateState.value.startDate) throw new Error("No start date");
          const body: {
            effectiveAt: string;
            changeDescription: string;
            title?: string;
            managerUuid?: string;
            groupUuid?: string;
            employmentType?: string;
            paymentUnit?: string;
            compensationRate?: number;
            expectedWeeklyHours?: string;
            scenarioUuid?: string;
            currency?: string;
          } = {
            effectiveAt: date(startDateState.value.startDate).format(
              "MM/DD/YYYY",
            ),
            changeDescription: changeDescription.value,
          };
          if (titleChanged) {
            body.title = jobTitle.value;
          }
          if (managerChanged) {
            body.managerUuid = managerState.selected?.value || undefined;
          }
          if (departmentChanged) {
            body.groupUuid = selectDepartment.selected?.value || undefined;
          }
          if (compensationDataChanged) {
            body.employmentType = employmentType.selected?.value || undefined;
            body.paymentUnit = selectedPaymentType.selected?.value || undefined;
            body.compensationRate = Number(paymentAmountState.value) * 100;
          }
          if (expectedHoursChanged) {
            body.expectedWeeklyHours = expectedHoursState.value || undefined;
          }
          if (activeScenarioUuid) {
            body.scenarioUuid = activeScenarioUuid;
          }
          if (currencyTypeState.selected?.value) {
            body.currency = currencyTypeState.selected.value;
          }

          return request({
            url: `/organizations/${organizationUuid}/positions/${positionUuid}`,
            method: "PATCH",
            body,
          });
        });

        await Promise.all(promises);

        resetFormState();
        onClose();
      } else {
        setStartDateState({
          ...startDateState,
          pristine: false,
          touched: true,
        });
        setChangeDescription({
          ...changeDescription,
          pristine: false,
          touched: true,
        });

        if (!compensationDataValid) {
          setEmploymentType({
            ...employmentType,
            pristine: false,
            touched: true,
          });
          setSelectedPaymentType({
            ...selectedPaymentType,
            pristine: false,
            touched: true,
          });
          setPaymentAmountState({
            ...paymentAmountState,
            pristine: false,
            touched: true,
          });
        }

        if (!expectedHoursValid) {
          setExpectedHoursState({
            ...expectedHoursState,
            pristine: false,
            touched: true,
          });
        }

        if (!titleValid) {
          setJobTitle({
            ...jobTitle,
            pristine: false,
            touched: true,
          });
        }

        if (!managerValid) {
          setManagerState({
            ...managerState,
            pristine: false,
            touched: true,
          });
        }

        if (!departmentValid) {
          setSelectDepartment({
            ...selectDepartment,
            pristine: false,
            touched: true,
          });
        }
      }
    } catch (e) {
      setErrorMessage("Unable to modify position. Please try again.");
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Modify Positions">
      <PositionForm
        mode="edit"
        isAdmin={isAdmin}
        onClose={onClose}
        jobTitle={jobTitle}
        setJobTitle={setJobTitle}
        managerState={managerState}
        setManagerState={setManagerState}
        selectDepartment={selectDepartment}
        setSelectDepartment={setSelectDepartment}
        startDateState={startDateState}
        setStartDateState={setStartDateState}
        employmentType={employmentType}
        setEmploymentType={setEmploymentType}
        selectedPaymentType={selectedPaymentType}
        setSelectedPaymentType={setSelectedPaymentType}
        paymentAmountState={paymentAmountState}
        setPaymentAmountState={setPaymentAmountState}
        currencyTypeState={currencyTypeState}
        setCurrencyTypeState={setCurrencyTypeState}
        errorMessage={errorMessage}
        savePosition={attemptModifyPosition}
        changeDescription={changeDescription}
        setChangeDescription={setChangeDescription}
        expectedHoursState={expectedHoursState}
        setExpectedHoursState={setExpectedHoursState}
        createNewScenario={createNewScenario}
        inScenarioEditMode={inScenarioEditMode}
      />
    </Modal>
  );
};

export default ModifyMultiplePositionsContainer;
