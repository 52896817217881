import React from "react";
import Typography from "~/components/Typography";
import expressionDetailsToEquationArray from "./expressionDetailsToEquationArray";
import SalaryCalculation from "./SalaryCalculation";
import formatCurrency from "~/utils/formatCurrency";
import { ICompCalcDrilldownDetails } from "../useDrilldownDrawer";
import { IDrilldownPosition } from "../useDrilldownDrawer";

interface Props {
  calculations: ICompCalcDrilldownDetails[];
  sumTotal: number;
  positions: IDrilldownPosition[];
}

const SalaryCalculationContainer = ({
  calculations,
  sumTotal,
  positions,
}: Props): JSX.Element => {
  return (
    <div className="min-w-full w-fit mt-2">
      <div>
        {calculations.map(
          (calculation: ICompCalcDrilldownDetails, index: number) => (
            <SalaryCalculation
              key={calculation.positionVersionUuid}
              position={positions[index]}
              startDate={calculation.startDate}
              endDate={calculation.endDate}
              equationArray={expressionDetailsToEquationArray(
                calculation.expressionDetails,
                [],
                positions[index].currency,
              )}
            />
          ),
        )}
      </div>
      {calculations.length > 1 && (
        <div className="flex w-full justify-end">
          <Typography color="secondary" size="xs" className="mr-3">
            Sum Total
          </Typography>
          <Typography size="xs">{formatCurrency(sumTotal)}</Typography>
        </div>
      )}
    </div>
  );
};

export default SalaryCalculationContainer;
