import Modal from "~/components/Modal";
import React, { useState, useEffect } from "react";
import DatePicker, { useDatePicker } from "~/components/DatePicker";
import date from "~/utils/dates/date";
import request from "~/utils/request";
import Button from "~/components/Button";
import Checkbox from "~/components/Checkbox";
import { capitalCase } from "change-case";
import toast from "react-hot-toast";
import usePositionFormState from "../PositionForm/usePositionFormState";
import BackfillForm from "../PositionForm/backfillForm";
import { PositionResponse } from "../PositionDetails";

interface Props {
  isOpen: boolean;
  setModal: (isOpen: boolean) => void;
  setPositionUuid: (uuid: string | null) => void;
  positionUuid?: string | null;
  successCallback?: () => void;
}

const ForecastTermination = ({
  isOpen,
  setModal,
  setPositionUuid,
  positionUuid,
  successCallback,
}: Props): React.ReactNode => {
  const {
    errorMessage,
    setErrorMessage,
    organizationUuid,
    jobTitle,
    setJobTitle,
    startDateState,
    setStartDateState,
    employmentType,
    setEmploymentType,
    selectedPaymentType,
    setSelectedPaymentType,
    paymentAmountState,
    setPaymentAmountState,
    currencyTypeState,
    setCurrencyTypeState,
    selectDepartment,
    setSelectDepartment,
    managerState,
    setManagerState,
    changeDescription,
    setChangeDescription,
    expectedHoursState,
    setExpectedHoursState,
    createNewScenario,
    bonusAmountState,
    setBonusAmountState,
    commissionAmountState,
    setCommissionAmountState,
    attainmentState,
    setAttainmentState,
  } = usePositionFormState();
  const [terminationDateState, setTerminationDateState] = useDatePicker({
    value: {
      startDate: null,
      endDate: null,
    },
    valid: false,
    pristine: true,
    touched: false,
    disabled: false,
    minDate: date().toISOString(),
  });
  const [backfillCheckbox, setBackfillCheckbox] = useState(false);
  const [useSameDetailsCheckbox, setUseSameDetailsCheckbox] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent): void => {
      if (event.key === "Escape") {
        setModal(false);
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleEscape);
    } else {
      document.removeEventListener("keydown", handleEscape);
    }

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [isOpen]);

  useEffect(() => {
    const getPositionDetails = async (): Promise<void> => {
      const positionDetailsResponse = (await request({
        url: `/organizations/${organizationUuid}/positions/${positionUuid}`,
        method: "GET",
      })) as PositionResponse;
      if (
        positionDetailsResponse.status === 200 &&
        positionDetailsResponse.data?.data
      ) {
        const positionDetails = positionDetailsResponse.data.data;

        setJobTitle({
          ...jobTitle,
          value: positionDetails.current.title,
          valid: true,
          disabled: useSameDetailsCheckbox,
        });

        if (
          !startDateState.value.startDate &&
          terminationDateState.value.startDate
        ) {
          setStartDateState({
            ...startDateState,
            value: {
              startDate: null,
              endDate: null,
            },
            pristine: true,
            touched: false,
            minDate: date(terminationDateState.value.startDate)
              .add(1, "day")
              .toISOString(),
          });
        }

        setEmploymentType({
          ...employmentType,
          selected: {
            label: capitalCase(positionDetails.current.employmentType),
            value: positionDetails.current.employmentType,
          },
          valid: true,
          disabled: useSameDetailsCheckbox,
        });
        setSelectedPaymentType({
          ...selectedPaymentType,
          selected: {
            label: capitalCase(positionDetails.current.paymentUnit),
            value: positionDetails.current.paymentUnit,
          },
          valid: true,
          disabled: useSameDetailsCheckbox,
        });

        setPaymentAmountState({
          ...paymentAmountState,
          value: (positionDetails.current.compensationRate / 100).toString(),
          valid: true,
          disabled: useSameDetailsCheckbox,
        });

        setCurrencyTypeState({
          ...currencyTypeState,
          selected: {
            label: positionDetails.current.currency,
            value: positionDetails.current.currency,
          },
          valid: true,
          disabled: useSameDetailsCheckbox,
        });
        setSelectDepartment((prevState) => ({
          ...prevState,
          selected: {
            label: positionDetails.currentDepartment?.name,
            value: positionDetails.currentDepartment?.uuid,
          },
          valid: true,
          disabled: useSameDetailsCheckbox,
        }));
        setManagerState({
          ...managerState,
          selected: {
            label: positionDetails.currentManager
              ? `${positionDetails.currentManager.firstName} ${positionDetails.currentManager.lastName}`
              : null,
            value: positionDetails.currentManager?.uuid ?? null,
          },
          valid: true,
          disabled: useSameDetailsCheckbox,
        });
        setExpectedHoursState({
          ...expectedHoursState,
          value: positionDetails.current.expectedWeeklyHours?.toString() ?? "",
          valid: true,
          disabled: true,
        });
        setChangeDescription({
          ...changeDescription,
          value: "Back-filling position",
          valid: true,
          disabled: false,
        });
        setBonusAmountState({
          ...bonusAmountState,
          value: positionDetails.current.bonus
            ? (positionDetails.current.bonus / 100).toString()
            : "",
          valid: true,
          disabled: true,
        });
        setCommissionAmountState({
          ...commissionAmountState,
          value: positionDetails.current.commission
            ? (positionDetails.current.commission / 100).toString()
            : "",
          valid: true,
          disabled: true,
        });
        setAttainmentState({
          ...attainmentState,
          value: positionDetails.current.attainment
            ? (positionDetails.current.attainment * 100).toString()
            : "",
          valid: true,
          disabled: true,
        });
      }
    };
    if (positionUuid && backfillCheckbox) {
      getPositionDetails();
    }
  }, [positionUuid, useSameDetailsCheckbox, backfillCheckbox]);

  useEffect(() => {
    if (terminationDateState.value.startDate) {
      if (
        startDateState.value.startDate &&
        date(startDateState.value.startDate).isAfter(
          date(terminationDateState.value.startDate),
        )
      ) {
        setStartDateState((prevState) => ({
          ...prevState,
          valid: true,
          errorMessage: "",
          minDate: date(terminationDateState.value.startDate || undefined)
            .add(1, "day")
            .toISOString(),
        }));
      } else {
        setStartDateState((prevState) => ({
          ...prevState,
          errorMessage: "Backfill must be after termination date",
          valid: false,
          minDate: date(terminationDateState.value.startDate || undefined)
            .add(1, "day")
            .toISOString(),
        }));
      }
    } else {
      setStartDateState((prevState) => ({
        ...prevState,
        minDate: date(terminationDateState.value.startDate || undefined)
          .add(1, "day")
          .toISOString(),
      }));
    }
  }, [
    terminationDateState.value.startDate,
    setStartDateState,
    useSameDetailsCheckbox,
  ]);

  const clearDetails = () => {
    setPositionUuid(null);
    setBackfillCheckbox(false);
    setUseSameDetailsCheckbox(true);
    setTerminationDateState({
      ...terminationDateState,
      value: {
        startDate: null,
        endDate: null,
      },
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
      minDate: date().toISOString(),
    });
    setStartDateState({
      ...startDateState,
      value: {
        startDate: null,
        endDate: null,
      },
      valid: false,
      pristine: true,
      touched: false,
      minDate: date().toISOString(),
    });
  };

  const issueWithFormState = () => {
    setJobTitle({ ...jobTitle, pristine: false, touched: true });
    setEmploymentType({
      ...employmentType,
      pristine: false,
      touched: true,
    });
    setSelectedPaymentType({
      ...selectedPaymentType,
      pristine: false,
      touched: true,
    });
    setSelectDepartment({
      ...selectDepartment,
      pristine: false,
      touched: true,
    });
    setPaymentAmountState({
      ...paymentAmountState,
      pristine: false,
      touched: true,
    });
    setStartDateState({
      ...startDateState,
      pristine: false,
      touched: true,
    });
    setChangeDescription({
      ...changeDescription,
      pristine: false,
      touched: true,
    });
    setBonusAmountState({
      ...bonusAmountState,
      pristine: false,
      touched: true,
    });
    setCommissionAmountState({
      ...commissionAmountState,
      pristine: false,
      touched: true,
    });
    setAttainmentState({
      ...attainmentState,
      pristine: false,
      touched: true,
    });
    if (selectedPaymentType.selected?.value === "HOURLY") {
      setExpectedHoursState({
        ...expectedHoursState,
        pristine: false,
        touched: true,
      });
    }
  };

  const attemptForecastTermination = async () => {
    setIsLoading(true);
    let backfillValues;

    if (
      backfillCheckbox &&
      jobTitle.value &&
      employmentType.selected?.value &&
      selectedPaymentType.selected?.value &&
      selectDepartment.selected?.value &&
      paymentAmountState.value &&
      startDateState.value.startDate &&
      currencyTypeState.selected?.value &&
      changeDescription.value &&
      (selectedPaymentType.selected.value === "SALARY" ||
        expectedHoursState.value) &&
      date(startDateState.value.startDate || undefined).isSameOrAfter(
        date(terminationDateState.value.startDate ?? undefined),
      )
    ) {
      backfillValues = {
        employeeUuid: null,
        groupUuid: selectDepartment.selected.value,
        managerUuid: managerState.selected?.value || undefined,
        employmentType: employmentType.selected.value,
        compensationRate: Number(paymentAmountState.value) * 100,
        paymentUnit: selectedPaymentType.selected.value,
        title: jobTitle.value,
        effectiveAt: date(startDateState.value.startDate).format("YYYY-MM-DD"),
        expectedWeeklyHours:
          selectedPaymentType.selected.value === "HOURLY"
            ? expectedHoursState.value
            : undefined,
        changeDescription: changeDescription.value,
        bonus: bonusAmountState.value.length
          ? Number(bonusAmountState.value) * 100
          : null,
        commission: commissionAmountState.value.length
          ? Number(commissionAmountState.value) * 100
          : null,
        attainment: attainmentState.value.length
          ? Number(attainmentState.value) / 100
          : null,
        currency: currencyTypeState.selected.value,
        isActive: true,
      };
    }

    if (
      terminationDateState.value.startDate &&
      date(terminationDateState.value.startDate ?? undefined).isSameOrAfter(
        date(),
      )
    ) {
      const termEmployeeResponse = await request({
        url: `/organizations/${organizationUuid}/positions/${positionUuid}/termEmployee`,
        method: "POST",
        body: {
          effectiveAt: date(terminationDateState.value.startDate).format(
            "YYYY-MM-DD",
          ),
          comment: "Termination",
          backfillValues,
        },
      });

      setIsLoading(false);

      if (termEmployeeResponse.status >= 400) {
        setErrorMessage("Error forecasting termination. Please try again.");
      } else {
        setModal(false);
        clearDetails();
        if (successCallback) {
          successCallback();
        }
        toast.success("Termination processed successfully");
      }
    } else {
      setTerminationDateState({
        ...terminationDateState,
        pristine: false,
        touched: true,
      });
      if (backfillCheckbox) {
        setStartDateState({
          ...startDateState,
          pristine: false,
          touched: true,
        });
        if (!useSameDetailsCheckbox) {
          issueWithFormState();
        }
        setIsLoading(false);
      }
    }
  };

  const addPositionBackfill = (
    <div data-testid="backfill-form">
      <BackfillForm
        mode="edit"
        jobTitle={jobTitle}
        setJobTitle={setJobTitle}
        managerState={managerState}
        setManagerState={setManagerState}
        selectDepartment={selectDepartment}
        setSelectDepartment={setSelectDepartment}
        startDateState={startDateState}
        setStartDateState={setStartDateState}
        employmentType={employmentType}
        setEmploymentType={setEmploymentType}
        selectedPaymentType={selectedPaymentType}
        setSelectedPaymentType={setSelectedPaymentType}
        paymentAmountState={paymentAmountState}
        currencyTypeState={currencyTypeState}
        setCurrencyTypeState={setCurrencyTypeState}
        setPaymentAmountState={setPaymentAmountState}
        bonusAmountState={bonusAmountState}
        setBonusAmountState={setBonusAmountState}
        commissionAmountState={commissionAmountState}
        setCommissionAmountState={setCommissionAmountState}
        attainmentState={attainmentState}
        setAttainmentState={setAttainmentState}
        errorMessage={errorMessage}
        changeDescription={changeDescription}
        setChangeDescription={setChangeDescription}
        expectedHoursState={expectedHoursState}
        setExpectedHoursState={setExpectedHoursState}
        createNewScenario={createNewScenario}
        inScenarioEditMode
        useSameDetailsCheckbox={useSameDetailsCheckbox}
        setUseSameDetailsCheckbox={setUseSameDetailsCheckbox}
      />
    </div>
  );

  return (
    <Modal isOpen={isOpen} title="Term Employee" size="md">
      <div
        data-testid="forecast-termination-modal"
        className="flex flex-col gap-4"
      >
        <p className="text-sm text-gray-500">
          {`Schedule employee's termination date and specify backfill
          requirements. If no backfill is added, the position will be
          deactivated.`}
        </p>
        <div className="flex flex-col">
          <DatePicker
            id="terminationAt"
            minDate={date().toDate()}
            state={terminationDateState}
            setState={setTerminationDateState}
            label="Term Date"
            errorMessage="Start date is required"
            required
          />
        </div>
        <div className="flex items-center gap-2">
          <Checkbox
            id="backfill-checkbox"
            checked={backfillCheckbox}
            toggleValue={() => setBackfillCheckbox((prev) => !prev)}
            className="cursor-pointer w-4 h-4"
          />
          <p>Schedule a Backfill</p>
        </div>
        {backfillCheckbox && addPositionBackfill}
        <div className="flex justify-between gap-5">
          <Button
            fill="clear"
            className="!w-auto !px-0"
            onClick={() => {
              clearDetails();
              setModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            className="px-6 !w-auto"
            onClick={attemptForecastTermination}
            id="forecast-termination-submit-button"
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ForecastTermination;
