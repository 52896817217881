export default (selector: string, maxLength: number) => {
  const elements = document.querySelectorAll(selector);
  elements.forEach((element) => {
    const htmlElement = element as HTMLElement; // Type assertion
    let text = htmlElement.innerText;
    if (text.length > maxLength) {
      text = `${text.substr(0, maxLength)}...`; // Using template literal
      htmlElement.innerText = text; // Now valid because of type assertion
    }
  });
};
