import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import customPlugins from "./datePlugins";

dayjs.extend(isSameOrAfter);
dayjs.extend(utc);
dayjs.extend(customPlugins);

export default (date?: string) => {
  if (date) return dayjs(date).utc();
  const { VITE_MOCK_DATE } = import.meta.env;
  if (VITE_MOCK_DATE) {
    return dayjs(VITE_MOCK_DATE, "MM-DD-YYYY").utc();
  }
  return dayjs().utc();
};
