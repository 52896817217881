import useInput from "~/components/Input/useInput";

const useNonHrisFieldValidationState = () => {
  const [expectedHoursState, setExpectedHoursState] = useInput({
    validation: /^[1-9]\d*$/,
    errorMessage: "Expected hours is required to be a number greater than zero",
  });
  const [bonusAmountState, setBonusAmountState] = useInput({
    validation: /^([0-9]\d*)?$/,
  });
  const [commissionAmountState, setCommissionAmountState] = useInput({
    validation: /^([0-9]\d*)?$/,
  });
  const [attainmentState, setAttainmentState] = useInput({
    validation: /^(100(\.00?)?|(\d{1,2}(\.\d{1,2})?))%?$/,
    errorMessage: "Between 0 & 100",
  });

  return {
    expectedHoursState,
    setExpectedHoursState,
    bonusAmountState,
    setBonusAmountState,
    commissionAmountState,
    setCommissionAmountState,
    attainmentState,
    setAttainmentState,
  };
};

export default useNonHrisFieldValidationState;
