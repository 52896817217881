import React from "react";
import { useSelector } from "react-redux";
import { State } from "~/store";
import { useEffect, useState } from "react";
import request from "~/utils/request";
import Table from "~/components/Table";
import Button from "~/components/Button";
import Header from "~/components/Header";
import useQueryParams from "~/utils/hooks/useQueryParams";
import date from "~/utils/dates/date";
import permissionsCheck from "~/utils/permissionsCheck";
import { useNavigate } from "react-router-dom";
import { PositionsImport } from "../headcount.types";
import PositionsImportModal from "./positionsImportModal";

interface ListPositionsImportResponse {
  request: unknown;
  data: {
    data: PositionsImport[];
  } | null;
  status: number;
}

const ImportPositions = (): React.ReactNode => {
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useQueryParams();
  const [importModalIsOpen, setImportModalIsOpen] = useState(
    queryParams.get("import") !== null,
  );
  const [positionsImports, setPositionsImports] = useState<PositionsImport[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(true);

  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );

  const {
    permissions: { role, departmentAccessList },
  } = useSelector((state: State) => state.user);

  useEffect(() => {
    if (!permissionsCheck(role, departmentAccessList)) navigate("/headcount");
  }, []);

  const getPositionsImports = async (): Promise<void> => {
    const response = (await request({
      url: `/organizations/${organizationUuid}/positions/imports`,
      method: "GET",
    })) as ListPositionsImportResponse;

    if (response.status !== 200) {
      throw new Error("Failed to fetch positions imports");
    }

    if (response.data?.data) {
      setIsLoading(false);
      setPositionsImports(response.data.data);
    }
  };

  useEffect(() => {
    getPositionsImports();
  }, [organizationUuid]);

  useEffect(() => {
    const newQueryParams: {
      import?: string;
    } = {};

    if (importModalIsOpen) newQueryParams.import = "true";

    setQueryParams(newQueryParams);
  }, [importModalIsOpen]);

  const tableData = positionsImports.map((positionImport) => ({
    id: positionImport.uuid,
    values: [
      {
        value: (
          <div key={`${positionImport.uuid}-file-name`} className="py-4 px-2">
            {positionImport.fileName}
          </div>
        ),
      },
      {
        value: (
          <div key={`${positionImport.uuid}-created-at`} className="py-4 px-2">
            {date(positionImport.createdAt).format("MM/DD/YYYY")}
          </div>
        ),
      },
      {
        value: (
          <div key={`${positionImport.uuid}-created-by`} className="py-4 px-2">
            {positionImport.createdByUser.name}
          </div>
        ),
      },
      {
        value: (
          <div
            key={`${positionImport.uuid}-positions-created`}
            className="py-4 px-2"
          >
            {positionImport.positionsCreated}
          </div>
        ),
      },
    ],
  }));

  return (
    <>
      <PositionsImportModal
        isOpen={importModalIsOpen}
        setModal={setImportModalIsOpen}
        getPositionsImports={getPositionsImports}
      />
      <Header
        breadCrumbs={[{ label: "Headcount", url: "/headcount" }]}
        title="Import Positions"
      >
        <Button
          onClick={() => setImportModalIsOpen(true)}
          className="!w-auto !px-10"
          id="import-positions-button"
        >
          Import
        </Button>
      </Header>
      <div className="flex flex-col px-10 gap-4 mt-12">
        <h1>Completed Imports</h1>
        <Table
          id="positions-imports-table"
          headers={[
            <div
              key="import-header-file-name"
              id="importFileName"
              className="py-4 px-2"
            >
              File Name
            </div>,
            <div
              key="import-header-created-at"
              id="importCreatedAt"
              className="py-4 px-2"
            >
              Uploaded
            </div>,
            <div
              key="import-header-created-by"
              id="importStatus"
              className="py-4 px-2"
            >
              Created By
            </div>,
            <div
              key="import-header-positions-created"
              id="importPositionsCreated"
              className="py-4 px-2"
            >
              Positions Created
            </div>,
          ]}
          autoSpacing={false}
          loadingState={{ isLoading }}
          data={tableData}
          emptyState={
            !isLoading && (
              <div
                className="flex flex-col items-center gap-4 py-16 bg-white"
                data-testid="empty-state"
              >
                <p>No imports found</p>
                <p>Upload CSV files to create positions.</p>
              </div>
            )
          }
        />
      </div>
    </>
  );
};

export default ImportPositions;
