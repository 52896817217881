import React, { useState, useEffect } from "react";
import request from "~/utils/request";
import { useSelector } from "react-redux";
import { State } from "~/store";
import date from "~/utils/dates/date";
import Table from "~/components/Table";
import Typography from "~/components/Typography";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import changeHistoryEmptyState from "~/assets/changeHistoryEmptyState.svg";
import truncateText from "~/utils/truncateText";
import formatCurrency from "~/utils/formatCurrency";
import EmploymentTypes from "~/utils/enums";

interface IChange {
  changeColumn: string;
  previousValue: string | null;
  newValue: string | null;
}

interface IChangeHistoryEntry {
  uuid: string;
  positionUuid: string;
  parentUuid: string;
  groupUuid: string | null;
  employeeUuid: string | null;
  employeeName?: string;
  groupName?: string;
  title: string;
  effectiveAt: string;
  forecastUuid: string | null;
  forecastComparison: string;
  changes: IChange[];
}

interface IChangeHistoryResponse {
  data: {
    data: IChangeHistoryEntry[];
  };
  status: number;
}

const ChangeHistory = (): React.ReactNode => {
  const [isLoading, setIsLoading] = useState(true);
  const [changeHistory, setChangeHistory] = useState<IChangeHistoryEntry[]>([]);
  const organizationUuid = useSelector(
    (state: State) => state.organization.uuid,
  );

  truncateText(".truncate", 26);

  const fetchChangeHistory = async (): Promise<void> => {
    try {
      const response = (await request({
        url: `/organizations/${organizationUuid}/positions/change-history`,
        method: "GET",
      })) as IChangeHistoryResponse;

      if (response.status !== 200) {
        throw new Error("Failed to fetch change history");
      }
      setChangeHistory(response.data.data);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchChangeHistory();
  }, []);

  const headerMap: Record<string, string> = {
    employeeUuid: "Employee",
    title: "Title",
    groupUuid: "Department",
    employmentType: "Employment Type",
    compensationRate: "Compensation Rate",
    isActive: "Status",
    managerUuid: "Manager",
    paymentUnit: "Payment Unit",
    expectedWeeklyHours: "Expected Weekly Hours",
    locationUuid: "Location",
    currency: "Currency",
  };

  const formatChangeData = (changes: IChange[]): React.ReactNode[] => {
    const employmentTypeToEnum = (value: string): EmploymentTypes => {
      const employmentTypeEnum =
        EmploymentTypes[value as keyof typeof EmploymentTypes];
      return employmentTypeEnum;
    };

    const paymentUnitToEnum = (value: string): string => {
      switch (value) {
        case "HOURLY":
          return "Hourly";
        case "SALARY":
          return "Salary";
        default:
          return "-";
      }
    };

    const transformValue = (
      change: IChange,
    ): {
      previousValue: string;
      newValue: string;
    } => {
      switch (change.changeColumn) {
        case "isActive":
          return {
            previousValue:
              change.previousValue === "true" ? "Filled" : "Closed",
            newValue: change.newValue === "true" ? "Filled" : "Closed",
          };
        case "compensationRate":
          return {
            previousValue: change.previousValue
              ? formatCurrency(change.previousValue)
              : "-",
            newValue: change.newValue ? formatCurrency(change.newValue) : "-",
          };
        case "paymentUnit":
          return {
            previousValue: change.previousValue
              ? paymentUnitToEnum(change.previousValue)
              : "-",
            newValue: change.newValue
              ? paymentUnitToEnum(change.newValue)
              : "-",
          };
        case "employmentType":
          return {
            previousValue: change.previousValue
              ? employmentTypeToEnum(change.previousValue)
              : "-",
            newValue: change.newValue
              ? employmentTypeToEnum(change.newValue)
              : "-",
          };
        default:
          return {
            previousValue: change.previousValue ?? "-",
            newValue: change.newValue ?? "-",
          };
      }
    };

    // If there is only one change, return the data in the normal format
    if (changes.length === 1 && changes[0]) {
      const change = changes[0];
      const { previousValue, newValue } = transformValue(change);
      return [
        <div key={`${change.changeColumn}`} className="p-4">
          <p>{headerMap[change.changeColumn]}</p>
        </div>,
        <div key={`${change.previousValue}`} className="p-4 max-2xl:hidden">
          <p>{previousValue}</p>
        </div>,
        <div key={`${change.newValue}`} className="p-4 max-2xl:hidden">
          <p>{newValue}</p>
        </div>,
      ];
    }

    // If there are multiple changes, return the data in the expanded format
    const summary = <div className="p-4">{changes.length} modifications</div>;
    const expandedContent = (
      <div className="w-full pb-4 pr-8">
        <div
          data-testid="change-history-change-list"
          className="flex flex-col gap-2 w-full mx-8 mb-4 p-4 border border-neutral-50 rounded-xl"
        >
          {changes.map((change) => {
            const { previousValue, newValue } = transformValue(change);
            return (
              <div
                className="flex flex-row bg-neutral-15 p-4 border border-neutral-50 rounded-lg"
                key={`change-${change.changeColumn}`}
              >
                <div className="w-[50%]">{headerMap[change.changeColumn]}</div>
                <div className="flex flex-row w-[50%] max-lg:hidden">
                  <div className="font-semibold w-[45%]">{previousValue}</div>
                  <div className="text-neutral-200 m-auto p-auto">
                    <ChevronRightIcon className="w-4 h-4" />
                  </div>
                  <div className="font-semibold w-[45%]">{newValue}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
    return [
      summary,
      <div key="multiple-changes-previous" className="p-4 max-2xl:hidden">
        <p>-</p>
      </div>,
      <div key="multiple-changes-new" className="p-4 max-2xl:hidden">
        <p>-</p>
      </div>,
      expandedContent,
    ];
  };

  const tableData = changeHistory.map((changeOccurence) => {
    const [changesSummary, previousValue, newValue, expandedContent] =
      formatChangeData(changeOccurence.changes);

    return {
      id: `change-history-${changeOccurence.uuid}`,
      values: [
        {
          value: (
            <div
              key="change-history-employee-name"
              title={changeOccurence.employeeName ?? "-"}
              className="p-4 truncate"
            >
              <p>{changeOccurence.employeeName ?? "-"}</p>
            </div>
          ),
        },
        {
          value: (
            <div
              key="change-history-title"
              title={changeOccurence.title}
              className="p-4 truncate"
            >
              <p>{changeOccurence.title}</p>
            </div>
          ),
        },
        {
          value: (
            <div key="change-history-group-name" className="p-4">
              <p>{changeOccurence.groupName}</p>
            </div>
          ),
        },
        {
          value: (
            <div
              key="change-history-effective-at"
              className="p-4 max-xl:hidden"
            >
              <p>{date(changeOccurence.effectiveAt).format("MM/DD/YYYY")}</p>
            </div>
          ),
        },
        { value: changesSummary },
        { value: previousValue },
        { value: newValue },
      ],
      expandedContent,
    };
  });
  return (
    <div className="w-full p-5 2xl:p-0">
      <Typography tag="h2" size="xl" weight="bold" className="mb-3">
        HRIS History
      </Typography>
      <Table
        id="change-history-table"
        className="w-full"
        loadingState={{ isLoading, skeletonRows: 15 }}
        headers={[
          <div
            key="import-header-created-at"
            id="importCreatedAt"
            className="p-4"
          >
            Employee
          </div>,
          <div key="import-header-created-by" id="importStatus" className="p-4">
            Position
          </div>,
          <div key="import-header-created-by" id="importStatus" className="p-4">
            Department
          </div>,
          <div
            key="import-header-positions-created"
            id="importPositionsCreated"
            className="p-4 max-xl:hidden"
          >
            Effective
          </div>,
          <div
            key="import-header-positions-created"
            id="importPositionsCreated"
            className="p-4"
          >
            Modification
          </div>,
          <div
            key="import-header-positions-created"
            id="importPositionsCreated"
            className="p-4 max-2xl:hidden"
          >
            Original
          </div>,
          <div
            key="import-header-positions-created"
            id="importPositionsCreated"
            className="p-4 max-2xl:hidden"
          >
            Updated
          </div>,
          <div key="empty-stuff" />,
        ]}
        emptyState={
          !isLoading && (
            <div className="w-full flex justify-center items-center pt-10 pb-12">
              <div className="flex flex-col w-[430px] items-center text-center gap-4">
                <img
                  src={changeHistoryEmptyState}
                  alt="Empty Change History Illustration"
                  className="w-64 h-auto"
                />
                <Typography
                  tag="h3"
                  size="md"
                  weight="semibold"
                  className="mt-4"
                >
                  No HRIS Changes
                </Typography>
                <Typography tag="p" size="md" color="secondary">
                  {`No new HRIS data has been received in the designated timeframe. If
              this seems incorrect, please reach out and we'll dig into the data
              to see what's happening.`}
                </Typography>
              </div>
            </div>
          )
        }
        autoSpacing={false}
        data={tableData}
      />
    </div>
  );
};

export default ChangeHistory;
