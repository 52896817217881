import React, { useEffect } from "react";
import Select, { useSelect } from "~/components/Select";
import Input from "~/components/Input";
import Typography from "~/components/Typography";
import Button from "~/components/Button";
import FileUpload, { useFile } from "~/components/FileUpload";
import Papa from "papaparse";
import toast from "react-hot-toast";
import customHrisOverrides from "./customHrisOverrides";
import handleUpload from "./handleUpload";
import { IPositionsImportedDetails } from "./SummaryStep";

interface Props {
  hrisName: Types.InputState;
  setHrisName: React.Dispatch<React.SetStateAction<Types.InputState>>;
  setPositionsImportedDetails: React.Dispatch<
    React.SetStateAction<IPositionsImportedDetails | undefined>
  >;
  organizationUuid: string;
  integrationUuid?: string;
  parsedFileData?: {
    headers: string[];
    data: Record<string, unknown>[];
  };
  setParsedFileData: React.Dispatch<
    React.SetStateAction<
      { headers: string[]; data: Record<string, unknown>[] } | undefined
    >
  >;
  onNext: (step?: string) => void;
  onCancel: () => void;
}

const SelectFileStep = ({
  hrisName,
  setHrisName,
  organizationUuid,
  setPositionsImportedDetails,
  integrationUuid,
  parsedFileData,
  setParsedFileData,
  onNext,
  onCancel,
}: Props): React.ReactNode => {
  const [fileUpload, setFileUpload] = useFile();
  const [hrisSelection, setHrisSelection] = useSelect({
    options: [
      { value: "Rippling", label: "Rippling" },
      { value: "Other", label: "Other" },
    ],
  });

  const fileUploadDescription = ((): React.ReactNode => {
    switch (hrisSelection.selected?.value) {
      case "Rippling":
        return (
          <>
            {`With Rippling, make sure you are uploading the Headcount Roster file
            with the Employee Number added. For step-by-step instructions, view `}
            <a
              className="text-green underline"
              href="https://support.getparallel.com/en/articles/8689244-manual-upload-for-rippling"
              target="_blank"
              rel="noreferrer"
            >
              this link
            </a>
          </>
        );
      case "Other":
      default:
        return (
          <>
            When uploading a CSV file containing headcount data, verify uniform
            data formatting is used across each column to ensure data integrity.
            <br />
            <a
              className="text-green underline"
              href="https://support.getparallel.com/en/articles/8690594-manual-hris-upload-data-requirements"
            >
              View Data Requirements
            </a>
          </>
        );
    }
  })();

  /**
   * Consolidate the value being referenced into one field by copying the selection over the hris value
   */
  useEffect(() => {
    if (hrisSelection.selected?.value === "Other") {
      setHrisName((prevState) => ({
        ...prevState,
        value: "",
        valid: false,
      }));
    } else {
      setHrisName((prevState) => ({
        ...prevState,
        value: hrisSelection.selected?.value ?? "",
        valid: !!hrisSelection.selected?.value,
      }));
    }
  }, [hrisSelection.selected]);

  useEffect(() => {
    const file = fileUpload.selectedFile;
    if (file) {
      Papa.parse(file, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: (result) => {
          const { fields } = result.meta;
          const data = result.data as Record<string, unknown>[];
          if (fields) {
            setParsedFileData({ headers: fields, data });
          } else {
            toast.error("Error parsing file");
          }
        },
        error: () => {
          toast.error("Error parsing file");
        },
      });
    }
  }, [fileUpload.selectedFile]);

  const attemptNext = async (): Promise<void> => {
    if (fileUpload.valid && hrisName.valid) {
      /**
       * If the user selected Rippling, we will massage the data to fit the Parallel format
       * and go directly to submitting the data
       */
      if (hrisSelection.selected?.value === "Rippling" && parsedFileData) {
        const standardizedData = customHrisOverrides.rippling(parsedFileData);
        const summaryDetails = await handleUpload({
          data: standardizedData,
          hrisName: hrisName.value,
          organizationUuid,
          integrationUuid,
        });
        setPositionsImportedDetails(summaryDetails);
        onNext("summary");
      } else {
        onNext();
      }
    } else {
      setHrisName((prevState) => ({
        ...prevState,
        touched: true,
        pristine: false,
      }));
      setFileUpload((prevState) => ({
        ...prevState,
        touched: true,
        pristine: false,
      }));
      setHrisSelection((prevState) => ({
        ...prevState,
        touched: true,
        pristine: false,
      }));
    }
  };

  return (
    <div className="flex flex-col gap-5 w-full">
      <Select
        id="manualIntegrationHrisSelection"
        label="HRIS Provider"
        state={hrisSelection}
        setState={setHrisSelection}
      />
      {hrisSelection.selected?.value === "Other" && (
        <Input
          id="other-hris-name"
          state={hrisName}
          setState={setHrisName}
          placeholder="Other"
          label="Data Source (HRIS Name, Manually Tracked in CSV, etc.)"
        />
      )}

      {hrisSelection.selected?.value && (
        <div className="flex flex-col gap-2">
          <div>
            <Typography weight="medium">File Upload</Typography>
            <br />
            <Typography color="secondary">{fileUploadDescription}</Typography>
          </div>
          <div className="relative flex flex-col justify-center items-center gap-4">
            <FileUpload
              id="manual-hris-upload"
              state={fileUpload}
              setState={setFileUpload}
            />
          </div>
        </div>
      )}
      <div className="w-full flex justify-between">
        <Button className="!w-auto !px-0" fill="clear" onClick={onCancel}>
          Cancel
        </Button>
        <Button id="upload-hris-file" className="!w-auto" onClick={attemptNext}>
          Upload
        </Button>
      </div>
    </div>
  );
};

export default SelectFileStep;
