import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ForgotPassword from "./ForgotPassword";
import useInput from "~/components/Input/useInput";
import { emailValidator } from "~/utils/validators";
import request from "~/utils/request";

const ForgotPasswordContainer = (): React.ReactNode => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [email, setEmail] = useInput({
    validation: emailValidator,
    errorMessage: "Please enter a valid email",
  });
  useEffect(() => {
    setErrorMessage("");
  }, [email.value]);

  const attemptForgotPassword = async (): Promise<void> => {
    if (email.valid) {
      try {
        const { data } = await request({
          url: "/auth/password-reset-requests",
          method: "POST",
          body: {
            email: email.value,
          },
        });
        if (data.data.errors) {
          setErrorMessage(data.data.errors[0].message);
        } else {
          setSuccessMessage(
            "Success! Check your email for a password reset link.",
          );
        }
      } catch (error) {
        setErrorMessage(
          "Unable to send password reset email. Please try again.",
        );
      }
    } else {
      setEmail({ ...email, pristine: false, touched: true });
    }
  };
  return (
    <ForgotPassword
      email={email}
      setEmail={setEmail}
      navigate={navigate}
      forgotPassword={attemptForgotPassword}
      successMessage={successMessage}
      errorMessage={errorMessage}
    />
  );
};

export default ForgotPasswordContainer;
