import Modal from "~/components/Modal";
import React from "react";
import Button from "~/components/Button";
import Typography from "~/components/Typography";
import { IPositionRequest } from "~/components/Tasks";
import Divider from "~/components/Divider";
import formatCurrency from "~/utils/formatCurrency";
import date from "~/utils/dates/date";
import { EmploymentTypes } from "~/utils/enums";

interface Props {
  isOpen: boolean;
  setModal: () => void;
  approveFunction: () => void;
  rejectFunction: () => void;
  request: IPositionRequest;
  createdAt: string;
  isAdmin?: boolean;
}

export const selectColor = (status: string): string => {
  switch (status) {
    case "APPROVED":
      return "green-400";
    case "REJECTED":
      return "red-500";
    case "PENDING":
      return "neutral-200";
    default:
      return "neutral-200";
  }
};

const ViewPositionRequest = ({
  isOpen,
  setModal,
  approveFunction,
  rejectFunction,
  request,
  createdAt,
  isAdmin,
}: Props): React.ReactNode => (
  <Modal title="Requested New Position" isOpen={isOpen} size="xs">
    <div
      data-testid="view-position-request-modal"
      className="w-full flex flex-col gap-4"
    >
      <div className="flex flex-row gap-6">
        <div className="flex flex-col">
          <Typography size="xs">Requested by</Typography>
          <Typography size="sm">{request.requestor}</Typography>
        </div>
        <div className="flex flex-col">
          <Typography size="xs">Date</Typography>
          <Typography size="sm">
            {date(createdAt).format("MM/DD/YYYY")}
          </Typography>
        </div>
        <div className="flex flex-col">
          <Typography size="xs">Status</Typography>
          <Typography
            size="sm"
            className={`text-${selectColor(request.status)}`}
          >
            {request.status}
          </Typography>
        </div>
      </div>
      <div>
        <Divider />
      </div>
      <div className="flex flex-col gap-2">
        <Typography size="sm" weight="semibold">
          Role Details
        </Typography>
        <div className="flex flex-col">
          <Typography size="xs" color="empty">
            Job Title
          </Typography>
          <Typography size="sm">{request.title}</Typography>
        </div>
        {request.manager && (
          <div className="flex flex-col">
            <Typography size="xs" color="empty">
              Manager
            </Typography>
            <Typography size="sm">{request.manager}</Typography>
          </div>
        )}
        {request.group && (
          <div className="flex flex-col">
            <Typography size="xs" color="empty">
              Department
            </Typography>
            <Typography size="sm">{request.group}</Typography>
          </div>
        )}
        <div className="flex flex-col">
          <Typography size="xs" color="empty">
            Hire Date
          </Typography>
          <Typography size="sm">
            {date(request.effectiveAt).format("MM/DD/YYYY")}
          </Typography>
        </div>
      </div>
      <div>
        <Divider />
      </div>
      <div className="flex flex-col gap-2">
        <Typography size="sm" weight="semibold">
          Compensation
        </Typography>
        <div className="flex flex-col">
          <Typography size="xs" color="empty">
            Employment Type
          </Typography>
          <Typography size="sm">
            {
              EmploymentTypes[
                request.employmentType as keyof typeof EmploymentTypes
              ]
            }
          </Typography>
        </div>
        <div className="flex flex-col">
          <Typography size="xs" color="empty">
            Payment Type
          </Typography>
          <Typography size="sm">
            {request.paymentUnit === "HOURLY" ? "Hourly" : "Salary"}
          </Typography>
        </div>
        {request.expectedWeeklyHours && (
          <div className="flex flex-col">
            <Typography size="xs" color="empty">
              Department
            </Typography>
            <Typography size="sm">{request.expectedWeeklyHours}</Typography>
          </div>
        )}
        {request.compensationRate && (
          <div className="flex flex-col">
            <Typography size="xs" color="empty">
              Salary
            </Typography>
            <Typography size="sm">
              {formatCurrency(
                request.compensationRate.toString(),
                request.expectedWeeklyHours ? true : false,
                request.currency || undefined,
              )}
            </Typography>
          </div>
        )}
        {request.group && (
          <div className="flex flex-col">
            <Typography size="xs" color="empty">
              Department
            </Typography>
            <Typography size="sm">{request.group}</Typography>
          </div>
        )}
        {request.bonus && (
          <div className="flex flex-col">
            <Typography size="xs" color="empty">
              Bonus
            </Typography>
            <Typography size="sm">
              {formatCurrency(
                request.bonus,
                false,
                request.currency || undefined,
              )}
            </Typography>
          </div>
        )}
        {request.commission && (
          <div className="flex flex-col">
            <Typography size="xs" color="empty">
              Commission
            </Typography>
            <Typography size="sm">
              {formatCurrency(
                request.commission,
                false,
                request.currency || undefined,
              )}
            </Typography>
          </div>
        )}
        {request.attainment && (
          <div className="flex flex-col">
            <Typography size="xs" color="empty">
              Attainment
            </Typography>
            <Typography size="sm">{request.attainment}</Typography>
          </div>
        )}
      </div>
      <div>
        <Divider />
      </div>
      {request.numberToCreate && (
        <div className="flex flex-col">
          <Typography size="sm" weight="semibold">
            Number of Positions to Create
          </Typography>
          <Typography size="sm">{request.numberToCreate}</Typography>
        </div>
      )}
      <div>
        <Divider />
      </div>
      <div className="flex flex-col gap-2">
        <Typography size="sm" weight="semibold">
          Request Reason
        </Typography>
        <Typography size="xs" className="italic">
          {`"${request.changeDescription}"`}
        </Typography>
      </div>
      <div className="w-full flex flex-row justify-between">
        <Button onClick={setModal} fill="clear" className="!w-fit !px-0">
          Cancel
        </Button>
        {isAdmin && (
          <div className="flex flex-row gap-2">
            <Button
              id="reject-position-request"
              onClick={rejectFunction}
              fill="destructive"
              className="!w-fit"
            >
              Reject
            </Button>
            <Button
              id="approve-position-request"
              onClick={approveFunction}
              className="!w-fit"
            >
              Approve
            </Button>
          </div>
        )}
      </div>
    </div>
  </Modal>
);

export default ViewPositionRequest;
