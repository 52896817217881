import { IListExpenseModelsResponse } from "~/pages/AllExpenseModels";
import { store } from "~/store";
import { IExpense } from "~/types/expenseModel/types";
import request from "~/utils/request";
import waitForStoreRehydration from "~/utils/waitForStoreRehydration";

export default async (): Promise<IExpense[]> => {
  await waitForStoreRehydration(store);

  const {
    organization: { uuid: organizationUuid },
  } = store.getState();

  const {
    data: { data: expenseModels },
  } = (await request({
    url: `/organizations/${organizationUuid}/expense-models`,
    method: "GET",
  })) as IListExpenseModelsResponse;

  return expenseModels;
};
