import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { IForecastRequest, TaskInterface } from "~/components/Tasks";
import { State } from "~/store";
import request from "~/utils/request";
import { tasksSlice } from "~/store/tasksSlice";
import permissionsCheck from "~/utils/permissionsCheck";
import Button from "~/components/Button";
import DeleteRequest from "~/components/Tasks/DeleteRequest";
import ViewForecastRequest from "~/components/Tasks/ViewRequest/ViewForecastRequest";
import RejectRequest from "~/components/Tasks/ViewRequest/RejectRequest";
import useRejectionState from "~/components/Tasks/ViewRequest/useRejectionState";
import Typography from "~/components/Typography";
import { toast } from "react-hot-toast";
import "./positionDetails.css";
import { Position } from "../headcount.types";

const PositionRequest = ({
  positionData,
  requestInformation,
  task,
  successCallback,
}: {
  positionData: Position;
  requestInformation: IForecastRequest;
  task: TaskInterface;
  successCallback: () => void;
}): React.ReactNode => {
  const dispatch = useDispatch();
  const [displayDeleteRequest, setDisplayDeleteRequest] = useState(false);
  const [displayViewRequest, setDisplayViewRequest] = useState(false);
  const [displayRejectRequest, setDisplayRejectRequest] = useState(false);
  const organizationUuid = useSelector(
    (state: State) => state.organization.uuid,
  );
  const {
    name: userName,
    uuid: userUuid,
    permissions: { role, departmentAccessList },
  } = useSelector((state: State) => state.user);
  const [isCompleted, setIsCompleted] = useState(
    (requestInformation.requestorUuid === userUuid &&
      !!requestInformation.deletedBy) ||
      ((requestInformation.requestorUuid !== userUuid &&
        requestInformation.status) === "WITHDRAWN" &&
        task.completedAt !== null),
  );
  const { total } = useSelector((state: State) => state.tasks);
  const { reason, setReason, resetFormState } = useRejectionState();
  const isAdmin = permissionsCheck(role, departmentAccessList);
  const isWithdrawn = requestInformation.status === "WITHDRAWN";

  const isRespondedTo =
    requestInformation.status !== "PENDING" &&
    requestInformation.status !== "WITHDRAWN" &&
    task.completedAt !== null;

  if (!requestInformation) {
    return null;
  }
  const handleDeleteRequest = async (): Promise<void> => {
    const taskCompletionResponse = await request({
      method: "DELETE",
      url: `/organizations/${organizationUuid}/requests/${task.uuid}`,
    });

    if (taskCompletionResponse.status >= 400)
      throw Error("Error Withdrawning Request");

    if (isAdmin) {
      toast.success("Request Dismissed Successfully");
    } else {
      toast.success("Request Withdrawn Successfully");
    }
    setDisplayDeleteRequest(false);
    // Wait for transition animation to run before setting it to completed
    setTimeout(() => {
      setIsCompleted(true);
    }, 300);

    if (isAdmin) {
      dispatch(tasksSlice.actions.update({ total: total - 1 }));
    } else if (
      taskCompletionResponse.data.data.metadata.requestPositionForecast
        .status === "WITHDRAWN"
    ) {
      dispatch(tasksSlice.actions.update({ total: total - 1 }));
    }
  };

  const handleStatus = (
    status: string,
  ): {
    color: string;
    message: string;
  } => {
    switch (status) {
      case "PENDING":
        return { color: "neutral-200", message: "Pending" };
      case "APPROVED":
        return { color: "green-400", message: "Approved" };
      case "REJECTED":
        return { color: "red-500", message: "Rejected" };
      default:
        return { color: "neutral-200", message: "Pending" };
    }
  };

  const determineStatusMessage = ((): string => {
    if (isAdmin) {
      if (isWithdrawn) {
        return `Withdrawn by ${requestInformation.requestor}`;
      }
      return `Requested by ${requestInformation.requestor}`;
    }
    // Default status handling for non-admin users
    return handleStatus(requestInformation.status || "PENDING").message;
  })();

  const determineStatusColor = ((): string => {
    if (isAdmin) {
      if (isWithdrawn) {
        return "neutral-400";
      }
      return "neutral-800";
    }
    return handleStatus(requestInformation.status || "PENDING").color;
  })();

  const approveRequest = async (): Promise<void> => {
    const taskCompletionResponse = await request({
      method: "POST",
      url: `/organizations/${organizationUuid}/requests/${task.uuid}/approve`,
      body: { requestType: "forecast", requestData: requestInformation },
    });

    if (taskCompletionResponse.status >= 400)
      throw Error("Error Approving Request");

    toast.success("Request Approved Successfully");
    dispatch(tasksSlice.actions.update({ total: total - 1 }));
    setDisplayViewRequest(false);

    // Wait for transition animation to run before setting it to completed
    setTimeout(() => {
      setIsCompleted(true);
    }, 300);
  };

  const rejectRequest = async (): Promise<void> => {
    const taskCompletionResponse = await request({
      method: "POST",
      url: `/organizations/${organizationUuid}/requests/${task.uuid}/reject`,
      body: {
        rejectionReason: reason.value,
      },
    });

    if (taskCompletionResponse.status >= 400)
      throw Error("Error Rejecting Request");

    toast.success("Request Rejected Successfully");
    setDisplayViewRequest(false);
    setDisplayRejectRequest(false);
    resetFormState();
    dispatch(tasksSlice.actions.update({ total: total - 1 }));

    // Wait for transition animation to run before setting it to completed
    setTimeout(() => {
      setIsCompleted(true);
    }, 300);
  };

  const handleButtons = ((): JSX.Element => {
    if (requestInformation.status === "REJECTED") {
      return (
        <div className="flex flex-row gap-4">
          <Button
            onClick={() => {
              setDisplayRejectRequest(true);
            }}
          >
            View Response
          </Button>
        </div>
      );
    }

    return <Button onClick={handleDeleteRequest}>Dismiss</Button>;
  })();

  return (
    <Transition
      show={!isCompleted}
      as={React.Fragment}
      enter="transition ease-in duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-in duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      afterLeave={successCallback}
    >
      <div
        data-testid={`position-request-${task.uuid}`}
        className={`flex flex-row justify-between py-4 px-6 border border-neutral-50 rounded-lg w-3/4 min-w-[388px] ${
          task.completedBy ? "opacity-50" : ""
        }${isWithdrawn ? " bg-neutral-25" : "bg-white"}`}
      >
        <DeleteRequest
          isOpen={displayDeleteRequest}
          setModal={() => setDisplayDeleteRequest(false)}
          submitFunction={handleDeleteRequest}
        />
        <ViewForecastRequest
          isOpen={displayViewRequest}
          setModal={() => setDisplayViewRequest(false)}
          position={positionData}
          request={requestInformation}
          approveFunction={approveRequest}
          rejectFunction={() => {
            setDisplayViewRequest(false);
            setDisplayRejectRequest(true);
          }}
          createdAt={task.createdAt}
          isAdmin={isAdmin}
        />
        <RejectRequest
          isOpen={displayRejectRequest}
          backFunction={() => {
            if (isAdmin) {
              setDisplayRejectRequest(false);
              setDisplayViewRequest(true);
              resetFormState();
            }
            setDisplayRejectRequest(false);
          }}
          reason={reason}
          setReason={setReason}
          submitFunction={isAdmin ? rejectRequest : handleDeleteRequest}
          isAdmin={isAdmin}
          task={task}
          rejectReason={requestInformation.rejectionReason}
        />
        <div key={task.uuid} className="flex flex-col">
          <Typography
            id="unassigned-employee-task-title"
            tag="h3"
            size="md"
            weight="semibold"
            className="mb-1"
          >
            Future Position Modification
          </Typography>
          <Typography className={`text-${determineStatusColor} font-normal`}>
            {determineStatusMessage}
          </Typography>
        </div>
        {isWithdrawn || isRespondedTo ? (
          <div className="col-span-2 flex flex-row gap-4 items-center max-xl:flex-col">
            {handleButtons}
          </div>
        ) : (
          <div className="col-span-2 flex flex-row gap-4 items-center max-xl:flex-col">
            <Button
              fill="destructiveOutline"
              onClick={() => {
                if (requestInformation.requestor === userName) {
                  setDisplayDeleteRequest(true);
                }
              }}
              className={`"!w-auto max-xl:!w-full ${isAdmin ? "hidden" : ""}`}
            >
              Delete
            </Button>
            <Button onClick={() => setDisplayViewRequest(true)}>View</Button>
          </div>
        )}
      </div>
    </Transition>
  );
};

export default PositionRequest;
