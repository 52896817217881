import { SelectState, SelectType } from "~/components/Select/Select.types";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import Typography from "~/components/Typography";
import React, { Fragment } from "react";
import useSelect from "./useSelect";
import { v4 } from "uuid";

interface Props {
  id: string;
  state: SelectState;
  setState: React.Dispatch<React.SetStateAction<SelectState>>;
  label?: string;
  className?: string;
  errorMessage?: string;
  placeholder?: string;
  disabled?: boolean;
  checkmark?: boolean;
  direction?: "below" | "above";
  required?: boolean;
  listBoxClassName?: string;
}

const Select = ({
  id,
  label,
  state,
  setState,
  className,
  errorMessage = "Please make a selection",
  placeholder = "Select an option",
  disabled = false,
  checkmark = true,
  direction = "below",
  required = false,
  listBoxClassName = "",
}: Props): React.ReactNode => {
  const showError = state.touched && !state.pristine && !state.valid;

  let spanClass = "truncate block truncate text-base";
  if (state.disabled) {
    spanClass += " text-neutral-75";
  } else {
    spanClass += " text-neutral-900";
  }

  return (
    <div className={`w-full ${className ?? ""}`} data-testid={id}>
      {label && (
        <div className="flex flex-row mb-1">
          <Typography
            tag="span"
            size="xs"
            className={`${state.disabled ? " !text-neutral-75" : ""}`}
          >
            {label}
          </Typography>
          {required && (
            <Typography
              tag="span"
              size="2xs"
              className={`${state.disabled ? " !text-neutral-75" : ""}`}
            >
              *
            </Typography>
          )}
        </div>
      )}
      <Listbox
        disabled={state.disabled ?? disabled}
        value={state.selected}
        onChange={(selections: SelectType) => {
          const selected = state.options.find(
            (option) => option.value === selections.value,
          );

          if (selected)
            setState((prevState) => ({
              ...prevState,
              valid: true,
              pristine: false,
              touched: true,
              selected,
            }));
        }}
      >
        <div className="relative">
          <Listbox.Button
            data-testid={`${id}-button`}
            className={`relative w-full max-w-full truncate cursor-default border border-solid ${
              showError ? "border-red-300" : "border-gray-300"
            } 
            ${className ?? ""}
            ${!state.selected?.value && "!text-neutral-75"}
            rounded shadow-sm bg-white disabled:bg-neutral-25 disabled:text-neutral-75 py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-green-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-green-300 sm:text-sm`}
          >
            <span data-testid={`${id}-selection`} className={spanClass}>
              {state.selected?.label ?? placeholder}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className={`${
                direction === "below" ? "mt-1" : "mt-[-286px]"
              } absolute max-h-60 z-20 rounded w-full overflow-auto bg-white text-base shadow-sm ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ${listBoxClassName}`}
            >
              {state.options.map((option) => {
                const isSelected = option.value === state.selected?.value;

                if (option.value === "divider") {
                  return (
                    <Listbox.Option
                      key={`${option.value}-${v4()}`}
                      disabled={!!true}
                      className="relative cursor-default select-none py-2 pl-3 pr-4 text-xs"
                      value={option}
                    >
                      <hr className="border-gray-300" />
                    </Listbox.Option>
                  );
                }

                return (
                  <Listbox.Option
                    data-testid={`${id}-option-${option.value}`}
                    key={`${option.value}-${v4()}`}
                    disabled={option.disabled}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2${
                        checkmark ? " pl-10" : " pl-3"
                      } pr-4${active ? " bg-green-100 text-green-900" : ""}${
                        isSelected ? " text-green-300" : " text-gray-900"
                      }${option.disabled ? " !text-neutral-75" : ""}`
                    }
                    value={option}
                  >
                    <span
                      className={`block truncate ${
                        option.value ? "font-medium" : "font-normal"
                      }`}
                    >
                      <Typography
                        tag="span"
                        size="xs"
                        className={`block truncate ${
                          option.value ? "font-medium" : "font-normal"
                        }`}
                      >
                        {option.node ?? option.label}
                      </Typography>
                    </span>
                    {isSelected && checkmark && (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-green-600">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </Listbox.Option>
                );
              })}
            </Listbox.Options>
          </Transition>
          {showError && (
            <p
              className="text-red-500 text-xs italic p-1"
              data-testid={`${id}-select-error`}
            >
              {errorMessage}
            </p>
          )}
        </div>
      </Listbox>
    </div>
  );
};

export { useSelect };
export default Select;
