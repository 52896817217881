import React from "react";
import Select from "~/components/Select";
import { SelectState } from "~/components/Select/Select.types";
import Typography from "~/components/Typography";
import getCurrencyInputLabel from "~/utils/getCurrencyInputLabel";
import CurrencyInput from "../../PositionForm/CurrencyInput";
import InputState = Types.InputState;

const CompensationForm = ({
  employmentType,
  setEmploymentType,
  selectedPaymentType,
  setSelectedPaymentType,
  paymentAmountState,
  setPaymentAmountState,
  currencyTypeState,
  setCurrencyTypeState,
}: {
  employmentType: SelectState;
  setEmploymentType: React.Dispatch<React.SetStateAction<SelectState>>;
  selectedPaymentType: SelectState;
  setSelectedPaymentType: React.Dispatch<React.SetStateAction<SelectState>>;
  paymentAmountState: InputState;
  setPaymentAmountState: React.Dispatch<React.SetStateAction<InputState>>;
  currencyTypeState: SelectState;
  setCurrencyTypeState: React.Dispatch<React.SetStateAction<SelectState>>;
}): React.ReactNode => (
  <div className="flex flex-col gap-3">
    <Typography size="sm" weight="semibold">
      Compensation
    </Typography>
    <div className="w-full flex flex-col gap-3">
      <Select
        id="employmentTypeSelect"
        label="Employment Type"
        state={employmentType}
        setState={setEmploymentType}
      />
      <Select
        id="paymentTypeSelect"
        label="Payment Type"
        state={selectedPaymentType}
        setState={setSelectedPaymentType}
      />
      <CurrencyInput
        paymentAmountState={paymentAmountState}
        currencyTypeState={currencyTypeState}
        setPaymentAmountState={setPaymentAmountState}
        setCurrencyTypeState={setCurrencyTypeState}
        label={getCurrencyInputLabel(selectedPaymentType)}
        id="paymentAmount"
      />
    </div>
  </div>
);

export default CompensationForm;
