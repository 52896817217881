import React, { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import Typography from "~/components/Typography";
import { usePopper } from "react-popper";
import useHover from "~/utils/hooks/useHover";

const ExplainManualFieldsPopover = (): React.ReactNode => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [ref, hovering] = useHover();

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start", // or 'top-start'
  });

  return (
    <div ref={ref}>
      <Popover className="relative">
        <Popover.Button
          ref={setReferenceElement}
          style={{ pointerEvents: "none" }}
        >
          <InformationCircleIcon className="h-4 w-4 svg-crisp stroke-[1.5px] stroke-gray-400" />
        </Popover.Button>
        <Transition
          show={Boolean(hovering)}
          as={Fragment}
          enter="transition-opacity ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-in duration-50"
          leaveTo="opacity-0"
        >
          <Popover.Panel
            ref={setPopperElement}
            style={{ ...styles.popper, zIndex: 20, position: "fixed" }}
            {...attributes.popper}
          >
            <div
              data-testid="import-error-popover"
              className="flex flex-col max-w-[360px] z-20 bg-white py-4 px-6 rounded-lg shadow gap-2"
            >
              <Typography size="xs">
                Any adjustments will be accepted as actuals and will not be
                reconciled with your HRIS
              </Typography>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
};

export default ExplainManualFieldsPopover;
