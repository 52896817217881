import { SelectState, SelectType } from "~/components/Select/Select.types";

export default (paymentTypeState: SelectState) => {
  const selectedType = paymentTypeState.selected as SelectType;

  switch (selectedType.value) {
    case "SALARY":
      return "Annual Salary";
    case "HOURLY":
      return "Hourly Rate";
    default:
      return "Unknown Payment Type";
  }
};
