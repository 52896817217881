import useInput from "~/components/Input/useInput";
import { State } from "~/store";
import { authSlice } from "~/store/authSlice";
import { organizationSlice } from "~/store/organizationSlice";
import { userSlice } from "~/store/userSlice";
import { emailValidator, passwordValidator } from "~/utils/validators";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import request from "~/utils/request";
import { scenarioSlice } from "~/store/scenarioSlice";
import useQueryParams from "~/utils/hooks/useQueryParams";
import Login from "./Login";

export interface IExchangeOtpForTokensResponse {
  status: number;
  data: {
    data?: {
      accessToken: string;
      refreshToken: string;
    };
  };
}

export const requestTokens = async ({
  methodType,
  email,
  password,
}: {
  methodType: string;
  email: string;
  password?: string;
}) => {
  const payload =
    methodType === "standard"
      ? {
          email: email,
          password: password,
        }
      : {
          email: email,
        };

  return request({
    url: "/auth/tokens",
    method: "POST",
    body: {
      method: methodType,
      payload,
    },
  });
};

const LoginContainer = (): React.ReactNode => {
  const navigate = useNavigate();
  const [queryParams] = useQueryParams();
  const redirectUrl = queryParams.get("redirectUrl");
  const [accessToken, setAccessToken] = useState<string>("");
  const [refreshToken, setRefreshToken] = useState<string>("");
  const firstLogin = queryParams.get("firstLogin");
  const oneTimePasscode = queryParams.get("otp");
  const emailValue = queryParams.get("emailValue");
  let method = queryParams.get("method") ?? "passwordless";
  if (import.meta.env.VITE_NODE_ENV === "local" && !queryParams.get("method")) {
    method = "standard";
  }
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state: State) => state.auth);
  const { uuid: userUuid, permissions } = useSelector(
    (state: State) => state.user,
  );
  const [errorMessage, setErrorMessage] = React.useState("");
  const [email, setEmail] = useInput({
    validation: emailValidator,
    errorMessage: "Please enter a valid email",
    value: import.meta.env.VITE_NODE_ENV === "local" ? "@getparallel.com" : "",
  });
  const [password, setPassword] = useInput({
    validation: passwordValidator,
    errorMessage:
      "Please enter a valid password (8 characters, a number, and a special character)",
    value: import.meta.env.VITE_NODE_ENV === "local" ? "Password123!" : "",
    pristine: import.meta.env.VITE_NODE_ENV !== "local",
    valid: import.meta.env.VITE_NODE_ENV === "local",
  });

  useEffect(() => {
    if (!isLoggedIn) return;
    const getUser = async (): Promise<void> => {
      const userResponse = await request({
        url: "/users/me",
        method: "GET",
      });

      dispatch(
        userSlice.actions.update({
          uuid: userResponse.data.data.uuid,
          name: userResponse.data.data.name,
          email: userResponse.data.data.email,
          preferences: userResponse.data.data.preferences,
          permissions: userResponse.data.data.permissions[0],
        }),
      );
      dispatch(scenarioSlice.actions.reset());
      const primaryOrg = userResponse.data.data.organizations.find(
        (org: { uuid: string }) =>
          org.uuid ===
          userResponse.data.data.preferences.primaryOrganizationUuid,
      );
      dispatch(organizationSlice.actions.update(primaryOrg));
    };
    getUser();
  }, [isLoggedIn]);

  useEffect(() => {
    setErrorMessage("");
  }, [email.value, password.value]);

  useEffect(() => {
    if (accessToken && refreshToken) {
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      dispatch(authSlice.actions.login(accessToken));
    }
  }, [accessToken, refreshToken, dispatch, navigate, method]);

  const attemptLogin = async () => {
    if (
      (method === "standard" && email.valid && password.valid) ||
      (method === "passwordless" && email.valid)
    ) {
      const { data, status } = await requestTokens({
        methodType: method,
        email: email.value,
        password: password.value,
      });

      if (status >= 400)
        setErrorMessage(
          "Unable to log in. Please try again or contact support.",
        );

      if (method === "standard") {
        localStorage.setItem("accessToken", data.data.accessToken);
        localStorage.setItem("refreshToken", data.data.refreshToken);
        dispatch(authSlice.actions.login(data.data.accessToken));
      }

      if (status < 400 && method === "passwordless") {
        navigate(`/auth/link-sent?emailValue=${email.value}`);
      }
    } else {
      setEmail({ ...email, touched: true, pristine: false });
      setPassword({ ...password, touched: true, pristine: false });
    }
  };

  const exchangeOtpForTokens = async ({
    oneTimePasscode,
    email,
  }: {
    oneTimePasscode: string;
    email: string;
  }): Promise<void> => {
    const response = (await request({
      url: `/auth/otp`,
      method: "POST",
      body: {
        oneTimePasscode,
        email,
      },
    })) as IExchangeOtpForTokensResponse;

    if (response.status === 200 && response.data.data) {
      setAccessToken(response.data.data.accessToken);
      setRefreshToken(response.data.data.refreshToken);
    } else {
      setErrorMessage("The link you used has exipred or is invalid");
    }
  };

  useEffect(() => {
    if (oneTimePasscode && emailValue) {
      exchangeOtpForTokens({
        oneTimePasscode: oneTimePasscode,
        email: emailValue,
      });
    }
  }, [oneTimePasscode, emailValue]);

  if (isLoggedIn && userUuid) {
    const changeUrl =
      permissions.role === "admin"
        ? `/onboarding${firstLogin ? "?firstLogin=true" : ""}`
        : `/dashboard${firstLogin ? "?firstLogin=true" : ""}`;
    return <Navigate to={redirectUrl ?? changeUrl} replace />;
  }

  return (
    <div>
      {firstLogin ? null : (
        <Login
          email={email}
          setEmail={setEmail}
          usePassword={method === "standard"}
          password={password}
          setPassword={setPassword}
          login={attemptLogin}
          errorMessage={errorMessage}
        />
      )}
    </div>
  );
};

export default LoginContainer;
