import bamboohr from "./bamboohr.jpg";
import freshbooks from "./freshbooks.jpg";
import greenhouse from "./greenhouse.jpg";
import quickbooks from "./quickbooks.png";
import rippling from "./rippling.jpg";
import talentReef from "./talent-reef.png";
import ukg from "./ukg.png";
import wave from "./wave.png";
import workday from "./workday.jpg";
import gsheets from "./gsheets.png";
import excel from "./excel.png";
import gmailLogo from "./gmail.png";
import outlookLogo from "./outlook.png";

export default {
  bamboohr,
  rippling,
  ukg,
  greenhouse,
  workday,
  talentReef,
  freshbooks,
  quickbooks,
  wave,
  gsheets,
  excel,
  gmailLogo,
  outlookLogo,
};
