import React, { useEffect, useState } from "react";
import { TaskInterface } from "~/components/Tasks";
import date from "~/utils/dates/date";
import Typography from "~/components/Typography";
import Button from "~/components/Button";
import formatCurrency from "~/utils/formatCurrency";
import useQueryParams from "~/utils/hooks/useQueryParams";
import { Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "~/store";
import { tasksSlice } from "~/store/tasksSlice";
import AssignEmployee from "../../pages/Headcount/AssignEmployee";

interface Props {
  task: TaskInterface;
}

const UnassignedEmployeeTask = ({ task }: Props): React.ReactNode => {
  const dispatch = useDispatch();
  const { total } = useSelector((state: State) => state.tasks);

  const [isCompleted, setIsCompleted] = useState(task.completedAt !== null);
  const [isLoading, setIsLoading] = useState(false);

  const [queryParams, setQueryParams] = useQueryParams();
  const [assignEmployeeUuid, setAssignEmployeeUuid] = useState(
    queryParams.get("assignEmployeeUuid") ?? null,
  );

  useEffect(() => {
    const newQueryParams: {
      assignEmployeeUuid?: string;
    } = {};
    if (assignEmployeeUuid) {
      newQueryParams.assignEmployeeUuid = assignEmployeeUuid;
    }

    setQueryParams(newQueryParams);
  }, [assignEmployeeUuid]);

  const closeTask = async (): Promise<void> => {
    dispatch(tasksSlice.actions.update({ total: total - 1 }));

    // Wait for transition animation to run before setting it to completed
    setTimeout(() => {
      setIsCompleted(true);
    }, 300);
  };

  let paymentUnit = "-";
  if (task.metadata.paymentUnit) {
    paymentUnit = task.metadata.paymentUnit === "HOURLY" ? "Hourly" : "Salary";
  }

  const compensationRate = task.metadata.compensationRate
    ? formatCurrency(
        task.metadata.compensationRate,
        task.metadata.paymentUnit === "HOURLY" ? true : false,
        task.metadata.currency ?? undefined,
      )
    : "-";

  return (
    <Transition
      show={!isCompleted}
      as={React.Fragment}
      enter="transition ease-in duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-in duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        id={`unassigned-employee-task-${task.uuid}`}
        className={`flex flex-row justify-between py-4 px-6 border border-neutral-50 rounded-lg bg-white w-full ${
          task.completedBy ? "opacity-50" : ""
        }`}
      >
        <AssignEmployee
          isOpen={!!assignEmployeeUuid}
          setModal={() => setAssignEmployeeUuid(null)}
          employeeUuid={assignEmployeeUuid}
          employeeData={task.metadata}
          successCallback={() => {
            setAssignEmployeeUuid(null);
            closeTask();
          }}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          modalSource="AssignEmployee"
        />
        <div key={task.uuid} className="col-span-7 flex flex-col items-start">
          <Typography
            id="unassigned-employee-task-title"
            tag="h3"
            size="md"
            weight="semibold"
            className="mb-1"
          >
            New Employee: Unassigned
          </Typography>
          <div
            data-testid={`unassigned-employee-metadata-${task.uuid}`}
            className="flex flex-row justify-between gap-6"
          >
            <div className="flex flex-col">
              <Typography size="xs" color="secondary">
                Name
              </Typography>
              <Typography size="xs" className="max-w-32 truncate">
                {task.metadata.employeeName ?? "-"}
              </Typography>
            </div>
            <div className="flex flex-col">
              <Typography size="xs" color="secondary">
                Department
              </Typography>
              <Typography size="xs" className="max-w-32 truncate">
                {task.metadata.department ?? "-"}
              </Typography>
            </div>
            <div className="flex flex-col">
              <Typography size="xs" color="secondary">
                Start Date
              </Typography>
              <Typography size="xs" className="max-w-32 truncate">
                {task.metadata.startDate
                  ? date(task.metadata.startDate).format("MM/DD/YYYY")
                  : "-"}
              </Typography>
            </div>
            <div className="max-xl:hidden flex flex-col">
              <Typography size="xs" color="secondary">
                Payment Type
              </Typography>
              <Typography size="xs" className="max-w-32 truncate">
                {paymentUnit}
              </Typography>
            </div>
            <div className="flex flex-col">
              <Typography size="xs" color="secondary">
                Compensation
              </Typography>
              <Typography size="xs" className="max-w-32 truncate">
                {compensationRate}
              </Typography>
            </div>
          </div>
        </div>
        <div className="col-span-2 flex flex-row gap-4 items-center max-xl:flex-col">
          <Button
            className="!w-auto max-xl:!w-full"
            id={`assign-employee-modal-button-${task.uuid}`}
            onClick={() => {
              setAssignEmployeeUuid(task.metadata.employeeUuid ?? null);
            }}
          >
            Assign to Position
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default UnassignedEmployeeTask;
