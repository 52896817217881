import Select, { useSelect } from "~/components/Select";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { State } from "~/store";
import request from "~/utils/request";
import { IDepartment, IDepartmentHierarchy } from "~/types/department/types";

const collectChildrenGroups = (
  departments: IDepartmentHierarchy[],
): string[] => {
  const output: string[] = [];
  departments.forEach((department) => {
    output.push(department.uuid);
    if (department.childDepartments.length) {
      const uuids = collectChildrenGroups(department.childDepartments);
      output.push(...uuids);
    }
  }, []);

  return output;
};

export interface DepartmentProps {
  onUpdate: () => void;
  allDepartments: IDepartment[];
  department: IDepartmentHierarchy;
}

const DepartmentListItem: React.FC<DepartmentProps> = ({
  onUpdate,
  allDepartments,
  department,
}) => {
  const parentDefault = {
    label: "No Parent",
    value: "none",
  };
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );

  const [departmentParent, setDepartmentParent] = useSelect({});
  const prevSelectionRef = useRef(departmentParent.selected);

  useEffect(() => {
    const childrenGroups = collectChildrenGroups([department]);
    const options = [
      parentDefault,
      ...allDepartments
        .map((departmentOption) => ({
          label: departmentOption.name,
          value: departmentOption.uuid,
        }))
        .filter(
          (departmentOption) =>
            !childrenGroups.includes(departmentOption.value),
        ),
    ];

    setDepartmentParent((prevState) => ({
      ...prevState,
      options,
      selected: allDepartments.reduce((output, selectedDepartmentOption) => {
        if (selectedDepartmentOption.uuid === department.parentUuid) {
          return {
            label: selectedDepartmentOption.name,
            value: selectedDepartmentOption.uuid,
          };
        }
        return output;
      }, parentDefault),
    }));
  }, [department]);

  useEffect(() => {
    const updateParent = async (): Promise<void> => {
      const selection = departmentParent.selected?.value;
      await request({
        url: `/organizations/${organizationUuid}/groups/${department.uuid}`,
        method: "PATCH",
        body: {
          parentUuid: selection !== parentDefault.value ? selection : null,
        },
      });
      onUpdate();
    };
    if (prevSelectionRef.current?.value !== departmentParent.selected?.value) {
      prevSelectionRef.current = departmentParent.selected;
      updateParent();
    }
  }, [departmentParent.selected?.value]);

  return (
    <div className="w-full">
      <div className="bg-green-25 p-2 pl-5 w-full rounded flex justify-between items-center">
        <div>{department.name}</div>
        <Select
          id={`select-parent-for-${department.uuid}`}
          className="!w-[200px]"
          state={departmentParent}
          setState={setDepartmentParent}
        />
      </div>
      {department.childDepartments.length > 0 && (
        <div className="ml-5 mt-5">
          {department.childDepartments.map((nestedDepartment) => (
            <div key={nestedDepartment.uuid} className="mt-5">
              <DepartmentListItem
                key={nestedDepartment.uuid}
                onUpdate={onUpdate}
                allDepartments={allDepartments}
                department={nestedDepartment}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DepartmentListItem;
