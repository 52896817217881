import Header from "~/components/Header";
import SegmentedControl from "~/components/SegmentedControl";
import Table, { TableData } from "~/components/Table";
import { State } from "~/store";
import formatCurrency from "~/utils/formatCurrency";
import request from "~/utils/request";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import date from "~/utils/dates/date";
import { Employee, Position } from "pages/Headcount/headcount.types";
import Select from "~/components/Select";
import emptyVarianceStateIllustration from "~/assets/emptyVarianceStateIllustration.svg";
import Typography from "~/components/Typography";
import DateRange from "~/components/DateRange";
import { format } from "date-fns";
import ExportData from "~/components/ExportData";
import formatItemizedVariancesForCSVExport from "~/utils/formatItemizedVariancesForCSVExport";
import formatDepartmentVariancesForCSVExport from "~/utils/formatDepartmentVariancesForCSVExport";
import BudgetCard from "../Dashboard/BudgetCard";
import SelectDepartment from "~/components/SelectDepartment";
import {
  DashboardContext,
  DashboardProvider,
} from "../Dashboard/context/DashboardContext";

type SelectedViewType = "itemized" | "department";

interface VariancesResponse {
  status: number;
  data: {
    data?: {
      variances: VarianceData[];
    };
  };
}

interface VarianceData {
  groupUuid: string;
  groupName: string;
  variances: SingleVariance[];
  totalUniqueRoles: number;
  totalImpact: number;
}

interface SingleVariance {
  variantType: "expense" | "position";
  uuid: string;
  variantA?: Position["current"] | undefined;
  variantB?: Position["current"] | undefined;
  amount: number;
  modification: string;
  name: string;
  title: string;
  employee: Employee | null | undefined;
  effectiveDate: string;
}

const VariancesContentContainer = (): React.ReactNode => {
  /**
   * Store
   */
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const dashboardContext = React.useContext(DashboardContext);
  if (!dashboardContext) {
    throw new Error("Dashboard page missing data to provide context");
  }
  const {
    departmentOptions,
    setDepartmentOptions,
    planOptions,
    setPlanOptions,
    periodDates,
    setPeriodDates,
  } = dashboardContext;
  /**
   * Component state
   */
  const controllerRef = useRef<AbortController>();
  const [isLoading, setIsLoading] = useState(true);

  const [pageData, setPageData] = useState<{
    roleVariance: TableData[];
    departmentVariance: TableData[];
  }>();
  const [selectedView, setSelectedView] =
    useState<SelectedViewType>("itemized");

  const buildRoleVarianceData = (
    departmentVariances: VarianceData[],
  ): TableData[] => {
    let totalImpact = 0;
    const tableData = departmentVariances.flatMap(({ groupName, variances }) =>
      variances.map((variance) => {
        totalImpact += variance.amount;

        return {
          id: (variance.variantA?.uuid ?? variance.variantB?.uuid) as string,
          exportLabel: groupName,
          values: [
            { value: variance.employee?.employeeNumber ?? "-" },
            { value: variance.name || "-" },
            { value: variance.title || "-" },
            { value: groupName },
            { value: variance.modification },
            { value: date(variance.effectiveDate).format("MM/DD/YY") },
            { value: formatCurrency(variance.amount, false) },
          ],
        };
      }),
    );
    if (tableData.length === 0) {
      return [...tableData];
    }
    return [
      ...tableData,
      {
        id: "totals",
        exportLabel: "Total",
        values: [
          { value: "Total" },
          { value: "" },
          { value: "" },
          { value: "" },
          { value: "" },
          { value: "" },
          { value: formatCurrency(totalImpact, false) },
        ],
      },
    ];
  };

  const buildDepartmentVarianceData = (
    variances: VarianceData[],
  ): TableData[] => {
    let totalOrgUniqueRoles = 0;
    let totalOrgImpact = 0;
    const tableData = variances.map((departmentVariance) => {
      totalOrgUniqueRoles += departmentVariance.totalUniqueRoles;
      totalOrgImpact += departmentVariance.totalImpact;
      return {
        id: departmentVariance.groupUuid,
        values: [
          { value: departmentVariance.groupName },
          { value: departmentVariance.totalUniqueRoles },
          { value: formatCurrency(departmentVariance.totalImpact, false) },
        ],
      };
    });
    return [
      ...tableData,
      {
        id: "totals",
        exportLabel: "Total",
        values: [
          {
            value: "Total",
          },
          { value: totalOrgUniqueRoles },
          { value: formatCurrency(totalOrgImpact, false) },
        ],
      },
    ];
  };

  useEffect(() => {
    const getVariances = async (): Promise<void> => {
      try {
        if (controllerRef.current) controllerRef.current.abort();
        controllerRef.current = new AbortController();
        const { signal } = controllerRef.current;
        setIsLoading(true);
        const variancesResponse = (await request({
          url: `/organizations/${organizationUuid}/variances`,
          method: "GET",
          params: {
            planUuid: planOptions.selected?.value,
            startDate: format(periodDates.startDate, "yyyy-MM-dd"),
            endDate: format(periodDates.endDate, "yyyy-MM-dd"),
            departments: departmentOptions.selected?.value
              ? [departmentOptions.selected.value]
              : undefined,
          },
          signal,
        })) as VariancesResponse;

        if (variancesResponse.status === 200 && variancesResponse.data.data) {
          const rawVarianceData = variancesResponse.data.data.variances;
          setPageData({
            roleVariance: buildRoleVarianceData(rawVarianceData),
            departmentVariance: buildDepartmentVarianceData(rawVarianceData),
          });
          if (rawVarianceData.length !== 0) {
            setIsLoading(false);
          }
        }
      } catch (error: unknown) {
        if (error.name !== "CanceledError") {
          setIsLoading(false);
          console.error("Error fetching variances:", error);
        }
      }
    };

    if (
      planOptions.options.length > 0 &&
      departmentOptions.options.length > 1 &&
      departmentOptions.selected &&
      planOptions.selected
    ) {
      getVariances();
    } else if (!planOptions.isLoading) {
      setIsLoading(false);
    }
  }, [
    organizationUuid,
    departmentOptions.options.length,
    planOptions.options.length,
    planOptions.selected,
    periodDates.startDate,
    periodDates.endDate,
    departmentOptions.selected,
    planOptions.isLoading,
  ]);

  const csvToExport =
    selectedView === "itemized"
      ? (formatItemizedVariancesForCSVExport(pageData?.roleVariance).reduce(
          (output, position, index) => {
            if (index === 0) {
              output.push(Object.keys(position));
            }
            output.push(Object.values(position));
            return output;
          },
          [] as (string | number | boolean | React.ReactNode)[],
        ) as unknown[][])
      : (formatDepartmentVariancesForCSVExport(
          pageData?.departmentVariance,
        ).reduce(
          (output, position, index) => {
            if (index === 0) {
              output.push(Object.keys(position));
            }
            output.push(Object.values(position));
            return output;
          },
          [] as (string | number | boolean | React.ReactNode)[],
        ) as unknown[][]);

  return (
    <div>
      <Header
        title="Variances"
        breadCrumbs={[{ label: "Dashboard", url: "/dashboard" }]}
      >
        <div className="flex gap-5">
          <SelectDepartment
            departmentOptions={departmentOptions}
            setDepartmentOptions={setDepartmentOptions}
            allowSelectAll
            className="!w-[250px]"
            listBoxClassName="!max-w-[400px] !w-full"
          />
          <Select
            id="select-plan"
            className="!w-[200px]"
            state={planOptions}
            placeholder="No Locked Plans"
            setState={setPlanOptions}
            disabled={planOptions.options.length === 0}
          />
          <DateRange state={periodDates} setState={setPeriodDates} />
        </div>
      </Header>
      <div className="flex flex-col p-5 pt-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-6">
          <BudgetCard linkToVariances={false} />
        </div>
        <div className="mb-2 flex justify-end gap-4">
          <SegmentedControl
            name="role-department-view"
            value={selectedView}
            disabled={isLoading}
            setValue={(val) => setSelectedView(val as SelectedViewType)}
            segments={[
              {
                label: "Itemized",
                value: "itemized",
              },
              {
                label: "Department",
                value: "department",
              },
            ]}
          />
          <ExportData
            id={`download-${selectedView}-csv`}
            data={csvToExport}
            filename={`variances-${selectedView}-${
              planOptions.selected?.label ?? "no-plan"
            }-${periodDates.mode}-${date(
              periodDates.startDate.toString(),
            ).format("YYYY-MMM")} to ${date(
              periodDates.endDate.toString(),
            ).format("YYYY-MMM")}.csv`}
            disabled={isLoading}
          />
        </div>
        {selectedView === "itemized" && (
          <Table
            rowClasses="last:font-bold"
            columnAlignment={[
              "left",
              "left",
              "left",
              "left",
              "left",
              "left",
              "right",
            ]}
            headers={[
              "ID Number",
              "Name",
              "Job Title",
              "Department",
              "Modification",
              "Effective Date",
              "Impact",
            ]}
            headerClassName="px-2 py-4"
            loadingState={{ isLoading, skeletonRows: 10 }}
            data={pageData?.roleVariance ?? null}
            emptyState={
              <div className="flex flex-col mx-auto my-10 items-center text-center w-1/3">
                <img
                  src={emptyVarianceStateIllustration}
                  alt="Empty Tasks Illustration"
                  className="w-72 h-auto"
                />
                <Typography
                  tag="h3"
                  size="md"
                  weight="semibold"
                  className="mt-4"
                >
                  Budget Variances
                </Typography>
                <Typography tag="p" size="md" color="secondary">
                  Once the plan is locked, you can see how the working model
                  deviates from the budget, including reasons like unexpected
                  salary changes, expense model adjustments, and incorrect hire
                  dates.
                </Typography>
              </div>
            }
          />
        )}
        {selectedView === "department" && (
          <Table
            rowClasses="last:font-bold"
            tableRowDataClasses={["w-3/4", "", ""]}
            columnAlignment={["left", "left", "right"]}
            headers={["Department", "Modifications", "Total Impact"]}
            headerClassName="px-6 py-4"
            loadingState={{
              isLoading,
              skeletonRows: 10,
            }}
            data={pageData?.departmentVariance ?? null}
            emptyState={
              <div className="flex flex-col mx-auto my-10 items-center text-center w-1/3">
                <img
                  src={emptyVarianceStateIllustration}
                  alt="Empty Tasks Illustration"
                  className="w-72 h-auto"
                />
                <Typography
                  tag="h3"
                  size="md"
                  weight="semibold"
                  className="mt-4"
                >
                  Budget Variances
                </Typography>
                <Typography tag="p" size="md" color="secondary">
                  Once the plan is locked, you can see how the working model
                  deviates from the budget, including reasons like unexpected
                  salary changes, expense model adjustments, and incorrect hire
                  dates.
                </Typography>
              </div>
            }
          />
        )}
      </div>
    </div>
  );
};

const VariancesContainer = (): React.ReactNode => (
  <DashboardProvider>
    <VariancesContentContainer />;
  </DashboardProvider>
);

export default VariancesContainer;
