import { z } from "zod";

export const headcountTotalsSchema = z.object({
  beginning: z.number(),
  newHires: z.number(),
  terminations: z.number(),
  ending: z.number(),
});

export const headcountSchema = z.object({
  currentWorkingModelHeadcount: headcountTotalsSchema,
  plannedHeadcount: headcountTotalsSchema.optional(),
  currentHeadcountTotal: z.number().optional(),
});

export const aggregatedHeadcountForDashboardSchema = z.object({
  headcount: headcountSchema,
  futureHeadcount: z.number(),
});
