import React, { useCallback, useEffect, useState } from "react";
import Button from "~/components/Button";
import Card from "~/components/Card";
import Header from "~/components/Header";
import IntegrationLink from "~/components/IntegrationLink";
import { State } from "~/store";
import { organizationSlice } from "~/store/organizationSlice";
import request from "~/utils/request";
import permissionsCheck from "~/utils/permissionsCheck";
import useQueryParams from "~/utils/hooks/useQueryParams";
import Pusher from "pusher-js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FirstTimeLogin from "./FirstTimeLogin";
import Typography from "~/components/Typography";
import { CheckIcon } from "@heroicons/react/24/outline";

interface IIntegrationResponse {
  status: number;
  data: {
    data: Types.Integration[];
  };
}

const Onboarding = (): React.ReactNode => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    permissions: { role, departmentAccessList },
  } = useSelector((state: State) => state.user);
  const [queryParams, setQueryParams] = useQueryParams();
  const [firstTimeLoginModal, setFirstTimeLoginModal] = useState(
    queryParams.get("firstLogin") === "true" || null,
  );
  const { name } = useSelector((state: State) => state.user);
  const organizationState = useSelector((state: State) => state.organization);
  const {
    uuid: organizationUuid,
    configuration: { positionsCreated, planCreated },
  } = organizationState;
  const firstName = name.split(" ")[0];
  const [integrations, setIntegrations] = useState<Types.Integration[]>([]);

  const getIntegrations = useCallback(async () => {
    const integrationsResponse: IIntegrationResponse = await request({
      url: `/organizations/${organizationUuid}/integrations`,
      method: "GET",
    });
    if (integrationsResponse.status >= 400) return;
    setIntegrations(integrationsResponse.data.data);
  }, []);

  useEffect(() => {
    getIntegrations();

    const pusher = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
      cluster: "us2",
    });
    const channel = pusher.subscribe(organizationUuid);
    channel.bind("sync-update", async ({ status }: { status: string }) => {
      if (status === "success") {
        getIntegrations();
        dispatch(
          organizationSlice.actions.update({
            ...organizationState,
            configuration: {
              ...organizationState.configuration,
              integrationCreated: true,
            },
          }),
        );
      } else if (status === "failed") {
        // TODO - handle failure with toast
      }
    });

    return () => {
      pusher.unsubscribe(organizationUuid);
    };
  }, [organizationUuid]);

  useEffect(() => {
    const newQueryParams: {
      firstLogin?: string;
    } = {};

    if (firstTimeLoginModal) {
      newQueryParams.firstLogin = "true";
    }

    setQueryParams(newQueryParams);
  }, [firstTimeLoginModal]);

  const hrisIntegration = integrations.find(
    (integration) => integration.type === "hris",
  );
  const hrisConnectionStatus = hrisIntegration?.connectionStatus;
  const onboardingComplete =
    positionsCreated && planCreated && hrisConnectionStatus === "CONNECTED";
  if (onboardingComplete) navigate("/dashboard");

  useEffect(() => {
    if (!permissionsCheck(role, departmentAccessList)) navigate("/dashboard");
  }, []);

  return (
    <>
      <FirstTimeLogin
        isOpen={firstTimeLoginModal ?? false}
        setModal={() => setFirstTimeLoginModal(null)}
      />
      <Header title="Getting Started" />
      <div className="container w-fit flex flex-col gap-6 my-10 ">
        <div className="flex flex-col">
          <Typography size="xl" weight="bold">
            Welcome{firstName ? `, ${firstName}` : ""}
          </Typography>
          <Typography color="secondary">{`Let's get you set so you can utilize all of the features Parallel has to offer!`}</Typography>
        </div>
        <Card className="!flex-row relative overflow-hidden max-w-[622px]">
          <div className="flex flex-col gap-2">
            <Typography size="lg" weight="bold">
              1. Link your HRIS
            </Typography>
            <div className="flex flex-col gap mb-2">
              <Typography size="sm" weight="thin">
                This allows Parallel to automatically pull in employee and
                position data.
              </Typography>
              <Typography size="sm" weight="thin">
                This connection removes the need to manually update any employee
                data.
              </Typography>
            </div>
            <div
              className={`${
                hrisConnectionStatus ? "items-start" : "items-end"
              }`}
            >
              {hrisConnectionStatus === "CONNECTED" && (
                <div className="flex flex-row bg-green-25 w-fit px-5 py-2 rounded">
                  <Typography size="sm" weight="thin" color="green">
                    Connected
                  </Typography>
                  <CheckIcon className="h-5 w-5 ml-1 text-green" />
                </div>
              )}
              {hrisConnectionStatus === "PENDING" && <p>Syncing</p>}
              {!hrisConnectionStatus && (
                <IntegrationLink category="hris" onComplete={getIntegrations}>
                  <span>Link Your HRIS</span>
                </IntegrationLink>
              )}
            </div>
          </div>
        </Card>
        <Card className="!flex-row relative overflow-hidden max-w-[622px]">
          <div className="flex flex-col gap-2">
            <Typography size="lg" weight="bold">
              2. Create future positions
            </Typography>
            <Typography size="sm" weight="thin" className="mb-2">
              {`Start by adding any positions that aren't currently open so you
              can get the full picture of your hiring plan for the short and
              long term.`}
            </Typography>
            <div>
              <div
                className={`flex flex-row bg-green-25 w-fit px-5 py-2 rounded ${
                  positionsCreated ? "" : "hidden"
                }`}
              >
                <Typography size="sm" weight="thin" color="green">
                  Completed
                </Typography>
                <CheckIcon className="h-5 w-5 ml-1 text-green" />
              </div>
              <Button
                onClick={() =>
                  navigate(
                    "/headcount?type=forecast&sortBy=hireDate&sortDirection=asc&createPosition=true",
                  )
                }
                disabled={hrisConnectionStatus !== "CONNECTED"}
                className={`!w-fit ${positionsCreated ? "hidden" : ""}`}
              >
                Create Positions
              </Button>
            </div>
          </div>
        </Card>
        <Card className="!flex-row relative overflow-hidden max-w-[622px]">
          <div className="flex flex-col gap-2">
            <Typography size="lg" weight="bold">
              3. Lock Down a Plan
            </Typography>
            <Typography size="sm" weight="thin" className="mb-2">
              {`Once you have a plan locked down, you'll be able to compare your actuals against that plan as things change over time.`}
            </Typography>
            <div>
              <div
                className={`flex flex-row bg-green-25 w-fit px-5 py-2 rounded ${
                  planCreated ? "" : "hidden"
                }`}
              >
                <Typography size="sm" weight="thin" color="green">
                  Completed
                </Typography>
                <CheckIcon className="h-5 w-5 ml-1 text-green" />
              </div>
              <Button
                onClick={() => navigate("/plans-and-scenarios")}
                className={`!w-fit  ${planCreated ? "hidden" : ""}`}
                disabled={!positionsCreated}
              >
                Lock Your Plan
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default Onboarding;
