import React, { useEffect, useRef, useState } from "react";
import Typography from "~/components/Typography";
import formatCurrency from "~/utils/formatCurrency";
import DrilldownMathPopover from "./DrilldownMathPopover";
import date from "~/utils/dates/date";
import { camelCaseToNormalText } from "~/utils/text/camelCaseToNormalText";
import isTextTruncated from "~/utils/isTextTruncated";
import { IDrilldownPosition } from "../useDrilldownDrawer";

export interface EquationElement {
  name: string;
  value: string | number;
  className: string;
}

interface Props {
  position: IDrilldownPosition;
  startDate: string;
  endDate: string;
  equationArray: EquationElement[];
}

const DisplaySalaryCalculation = ({
  position,
  startDate,
  endDate,
  equationArray,
}: Props): JSX.Element => {
  const positionTextRef = useRef<HTMLElement>();
  const [positonTextIsTruncated, setPositonIsTruncated] = useState(false);

  useEffect(() => {
    if (positionTextRef.current) {
      const isTrunc = isTextTruncated(positionTextRef.current, 200);
      setPositonIsTruncated(isTrunc);
    }
  }, []);

  return (
    <div className="w-full p-4 border-[1px] bg-neutral-15 mb-3 flex items-center justify-between rounded-xl">
      <div className="flex">
        <DrilldownMathPopover
          popoverMessage={
            positonTextIsTruncated ? `Position | ${position.title}` : "Position"
          }
          className="mr-3"
        >
          <Typography
            ref={positionTextRef}
            className="truncate max-w-[200px]"
            size="xs"
            weight="thin"
            color="empty"
          >
            {position.title}
          </Typography>
        </DrilldownMathPopover>
        <DrilldownMathPopover popoverMessage="Start Date">
          <Typography size="xs" weight="thin" color="empty">
            {date(startDate).format("MM/DD/YYYY")}
          </Typography>
        </DrilldownMathPopover>
        <Typography size="xs" weight="thin" color="empty">
          -
        </Typography>
        <DrilldownMathPopover
          popoverMessage="End Date"
          className="col-start-5 col-span-2 flex items-center justify-center"
        >
          <Typography size="xs" weight="thin" color="empty">
            {date(endDate).format("MM/DD/YYYY")}
          </Typography>
        </DrilldownMathPopover>
      </div>
      <div className="flex justify-end col-start-7 col-span-6 flex-wrap">
        {equationArray.map((equationElement, index) => (
          <>
            {equationElement.className.includes("math-operator") ? (
              <Typography
                size="xs"
                weight="thin"
                color="empty"
                className={`
                ${
                  (equationArray[index - 1] &&
                    equationArray[index - 1].name === "activeDays") ||
                  equationElement.value === "(" ||
                  (equationArray[index + 1] &&
                    equationArray[index + 1].value === ")")
                    ? "mr-1"
                    : "mr-2"
                }
                `}
              >
                {equationElement.value}
              </Typography>
            ) : equationElement.name === "Total" ? (
              <DrilldownMathPopover
                popoverMessage={camelCaseToNormalText(equationElement.name)}
                className="mr-2"
              >
                {formatCurrency(equationElement.value)}
              </DrilldownMathPopover>
            ) : (
              <DrilldownMathPopover
                popoverMessage={camelCaseToNormalText(
                  equationElement.name === "conversionRate"
                    ? `currencyConversion | ${position.currency}`
                    : equationElement.name,
                )}
                className={`${equationElement.name === "activeDays" ? "mr-1" : "mr-2"}`}
              >
                {equationElement.value}
              </DrilldownMathPopover>
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default DisplaySalaryCalculation;
