import Typography from "~/components/Typography";
import Button from "~/components/Button";
import React from "react";

export type IPositionsImportedDetails =
  | {
      terminations: number;
      newEmployees: number;
      updatedEmployees: number;
    }
  | { positionsCreated: number };

interface Props {
  onClose: () => void;
  positionsImportedDetails: IPositionsImportedDetails;
}
const SummaryStep = ({ positionsImportedDetails, onClose }: Props) => (
  <div className="flex flex-col w-full">
    {"positionsCreated" in positionsImportedDetails && (
      <Typography color="secondary">
        {positionsImportedDetails.positionsCreated} current and past roles were
        successfully imported. View uploaded employees on the Headcount page.
      </Typography>
    )}
    {"terminations" in positionsImportedDetails &&
      "newEmployees" in positionsImportedDetails &&
      "updatedEmployees" in positionsImportedDetails && (
        <>
          <Typography color="secondary">
            {`There were ${
              positionsImportedDetails.terminations +
              positionsImportedDetails.newEmployees +
              positionsImportedDetails.updatedEmployees
            } updates to your headcount data`}
          </Typography>
          <div className="bg-green-15 p-5 rounded-2xl w-full flex flex-col gap-2">
            <div className="flex justify-between">
              <p
                className="text-start font-thin"
                data-testid="modifications-summary-label"
              >
                Employee Data Modifications
              </p>
              <p
                className="text-end font-thin"
                data-testid="modifications-summary-value"
              >
                {positionsImportedDetails.updatedEmployees}
              </p>
            </div>
            <div className="flex justify-between">
              <p
                className="text-start font-thin"
                data-testid="new-employees-summary-label"
              >
                New Employees
              </p>
              <p
                className="text-end font-thin"
                data-testid="new-employees-summary-value"
              >
                {positionsImportedDetails.newEmployees}
              </p>
            </div>
            <div className="flex justify-between pb-1">
              <p
                className="text-start font-thin"
                data-testid="terms-summary-label"
              >
                Terminations
              </p>
              <p
                className="text-end font-thin"
                data-testid="terms-summary-value"
              >
                {positionsImportedDetails.terminations}
              </p>
            </div>
          </div>
        </>
      )}
    <div className="flex w-full justify-end mt-3">
      <Button className="!w-auto" onClick={onClose}>
        Close
      </Button>
    </div>
  </div>
);

export default SummaryStep;
