import { z } from "zod";

export const conditionSchema = z.object({
  field: z.string(),
  condition: z.string(),
  value: z.array(z.string()),
});

export const expenseModelSegmentSchema = z.object({
  name: z.string(),
  value: z.number(),
  conditions: z.array(conditionSchema),
  uuid: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const userSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
});

export const changeSchema = z.lazy(() =>
  z.object({
    label: z.string(),
    changedFrom: z.union([
      z.string(),
      z.number(),
      z.boolean(),
      z.instanceof(Date),
      z.array(z.string()),
      z.null(),
      z.undefined(),
      z.array(expenseModelSegmentSchema),
    ]),
    changedFromFormat: z.string(),
    changedTo: z.union([
      z.string(),
      z.number(),
      z.boolean(),
      z.instanceof(Date),
      z.array(z.string()),
      z.null(),
      z.undefined(),
      z.array(expenseModelSegmentSchema),
    ]),
    changedToFormat: z.string(),
  }),
);

export const expenseAuditEventSchema = z.object({
  versionUuid: z.string(),
  changeDescription: z.string(),
  changedBy: z.union([z.string(), userSchema, z.null()]),
  effectiveAt: z.string(),
  changedOn: z.string(),
  changes: z.array(changeSchema),
});

export const currentExpenseSchema = z.object({
  versionUuid: z.string(),
  expenseUuid: z.string(),
  parentUuid: z.string().nullable(),
  organizationUuid: z.string(),
  scenarioUuid: z.string().nullable(),
  name: z.string(),
  effectiveAt: z.string(),
  type: z.enum([
    "FIXED_PER_EMPLOYEE",
    "PERCENT_EMPLOYEE_SALARY",
    "MANUAL_ADJUSTMENT",
  ]),
  changeDescription: z.string(),
  createdAt: z.string(),
  createdBy: z.string().nullable(),
  updatedAt: z.string(),
  deletedAt: z.string().nullable(),
  deletedBy: z.string().nullable(),
  expenseModelSegments: z.array(expenseModelSegmentSchema),
});

export const expenseSchema = z.object({
  expenseUuid: z.string(),
  current: currentExpenseSchema,
  lastModified: z.string(),
  auditLog: z.array(expenseAuditEventSchema).optional(),
  canBeDeleted: z.boolean(),
});
