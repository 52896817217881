// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export default (xhr, keysObject) => {
  const url = xhr.responseURL;
  const urlSearchParams = new URLSearchParams(url.split("?")[1]);

  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in keysObject) {
    const valueFromResponse = urlSearchParams.get(key);
    const valueFromObject = keysObject[key];

    if (valueFromResponse !== valueFromObject) {
      return false;
    }
  }

  return true;
};
