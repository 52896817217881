import React from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Typography from "../Typography";

const MetricWarning = (): React.ReactNode => {
  return (
    <div className="w-full justify-end flex flex-row gap-2 items-center pr-2">
      <ExclamationTriangleIcon className="h-[18px] w-[18px] text-center text-neutral-200" />
      <Typography size="sm" color="empty">
        Incomplete tasks may affect your metrics
      </Typography>
    </div>
  );
};

export default MetricWarning;
