import React from "react";
import Typography from "~/components/Typography";
import capitalize from "~/utils/capitalize";
import EllipsisDropdown from "~/components/EllipsisDropdown";
import Table from "~/components/Table";

interface IDropdownOption {
  label: string;
  onClick: () => void;
  className?: string;
}

interface Props {
  uuid: string;
  name: string;
  email: string;
  role: string;
  status: string;
  departmentAccessList: string[];
  departmentMap: Record<string, string>;
  isUser?: boolean;
  options: IDropdownOption[];
}

const getDepartmentAccessString = (
  role: string,
  departmentAccessList: string[],
  departmentMap: Record<string, string>,
): string =>
  role === "admin"
    ? "All"
    : departmentAccessList
        .map((deptUuid) => departmentMap[deptUuid])
        .join(", ");

const FormatTableData = (
  rows: Props[],
  isLoading: boolean,
): React.ReactNode => {
  return (
    <Table
      id="org-users-table"
      className="w-full"
      loadingState={{ isLoading, skeletonRows: 15 }}
      headers={[
        <div key="import-header-file-name" id="importFileName" className="p-4">
          Name
        </div>,
        <div
          key="import-header-created-at"
          id="importCreatedAt"
          className="p-4"
        >
          Role
        </div>,
        <div key="import-header-created-by" id="importStatus" className="p-4">
          Department Access
        </div>,
        <div key="import-header-created-by" id="importStatus" className="p-4">
          Status
        </div>,
        <div key="options" />,
      ]}
      autoSpacing={false}
      data={rows.map(
        ({
          uuid,
          name,
          email,
          role,
          status,
          departmentAccessList,
          departmentMap,
          isUser,
          options,
        }) => ({
          id: uuid,
          values: [
            {
              value: (
                <div
                  key={uuid}
                  title={name}
                  className="flex flex-col p-4 max-w-[200px] truncate"
                >
                  <Typography>
                    {name}{" "}
                    <span className="!text-green-300">
                      {isUser ? " (you)" : null}
                    </span>
                  </Typography>
                  <Typography size="xs" color="empty">
                    {email}
                  </Typography>
                </div>
              ),
            },
            {
              value: (
                <div key={`${uuid}-role`} className="p-4">
                  <Typography>{capitalize(role)}</Typography>
                </div>
              ),
            },
            {
              value: (
                <div
                  key={`${uuid}-departmentAccess`}
                  className="p-4 max-w-[250px] truncate"
                  title={getDepartmentAccessString(
                    role,
                    departmentAccessList,
                    departmentMap,
                  )}
                >
                  <Typography>
                    {getDepartmentAccessString(
                      role,
                      departmentAccessList,
                      departmentMap,
                    )}
                  </Typography>
                </div>
              ),
            },
            {
              value: (
                <div key={`${uuid}-status`} className="p-4">
                  <Typography
                    color={
                      status === "Pending Approval" ? "special" : "primary"
                    }
                  >
                    {status}
                  </Typography>
                </div>
              ),
            },
            {
              value: (
                <div key={`${uuid}-options`}>
                  {options.length > 0 && (
                    <EllipsisDropdown
                      id={`${email}-options`}
                      options={options}
                      onClick={() => {}}
                    />
                  )}
                </div>
              ),
            },
          ],
        }),
      )}
    />
  );
};

export default FormatTableData;
