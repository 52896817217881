export default (employmentType: string) => {
  switch (employmentType) {
    case "FULL_TIME":
      return "Full Time";
    case "PART_TIME":
      return "Part Time";
    case "CONTRACTOR":
      return "Contractor";
    default:
      return "Unknown";
  }
};
