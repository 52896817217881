import React from "react";
import Input, { useInput } from "~/components/Input";
import request from "~/utils/request";
import { useSelector } from "react-redux";
import { State } from "~/store";
import Typography from "~/components/Typography";
import Button from "~/components/Button";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ICurrentExpense } from "../../types/expenseModel/types";

interface CreateManualAdjustmentsProps {
  onClose: () => void;
}

interface ICreatedManualAdjustment {
  data: {
    data: ICurrentExpense;
  };
  status: number;
}

const CreateManualAdjustments = ({
  onClose,
}: CreateManualAdjustmentsProps): React.ReactElement => {
  const navigate = useNavigate();

  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const [name, setName] = useInput({});
  const [isLoading, setIsLoading] = React.useState(false);

  const attemptSubmit = async (): Promise<void> => {
    setIsLoading(true);
    if (name.valid) {
      const createExpenseModelResponse = (await request({
        url: `/organizations/${organizationUuid}/expense-models`,
        method: "POST",
        body: {
          type: "MANUAL_ADJUSTMENT",
          name: name.value,
        },
      })) as ICreatedManualAdjustment;

      if (createExpenseModelResponse.status === 201) {
        toast.success("Manual Adjustment created");
        setName({
          ...name,
          value: "",
          valid: false,
          pristine: true,
          touched: false,
          disabled: false,
        });
        onClose();
        navigate(
          `/expense-models/${createExpenseModelResponse.data.data.expenseUuid}`,
        );
      } else {
        toast.error("Unable to create manual adjustment");
      }
    } else {
      setName((prevState) => ({
        ...prevState,
        pristine: false,
        touched: true,
      }));
    }
    setIsLoading(false);
  };

  return (
    <div className="flex flex-col w-full space-y-4">
      <Typography size="xl" weight="bold">
        New Manual Adjustment
      </Typography>
      <Typography size="xs" color="secondary" className="!mt-0">
        This will be a new model that will show up on forecasts and is able to
        manually adjust department budgets
      </Typography>
      <Input
        id="manualAdjustment-name"
        state={name}
        setState={setName}
        label="Name"
        required
      />
      <div className="w-full flex justify-between mt-3">
        <Button className="!w-auto !px-0" fill="clear" onClick={onClose}>
          Cancel
        </Button>
        <Button
          id="save-manual-adjustment-button"
          className="!w-auto"
          onClick={attemptSubmit}
          loading={isLoading}
        >
          Create
        </Button>
      </div>
    </div>
  );
};
export default CreateManualAdjustments;
