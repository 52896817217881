import Table from "~/components/Table";
import Typography from "~/components/Typography";
import SelectFieldMapping from "~/components/FieldMapping/SelectFieldMapping";
import React from "react";
import { IFieldMappingState } from "~/components/FieldMapping/useFieldMapping";

interface IProps {
  options: { value?: string; label: string }[];
  state: IFieldMappingState;
  setState: React.Dispatch<React.SetStateAction<IFieldMappingState>>;
}

const FieldMapping = ({ options, state, setState }: IProps) => {
  const showError = !state.valid && state.touched && !state.pristine;
  return (
    <>
      <Table
        id="employee-information-mapping"
        headers={["Data", "Data Example", "Requirements"]}
        data={state.rawFileData.headers.map((providedHeader: string, index) => {
          const [firstSample, secondSample, thirdSample] =
            state.rawFileData.data;
          const sample = (
            <Typography className="block max-w-[300px] truncate">
              {firstSample &&
              providedHeader in firstSample &&
              firstSample[providedHeader] ? (
                (firstSample[providedHeader] as string)
              ) : (
                <Typography color="lightGray">Empty</Typography>
              )}
              <br />
              {secondSample &&
              providedHeader in secondSample &&
              secondSample[providedHeader] ? (
                (secondSample[providedHeader] as string)
              ) : (
                <Typography color="lightGray">Empty</Typography>
              )}
              <br />
              {thirdSample &&
              providedHeader in thirdSample &&
              thirdSample[providedHeader] ? (
                (thirdSample[providedHeader] as string)
              ) : (
                <Typography color="lightGray">Empty</Typography>
              )}
            </Typography>
          );

          const ROWS_TO_HAVE_SELECT_POSITIONED_ABOVE = 2;
          return {
            id: providedHeader,
            values: [
              <div key="provided-header" className="max-w-[250px] truncate">
                {providedHeader}
              </div>,
              sample,
              <SelectFieldMapping
                key={providedHeader}
                options={options}
                fieldMappings={state.fieldMappings}
                setFieldMappings={(fieldMappings) => {
                  setState((prevState) => ({
                    ...prevState,
                    fieldMappings,
                  }));
                }}
                providedHeader={providedHeader}
                direction={
                  index >=
                  state.rawFileData.headers.length -
                    ROWS_TO_HAVE_SELECT_POSITIONED_ABOVE
                    ? "above"
                    : "below"
                }
              />,
            ],
          };
        })}
      />
      {showError && (
        <Typography color="warning">
          Required Data Missing:
          {state.missingFields.length > 0 && (
            <ul
              className="list-disc list-inside"
              data-testid="mapping-required-fields"
            >
              {state.missingFields.map((key) => (
                <li key={key}>
                  {options.find((option) => option.value === key)?.label}
                </li>
              ))}
            </ul>
          )}
        </Typography>
      )}
    </>
  );
};

export default FieldMapping;
