import React from "react";
import ReactCurrencyInputField from "react-currency-input-field";

interface Props {
  id: string;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  onBlur: () => void;
  disabled?: boolean;
  className: string;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  required?: boolean;
}

const CurrencyInput = ({
  id,
  placeholder = "Please enter an amount",
  value,
  onChange,
  className,
  disabled = false,
  onBlur,
  onKeyDown,
  required = false,
}: Props) => (
  <ReactCurrencyInputField
    className={`${className} w-full p-[0.57rem] h-[42px] border border-solid border-gray-300 disabled:bg-neutral-25 disabled:text-neutral-75 rounded shadow-sm focus:outline-none focus-visible:border-green-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-green-300`}
    id={id}
    data-testid={`${id}-input`}
    name={id}
    placeholder={placeholder}
    value={value}
    onValueChange={(newValue) => {
      onChange(newValue || "");
    }}
    disabled={disabled}
    onBlur={onBlur}
    onKeyDown={onKeyDown}
    required={required}
  />
);

export default CurrencyInput;
