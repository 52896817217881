import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Button from "~/components/Button";

interface Option {
  label: string;
  onClick: () => void;
}
interface Props {
  id: string;
  className?: string;
  label: string;
  onClick: () => void;
  options: Option[];
  fill?: "solid" | "outline" | "clear";
}
const DropdownButton = ({
  id,
  className = "",
  fill = "solid",
  label,
  options,
  onClick,
}: Props) => (
  <div className={className} data-tesid={`dropdown-${id}`}>
    <Menu as="div" className="relative inline-block text-left">
      <div className="inline-flex w-full justify-center rounded bg-green-500 text-gray-50 border-transparent">
        <Button
          id={`dropdown-main-${id}`}
          className="text-white px-6"
          fill={fill}
          onClick={onClick}
        >
          {label}
        </Button>
        {options.length > 0 && (
          <Menu.Button
            data-testid={`dropdown-icon-${id}`}
            className="px-2 border-l bg-green-400 hover:bg-green-500 rounded-r !border-green-200"
          >
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        )}
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-1 py-1 ">
            {options.map((option) => (
              <Menu.Item key={option.label}>
                <button
                  onClick={option.onClick}
                  type="button"
                  className="text-gray-900 group flex w-full items-center rounded px-2 py-2 text-sm"
                >
                  {option.label}
                </button>
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  </div>
);

export default DropdownButton;
