import { useEffect, useState } from "react";

export default () => {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const openDrawer = () => setIsOpen(true);
  const [closeOnEscape, setCloseOnEscape] = useState(true);

  const [isAnimating, setIsAnimating] = useState(false);

  const handleEscape = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen && closeOnEscape) {
      // Adding the event listener when the component mounts
      document.addEventListener("keydown", handleEscape);
    } else {
      document.removeEventListener("keydown", handleEscape);
    }

    setIsAnimating(true);
    const timeout = setTimeout(() => {
      setIsAnimating(false);
    }, 300);

    return () => {
      document.removeEventListener("keydown", handleEscape);
      clearTimeout(timeout);
    };
  }, [isOpen, closeOnEscape]);

  return {
    isOpen,
    onClose,
    openDrawer,
    isAnimating,
    setCloseOnEscape,
  };
};
