import React from "react";
import Tasks from "~/components/Tasks";
import Header from "~/components/Header";
import ChangeHistory from "./ChangeHistory";

const TasksPage = () => {
  return (
    <>
      <Header
        title="Tasks"
        breadCrumbs={[{ label: "Dashboard", url: "/dashboard" }]}
      />
      <div className="p-5 max-2xl:container 2xl:px-[5%]">
        <Tasks />
        <ChangeHistory />
      </div>
    </>
  );
};

export default TasksPage;
