import Modal from "~/components/Modal";
import React, { useState } from "react";
import Table, { TableData } from "~/components/Table";
import Button from "~/components/Button";
import {
  ArrowDownOnSquareIcon as ArrowDownOnSquareIconOutline,
  DocumentCheckIcon,
} from "@heroicons/react/24/outline";
import { ArrowDownOnSquareIcon as ArrowDownOnSquareIconSolid } from "@heroicons/react/24/solid";
import date from "~/utils/dates/date";
import Papa from "papaparse";
import request from "~/utils/request";
import { State } from "~/store";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { PositionsImport, Position } from "../headcount.types";
import ErrorPopover from "./errorPopover";

type FormattedErrors = Record<
  string,
  {
    count: number;
    preview: string[];
  }
>;

interface ErrorResponse {
  code: string;
  message: string;
  cause: ({ row: number; column: string; valueProvided: string } | string)[];
}

interface CreatePositionsImportResponse {
  data: { data: MyResponseData } | { errors: ErrorResponse[] };
  status: number;
}

interface Props {
  isOpen: boolean;
  setModal: (isOpen: boolean) => void;
  getPositionsImports: () => void;
}

interface MyResponseData {
  newPositionsImport: PositionsImport; // Define the actual type here
  createdPositions: Position[]; // Again, define the actual type
}

const PositionsImportModal = ({
  isOpen,
  setModal,
  getPositionsImports,
}: Props): React.ReactNode => {
  // State
  const [modalDisplayState, setModalDisplayState] =
    useState<string>("selectFile"); // "selectFile" | "mapHeaders" | "errorDisplay"
  const [onFileDrag, setOnFileDrag] = useState<boolean>(false);
  const [fileError, setFileError] = useState<string | null>(null);
  const [importErrors, setImportErrors] = useState<FormattedErrors | null>(
    null,
  );
  const [columnsWithErrors, setColumnsWithErrors] = useState<number>(0);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [parsedResults, setParsedResults] = useState<unknown>(null);
  const [errorMappingMessages, setErrorMappingMessages] = useState<string[]>(
    [],
  );
  const [selectedTags, setSelectedTags] = useState<Record<string, string>>({});
  const [untaggedHeaders, setUntaggedHeaders] = useState<string[]>([]);
  const [positionsCreatedLength, setPositionsCreatedLength] =
    useState<number>(0);
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );

  // Constants
  const expectedHeaders = [
    "title",
    "managerName",
    "groupName",
    "effectiveAt",
    "employmentType",
    "compensationRate",
    "paymentUnit",
    "expectedWeeklyHours",
  ];
  const headers = parsedResults?.meta?.fields || [];
  const tableMappingData: TableData[] = [];

  // This is the function that attempts to create a Positions Import
  const attemptCreateCustomImport = async (mappedData: unknown) => {
    setImportErrors(null);
    const payload = {
      fileName: selectedFile?.name,
      data: mappedData,
    };

    const response = (await request({
      url: `/organizations/${organizationUuid}/positions/imports`,
      method: "POST",
      body: payload,
      headers: {
        "Content-Type": "application/json",
      },
    })) as CreatePositionsImportResponse;

    if (
      response.status !== 201 &&
      "errors" in response.data &&
      !!response.data.errors &&
      response.data.errors.length > 0 &&
      Array.isArray(response.data.errors[0].cause)
    ) {
      const formattedErrors = response.data.errors[0].cause.reduce(
        (output, cause) => {
          if (typeof cause === "string") return output;

          const updatedOutput = { ...output };
          if (cause.column in output) {
            updatedOutput[cause.column].count += 1;
            if (updatedOutput[cause.column].preview.length < 3) {
              updatedOutput[cause.column].preview.push(
                cause.valueProvided === ""
                  ? "<Empty Value>"
                  : cause.valueProvided,
              );
            }
          } else {
            updatedOutput[cause.column] = {
              count: 1,
              preview: [
                cause.valueProvided === ""
                  ? "<Empty Value>"
                  : cause.valueProvided,
              ],
            };
          }
          return updatedOutput;
        },
        {} as FormattedErrors,
      );
      const colCount = Object.keys(formattedErrors).length;
      setColumnsWithErrors(colCount);
      setImportErrors(formattedErrors);
      setModalDisplayState("errorDisplay");
      throw new Error("Failed to upload positions import");
    }
    toast.success("Positions imported successfully 🎉");
    setSelectedFile(null);
    getPositionsImports();
    setModalDisplayState("success");
  };

  const formatHeader = (header: string): string =>
    header
      .replace(/([A-Z])/g, " $1") // Insert space before each capital letter
      .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
  // This section creates the Errors table
  const tableErrorData = importErrors
    ? Object.entries(importErrors).map(([columnName, error]) => ({
        id: columnName,
        values: [
          {
            value: (
              <div key={`${columnName}-column-name`} className="py-4 px-2">
                {formatHeader(columnName)}
              </div>
            ),
          },
          {
            value: (
              <div key={`${columnName}-preview`} className="py-4 px-2">
                <ul>
                  {error.preview.map((previewValue, index) => (
                    // Because Austin SAID SO, so don't you dare question it you fricken fricks
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={`${columnName}-preview-${previewValue}-${index}`}>
                      {previewValue}
                    </li>
                  ))}
                </ul>
              </div>
            ),
          },
          {
            value: (
              <div key={`${columnName}-details`} className="py-4 px-2">
                <ErrorPopover columnName={columnName} error={error} />
              </div>
            ),
          },
        ],
      }))
    : [];

  //

  const updateTypes = (myParsedResults: unknown, providedHeaders: string[]) => {
    const updatedData = myParsedResults.data.map((record: unknown) =>
      providedHeaders.reduce((acc: any, key) => {
        let value = record[key];

        if (typeof value === "string") {
          value = value.trim();
        }

        // Convert an empty string to null
        if (value === "") {
          return acc;
        }

        if (key === "compensationRate") {
          value = Number(value) * 100;
        }

        // Convert string to number if it's a number
        if (!Number.isNaN(Number(value))) {
          value = Number(value);
        }

        // Convert to uppercase for specific keys
        else if (key === "paymentUnit" || key === "employmentType") {
          value = value.trim().toUpperCase();
        }

        // Convert to ISO date string for specific keys
        else if (key === "effectiveAt") {
          const parsedDate = date(value); // Assuming 'date' can parse your date
          if (parsedDate.isValid()) {
            value = parsedDate.toISOString();
          }
        }

        acc[key] = value;

        return acc;
      }, {}),
    );
    return updatedData;
  };

  const checkHeaders = (receivedHeaders: string[] | undefined): boolean => {
    const missingHeaders = expectedHeaders.filter(
      (header) => !receivedHeaders?.includes(header),
    );
    if (missingHeaders.length > 0) {
      return true;
    }
    return false;
  };
  const handleParseAndValidate = (file: File): void => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete(results) {
        setPositionsCreatedLength(results.data.length);
        const missingHeaders = checkHeaders(results.meta.fields);
        if (missingHeaders) {
          setParsedResults(results);
          setModalDisplayState("mapHeaders");
        } else {
          if (!results.meta.fields) {
            return;
          }
          const updatedData = updateTypes(results, results.meta.fields);
          attemptCreateCustomImport(updatedData);
        }
      },
      error() {
        toast.error("There was an error parsing the file.");
      },
    });
  };

  // This section handles
  headers?.forEach((header: string) => {
    // Get the first 3 records for this header
    const firstThreeRecords = parsedResults.data
      .slice(0, 3)
      .map((record: unknown) => record[header]);

    // Create table data for this header
    const rowData = {
      id: header,
      values: [
        // Column Name
        {
          value: (
            <div key={`${header}-column-name`} className="py-4 px-2">
              {header}
            </div>
          ),
        },

        // Preview
        {
          value: (
            <div key={`${header}-preview`} className="py-4 px-2">
              <ul>
                {firstThreeRecords.map((value: unknown, index: number) => (
                  // Because Austin SAID SO, so don't you dare question it you fricken fricks
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={`${header}-preview-${value}-${index}`}>{value}</li>
                ))}
              </ul>
            </div>
          ),
        },

        // Tag (Dropdown)
        {
          value: (
            <div key={`${header}-tag`} className="py-4 px-2">
              <select
                value={selectedTags[header] || ""}
                onChange={(e) => {
                  setSelectedTags({
                    ...selectedTags,
                    [header]: e.target.value,
                  });
                }}
                data-testid={`${header}-tag`}
                placeholder="Property"
                className={`w-full border border-neutral-75 py-3 rounded px-2 hover:border-green-400 focus:outline-none focus:ring-2 focus:ring-green-400 focus:border-transparent ${
                  selectedTags[header] ? "text-neutral-700" : "text-neutral-200"
                }`}
              >
                <option className="text-neutral-200" value="" disabled>
                  Property
                </option>
                {expectedHeaders.map((expectedHeader) => (
                  <option
                    className="text-neutral-700"
                    key={expectedHeader}
                    value={expectedHeader}
                  >
                    {formatHeader(expectedHeader)}
                  </option>
                ))}
              </select>
              {untaggedHeaders.includes(header) && (
                <p
                  data-testid={`${header}-tag-error`}
                  className="text-red-500 mt-2"
                >
                  Needs property
                </p>
              )}
            </div>
          ),
        },
      ],
    };

    // Add this row data to the tableMappingData array
    tableMappingData.push(rowData);
  });

  // This section maps the users headers to our expected position Properties
  const checkForDuplicateHeaders = (
    tags: Record<string, string>,
  ): string | null => {
    const seen = new Set<string>();
    const hasDuplicate = Object.values(tags).some((tag) => {
      if (seen.has(tag)) return true;
      seen.add(tag);
      return false;
    });
    return hasDuplicate ? "You have assigned a header more than once." : null;
  };

  const checkForMissingMandatoryHeaders = (
    tags: Record<string, string>,
  ): string | null => {
    const mandatoryHeaders = [
      "title",
      "groupName",
      "effectiveAt",
      "employmentType",
      "compensationRate",
      "paymentUnit",
    ];
    const tagValues = Object.values(tags);

    const isMissing = mandatoryHeaders.some(
      (header) => !tagValues.includes(header),
    );

    return isMissing ? "You missed assigning a mandatory header." : null;
  };

  const checkForUntaggedHeaders = (
    originalHeaders: string[],
    tags: Record<string, string>,
  ): string[] =>
    originalHeaders.reduce((output: string[], header: string) => {
      if (tags[header]) return output;
      return [...output, header];
    }, []);

  const updateDataToMappedValues = (myParsedResults: {
    data: Record<string, string>[];
  }): Record<string, string | number | boolean>[] => {
    const updatedData = myParsedResults.data.map(
      (record: Record<string, string>) =>
        Object.keys(record).reduce(
          (acc: Record<string, string | number | boolean>, key) => {
            const newKey = selectedTags[key];
            if (newKey) {
              let value: string | number | boolean = record[key];

              if (typeof value === "string") {
                value = value.trim();
              }

              // Convert an empty string to null
              if (value === "") {
                return acc;
              }

              if (key === "compensationRate") {
                value = Number(value) * 100;
              }

              // Convert string to number if it's a number
              if (!Number.isNaN(Number(value))) {
                value = Number(value);
              } else if (
                (newKey === "paymentUnit" || newKey === "employmentType") &&
                typeof value === "string"
              ) {
                value = value.toUpperCase();
              } else if (
                newKey === "effectiveAt" &&
                typeof value === "string"
              ) {
                const parsedDate = date(value); // Assuming date is a function that can parse your date
                if (parsedDate.isValid()) {
                  // Check if date is valid
                  value = parsedDate.toISOString(); // Convert it to an ISO string or however you want to format it
                }
              }

              acc[newKey] = value;
            }
            return acc;
          },
          {},
        ),
    );
    return updatedData;
  };

  // This section handles the file drag state, dropping, and changing the file
  const onFileDragOverInput = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setOnFileDrag(true);
  };
  const onFileDragLeaveInput = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setOnFileDrag(false);
  };
  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.name.endsWith(".csv")) {
        setSelectedFile(file);
        setFileError(null); // Clear any previous error
      } else {
        setFileError("The selected file was not the correct file format");
      }
    }
  };
  const onFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setOnFileDrag(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      if (file.name.endsWith(".csv")) {
        setSelectedFile(file);
        setFileError(null); // Clear any previous error
      } else {
        setFileError("The selected file was not the correct file format");
      }
    }
  };

  // This section allows users to download a template csv file
  const csvData = `
  title,	managerName,	groupName,	effectiveAt,	employmentType,	compensationRate,	paymentUnit,	expectedWeeklyHours,
  software engineer,	Manager Name,	Engineering,	8/16/2023,	FULL_TIME,	35,	HOURLY,	40
  software developer,	Manager Name,	Finance,	8/18/2023,	PART_TIME,	65000,	Salary	
  designer,	Manager Name,	Finance,	8/20/2023,	FULL_TIME,	40,	hourly,	40
  `;

  const blob = new Blob([csvData], { type: "text/csv" });
  const templateCsvUrlDownload = window.URL.createObjectURL(blob);

  // This section is for style state
  const fileDropColor = (): string => {
    if (!selectedFile) {
      return onFileDrag
        ? "bg-green-25 border-green-200"
        : "bg-green-15 border-green-100";
    }
    return "bg-green-50 border-green-50";
  };

  const handleValidateMapping = (): void => {
    const newErrorMessages: string[] = [];

    const duplicateHeaderError = checkForDuplicateHeaders(selectedTags);
    const missingMandatoryHeaderError =
      checkForMissingMandatoryHeaders(selectedTags);
    const newUntaggedHeaders = checkForUntaggedHeaders(
      parsedResults.meta.fields,
      selectedTags,
    );

    if (duplicateHeaderError) newErrorMessages.push(duplicateHeaderError);
    if (missingMandatoryHeaderError)
      newErrorMessages.push(missingMandatoryHeaderError);

    // Update React's state for error messages and untagged headers
    setErrorMappingMessages(newErrorMessages);
    setUntaggedHeaders(newUntaggedHeaders);

    // Check the local variables, not the React state
    if (newErrorMessages.length === 0 && newUntaggedHeaders.length === 0) {
      const mappedData = updateDataToMappedValues(parsedResults);
      attemptCreateCustomImport(mappedData);
    }
  };

  // This is the inner modal state for selecting a file
  const selectFileState = (
    <div
      data-testid="select-file-state-modal"
      className="w-full flex flex-col gap-4"
    >
      <div className="gap-4">
        <div className="relative flex flex-col justify-center items-center gap-4">
          <div
            className={`group flex w-full h-56 my-8 ${fileDropColor()} justify-center items-center border-2 ${
              selectedFile ? "border-solid" : "border-dashed"
            } rounded-lg ${!selectedFile ? "cursor-pointer" : null} shadow-sm ${
              !selectedFile ? "hover:border-green-100" : null
            }`}
            onClick={() => {
              const fileInput = document.getElementById(
                "positions-import-file-upload",
              );
              if (fileInput) {
                fileInput.click();
              }
            }}
            onDragOver={onFileDragOverInput}
            onDrop={onFileDrop}
            onDragLeave={onFileDragLeaveInput}
            data-testid="positions-import-file-dropzone"
          >
            {selectedFile ? (
              <div className="flex flex-col group-hover justify-center items-center">
                <DocumentCheckIcon className="my-2 h-8 w-8 stroke-green-500" />
                <p>File Ready: {selectedFile.name}</p>
                <Button
                  onClick={(e) => {
                    if (!e) {
                      return;
                    }
                    e.stopPropagation();
                    setSelectedFile(null);
                  }}
                  fill="clear"
                  className="text-red-500 mt-2"
                >
                  Remove
                </Button>
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center">
                {" "}
                {/* Added this flex container */}
                {onFileDrag ? (
                  <>
                    <ArrowDownOnSquareIconSolid className="my-2 h-8 w-8 fill-green-500" />
                    <p className="text-green-500">Upload File</p>
                  </>
                ) : (
                  <>
                    <ArrowDownOnSquareIconOutline className="my-2 h-8 w-8 stroke-green-500" />
                    <p>
                      Drag and drop or{" "}
                      <span className="group-hover:underline text-green-400">
                        choose a file
                      </span>{" "}
                      to upload your Positions
                    </p>
                    <p>.csv file compatible</p>
                  </>
                )}
                {fileError && <p className="text-red-500 mt-2">{fileError}</p>}
              </div>
            )}
          </div>
          <input
            type="file"
            data-testid="positions-import-file-input"
            id="positions-import-file-upload"
            className="hidden"
            onChange={onFileChange}
            accept=".csv"
          />
        </div>
      </div>
      <div className="w-full flex flex-row justify-between">
        <Button
          onClick={() => {
            setSelectedFile(null);
            setImportErrors(null);
            setModal(false);
            setModalDisplayState("selectFile");
          }}
          fill="clear"
          className="!w-auto !px-0"
        >
          Cancel
        </Button>
        <div className=" flex flex-row gap-3">
          <a href={templateCsvUrlDownload} download="template.csv">
            <Button fill="outline" className="!w-auto !px-10">
              Download Template
            </Button>
          </a>
          <Button
            id="upload-positions-import-button"
            onClick={() => {
              if (selectedFile) {
                handleParseAndValidate(selectedFile);
              }
            }}
            disabled={!selectedFile}
            className="!w-auto !px-10"
          >
            Upload
          </Button>
        </div>
      </div>
    </div>
  );

  // This is the inner modal state for mapping the file
  const mapHeadersState = (
    <div
      data-testid="file-mapping-state-modal"
      className="w-full flex flex-col gap-4"
    >
      <p>
        Please map the imported columns to the appropriate data types within our
        system. This step is crucial for accurate data processing and analysis.
      </p>
      <Table
        id="import-mapping-table"
        headers={[
          <div
            key="import-mapping-column-name"
            id="importMappingColumnName"
            className="py-4 px-2"
          >
            Column Name
          </div>,
          <div
            key="import-mapping-preview"
            id="importMappingPreview"
            className="py-4 px-2"
          >
            Preview
          </div>,
          <div
            key="import-mapping-tag"
            id="importMappingTag"
            className="py-4 px-2"
          >
            Tag
          </div>,
        ]}
        autoSpacing={false}
        data={tableMappingData}
      />
      {errorMappingMessages.length > 0 && (
        <div
          data-testid="import-mapping-errors"
          className="flex flex-col gap-1"
        >
          <h2>Errors:</h2>
          <ul className="text-red-300">
            {errorMappingMessages.map((errorMappingMessage) => (
              <li key={errorMappingMessage}>{errorMappingMessage}</li>
            ))}
          </ul>
        </div>
      )}
      <div className="w-full flex flex-row justify-between">
        <Button
          onClick={() => {
            setSelectedFile(null);
            setImportErrors(null);
            setModalDisplayState("selectFile");
            setModal(false);
          }}
          fill="clear"
          className="!w-auto !px-0"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleValidateMapping();
          }}
          disabled={!selectedFile}
          className="!w-auto !px-10"
          id="validate-mapping-button"
        >
          Submit
        </Button>
      </div>
    </div>
  );

  // This is the inner modal state for displaying errors
  const errorDisplayState = (
    <div className="w-full flex flex-col gap-4">
      <p>
        The upload contains a number of errors. We displayed up to three
        examples for each column. Please address all errors and try again.
      </p>
      <p data-testid="columns-with-errors-count" className="text-red-300">
        {columnsWithErrors} columns with errors
      </p>
      <Table
        id="import-errors-table"
        headers={[
          <div
            key="import-errors-column-name"
            id="importErrorsColumnName"
            className="py-4 px-2"
          >
            Column Name
          </div>,
          <div
            key="import-errors-preview"
            id="importErrorsPreview"
            className="py-4 px-2"
          >
            Preview
          </div>,
          <div
            key="import-errors-column-name"
            id="importErrorsDetails"
            className="py-4 px-2"
          >
            Details
          </div>,
        ]}
        autoSpacing={false}
        data={tableErrorData}
      />
      <div className="w-full flex flex-row justify-between">
        <Button
          onClick={() => {
            setSelectedFile(null);
            setImportErrors(null);
            setModalDisplayState("selectFile");
            setModal(false);
          }}
          fill="clear"
          className="!w-auto !px-0"
        >
          Cancel
        </Button>
        <Button
          id="reupload-positions-import-button"
          onClick={() => {
            setModalDisplayState("selectFile");
            setSelectedFile(null);
          }}
          className="!w-auto !px-10"
        >
          Re-upload File
        </Button>
      </div>
    </div>
  );

  const successDisplayState = (
    <div
      data-testid="success-state-modal"
      className="w-full flex flex-col gap-4"
    >
      <p data-testid="success-positions-created-text">
        {positionsCreatedLength} roles were successfully imported.
      </p>
      <div>
        <Button
          id="success-close-button"
          onClick={() => {
            setModalDisplayState("selectFile");
            setSelectedFile(null);
            setModal(false);
          }}
          className="float-right !w-auto !px-10"
        >
          Close
        </Button>
      </div>
    </div>
  );

  const chooseModalDisplayState = (): React.ReactNode => {
    switch (modalDisplayState) {
      case "mapHeaders":
        return mapHeadersState;
      case "errorDisplay":
        return errorDisplayState;
      case "success":
        return successDisplayState;
      case "selectFile":
      default:
        return selectFileState;
    }
  };

  const chooseModalTitle = (): string => {
    switch (modalDisplayState) {
      case "mapHeaders":
        return "Field Mapping";
      case "errorDisplay":
        return "Upload Failed";
      case "success":
        return "Success";
      case "selectFile":
      default:
        return "Upload File";
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setModal(false);
        setSelectedFile(null);
        setImportErrors(null);
        setModalDisplayState("selectFile");
      }}
      title={chooseModalTitle()}
      size="lg"
    >
      {chooseModalDisplayState()}
    </Modal>
  );
};

export default PositionsImportModal;
