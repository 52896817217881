import React from "react";
import Typography from "~/components/Typography";
import positionForecastEmptyState from "~/assets/positionForecastEmptyState.svg";
import positionHistoryEmptyState from "~/assets/positionHistoryEmptyState.svg";
import { IForecastRequest, TaskInterface } from "~/components/Tasks";
import PositionVersionCard from "./PositionVersionCard";
import "./positionDetails.css";
import { Position, IPositionHistoryVersion } from "../headcount.types";
import PositionRequest from "./PositionRequest";

interface Props {
  positionForecasts: IPositionHistoryVersion[];
  positionHistory: IPositionHistoryVersion[];
  fetchPositionData: () => Promise<void>;
  fetchPositionRequests: () => Promise<void>;
  positionData: Position;
  organizationUuid: string;
  setPositionVersionInEditModeEffectiveAt: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  requestData?: TaskInterface[];
}

const PositionHistoryList = ({
  positionForecasts,
  positionHistory,
  fetchPositionData,
  fetchPositionRequests,
  positionData,
  organizationUuid,
  setPositionVersionInEditModeEffectiveAt,
  requestData,
}: Props): React.ReactNode => (
  <div className="w-full min-w-[390px] max-w-[800px] max-xl:px-4 px-16">
    {requestData && requestData.length > 0 && (
      <div className="flex flex-col justify-center items-center gap-4 mt-16">
        {requestData.map((request) => {
          const requestInformation = request.metadata
            .requestPositionForecast as IForecastRequest;
          return (
            <PositionRequest
              key={request.uuid}
              positionData={positionData}
              requestInformation={requestInformation}
              task={request}
              successCallback={() => {
                fetchPositionRequests();
                fetchPositionData();
              }}
            />
          );
        })}
      </div>
    )}
    <div>
      {positionForecasts.length > 0 ? (
        <div className="flex flex-col gap-4">
          <div className="relative flex py-5 items-center gap-4 mt-6">
            <div className="flex-grow border-t border-gray-300" />
            <Typography
              tag="h2"
              color="lightGray"
              size="xs"
              className="tracking-widest"
            >
              FORECASTED CHANGES
            </Typography>
            <div className="flex-grow border-t border-gray-300" />
          </div>

          {positionForecasts.map((version) => (
            <PositionVersionCard
              key={version.uuid}
              id={`position-version-forecast-card-${version.uuid}`}
              version={version}
              fetchPositionData={fetchPositionData}
              positionData={positionData}
              organizationUuid={organizationUuid}
              setPositionVersionInEditModeEffectiveAt={
                setPositionVersionInEditModeEffectiveAt
              }
            />
          ))}
        </div>
      ) : (
        <div className="flex flex-col items-center text-center mt-24 mb-4">
          <img
            src={positionForecastEmptyState}
            alt="Empty Tasks Illustration"
            className="w-26 h-auto"
          />
          <Typography tag="h3" color="empty" className="mt-4">
            No Forecasted Changes
          </Typography>
        </div>
      )}
    </div>
    <div>
      {positionHistory.length > 0 ? (
        <div className="flex flex-col gap-4 mb-10">
          <div className="relative flex py-5 items-center gap-4 mt-6">
            <div className="flex-grow border-t border-gray-300" />
            <Typography
              tag="h2"
              color="lightGray"
              size="xs"
              className="tracking-widest"
            >
              POSITION HISTORY
            </Typography>
            <div className="flex-grow border-t border-gray-300" />
          </div>
          {positionHistory.map((version) => (
            <PositionVersionCard
              key={version.uuid}
              id={`position-version-actual-card-${version.uuid}"`}
              positionData={positionData}
              version={version}
              organizationUuid={organizationUuid}
            />
          ))}
        </div>
      ) : (
        <div className="flex flex-col items-center text-center mt-24">
          <img
            src={positionHistoryEmptyState}
            alt="Empty Tasks Illustration"
            className="w-26 h-auto"
          />
          <Typography tag="h3" color="empty" className="mt-4">
            No Position History
          </Typography>
        </div>
      )}
    </div>
  </div>
);

export default PositionHistoryList;
