import MobileNav from "~/components/MobileNav";
import { State } from "~/store";
import React, { ReactNode, useRef } from "react";
import { useSelector } from "react-redux";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import Button from "~/components/Button";
import { Link, useNavigate } from "react-router-dom";
import Typography from "~/components/Typography";

interface Props {
  title?: string;
  breadCrumbs?: { label: string; url: string }[];
  goBackLabel?: string;
  children?: ReactNode | string;
  className?: string;
}

const Header = ({
  title,
  goBackLabel,
  children,
  className,
  breadCrumbs,
}: Props) => {
  const navigate = useNavigate();
  const headerRef = useRef(null);
  const { isMobile } = useSelector((state: State) => state.settings);

  return (
    <div
      ref={headerRef}
      className={`sticky top-0 z-20 bg-white h-16 px-4 md:px-6 ${className}`}
    >
      <div className="w-full h-full flex flex-wrap items-center justify-between bg-white border-b border-green-50 ">
        {isMobile && <MobileNav />}
        {!isMobile ? (
          <div className="flex flex-nowrap items-center justify-start gap-3">
            {goBackLabel && (
              <Button
                onClick={() => navigate(-1)}
                fill="clear"
                className="bg-white text-neutral-500 border-2 border-[#E4E4E4] !w-auto flex items-center gap-1"
              >
                <ChevronLeftIcon className="w-5 h-5" />
                {goBackLabel}
              </Button>
            )}
            {breadCrumbs && (
              <>
                {breadCrumbs.map((crumb: { label: string; url: string }) => (
                  <Link key={crumb.url} to={crumb.url}>
                    <Typography color="secondary" weight="medium">
                      {`${crumb.label} /`}
                    </Typography>
                  </Link>
                ))}
              </>
            )}
            <Typography
              size={breadCrumbs?.length ? "md" : "lg"}
              weight="bold"
              className={`truncate overflow-hidden whitespace-nowrap max-w-[500px] mb-4 md:mb-0 hidden ${
                !isMobile ? "md:block" : ""
              }`}
            >
              {title}
            </Typography>
          </div>
        ) : (
          <div />
        )}
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Header;
