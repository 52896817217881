import { IUseDrilldownDrawer } from "~/components/Drawer/DrilldownDrawer/useDrilldownDrawer";
import { capitalizeWords } from "~/utils/capitalize";
import { IDatePickerState } from "~/components/DatePicker/useDatePicker";
import { utcToZonedTime } from "date-fns-tz";
import {
  addMonths,
  endOfMonth,
  format,
  parseISO,
  startOfMonth,
} from "date-fns";
import { IDepartment } from "~/types/department/types";

const DEFAULT_DEPARTMENT_NAME = "Total";

export default ({
  category,
  subCategory,
  departmentUuids,
  monthIndex,
  expenseModelUuid,
  selectYearState,
  departments,
  drilldownDrawerState,
}: {
  category: string;
  subCategory?: string;
  departmentUuids: string[];
  monthIndex: number;
  expenseModelUuid?: string;
  selectYearState: IDatePickerState;
  departments: IDepartment[];
  drilldownDrawerState: IUseDrilldownDrawer;
}): void => {
  if (!selectYearState.value.startDate)
    throw new Error("Start date is required for opening the drilldown drawer.");

  let departmentName = "";

  if (departmentUuids.length === 1 && !!departmentUuids[0]) {
    departmentName =
      departments.find((department) => department.uuid === departmentUuids[0])
        ?.name ?? "Not found";
  } else if (subCategory) {
    departmentName = subCategory;
  } else {
    departmentName = DEFAULT_DEPARTMENT_NAME;
    departmentUuids = departments.map((department) => department.uuid);
  }

  const parsedDate = utcToZonedTime(
    parseISO(new Date(selectYearState.value.startDate).toISOString()),
    "UTC",
  );
  const relativeDate = addMonths(parsedDate, monthIndex);

  drilldownDrawerState.open({
    title: `${capitalizeWords(category)}`,
    subCategory: subCategory,
    subtitle: `${format(relativeDate, "MMMM yyyy")} | ${departmentName}`,
    startDate: format(startOfMonth(relativeDate), "yyyy-MM-dd"),
    endDate: format(endOfMonth(relativeDate), "yyyy-MM-dd"),
    departmentUuids: departmentUuids,
    category,
    expenseModelUuid,
  });
};
