import React from "react";
import Card from "~/components/Card";
import { Transition } from "@headlessui/react";

interface Props {
  isOpen: boolean;
  children: React.ReactNode;
}
const Tray = ({ children, isOpen }: Props) => (
  <Transition
    className="z-10 transition-all duration-500 fixed bottom-0 p-4 !w-[inherit]"
    show={isOpen}
    appear
    enter="transition-all ease-in-out duration-500"
    enterFrom="opacity-0 translate-y-6"
    enterTo="opacity-100 translate-y-0"
    leave="transition-all ease-in-out duration-300"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    <div className={`${isOpen ? " animate-fade-in-up" : " hidden"}`}>
      <Card className="!px-8 !py-6">{children}</Card>
    </div>
  </Transition>
);

export default Tray;
