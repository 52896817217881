import React from "react";

export type SortDirection = "asc" | "desc";

export type SortType = {
  key: string;
  direction: SortDirection;
} | null;
const useSort = (
  initialSort: SortType,
): [SortType, React.Dispatch<React.SetStateAction<SortType>>] => {
  const [sort, setSort] = React.useState<SortType>(initialSort || null);

  return [sort, setSort];
};

export default useSort;
