import React from "react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

export const ListItem = ({
  id,
  children,
  onClick,
  className = "",
  noBorder = false,
  showChevron = true,
}: {
  id?: string;
  children: React.ReactNode;
  onClick?: () => void;
  noBorder?: boolean;
  className?: string;
  showChevron?: boolean;
}) => (
  <li
    className={`${className} ${
      !onClick
        ? `w-full flex justify-between items-center gap-2 py-2 ${
            !noBorder ? "border-b" : ""
          }`
        : ""
    }`}
  >
    {onClick && (
      <button
        type="button"
        className={`w-full flex justify-between items-center gap-2 py-2 ${
          !noBorder ? "border-b" : ""
        }`}
        onClick={onClick}
        data-testid={`${id}-button`}
      >
        <div>{children}</div>
        {showChevron && <ChevronRightIcon className="w-5 h-5" />}
      </button>
    )}
    {!onClick && children}
  </li>
);

interface Props {
  children: React.ReactNode[] | React.ReactNode;
}
const List = ({ children }: Props) => (
  <ul>{Array.isArray(children) ? children.map((child) => child) : children}</ul>
);

export default List;
