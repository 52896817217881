import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface TasksState {
  total: number;
}

const initialState = {
  total: 0,
} as TasksState;

export const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    update: (state, action: PayloadAction<TasksState>) => action.payload,
    reset: () => initialState,
  },
});

export const { update, reset } = tasksSlice.actions;
export const tasks = tasksSlice.actions;
export default tasksSlice.reducer;
