import React, { ReactElement } from "react";
import ExportData from "~/components/ExportData";
import MetricWarning from "~/components/MetricWarning";
import Table from "~/components/Table";
import Typography from "~/components/Typography";
import date from "~/utils/dates/date";
import { v4 } from "uuid";
import { ForecastPageContext } from "./context/ForecastPageContext";

const ForecastTableRenderer = (): ReactElement | ReactElement[] => {
  const forecastContext = React.useContext(ForecastPageContext);
  if (!forecastContext) {
    throw new Error("Forecast page missing data to provide context");
  }
  const {
    selectYearState,
    projections: projectionsForTables,
    headers: headersForTable,
    headcountOrSalary,
    isLoading,
    total,
  } = forecastContext;

  if (!headersForTable) {
    return <div />;
  }

  let columnAlignment: (
    | "left"
    | "center"
    | "right"
    | "justify"
    | "char"
    | undefined
  )[] = ["left"];

  if (headersForTable.length) {
    columnAlignment = [
      "left",
      ...Array<"left" | "center" | "right" | "justify" | "char" | undefined>(
        headersForTable.length - 1,
      ).fill("right"),
    ];
  }

  return projectionsForTables && !isLoading ? (
    projectionsForTables[
      `${
        headcountOrSalary === "salaries"
          ? "currencyBasedProjections"
          : "headcountBasedProjections"
      }`
    ].map(({ title, data }, index) => (
      <div
        key={`${title}-${v4()}`}
        data-testid={`forecast-table-for-${title}`}
        className="flex flex-col"
      >
        <div
          data-testid={`forecast-header-${title}`}
          className="flex flex-row justify-between pr-8"
        >
          <Typography size="xl" weight="bold">
            {title}
          </Typography>
          <div className="flex flex-row gap-2">
            {index === 0 && total > 0 && <MetricWarning />}
            {index === 0 && (
              <ExportData
                id={`download-${headcountOrSalary}-projections-csv`}
                data={projectionsForTables[
                  `${
                    headcountOrSalary === "salaries"
                      ? "currencyBasedProjections"
                      : "headcountBasedProjections"
                  }`
                ].reduce(
                  (output, projection) => {
                    output.push([projection.title]);
                    output.push([...headersForTable]);
                    output.push(
                      ...projection.data.map(({ values }) =>
                        values.map(({ value }) => value),
                      ),
                    );
                    return output;
                  },
                  [] as (string | number | boolean | React.ReactNode)[][],
                )}
                filename={`forecasts-${headcountOrSalary}-${date(
                  selectYearState.value.startDate ?? undefined,
                ).format("YYYY-MMM")} to ${date(
                  selectYearState.value.endDate ?? undefined,
                ).format("YYYY-MMM")}.csv`}
                disabled={isLoading}
              />
            )}
          </div>
        </div>
        <Table
          className="mt-3 mb-10"
          rowClasses="last:font-bold"
          headers={headersForTable}
          data={data}
          columnAlignment={columnAlignment}
        />
      </div>
    ))
  ) : (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between pr-8">
        <Typography id="forecast-header-totals" size="xl" weight="bold">
          Salaries
        </Typography>
        <div className="flex flex-row gap-2">
          {total > 0 && <MetricWarning />}
          <ExportData
            id="export-data-loading"
            data={[[]]}
            filename=""
            disabled={isLoading}
          />
        </div>
      </div>
      <Table
        className="mt-3 mb-10"
        rowClasses="last:font-bold"
        headers={headersForTable}
        loadingState={{ isLoading, skeletonRows: 10 }}
        data={[{ id: "never", values: [] }]}
        columnAlignment={columnAlignment}
      />
    </div>
  );
};

export default ForecastTableRenderer;
