type IRecord = Record<string, unknown>;

export default (parsedFileData: {
  data: IRecord[];
  headers: string[];
}): IRecord[] => {
  const normalizedData: IRecord[] = parsedFileData.data.map((record) => {
    if (
      "Employment type name" in record &&
      typeof record["Employment type name"] === "string"
    ) {
      const paymentType =
        record["Employment type name"].split(", ")[0] === "Salaried"
          ? "salary"
          : "hourly";
      const employmentType =
        record["Employment type name"].split(", ")[1] === "full-time"
          ? "Full Time"
          : "part time";

      let compensationRate = record["Annual compensation"];
      const employmentStatus = record["Employment status"];
      if (employmentStatus === "Terminated" && !compensationRate) {
        compensationRate = 0;
      }

      let currency = record["Annual compensation - Currency"];
      if (!currency) {
        currency = "USD";
      }

      return {
        ...record,
        paymentType,
        employmentType,
        compensationRate,
        currency,
      };
    }
    throw new Error("Unable to process file");
  });

  const mapping: IRecord = {
    employeeNumber: "Rippling profile ID",
    employeeFirstName: "Legal first name",
    employeeLastName: "Legal last name",
    employeeEmail: "Work email",
    jobTitle: "Title",
    compensationRate: "compensationRate",
    paymentType: "paymentType",
    employmentType: "employmentType",
    currency: "currency",
    department: "Department",
    managerName: "Manager",
    hireDate: "Start date",
    terminationDate: "End date",
  };

  return normalizedData.map((row) => {
    const mappedRow: IRecord = {};
    Object.entries(mapping).forEach(([parallelKey, providedKey]) => {
      if (providedKey) {
        mappedRow[parallelKey] = row[providedKey as string];
      }
    });
    return mappedRow;
  });
};
