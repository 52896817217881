import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IPlan } from "~/types/plans/types";
import { planSchema } from "~/types/plans/schemas";
import { State } from "~/store";
import { IDepartment } from "~/types/department/types";
import request from "~/utils/request";
import { departmentSchema } from "~/types/department/schemas";
import { z } from "zod";
import { SelectState } from "~/components/Select/Select.types";
import { useSelect } from "~/components/Select";
import formatDepartmentOptions from "./utils/formatDepartmentOptions";
import formatPlanOptions from "./utils/formatPlanOptions";
import usePeriodPicker from "~/components/PeriodPicker/usePeriodPicker";
import { endOfMonth, startOfMonth } from "date-fns";
import date from "~/utils/dates/date";

interface ICommonlyFetchedValuesContext {
  departments: IDepartment[];
  departmentOptions: SelectState;
  setDepartmentOptions: React.Dispatch<React.SetStateAction<SelectState>>;
  plans: IPlan[];
  planOptions: SelectState;
  setPlanOptions: React.Dispatch<React.SetStateAction<SelectState>>;
  isLoadingDepartments: boolean;
  setIsLoadingDepartments: (value: boolean) => void;
  isLoadingPlans: boolean;
  setIsLoadingPlans: (value: boolean) => void;
  getDepartments: () => Promise<void>;
  getPlans: () => Promise<void>;
  periodDates: {
    startDate: Date;
    endDate: Date;
    mode: "month" | "quarter" | "year";
  };
  setPeriodDates: React.Dispatch<
    React.SetStateAction<{
      startDate: Date;
      endDate: Date;
      mode: "month" | "quarter" | "year";
    }>
  >;
}

interface IAPIResponse {
  data: {
    data: unknown;
  };
}

export const CommonlyFetchedValuesContext =
  React.createContext<ICommonlyFetchedValuesContext | null>(null);

export const CommonlyFetchedValuesProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactNode => {
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const [departments, setDepartments] = useState<IDepartment[]>([]);
  const [plans, setPlans] = useState<IPlan[]>([]);
  const [isLoadingDepartments, setIsLoadingDepartments] = useState(true);
  const [isLoadingPlans, setIsLoadingPlans] = useState(true);
  const [departmentOptions, setDepartmentOptions] = useSelect({
    options: [],
  });
  const [planOptions, setPlanOptions] = useSelect({
    options: [],
  });
  const [periodDates, setPeriodDates] = usePeriodPicker({
    startDate: startOfMonth(date().toDate()),
    endDate: endOfMonth(date().toDate()),
    mode: "month",
  });

  const getDepartments = async (): Promise<void> => {
    try {
      setIsLoadingDepartments(true);
      const response = (await request({
        url: `/organizations/${organizationUuid}/groups`,
        method: "GET",
      })) as IAPIResponse;
      const departments = z.array(departmentSchema).parse(response.data.data);
      const formattedDepartmentOptions = formatDepartmentOptions(departments);
      setDepartmentOptions((prevState) => ({
        ...prevState,
        selected:
          formattedDepartmentOptions.length === 1
            ? formattedDepartmentOptions[0]
            : undefined,
        options: formattedDepartmentOptions,
      }));
      setDepartments(departments);
    } catch (error) {
      if (error instanceof z.ZodError) {
        console.error("Validation error:", error.issues);
      } else {
        console.error(error);
      }
    } finally {
      setIsLoadingDepartments(false);
    }
  };

  useEffect(() => {
    getDepartments();
  }, [organizationUuid]);

  const getPlans = async (): Promise<void> => {
    setIsLoadingPlans(true);
    try {
      const response = (await request({
        url: `/organizations/${organizationUuid}/plans`,
        method: "GET",
      })) as IAPIResponse;
      const plans = z.array(planSchema).parse(response.data.data);
      const { selected, options } = formatPlanOptions({ plans });
      setPlanOptions((prevState) => ({
        ...prevState,
        selected,
        options,
      }));
      setPlans(plans);
    } catch (error) {
      if (error instanceof z.ZodError) {
        console.error("Validation error:", error.issues);
      } else {
        console.error(error);
      }
    } finally {
      setIsLoadingPlans(false);
    }
  };

  useEffect(() => {
    getPlans();
  }, [organizationUuid]);

  return (
    <CommonlyFetchedValuesContext.Provider
      value={{
        departments,
        departmentOptions,
        setDepartmentOptions,
        plans,
        planOptions,
        setPlanOptions,
        isLoadingDepartments,
        setIsLoadingDepartments,
        isLoadingPlans,
        setIsLoadingPlans,
        getDepartments,
        getPlans,
        periodDates,
        setPeriodDates,
      }}
    >
      {children}
    </CommonlyFetchedValuesContext.Provider>
  );
};

export default CommonlyFetchedValuesProvider;
