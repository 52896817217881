import React from "react";
import formatCurrency from "~/utils/formatCurrency";
import date from "~/utils/dates/date";
import { Position } from "../headcount.types";

interface Props {
  position: Position;
  isSelected: boolean;
  onSelect: (position: Position | null) => void;
  id: string;
}

const MiniPositionCard = ({
  isSelected,
  onSelect,
  position,
  id,
}: Props): React.ReactNode => {
  const formatEmploymentType = (value: string): string =>
    value
      .replace("_", " ")
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

  let paymentUnit = "-";
  if (position.current.paymentUnit) {
    paymentUnit =
      position.current.paymentUnit === "HOURLY" ? "Hourly" : "Salary";
  }

  return (
    <div
      data-testid={id}
      onClick={() => {
        if (isSelected) {
          onSelect(null);
        } else {
          onSelect(position);
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          if (isSelected) {
            onSelect(null);
          } else {
            onSelect(position);
          }
        }
      }}
      role="button"
      tabIndex={0}
      className={`${
        isSelected
          ? "bg-green-25 border border-green-400"
          : "bg-white border border-neutral-50"
      } flex flex-row justify-between rounded-lg p-4 cursor-pointer`}
    >
      <p className="w-64 truncate" title={position.current.title || "-"}>
        {position.current.title || "-"}
      </p>
      <p
        className="w-32 truncate"
        title={position.currentDepartment?.name ?? "-"}
      >
        {position.currentDepartment?.name ?? "-"}
      </p>
      <p className="w-32 truncate">
        {position.current.employmentType
          ? formatEmploymentType(position.current.employmentType)
          : "-"}
      </p>
      <p className="w-32 truncate">{paymentUnit}</p>
      <p className="w-32 truncate">
        {position.current.compensationRate
          ? formatCurrency(
              position.current.compensationRate,
              position.current.paymentUnit === "HOURLY" ? true : false,
              position.current.currency,
            )
          : "-"}
      </p>
      <p className="w-32 truncate">
        {position.current.effectiveAt
          ? date(position.current.effectiveAt).format("MM/DD/YYYY")
          : "-"}
      </p>
    </div>
  );
};

export default MiniPositionCard;
