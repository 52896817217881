export default (data: unknown[][]): unknown[][] => {
  const jsonData = JSON.parse(JSON.stringify(data));
  return jsonData.map((row: unknown[]) => {
    return row.map((cell: unknown) => {
      if (!cell) return cell;
      const cellString = String(cell);
      if (
        (cellString.includes("+") ||
          cellString.includes("*") ||
          cellString.includes("=") ||
          cellString.includes("@")) &&
        !cellString.startsWith("=ROUND")
      ) {
        return "'" + cellString.replace(/[";!|\\]/g, '""'); // this regex removes potentially dangerous characters from the cell
      } else {
        return cellString.replace(/[";!|\\]/g, '""');
      }
    });
  });
};
