import React from "react";
import Input from "~/components/Input";
import Combobox from "~/components/Combobox/Combobox";
import { State as ComboboxState } from "~/components/Combobox/Combobox.types";
import { SelectState } from "~/components/Select/Select.types";
import SelectDepartment from "~/components/SelectDepartment";
import Typography from "~/components/Typography";
import InputState = Types.InputState;

const RoleDetailForm = ({
  jobTitle,
  setJobTitle,
  selectDepartment,
  setSelectDepartments,
  managerState,
  setManagerState,
}: {
  jobTitle: InputState;
  setJobTitle: React.Dispatch<React.SetStateAction<InputState>>;
  selectDepartment: SelectState;
  setSelectDepartments: React.Dispatch<React.SetStateAction<SelectState>>;
  managerState: ComboboxState;
  setManagerState: React.Dispatch<React.SetStateAction<ComboboxState>>;
}): React.ReactNode => (
  <div className="flex flex-col gap-3">
    <Typography size="sm" weight="semibold">
      Role Details
    </Typography>
    <div className="w-full flex flex-col gap-3">
      <Input
        id="jobTitle"
        label="Job Title"
        state={jobTitle}
        setState={setJobTitle}
        placeholder="Job title"
      />
      <SelectDepartment
        label="Select a department"
        departmentOptions={selectDepartment}
        setDepartmentOptions={setSelectDepartments}
      />
      <Combobox
        id="select-manager-combobox"
        label="Manager"
        state={managerState}
        setState={setManagerState}
        errorMessage="Manager is required"
        className="hidden"
      />
    </div>
  </div>
);

export default RoleDetailForm;
