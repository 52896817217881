import React from "react";
import Typography from "~/components/Typography";
import Button from "~/components/Button";
import { SelectState } from "~/components/Select/Select.types";
import NonHrisFieldValidation from "./NonHrisFieldValidation";

interface Props {
  id?: string;
  onSubmit: () => void;
  onBack: () => void;
  expectedHoursState: Types.InputState;
  setExpectedHoursState: React.Dispatch<React.SetStateAction<Types.InputState>>;
  bonusAmountState: Types.InputState;
  setBonusAmountState: React.Dispatch<React.SetStateAction<Types.InputState>>;
  commissionAmountState: Types.InputState;
  setCommissionAmountState: React.Dispatch<
    React.SetStateAction<Types.InputState>
  >;
  attainmentState: Types.InputState;
  setAttainmentState: React.Dispatch<React.SetStateAction<Types.InputState>>;
  currencyTypeState: SelectState;
  setCurrencyTypeState: React.Dispatch<React.SetStateAction<SelectState>>;
  paymentUnit: string;
  isLoading: boolean;
}

const ParallelFieldValidation = ({
  id,
  onSubmit,
  onBack,
  expectedHoursState,
  setExpectedHoursState,
  bonusAmountState,
  setBonusAmountState,
  commissionAmountState,
  setCommissionAmountState,
  attainmentState,
  setAttainmentState,
  currencyTypeState,
  setCurrencyTypeState,
  paymentUnit,
  isLoading,
}: Props): React.ReactNode => (
  <div className="flex flex-col space-y-3" data-testid="validate-nonHris-modal">
    <Typography size="xs" color="secondary">
      A few values are not accessible from the HRIS and are sourced from
      Parallel. For these specific fields, validate the data that should be
      associated to the newly filled position.
    </Typography>
    <div className="flex flex-row">
      <NonHrisFieldValidation
        id={id}
        showExpectedHours={paymentUnit === "Hourly"}
        currencyTypeState={currencyTypeState}
        setCurrencyTypeState={setCurrencyTypeState}
        expectedHoursState={expectedHoursState}
        setExpectedHoursState={setExpectedHoursState}
        bonusAmountState={bonusAmountState}
        setBonusAmountState={setBonusAmountState}
        commissionAmountState={commissionAmountState}
        setCommissionAmountState={setCommissionAmountState}
        attainmentState={attainmentState}
        setAttainmentState={setAttainmentState}
      />
    </div>
    <div className="w-full flex flex-row justify-between">
      <Button
        fill="clear"
        className="!w-auto !px-0"
        id="validate-transfer-button-cancel"
        onClick={onBack}
      >
        Back
      </Button>
      <Button
        className="!w-auto"
        id="submit-transfer-button"
        onClick={onSubmit}
        loading={isLoading}
        disabled={isLoading}
      >
        Submit
      </Button>
    </div>
  </div>
);

export default ParallelFieldValidation;
