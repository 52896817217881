import React, { useRef } from "react";
import {
  ArrowLeftIcon,
  CalendarIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  PlusIcon,
  RectangleGroupIcon,
  TagIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Button from "~/components/Button";
import useClickOutside from "~/utils/hooks/useClickOutside";
import DepartmentFilter from "~/components/SearchFilters/DepartmentFilter";
import JobTitleFilter from "~/components/SearchFilters/JobTitleFilter";
import EmployeeNameFilter from "~/components/SearchFilters/EmployeeNameFilter";
import HireDateFilter from "~/components/SearchFilters/HireDateFilter";
import useSearchFilters, { Filter } from "./useSearchFilters";
import EmploymentTypeFilter from "./EmploymentTypeFilter";

interface Props {
  activeFilters: Filter[];
  setActiveFilters: React.Dispatch<React.SetStateAction<Filter[]>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export enum FilterType {
  DEPARTMENT = "DEPARTMENT",
  JOB_TITLE = "JOB_TITLE",
  HIRE_DATE = "HIRE_DATE",
  EMPLOYEE_NAME = "EMPLOYEE_NAME",
  EMPLOYMENT_TYPE = "EMPLOYMENT_TYPE",
}

const SearchFilters = ({
  activeFilters,
  setActiveFilters,
  setIsLoading,
}: Props) => {
  const wrapperRef = useRef<HTMLElement>(null);
  const [addFilterIsOpen, setAddFilterIsOpen] = React.useState(false);
  const [pendingFilter, setPendingFilter] = React.useState<FilterType | null>(
    null,
  );

  useClickOutside(wrapperRef, () => {
    setAddFilterIsOpen(false);
    setPendingFilter(null);
  });

  const availableFilters = [
    {
      id: FilterType.EMPLOYEE_NAME,
      label: (
        <div className="flex items-center gap-2">
          <TagIcon className="w-7 h-7 bg-green p-1 rounded text-white" /> Name{" "}
          <span className="text-neutral-200">· Employee</span>
        </div>
      ),
    },
    {
      id: FilterType.EMPLOYMENT_TYPE,
      label: (
        <div className="flex items-center gap-2">
          <TagIcon className="w-7 h-7 bg-green p-1 rounded text-white" />{" "}
          Employment Type <span className="text-neutral-200">· Position</span>
        </div>
      ),
    },
    {
      id: FilterType.JOB_TITLE,
      label: (
        <div className="flex items-center gap-2">
          <ChatBubbleOvalLeftEllipsisIcon className="w-7 h-7 bg-green p-1 rounded text-white" />{" "}
          Job Title <span className="text-neutral-200">· Position</span>
        </div>
      ),
    },
    {
      id: FilterType.DEPARTMENT,
      label: (
        <div className="flex items-center gap-2">
          <RectangleGroupIcon className="w-7 h-7 bg-green p-1 rounded text-white" />{" "}
          Department <span className="text-neutral-200">· Position</span>
        </div>
      ),
    },
    {
      id: FilterType.HIRE_DATE,
      label: (
        <div className="flex items-center gap-2">
          <CalendarIcon className="w-7 h-7 bg-green p-1 rounded text-white" />{" "}
          Hire Date <span className="text-neutral-200">· Employee</span>
        </div>
      ),
    },
  ];

  const removeActiveFilter = (id: FilterType) => {
    const modifiedFilters = activeFilters.filter((filter) => filter.id !== id);
    setActiveFilters(modifiedFilters);
  };

  const editActiveFilter = (id: FilterType) => {
    const activeFilter = activeFilters.find((filter) => filter.id === id);
    if (activeFilter) {
      setPendingFilter(activeFilter.id);
      setAddFilterIsOpen(true);
    }
  };

  const getSelectedComponent = () => {
    switch (pendingFilter) {
      case FilterType.DEPARTMENT:
        return (
          <DepartmentFilter
            activeFilters={activeFilters}
            onSave={(value: Filter[]) => {
              setActiveFilters(value);
              setPendingFilter(null);
              setAddFilterIsOpen(false);
              setIsLoading(true);
            }}
            onClose={() => {
              setPendingFilter(null);
              setAddFilterIsOpen(false);
            }}
          />
        );
      case FilterType.JOB_TITLE:
        return (
          <JobTitleFilter
            activeFilters={activeFilters}
            onSave={(value: Filter[]) => {
              setActiveFilters(value);
              setPendingFilter(null);
              setAddFilterIsOpen(false);
            }}
            onClose={() => {
              setPendingFilter(null);
              setAddFilterIsOpen(false);
            }}
          />
        );
      case FilterType.EMPLOYEE_NAME:
        return (
          <EmployeeNameFilter
            activeFilters={activeFilters}
            onSave={(value: Filter[]) => {
              setActiveFilters(value);
              setPendingFilter(null);
              setAddFilterIsOpen(false);
              setIsLoading(true);
            }}
            onClose={() => {
              setPendingFilter(null);
              setAddFilterIsOpen(false);
            }}
          />
        );
      case FilterType.HIRE_DATE:
        return (
          <HireDateFilter
            activeFilters={activeFilters}
            onSave={(value: Filter[]) => {
              setActiveFilters(value);
              setPendingFilter(null);
              setAddFilterIsOpen(false);
              setIsLoading(true);
            }}
            onClose={() => {
              setPendingFilter(null);
              setAddFilterIsOpen(false);
            }}
          />
        );
      case FilterType.EMPLOYMENT_TYPE:
        return (
          <EmploymentTypeFilter
            activeFilters={activeFilters}
            onSave={(value: Filter[]) => {
              setActiveFilters(value);
              setPendingFilter(null);
              setAddFilterIsOpen(false);
              setIsLoading(true);
            }}
            onClose={() => {
              setPendingFilter(null);
              setAddFilterIsOpen(false);
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex gap-3">
      {activeFilters.length > 0 && (
        <div className="flex gap-2">
          {activeFilters.map((filter) => (
            <div
              key={filter.id}
              className="flex gap-2 items-center hover:text-green-500 text-neutral-500 bg-white px-2 rounded bg-transparent border-green-500 hover:border-green-700 border"
            >
              {filter.id !== FilterType.EMPLOYMENT_TYPE ? (
                <span className="whitespace-nowrap">{filter.label}</span>
              ) : (
                <button
                  type="button"
                  className="whitespace-nowrap cursor-pointer"
                  onClick={() => editActiveFilter(filter.id)}
                >
                  {filter.label}
                </button>
              )}

              {filter.id !== FilterType.EMPLOYMENT_TYPE && (
                <Button
                  className="text-red !p-0 !m-0"
                  fill="clear"
                  onClick={() => removeActiveFilter(filter.id)}
                >
                  <XMarkIcon className="w-4 h-4" />
                </Button>
              )}
            </div>
          ))}
        </div>
      )}
      <div
        ref={wrapperRef}
        className="bg-white flex items-center gap-2 relative"
      >
        <Button
          fill="outline"
          onClick={() => setAddFilterIsOpen(true)}
          className="flex items-center gap-1"
        >
          <PlusIcon className="w-5 h-5" />
          <span>Add Filter</span>
        </Button>
        {addFilterIsOpen && (
          <div className="absolute top-[50px] left-0 flex-col w-[350px] shadow bg-white rounded p-3">
            {pendingFilter === null &&
              availableFilters
                .filter(
                  (availableFilter) =>
                    !activeFilters.find(
                      (selectedFilter) =>
                        selectedFilter.id === availableFilter.id,
                    ),
                )
                .map((option) => (
                  <Button
                    key={option.id}
                    id={option.id}
                    fill="clear"
                    className="text-neutral-900 flex p-2 my-1 hover:bg-green-25 rounded !justify-start"
                    onClick={() => setPendingFilter(option.id)}
                  >
                    {option.label}
                  </Button>
                ))}
            {pendingFilter !== null && (
              <div className="flex-col w-full">
                {pendingFilter !== FilterType.EMPLOYMENT_TYPE && (
                  <Button
                    fill="clear"
                    className="w-full flex gap-2 items-center"
                    onClick={() => setPendingFilter(null)}
                  >
                    <ArrowLeftIcon className="w-5 h-5" />
                    Back to all filters
                  </Button>
                )}
                {getSelectedComponent()}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export { useSearchFilters };

export default SearchFilters;
