import { z } from "zod";

export const departmentSchema = z.object({
  uuid: z.string().uuid(),
  name: z.string(),
  organizationUuid: z.string(),
  mergeId: z.string().nullable(),
  remoteId: z.string().nullable(),
  type: z.string().nullable(),
  parentUuid: z.string().nullable(),
});
