import React from "react";
import Button from "~/components/Button";

interface Props {
  idsToModify: string[];
  clear: () => void;
}

const SelectedRowActions = ({ clear, idsToModify }: Props) => (
  <div
    className={`sticky shadow top-0 z-30 flex flex-wrap items-center justify-end bg-white h-16 px-4 md:px-6 bb-gray-light gap-5 ${
      idsToModify.length === 0 && "hidden"
    }`}
  >
    <Button
      onClick={clear}
      className="!w-auto border-red text-red"
      fill="outline"
    >
      Clear Selected
    </Button>
  </div>
);

export default SelectedRowActions;
