import React, { ReactNode, useState } from "react";
import { FilterType } from "~/components/SearchFilters/index";

export interface Filter {
  id: FilterType;
  label: string | ReactNode;
  data?: object;
  queryParam?: {
    employeeName?: string;
    employmentType?: string[];
    department?: string;
    title?: string;
    hiredAfter?: string;
    hiredBefore?: string;
  };
}

const useSearchFilters = (
  initialFilters: Filter[] = [],
): [
  filters: Filter[],
  setFilters: React.Dispatch<React.SetStateAction<Filter[]>>,
] => {
  const [filters, setFilters] = useState<Filter[]>(initialFilters);

  return [filters, setFilters];
};
export default useSearchFilters;
