import Button from "~/components/Button";
import Header from "~/components/Header";
import Table, { useSort } from "~/components/Table";
import formatCurrency from "~/utils/formatCurrency";
import request from "~/utils/request";
import date from "~/utils/dates/date";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { State } from "~/store";
import SegmentedControl from "~/components/SegmentedControl";
import useQueryParams from "~/utils/hooks/useQueryParams";
import SearchFilters, {
  FilterType,
  useSearchFilters,
} from "~/components/SearchFilters";
import SelectRow from "~/components/Table/SelectRow";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import SelectedRowActions from "~/components/Table/SelectedRowActions";
import SortableHeader from "~/components/Table/SortableHeader";
import { SortDirection } from "~/components/Table/useSort";
import requestMatchesState from "~/utils/requestMatchesState";
import permissionsCheck from "~/utils/permissionsCheck";
import truncateText from "~/utils/truncateText";
import Typography from "~/components/Typography";
import ExportData from "~/components/ExportData";
import ModifyMultiplePositions from "./ModifyMultiplePositions";
import { Employee, Position, Task } from "./headcount.types";
import CreatePositionContainer from "./CreatePosition";
import "./headcount.css";
import AssignEmployee from "./AssignEmployee";
import HeadcountTagPopover from "./HeadcountTag";

interface AggregatedDataResponse {
  request: unknown;
  data: {
    data: {
      currentHeadcount: Position[];
      forecastedHeadcount: Position[];
      unassignedEmployees: Employee[];
    };
  };
  status: number;
}

interface TaskResponse {
  data: {
    data: Task;
  } | null;
  status: number;
}

type SelectionType = "forecast" | "current";

const Positions = (): React.ReactNode => {
  const navigate = useNavigate();
  const controllerRef = useRef<AbortController>();
  const [queryParams, setQueryParams] = useQueryParams();
  const [selection, setSelection] = useState<SelectionType>(
    (queryParams.get("type") as SelectionType | undefined) ?? "current",
  );
  const [activeFilters, setActiveFilters] = useSearchFilters([
    {
      id: FilterType.EMPLOYMENT_TYPE,
      label: "All Employment Types",
      queryParam: {
        employmentType: ["FULL_TIME", "PART_TIME", "INTERN"],
      },
    },
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const [aggregatedDataState, setAggregatedDataState] = useState<{
    currentPositions: Position[];
    forecastedPositions: Position[];
    unassignedEmployees: Employee[];
  }>({
    currentPositions: [],
    forecastedPositions: [],
    unassignedEmployees: [],
  });
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const {
    permissions: { role, departmentAccessList },
  } = useSelector((state: State) => state.user);
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [taskMetadata, setTaskMetadata] = useState<{
    employeeUuid?: string | null;
    title?: string | null;
    effectiveAt?: string | null;
    manager?: string | null;
    department?: string | null;
    compensationRate?: number | null;
    paymentUnit?: string | null;
    startDate?: string | null;
    employmentType?: string | null;
    employeeName?: string | null;
  } | null>(null);
  const [editModalIsOpen, setEditModalIsOpen] = useState(
    queryParams.get("bulkEdit") !== null,
  );
  const [createPositionModalIsOpen, setCreatePositionModalIsOpen] = useState(
    queryParams.get("createPosition") !== null,
  );
  const [assignEmployeeUuid, setAssignEmployeeUuid] = useState(
    queryParams.get("assignEmployeeUuid") ?? null,
  );
  const [sortTableData, setSortTableData] = useSort({
    key: queryParams.get("sortBy") ?? "hireDate",
    direction:
      (queryParams.get("sortDirection") as SortDirection | undefined) ?? "asc",
  });

  truncateText(".truncate", 26);

  const formatDataForExport = (positions: Position[]) =>
    positions.map((position) => ({
      "Employee ID": position.currentEmployee?.employeeNumber,
      "Employee Name": position.currentEmployee
        ? `${position.currentEmployee.firstName} ${position.currentEmployee.lastName}`
        : undefined,
      "Job Title": position.current.title,
      "Manager Name": position.currentManager
        ? `${position.currentManager.firstName} ${position.currentManager.lastName}`
        : undefined,
      Department: position.currentDepartment?.name ?? undefined,
      "Employment Type": position.current.employmentType,
      "Payment Unit": position.current.paymentUnit,
      Compensation: position.current.compensationRate / 100,
      Currency: position.current.currency,
      "Expected Weekly Hours": position.current.expectedWeeklyHours,
      Bonus: position.current.bonus ? position.current.bonus / 100 : undefined,
      Commission: position.current.commission
        ? position.current.commission / 100
        : undefined,
      Attainment: position.current.attainment
        ? position.current.attainment
        : undefined,
      Location: position.current.location
        ? position.current.location.country
        : undefined,
      "Hire Date":
        position.current.employeeUuid && position.currentEmployee
          ? date(position.currentEmployee.startDate).format("MM/DD/YYYY")
          : date(position.current.effectiveAt).format("MM/DD/YYYY"),
    }));

  const getPageData = async (): Promise<void> => {
    try {
      if (controllerRef.current) controllerRef.current.abort();
      controllerRef.current = new AbortController();
      const { signal } = controllerRef.current;

      const department = activeFilters.find(
        (filter) => filter.id === FilterType.DEPARTMENT,
      )?.queryParam?.department;

      const title = activeFilters.find(
        (filter) => filter.id === FilterType.JOB_TITLE,
      )?.queryParam?.title;

      const employeeName = activeFilters.find(
        (filter) => filter.id === FilterType.EMPLOYEE_NAME,
      )?.queryParam?.employeeName;

      const employmentType = activeFilters.find(
        (filter) => filter.id === FilterType.EMPLOYMENT_TYPE,
      )?.queryParam?.employmentType;

      const hireDates = activeFilters.find(
        (filter) => filter.id === FilterType.HIRE_DATE,
      )?.queryParam;

      const aggregatedDataResponse = (await request({
        url: `/organizations/${organizationUuid}/aggregated-data/headcount`,
        method: "GET",
        params: {
          departments: department ? [department] : undefined,
          title,
          employeeName,
          employmentTypes: employmentType,
          hiredBefore: hireDates?.hiredBefore,
          hiredAfter: hireDates?.hiredAfter,
          sortBy: sortTableData?.key,
          sortDirection: sortTableData?.direction,
        },
        signal,
      })) as AggregatedDataResponse;

      /**
       * TODO - Fix so that we aren't pulling in previous requests. Cancel tokens or something
       */
      if (
        requestMatchesState(aggregatedDataResponse.request, {
          isFilled: selection === "current",
        })
      ) {
        return;
      }

      if (aggregatedDataResponse.status >= 400)
        throw Error("Error fetching positions");
      if (aggregatedDataResponse.data.data) {
        setAggregatedDataState((prevState) => ({
          ...prevState,
          currentPositions: aggregatedDataResponse.data.data.currentHeadcount,
          forecastedPositions:
            aggregatedDataResponse.data.data.forecastedHeadcount,
          unassignedEmployees:
            aggregatedDataResponse.data.data.unassignedEmployees,
        }));
        setIsLoading(false);
      }
    } catch (error) {
      if (error.name !== "CanceledError") {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getPageData();
    setSelectedRowIds([]);
  }, [activeFilters, sortTableData]);

  useEffect(() => {
    setSelectedRowIds([]);
  }, [selection]);

  /**
   * Keep query params in sync with sort state
   */
  useEffect(() => {
    const newQueryParams: {
      type?: SelectionType;
      sortBy?: string;
      sortDirection?: SortDirection;
      bulkEdit?: string;
      createPosition?: string;
      assignEmployeeUuid?: string;
    } = {
      type: selection,
    };
    if (editModalIsOpen) newQueryParams.bulkEdit = "true";
    if (sortTableData) {
      newQueryParams.sortBy = sortTableData.key;
      newQueryParams.sortDirection = sortTableData.direction;
    }
    if (createPositionModalIsOpen) newQueryParams.createPosition = "true";
    if (assignEmployeeUuid)
      newQueryParams.assignEmployeeUuid = assignEmployeeUuid;

    setQueryParams(newQueryParams);
  }, [
    sortTableData,
    editModalIsOpen,
    selection,
    createPositionModalIsOpen,
    assignEmployeeUuid,
  ]);

  const fetchTaskAndOpenAssignModal = async (
    employeeUuid: string,
  ): Promise<void> => {
    const taskResponse = (await request({
      url: `/organizations/${organizationUuid}/employees/${employeeUuid}`,
      method: "GET",
      params: {
        includeTasks: true,
      },
    })) as TaskResponse;

    if (taskResponse.status >= 400) {
      throw Error("Error fetching task");
    }
    if (!taskResponse.data?.data) {
      throw Error("Error fetching task");
    }
    setTaskMetadata(taskResponse.data.data.metadata);

    setAssignEmployeeUuid(employeeUuid);
  };

  const positions =
    selection === "forecast"
      ? aggregatedDataState.forecastedPositions
      : aggregatedDataState.currentPositions ?? [];
  const unassignedEmployees =
    selection === "forecast"
      ? []
      : aggregatedDataState.unassignedEmployees ?? [];

  const tableData = [
    ...unassignedEmployees.map((employee) => ({
      id: employee.uuid,
      values: [
        {
          value: "",
          tdClassName: "px-2 py-4",
        },
        {
          value: employee.employeeNumber ?? "-",
          tdClassName: "px-2 py-4",
        },
        {
          value: `${employee.firstName} ${employee.lastName}`,
          tdClassName: "px-2 py-4 max-w-[200px]",
        },
        {
          value: "-",
          tdClassName: "px-2 py-4",
        },
        {
          value: "-",
          tdClassName: "px-2 py-4",
        },
        {
          value: "-",
          tdClassName: "px-2 py-4",
        },
        {
          value: date(employee.startDate).format("MM/DD/YYYY"),
          tdClassName: "px-2 py-4",
        },
        {
          value: (
            <div className="flex items-center w-full">
              <Button
                onClick={() => fetchTaskAndOpenAssignModal(employee.uuid)}
                fill="clear"
                className="!w-auto !px-0"
              >
                Assign
              </Button>
            </div>
          ),
          tdClassName: "px-2",
        },
      ],
      rowColor: "neutral",
    })),
    ...positions.map((position) => {
      const compensation = `${formatCurrency(
        position.current.compensationRate,
        position.current.paymentUnit === "HOURLY" ? true : false,
        position.current.currency,
      )}/${position.current.paymentUnit === "SALARY" ? "yr" : "hr"}`;

      const toggleSelection = (): void => {
        const isCurrentlySelected = selectedRowIds.includes(
          position.current.positionUuid,
        );
        let updatedSelectedRowIds;

        if (isCurrentlySelected) {
          updatedSelectedRowIds = selectedRowIds.filter(
            (id) => id !== position.current.positionUuid,
          );
        } else {
          updatedSelectedRowIds = [
            ...selectedRowIds,
            position.current.positionUuid,
          ];
        }
        if (updatedSelectedRowIds.length === positions.length) {
          setSelectAll(true);
        } else {
          setSelectAll(false);
        }

        setSelectedRowIds(updatedSelectedRowIds);
      };

      const isSelected = selectedRowIds.includes(position.current.positionUuid);

      return {
        id: position.current.positionUuid,
        isSelected,
        values: [
          {
            value: (
              <SelectRow
                isSelected={isSelected}
                toggleSelected={toggleSelection}
              />
            ),
          },
          {
            value: position.currentEmployee?.employeeNumber ?? "-",
            onClickText: position.currentEmployee?.employeeNumber
              ? (): void =>
                  navigate(
                    `/positions/${position.current.positionUuid}?type=${selection}`,
                  )
              : undefined,
            tdClassName: "px-2 py-4",
          },
          {
            value:
              selection === "forecast" && position.currentEmployee ? (
                <div className="flex flex-col">
                  <Typography size="xs" color="lightGray">
                    Backfill for:
                  </Typography>
                  <Typography color="lightGray">
                    {`${position.currentEmployee.firstName} ${position.currentEmployee.lastName}`}
                  </Typography>
                </div>
              ) : position.currentEmployee ? (
                `${position.currentEmployee.firstName} ${position.currentEmployee.lastName}`
              ) : (
                "-"
              ),
            onClickText: position.currentEmployee
              ? (): void =>
                  navigate(
                    `/positions/${position.current.positionUuid}?type=${selection}`,
                  )
              : undefined,
            tdClassName: "px-2 py-4 max-w-[200px]",
          },
          {
            value: position.current.title,
            onClickText: position.current.title
              ? (): void =>
                  navigate(
                    `/positions/${position.current.positionUuid}?type=${selection}`,
                  )
              : undefined,
            tdClassName: "px-2 py-4 max-w-[300px]",
          },
          {
            value: compensation,
            tdClassName: "px-2 py-4",
          },
          {
            value: position.currentDepartment?.name,
            tdClassName: "px-2 py-4 max-w-[250px]",
          },
          {
            value:
              position.currentEmployee && selection !== "forecast"
                ? date(position.currentEmployee.startDate).format("MM/DD/YYYY")
                : date(position.current.effectiveAt).format("MM/DD/YYYY"),
            tdClassName: "px-2 py-4",
          },
          {
            value: (
              <div
                key={`${position.current.positionUuid}-action-items`}
                className="flex items-center w-full justify-end"
              >
                <div className="px-4 gap-2 flex flex-row">
                  {position.hasFutureChanges && selection === "current" && (
                    <HeadcountTagPopover state="forecastedChange" />
                  )}
                  {position.hasOutdatedForecast && (
                    <HeadcountTagPopover state="outdatedForecast" />
                  )}
                </div>
                <Button
                  id={`go-to-position-${position.current.positionUuid}`}
                  onClick={() =>
                    navigate(
                      `/positions/${position.current.positionUuid}?type=${selection}`,
                    )
                  }
                  fill="clear"
                  className="!w-auto !px-2"
                >
                  <ChevronRightIcon className="w-5 h-5" />
                </Button>
              </div>
            ),
          },
        ],
      };
    }),
  ];

  const emptyState =
    positions.length === 0 && selection === "current" && !isLoading ? (
      <>
        <p className="font-light max-w-lg">
          Link your HRIS to auto-populate your existing positions. <br />
          Once your existing positions are in place, come back and add future
          positions here as well so that you can project your future headcount.
        </p>
        <Link to="/onboarding">
          <Button className="!w-auto !px-10">Link HRIS</Button>
        </Link>
      </>
    ) : (
      <>
        <p className="font-light max-w-lg">
          No positions are currently planned. <br />
          Add positions to project your future headcount.
        </p>

        <div className=" flex flex-row gap-4">
          <Link to="/positions/imports">
            <Button fill="outline" className="!w-auto !px-10">
              Import
            </Button>
          </Link>
          <Button
            onClick={() => setCreatePositionModalIsOpen(true)}
            className="!w-auto !px-10"
          >
            Create New Position
          </Button>
        </div>
      </>
    );

  const isAllSelected = (): boolean =>
    selectedRowIds.length === positions.length;
  const isSomeSelected = (): boolean =>
    selectedRowIds.length > 0 && !isAllSelected();

  const handleSelectAll = (): void => {
    if (isAllSelected() || isSomeSelected()) {
      setSelectedRowIds([]);
      setSelectAll(false);
    } else {
      setSelectedRowIds(
        positions.map((position) => position.current.positionUuid),
      );
      setSelectAll(true);
    }
  };

  const csvToExport =
    selection === "forecast"
      ? formatDataForExport(aggregatedDataState.forecastedPositions).reduce(
          (output, position, index) => {
            if (index === 0) {
              output.push(Object.keys(position));
            }
            output.push(Object.values(position));
            return output;
          },
          [] as (string | number | boolean | React.ReactNode)[][],
        )
      : formatDataForExport(aggregatedDataState.currentPositions).reduce(
          (output, position, index) => {
            if (index === 0) {
              output.push(Object.keys(position));
            }
            output.push(Object.values(position));
            return output;
          },
          [] as (string | number | boolean | React.ReactNode)[][],
        );

  return (
    <>
      <CreatePositionContainer
        isOpen={createPositionModalIsOpen}
        setModal={setCreatePositionModalIsOpen}
        modalSource={selection}
        successCallback={getPageData}
      />
      <ModifyMultiplePositions
        selectedPositions={positions.filter((position) =>
          selectedRowIds.includes(position.current.positionUuid),
        )}
        isOpen={editModalIsOpen}
        onClose={() => {
          getPageData();
          setSelectedRowIds([]);
          setSelectAll(false);
          setEditModalIsOpen(false);
        }}
      />
      <AssignEmployee
        isOpen={!!assignEmployeeUuid}
        setModal={() => {
          setAssignEmployeeUuid(null);
          setTaskMetadata(null);
        }}
        employeeUuid={assignEmployeeUuid}
        employeeData={taskMetadata}
        successCallback={() => {
          setAssignEmployeeUuid(null);
          setTaskMetadata(null);
          setIsLoading(true);
          getPageData();
        }}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        modalSource="AssignEmployee"
      />

      <SelectedRowActions
        clear={() => {
          setSelectAll(false);
          setSelectedRowIds([]);
        }}
        idsToModify={selectedRowIds}
      />
      <Header
        title="Headcount"
        className={`${selectedRowIds.length > 0 && "-top-16 !absolute"}`}
      >
        <div className="w-50 flex justify-center gap-2">
          <SegmentedControl
            name="filters-future-current"
            value={selection}
            disabled={isLoading}
            setValue={(val) => {
              setSelection(val as SelectionType);
            }}
            segments={[
              {
                label: "Current",
                value: "current",
              },
              {
                label: "Future",
                value: "forecast",
              },
            ]}
          />
        </div>
      </Header>
      <div className="flex flex-col container max-w-full items-end justify-center w-full pt-4 pb-10 px-10 mx-auto">
        <div
          className={` flex flex-row gap-4 ${
            !isLoading && selection === "forecast" ? "" : "hidden"
          }`}
        >
          <Link
            to="/positions/imports"
            className={`${
              permissionsCheck(role, departmentAccessList) ? "" : "hidden"
            }`}
          >
            <Button fill="outline" className="!w-auto !px-10">
              Import
            </Button>
          </Link>
          <Button
            onClick={() => setCreatePositionModalIsOpen(true)}
            id="open-create-position-modal"
            className="!w-auto !px-10"
          >
            {permissionsCheck(role, departmentAccessList)
              ? "Create New Position"
              : "Request New Position"}
          </Button>
        </div>
        <div
          className={`${
            selection === "forecast"
              ? "flex flex-row justify-between w-full mb-3 pt-6"
              : "flex flex-row justify-between w-full mb-3 pt-[66px]"
          }`}
        >
          <div className="flex flex-row gap-2 z-10">
            <SearchFilters
              activeFilters={activeFilters}
              setActiveFilters={setActiveFilters}
              setIsLoading={setIsLoading}
            />
          </div>
          <ExportData
            id={`download-${selection}-csv`}
            data={csvToExport}
            filename={`positions-${selection}-${date().format(
              "YYYY-MM-DD",
            )}.csv`}
            disabled={isLoading}
          />
        </div>
        <Table
          id="headcount-table"
          headers={[
            <div
              key="header-select-row"
              className="flex justify-center px-4 m-auto"
            >
              <SelectRow
                key="header-checkbox"
                isSelected={selectAll}
                isIndeterminate={isSomeSelected()}
                toggleSelected={handleSelectAll}
              />
            </div>,
            <div key="header-id" className="py-4 px-2">
              <Typography color="empty">ID</Typography>
            </div>,
            <SortableHeader
              key="header-employee"
              id="employeeName"
              sort={sortTableData}
              setSort={setSortTableData}
              className="py-4 !px-2"
            >
              <Typography color="empty">Employee</Typography>
            </SortableHeader>,
            <SortableHeader
              key="header-job-title"
              id="title"
              sort={sortTableData}
              setSort={setSortTableData}
              className="py-4 !px-2"
            >
              <Typography color="empty">Job Title</Typography>
            </SortableHeader>,
            <SortableHeader
              key="header-pay-rate"
              id="compensationRate"
              sort={sortTableData}
              setSort={setSortTableData}
              className="py-4 !px-2"
            >
              <Typography color="empty">Pay Rate</Typography>
            </SortableHeader>,
            <SortableHeader
              key="header-department"
              id="department"
              sort={sortTableData}
              setSort={setSortTableData}
              className="py-4 !px-2"
            >
              <Typography color="empty">Department</Typography>
            </SortableHeader>,
            <SortableHeader
              key="header-hire-date"
              id="hireDate"
              sort={sortTableData}
              setSort={setSortTableData}
              className="py-4 !px-2"
            >
              <Typography color="empty">Hire Date</Typography>
            </SortableHeader>,
            <div key="header-action-items" className="py-4 px-2 w-[30px]" />,
          ]}
          autoSpacing={false}
          data={tableData}
          loadingState={{
            isLoading,
            skeletonRows: 20,
            widths: [26, 50, 150, 170, 100, 160, 100, 180],
          }}
          emptyState={
            !isLoading && (
              <div className="flex flex-col items-center gap-4 py-16 bg-white">
                <p>No positions found</p>
                {emptyState}
              </div>
            )
          }
        />
      </div>
    </>
  );
};

export default Positions;
