import capitalize from "~/utils/capitalize";
import formatCurrency from "~/utils/formatCurrency";
import { TableData } from "~/components/Table";
import { Projection } from "../types";
import openDrilldownDrawer from "./openDrilldownDrawer";
import { IUseDrilldownDrawer } from "~/components/Drawer/DrilldownDrawer/useDrilldownDrawer";
import { IDatePickerState } from "~/components/DatePicker/useDatePicker";
import { IDepartment } from "~/types/department/types";

export default ({
  headcountTotalParity,
  projections,
  includeTotalRow = true,
  selectYearState,
  departments,
  drilldownDrawerState,
}: {
  headcountTotalParity: boolean;
  projections: Projection[];
  includeTotalRow?: boolean;
  selectYearState: IDatePickerState;
  departments: IDepartment[];
  drilldownDrawerState: IUseDrilldownDrawer;
}): { title: string; data: TableData[] }[] =>
  projections.map(({ title, format, groups, expenseModelUuid }) => {
    // Data level maps over all the groups and extracts the values
    const data: TableData[] = groups.map(
      ({ groupUuid, groupName, values }) => ({
        id: groupUuid,
        exportLabel: groupName,
        rowColor:
          groupName === "New Hires / Transfers In" ||
          groupName === "Terms / Transfers Out"
            ? "neutral"
            : "white",
        values: [
          { value: capitalize(groupName), tdClassName: "max-w-[250px]" },
          ...values.map((val, monthIndex) => {
            if (format === "CURRENCY")
              return {
                value: formatCurrency(val.value, false),
                // Restrict to salaries for now, expand to other tables by removing conditional
                onClickText:
                  title !== "Headcount"
                    ? (): void =>
                        openDrilldownDrawer({
                          category: title,
                          subCategory: groupName,
                          departmentUuids: groupUuid.includes("All Groups")
                            ? []
                            : [groupUuid],
                          monthIndex,
                          expenseModelUuid,
                          selectYearState,
                          departments,
                          drilldownDrawerState,
                        })
                    : headcountTotalParity
                      ? (): void =>
                          openDrilldownDrawer({
                            category: title,
                            subCategory: groupName,
                            departmentUuids: groupUuid.includes("All Groups")
                              ? []
                              : [groupUuid],
                            monthIndex,
                            selectYearState,
                            departments,
                            drilldownDrawerState,
                          })
                      : undefined,
              };

            return {
              value: val.value,
              onClickText: headcountTotalParity
                ? (): void =>
                    openDrilldownDrawer({
                      category: title,
                      subCategory: groupName,
                      departmentUuids: groupUuid.includes("All Groups")
                        ? []
                        : [groupUuid],
                      monthIndex,
                      selectYearState,
                      departments,
                      drilldownDrawerState,
                    })
                : undefined,
            };
          }),
        ],
      }),
    );

    // Generate a totals row that pulls from all the nested value fields and sums up the "columns"
    const totalsRow: TableData = {
      id: "totals",
      exportLabel: "Total",
      values: [
        { value: "Total" },
        ...groups
          .reduce((result, { values }) => {
            const output: number[] = result;
            values.forEach(({ value }, index) => {
              if (!result[index]) output[index] = 0;
              output[index] += value;
            });
            return result;
          }, [])
          .map((val, monthIndex) => {
            if (format === "CURRENCY") {
              return {
                value: formatCurrency(val, false),
                onClickText:
                  title !== "Headcount"
                    ? (): void =>
                        openDrilldownDrawer({
                          category: title,
                          departmentUuids: [],
                          monthIndex,
                          expenseModelUuid,
                          selectYearState,
                          departments,
                          drilldownDrawerState,
                        })
                    : headcountTotalParity
                      ? (): void =>
                          openDrilldownDrawer({
                            category: title,
                            departmentUuids: [],
                            monthIndex,
                            selectYearState,
                            departments,
                            drilldownDrawerState,
                          })
                      : undefined,
              };
            }
            return {
              value: val,
              onClickText: headcountTotalParity
                ? (): void =>
                    openDrilldownDrawer({
                      category: title,
                      departmentUuids: [],
                      monthIndex,
                      selectYearState,
                      departments,
                      drilldownDrawerState,
                    })
                : undefined,
            };
          }),
      ],
    };
    if (includeTotalRow) {
      return {
        title,
        data: [...data, totalsRow],
      };
    }
    return {
      title,
      data,
    };
  });
