import React from "react";
import Card from "~/components/Card";
import Typography from "~/components/Typography";
import Skeleton from "~/components/Skeleton";
import { DashboardContext } from "./context/DashboardContext";

const FutureHeadcountCard = (): React.ReactNode => {
  const dashboardContext = React.useContext(DashboardContext);
  if (!dashboardContext) {
    throw new Error("Dashboard page missing data to provide context");
  }
  const { pageData } = dashboardContext;
  const { isLoading, data } = pageData;

  return (
    <Card>
      <>
        <div className="flex justify-between items-center w-full">
          <Typography tag="h3" color="subHead" size="lg" weight="medium">
            Future Headcount
          </Typography>
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-10">
          <div className="flex flex-col justify-start pr-5">
            <p className="font-thin mt-5">Planned Hires</p>
            {!isLoading ? (
              <p
                className="text-[2.5rem] py-2"
                data-testid="future-headcount-planned-hires"
              >
                {data?.plannedHires}
              </p>
            ) : (
              <Skeleton
                baseColor="green"
                className="my-[25px]"
                height={26}
                width={148}
              />
            )}
          </div>
        </div>
      </>
    </Card>
  );
};

export default FutureHeadcountCard;
