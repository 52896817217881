import React from "react";
import { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { usePopper } from "react-popper";

interface Props {
  columnName: string;
  error: { count: number; preview: string[] };
}

const ErrorPopover = ({ columnName, error }: Props): React.ReactNode => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement);

  const formatHeader = (header: string): string =>
    header
      .replace(/([A-Z])/g, " $1") // Insert space before each capital letter
      .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter

  return (
    <Popover className="relative">
      <Popover.Button ref={setReferenceElement}>
        <InformationCircleIcon className="h-6 w-6" />
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          ref={setPopperElement}
          style={{ ...styles.popper, zIndex: 20, position: "fixed" }}
          {...attributes.popper}
        >
          <div
            data-testid="import-error-popover"
            className="flex flex-col z-20 bg-white py-4 px-6 rounded-lg border-2 border-green-100 gap-2"
          >
            <p className="font-semibold">
              Invalid {formatHeader(columnName)} values
            </p>
            <p className="text-red-300">{error.count} value errors</p>
            <p>
              {`This column contains values
                that aren't able to be imported`}
            </p>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default ErrorPopover;
