import Button from "~/components/Button";
import Header from "~/components/Header";
import Select, { useSelect } from "~/components/Select";
import Card from "~/components/Card";
import { State } from "~/store";
import Typography from "~/components/Typography";
import request from "~/utils/request";
import Divider from "~/components/Divider";
import permissionsCheck from "~/utils/permissionsCheck";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Groups from "./Groups";
import ConnectHris from "./ConnectHRIS";
import Users from "./Users";
import { IOrganizationConfigurationResponse } from "~/components/SideMenu";

const SettingsContainer = (): React.ReactNode => {
  const navigate = useNavigate();
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const {
    permissions: { role, departmentAccessList },
  } = useSelector((state: State) => state.user);

  useEffect(() => {
    if (!permissionsCheck(role, departmentAccessList)) navigate("/dashboard");
  }, []);

  const [fiscalYearStart, setFiscalYearStart] = useSelect({
    options: [
      { value: "JANUARY", label: "January" },
      { value: "FEBRUARY", label: "February" },
      { value: "MARCH", label: "March" },
      { value: "APRIL", label: "April" },
      { value: "MAY", label: "May" },
      { value: "JUNE", label: "June" },
      { value: "JULY", label: "July" },
      { value: "AUGUST", label: "August" },
      { value: "SEPTEMBER", label: "September" },
      { value: "OCTOBER", label: "October" },
      { value: "NOVEMBER", label: "November" },
      { value: "DECEMBER", label: "December" },
    ],
  });

  const getOrganizationConfigurationQuery = useCallback(async () => {
    const { data, status } = (await request({
      url: `/organizations/${organizationUuid}/settings`,
      method: "GET",
    })) as IOrganizationConfigurationResponse;
    if (status === 200 && data.data) {
      if (data.data.fiscalYearStart) {
        const matchedOption = fiscalYearStart.options.find(
          (option) => option.value === data.data?.fiscalYearStart,
        );
        if (matchedOption) fiscalYearStart.selected = matchedOption;
      }
      setFiscalYearStart({
        ...fiscalYearStart,
      });
    }
  }, [organizationUuid, fiscalYearStart]);

  useEffect(() => {
    getOrganizationConfigurationQuery();
  }, []);

  const attemptUpdateOrganizationConfiguration = async (): Promise<void> => {
    if (fiscalYearStart.selected?.value) {
      const updateSettingsResponse = await request({
        url: `/organizations/${organizationUuid}/settings`,
        method: "PATCH",
        body: {
          fiscalYearStart: fiscalYearStart.selected.value,
        },
      });
      if (updateSettingsResponse.status === 200) {
        toast.success("Company settings updated");
      } else {
        getOrganizationConfigurationQuery();
        toast.error("Failed to update company settings");
      }
    } else {
      setFiscalYearStart({
        ...fiscalYearStart,
        pristine: false,
        touched: true,
      });
    }
  };

  return (
    <div className="w-full h-full flex flex-col">
      <Header title="Settings" />
      <div className="flex w-full h-full justify-center px-10 mb-10">
        <div className="max-w-[880px] w-full flex flex-col !items-start">
          <div className="flex w-full flex-row max-lg:flex-col lg:gap-[24px]">
            <div className="flex flex-col lg:w-[50%]">
              <Typography
                tag="h1"
                size="xl"
                weight="bold"
                className="mb-5 mt-10"
              >
                Company Settings
              </Typography>
              <Card className="w-full h-[178px] md:h-[210px] !px-8">
                <Select
                  id="fiscal-year-start"
                  label="Start of fiscal year"
                  state={fiscalYearStart}
                  setState={setFiscalYearStart}
                />
                <div className="flex w-full justify-end">
                  <Button
                    id="fiscal-year-start-save-button"
                    className="mt-5 w-auto px-10"
                    onClick={attemptUpdateOrganizationConfiguration}
                  >
                    Save
                  </Button>
                </div>
              </Card>
            </div>
            <div className="flex flex-col lg:w-[50%]">
              <Typography
                tag="h1"
                size="xl"
                weight="bold"
                className="mb-5 mt-10"
              >
                HRIS Data Upload
              </Typography>
              <Card className="w-full h-[178px] md:h-[210px] !px-8">
                <ConnectHris />
              </Card>
            </div>
          </div>
          <Typography tag="h1" size="xl" weight="bold" className="mb-5 mt-10">
            Manage Departments
          </Typography>
          <div className="w-full mb-10">
            <Groups />
            <Divider className="mt-10" />
            <Users />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsContainer;
