import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { State } from "~/store";
import request from "~/utils/request";
import Card from "~/components/Card";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import Typography from "~/components/Typography";
import Skeleton from "~/components/Skeleton";
import permissionsCheck from "~/utils/permissionsCheck";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

export interface ITaskSummaryResponse {
  data: {
    data: {
      unassignedEmployee: number;
      backfillPosition: number;
      outdatedChange: number;
      outdatedPosition: number;
      outdatedTermination: number;
      requestNewPosition: number;
      requestPositionForecast: number;
      potentialTransfer: number;
    };
  };
  status: number;
}

const TasksCard = (): React.ReactNode => {
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const { total } = useSelector((state: State) => state.tasks);
  const {
    permissions: { role, departmentAccessList },
  } = useSelector((state: State) => state.user);
  const [taskSummary, setTaskSummary] = useState<{
    isLoading: boolean;
    hasTasks?: boolean;
    error?: unknown;
    data?: {
      unassignedEmployee: number;
      backfillPosition: number;
      outdatedChange: number;
      outdatedPosition: number;
      outdatedTermination: number;
      requestNewPosition: number;
      requestPositionForecast: number;
      potentialTransfer: number;
    };
  }>({
    isLoading: true,
  });

  useEffect(() => {
    const getTaskSummary = async (): Promise<void> => {
      try {
        const taskSummaryResponse = (await request({
          url: `/organizations/${organizationUuid}/tasks/summary`,
          method: "GET",
        })) as ITaskSummaryResponse;

        if (taskSummaryResponse.status >= 400)
          throw Error("Failed to fetch task summary");

        const { data } = taskSummaryResponse.data;
        const {
          unassignedEmployee,
          backfillPosition,
          outdatedChange,
          outdatedPosition,
          outdatedTermination,
          requestNewPosition,
          requestPositionForecast,
          potentialTransfer,
        } = data;

        setTaskSummary({
          isLoading: false,
          hasTasks: Object.values(data).some((value) => value > 0),
          data: {
            unassignedEmployee,
            backfillPosition,
            outdatedChange,
            outdatedPosition,
            outdatedTermination,
            requestNewPosition,
            requestPositionForecast,
            potentialTransfer,
          },
        });
      } catch (error: unknown) {
        setTaskSummary({
          isLoading: false,
          error,
        });
      }
    };
    getTaskSummary();
  }, [organizationUuid]);

  const outdatedForecastTotal = ((): number => {
    const { outdatedChange, outdatedPosition, outdatedTermination } =
      taskSummary.data ?? {};

    const totalOutdated =
      (outdatedChange ?? 0) +
      (outdatedTermination ?? 0) +
      (outdatedPosition ?? 0);

    return totalOutdated;
  })();

  const departmentLeaderRequestTotal = ((): number => {
    let totalRequests = 0;
    const { requestNewPosition } = taskSummary.data ?? {};
    totalRequests += requestNewPosition ?? 0;
    const { requestPositionForecast } = taskSummary.data ?? {};
    totalRequests += requestPositionForecast ?? 0;

    return totalRequests;
  })();

  return (
    <Card data-testid="tasks-card">
      <div className="flex flex-col justify-between w-full">
        <Typography tag="h3" color="subHead" size="lg" weight="medium">
          Tasks & Requests
        </Typography>

        {!permissionsCheck(role, departmentAccessList) ? (
          <div
            data-testid="tasks-department-leader-card-state"
            className={`bg-green-15 mt-4 p-5 rounded-2xl w-full flex flex-col gap-1 ${
              !permissionsCheck(role, departmentAccessList) ? "" : "hidden"
            }`}
          >
            <div
              className={`flex justify-between ${
                taskSummary.data?.requestNewPosition ? "" : "hidden"
              }`}
              data-testid="position-request-total"
            >
              <p className="text-start font-thin">Position Requests</p>
              {taskSummary.isLoading ? (
                <Skeleton
                  baseColor="white"
                  className="my-0.5"
                  height={16}
                  width={108}
                />
              ) : (
                <p className="text-end font-thin">
                  {taskSummary.data?.requestNewPosition}
                </p>
              )}
            </div>
            <div
              className={`flex justify-between ${
                taskSummary.data?.requestPositionForecast ? "" : "hidden"
              }`}
              data-testid="position-change-request-total"
            >
              <p className="text-start font-thin">Position Change Requests</p>
              {taskSummary.isLoading ? (
                <Skeleton
                  baseColor="white"
                  className="my-0.5"
                  height={16}
                  width={108}
                />
              ) : (
                <p className="text-end font-thin">
                  {taskSummary.data?.requestPositionForecast ?? 0}
                </p>
              )}
            </div>
            <div
              className={`flex flex-row justify-center ${
                taskSummary.hasTasks ?? taskSummary.isLoading ? "hidden" : ""
              }`}
              data-testid="no-outstanding-tasks"
            >
              <CheckCircleIcon className="mr-2 h-5 w-5 text-green-100" />
              <Typography size="sm" color="lightGray">
                No Outstanding Tasks
              </Typography>
            </div>
          </div>
        ) : (
          <div
            data-testid="tasks-card-state"
            className="bg-green-15 mt-4 p-5 rounded-2xl w-full flex flex-col gap-1"
          >
            <div
              className={`flex justify-between ${
                taskSummary.data?.backfillPosition ? "" : "hidden"
              }`}
              data-testid="backfill-total"
            >
              <p className="text-start font-thin">Backfills</p>
              {taskSummary.isLoading ? (
                <Skeleton
                  baseColor="white"
                  className="my-0.5"
                  height={16}
                  width={108}
                />
              ) : (
                <p className="text-end font-thin">
                  {taskSummary.data?.backfillPosition}
                </p>
              )}
            </div>
            <div
              className={`flex justify-between ${
                departmentLeaderRequestTotal ? "" : "hidden"
              }`}
              data-testid="department-leader-requests-total"
            >
              <p className="text-start font-thin">Department Leader Requests</p>
              {taskSummary.isLoading ? (
                <Skeleton
                  baseColor="white"
                  className="my-0.5"
                  height={16}
                  width={108}
                />
              ) : (
                <p className="text-end font-thin">
                  {departmentLeaderRequestTotal}
                </p>
              )}
            </div>
            <div
              className={`flex justify-between ${
                outdatedForecastTotal ? "" : "hidden"
              }`}
              data-testid="outdated-forecast-total"
            >
              <p className="text-start font-thin">Outdated Forecasts</p>
              {taskSummary.isLoading ? (
                <Skeleton
                  baseColor="white"
                  className="my-0.5"
                  height={16}
                  width={108}
                />
              ) : (
                <p className="text-end font-thin">{outdatedForecastTotal}</p>
              )}
            </div>
            <div
              className={`flex justify-between ${
                taskSummary.data?.potentialTransfer ? "" : "hidden"
              }`}
              data-testid="potential-transfers-total"
            >
              <p className="text-start font-thin">Potential Transfers</p>
              {taskSummary.isLoading ? (
                <Skeleton
                  baseColor="white"
                  className="my-0.5"
                  height={16}
                  width={108}
                />
              ) : (
                <p className="text-end font-thin">
                  {taskSummary.data?.potentialTransfer}
                </p>
              )}
            </div>
            <div
              className={`flex justify-between ${
                taskSummary.data?.unassignedEmployee ? "" : "hidden"
              }`}
              data-testid="unassigned-total"
            >
              <p className="text-start font-thin">New Employees</p>
              {taskSummary.isLoading ? (
                <Skeleton
                  baseColor="white"
                  className="my-0.5"
                  height={16}
                  width={108}
                />
              ) : (
                <p className="text-end font-thin">
                  {taskSummary.data?.unassignedEmployee}
                </p>
              )}
            </div>
            <div
              className={`flex flex-row justify-center ${
                taskSummary.hasTasks ?? taskSummary.isLoading ? "hidden" : ""
              }`}
              data-testid="no-outstanding-tasks"
            >
              <CheckCircleIcon className="mr-2 h-5 w-5 text-green-100" />
              <Typography size="sm" color="lightGray">
                No Outstanding Tasks
              </Typography>
            </div>
          </div>
        )}
        <div className="w-full flex justify-end text-green">
          {taskSummary.isLoading ? (
            <Skeleton
              baseColor="green"
              className="mt-2.5"
              height={26}
              width={182}
            />
          ) : (
            <Link
              to="/tasks"
              className="mt-3 flex items-center"
              data-testid="view-all-tasks-link"
            >
              {total > 0 ? `(${total})` : ""} View All Tasks
              <ChevronRightIcon className="w-5 h-5 inline-block ml-3" />
            </Link>
          )}
        </div>
      </div>
    </Card>
  );
};
export default TasksCard;
