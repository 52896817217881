import React, { useEffect, useState } from "react";
import request from "~/utils/request";
import { TaskInterface } from "~/components/Tasks";
import { useDispatch, useSelector } from "react-redux";
import { State } from "~/store";
import Button from "~/components/Button";
import Typography from "~/components/Typography";
import useQueryParams from "~/utils/hooks/useQueryParams";
import toast from "react-hot-toast";
import { Transition } from "@headlessui/react";
import { tasksSlice } from "~/store/tasksSlice";
import date from "~/utils/dates/date";
import Modal from "~/components/Modal";
import ModifyFutureVersion from "../../pages/Headcount/UpdateFutureVersion";
import ConfirmPrompt from "~/components/ConfirmPrompt";
import { userSlice, IUserPreferences } from "~/store/userSlice";

interface Props {
  task: TaskInterface;
}

interface IUserPreferencesResponse {
  data: {
    data: IUserPreferences;
  };
  status: number;
}

const OutdatedForecastTask = ({ task }: Props): React.ReactNode => {
  const dispatch = useDispatch();
  const organizationUuid = useSelector(
    (state: State) => state.organization.uuid,
  );

  const { total } = useSelector((state: State) => state.tasks);
  const [isCompleted, setIsCompleted] = useState(task.completedAt !== null);

  const [queryParams, setQueryParams] = useQueryParams();
  const [modifyPositionModalIsOpen, setModifyPositionModalIsOpen] = useState(
    queryParams.get("modifyPosition") !== null,
  );
  const [modifyPositionUuid, setModifyPositionUuid] = useState(
    queryParams.get("positionUuid") ?? null,
  );
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
  const [doNotDisplayDeleteModalAgain, setDoNotDisplayDeleteModalAgain] =
    useState(false);
  const {
    uuid: userUuid,
    preferences: { showDeleteTaskConfirmationPrompt },
  } = useSelector((state: State) => state.user);

  const updateConfirmPromptPreference = async (
    updatedValue: boolean,
  ): Promise<void> => {
    try {
      const userPreferenceResponse = (await request({
        url: `/users/${userUuid}/preferences`,
        method: "PATCH",
        body: {
          showDeleteTaskConfirmationPrompt: updatedValue,
        },
      })) as IUserPreferencesResponse;

      if (userPreferenceResponse.status >= 400)
        throw Error("Error updating user preferences");

      dispatch(
        userSlice.actions.setPreferences(userPreferenceResponse.data.data),
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const newQueryParams: {
      modifyPosition?: string;
      positionUuid?: string;
    } = {};

    const handleEscape = (event: KeyboardEvent): void => {
      if (event.key === "Escape") {
        setModifyPositionUuid(null);
        setModifyPositionModalIsOpen(false);
      }
    };

    if (modifyPositionUuid) {
      newQueryParams.modifyPosition = "true";
      newQueryParams.positionUuid = modifyPositionUuid;
    }

    setQueryParams(newQueryParams);

    if (modifyPositionModalIsOpen) {
      document.addEventListener("keydown", handleEscape);
    } else {
      document.removeEventListener("keydown", handleEscape);
    }

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [modifyPositionModalIsOpen, modifyPositionUuid]);

  const deleteOutdatedForecast = async (
    positionUuid: string,
    effectiveAt: string,
  ): Promise<void> => {
    const deletePositionVersionResponse = await request({
      url: `/organizations/${organizationUuid}/positions/${positionUuid}`,
      params: {
        effectiveAt,
      },
      method: "DELETE",
    });

    if (deletePositionVersionResponse.status >= 400) {
      throw Error("Error deleting outdated forecast");
    }

    toast.success("Forecast Deleted");
    dispatch(tasksSlice.actions.update({ total: total - 1 }));

    // Wait for transition animation to run before setting it to completed
    setTimeout(() => {
      setIsCompleted(true);
    }, 300);
  };

  const closeTask = async (): Promise<void> => {
    const taskCompletionResponse = await request({
      method: "POST",
      url: `/organizations/${organizationUuid}/tasks/${task.uuid}/complete`,
    });

    if (taskCompletionResponse.status >= 400)
      throw Error("Error completing task");

    toast.success("Task Completed Successfully");
    dispatch(tasksSlice.actions.update({ total: total - 1 }));

    // Wait for transition animation to run before setting it to completed
    setTimeout(() => {
      setIsCompleted(true);
    }, 300);
  };

  const taskTypeTitleObject: Record<string, string> = {
    outdatedPosition: "New Role",
    outdatedChange: "Change",
    outdatedTermination: "Termination",
  };

  const taskTypeTitle = taskTypeTitleObject[task.type];

  const confirmationMessage =
    task.type === "outdatedTermination"
      ? "The forecasted term and associated backfill will both be deleted. Are you sure you want to continue?"
      : "The forecast will be deleted and any impact on the budget will be removed. Are you sure you want to do this?";

  const deleteButtonText =
    task.type === "outdatedPosition" ? "Delete Position" : "Delete Forecast";

  return (
    <Transition
      show={!isCompleted}
      as={React.Fragment}
      enter="transition ease-linear duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-linear duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        id={`${task.type}-task-${task.uuid}`}
        className={`flex flex-row justify-between py-4 px-6 border border-neutral-50 rounded-lg bg-white w-full ${
          task.completedBy ? "opacity-50" : ""
        }`}
      >
        <Modal
          isOpen={modifyPositionModalIsOpen}
          title="Modify Position Forecast"
          size="md"
        >
          <ModifyFutureVersion
            positionUuid={task.metadata.positionUuid ?? ""}
            originalVersionEffectiveAt={task.metadata.effectiveAt ?? ""}
            onClose={() => {
              setModifyPositionUuid(null);
              setModifyPositionModalIsOpen(false);
            }}
            onComplete={closeTask}
            modalSource={`${task.type}-task`}
            isForecast={task.type === "outdatedPosition"}
          />
        </Modal>
        <div key={task.uuid} className="col-span-7 flex flex-col items-start">
          <Typography
            id={`${task.type}-task-title`}
            tag="h3"
            size="md"
            weight="semibold"
            className="mb-1"
          >
            Forecasted {taskTypeTitle}: Outdated
          </Typography>
          <div
            data-testid={`${task.type}-metadata`}
            className="flex flex-row justify-between gap-6"
          >
            {task.metadata.title && (
              <div className="flex flex-col">
                <Typography size="xs" color="secondary">
                  Title
                </Typography>
                <Typography size="xs" className="max-w-48 truncate">
                  {task.metadata.title}
                </Typography>
              </div>
            )}
            {task.metadata.employeeName && (
              <div className="flex flex-col">
                <Typography size="xs" color="secondary">
                  Employee
                </Typography>
                <Typography size="xs" className="max-w-32 truncate">
                  {task.metadata.employeeName}
                </Typography>
              </div>
            )}
            {task.metadata.effectiveAt && (
              <div className="flex flex-col">
                <Typography size="xs" color="secondary">
                  Forecast Date
                </Typography>
                <Typography size="xs" className="max-w-32 truncate">
                  {date(task.metadata.effectiveAt).format("MM/DD/YYYY")}
                </Typography>
              </div>
            )}
            {task.metadata.department && (
              <div className="flex flex-col max-xl:hidden">
                <Typography size="xs" color="secondary">
                  Department
                </Typography>
                <Typography size="xs" className="max-w-32 truncate">
                  {task.metadata.department}
                </Typography>
              </div>
            )}
            {task.metadata.changeDescription && (
              <div className="flex flex-col">
                <Typography size="xs" color="secondary">
                  Change Description
                </Typography>
                <Typography size="xs" className="max-w-32 truncate">
                  {task.metadata.changeDescription}
                </Typography>
              </div>
            )}
          </div>
        </div>
        {task.completedAt === null && (
          <div className="col-span-2 flex flex-row gap-4 items-center max-xl:flex-col">
            <Button
              fill="destructiveOutline"
              className="!w-auto max-xl:!w-full"
              id={`${task.type}-task-button-delete`}
              onClick={() => {
                if (showDeleteTaskConfirmationPrompt) {
                  setDisplayDeleteModal(true);
                } else {
                  deleteOutdatedForecast(
                    task.metadata.positionUuid ?? "",
                    task.metadata.effectiveAt ?? "",
                  );
                }
              }}
            >
              {deleteButtonText}
            </Button>
            <Button
              className="!w-auto max-xl:!w-full"
              id={`${task.type}-task-button-edit`}
              onClick={() => {
                setModifyPositionModalIsOpen(true);
                setModifyPositionUuid(task.metadata.positionVersionUuid ?? "");
              }}
            >
              Edit
            </Button>
          </div>
        )}
        <ConfirmPrompt
          isOpen={displayDeleteModal}
          onClose={() => setDisplayDeleteModal(false)}
          title={
            task.type === "outdatedTermination"
              ? "Delete Forecasted Term & Backfill"
              : "Delete Forecast"
          }
          message={confirmationMessage}
          confirmButtonText="Confirm, Delete"
          onConfirm={() => {
            deleteOutdatedForecast(
              task.metadata.positionUuid ?? "",
              task.metadata.effectiveAt ?? "",
            );
            setDisplayDeleteModal(false);
            if (doNotDisplayDeleteModalAgain) {
              updateConfirmPromptPreference(false);
            }
          }}
          isChecked={doNotDisplayDeleteModalAgain}
          setIsChecked={() => setDoNotDisplayDeleteModalAgain((prev) => !prev)}
          modalSize="lg"
        />
      </div>
    </Transition>
  );
};

export default OutdatedForecastTask;
