import { useDispatch, useSelector } from "react-redux";
import { State } from "~/store";
import { scenarioSlice } from "~/store/scenarioSlice";
import React, { useEffect, useState } from "react";
import Tray from "~/components/Tray";
import Typography from "~/components/Typography";
import Button from "~/components/Button";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import Divider from "~/components/Divider";
import Segment from "~/components/SegmentedControl";
import toast from "react-hot-toast";
import formatCurrency from "~/utils/formatCurrency";
import request from "~/utils/request";
import Pusher from "pusher-js";
import formatPercent from "~/utils/formatPercent";
import Input, { useInput } from "~/components/Input";

type ScenarioPeriod = "month" | "quarter" | "year";
interface ScenarioSummaryData {
  title: string;
  expenseDifference: {
    current: number;
    adjusted: number;
    differenceAmount: number;
    differencePercent: number;
  };
  adjustments: {
    newRoles: number;
    adjustments: number;
    total: number;
  };
}
interface DeleteScenarioResponse {
  status: number;
  data: unknown;
}
interface GetScenarioDetailsResponse {
  status: number;
  data: {
    data: {
      uuid: string;
      summaryData: ScenarioSummaryData;
    };
  };
}

const ScenarioTray = () => {
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const scenarioState = useSelector((state: State) => state.scenario);
  const scenarioUuid = scenarioState.activeScenarioUuid || undefined;

  const [editTitle, setEditTitle] = useState(false);
  const [scenarioTitleInput, setScenarioTitleInput] = useInput({
    errorMessage: "Please enter a valid title",
  });
  const [scenarioPeriod, setScenarioPeriod] = useState<ScenarioPeriod>("month");
  const [scenarioSummaryData, setScenarioSummaryData] =
    useState<ScenarioSummaryData>();
  const dispatch = useDispatch();
  const [expandedViewEnabled, setExpandedViewEnabled] = useState(false);
  const { inEditMode } = useSelector((state: State) => state.scenario);

  const onClose = () => {
    dispatch(scenarioSlice.actions.update({ inEditMode: false }));
  };

  const onSave = () => {
    toast.success("Scenario saved");
    dispatch(scenarioSlice.actions.update({ inEditMode: false }));
  };

  const deleteScenario = async () => {
    const deleteScenarioResponse = (await request({
      url: `/organizations/${organizationUuid}/scenarios/${scenarioUuid}`,
      method: "DELETE",
    })) as DeleteScenarioResponse;
    if (deleteScenarioResponse.status === 204) {
      toast.success("Changes discarded");
      onClose();
    } else {
      toast.error("Something went wrong, please try again");
    }
  };

  const refreshScenario = async () => {
    const getScenarioDetailsResponse = (await request({
      url: `/organizations/${organizationUuid}/scenarios/${scenarioUuid}`,
      method: "GET",
      params: {
        period: scenarioPeriod,
      },
    })) as GetScenarioDetailsResponse;
    if (getScenarioDetailsResponse.status === 200) {
      setScenarioSummaryData(getScenarioDetailsResponse.data.data.summaryData);
      setScenarioTitleInput((prevState) => ({
        ...prevState,
        value: getScenarioDetailsResponse.data.data.summaryData.title,
        valid: true,
      }));
    }
  };

  useEffect(() => {
    if (scenarioUuid) {
      refreshScenario();
    } else {
      setScenarioSummaryData(undefined);
    }

    const pusher = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
      cluster: "us2",
    });
    const channel = pusher.subscribe(organizationUuid);
    channel.bind(
      "scenario-update",
      async (pusherData: { scenarioUuid: string }) => {
        if (pusherData.scenarioUuid === scenarioUuid) {
          refreshScenario();
        }
      },
    );

    return () => {
      pusher.unsubscribe(organizationUuid);
    };
  }, [scenarioPeriod, scenarioUuid]);

  const attemptSaveTitle = async () => {
    if (scenarioTitleInput.valid) {
      setScenarioSummaryData((prevState) =>
        prevState
          ? {
              ...prevState,
              title: scenarioTitleInput.value,
            }
          : undefined,
      );
      setEditTitle(false);

      const updateScenarioTitleResponse = await request({
        url: `/organizations/${organizationUuid}/scenarios/${scenarioUuid}`,
        method: "PATCH",
        body: {
          changeDescription: scenarioTitleInput.value,
        },
      });
      if (updateScenarioTitleResponse.status === 200) {
        await refreshScenario();
        setScenarioTitleInput((prevState) => ({
          ...prevState,
          pristine: true,
          touched: false,
        }));
      } else {
        setEditTitle(true);
        toast.error("Something went wrong");
      }
    } else {
      setScenarioTitleInput((prevState) => ({
        ...prevState,
        touched: true,
        pristine: false,
      }));
    }
  };

  const periodLabel = {
    month: "Monthly",
    quarter: "Quarterly",
    year: "Yearly",
  };
  return (
    <Tray isOpen={inEditMode}>
      <div className="flex flex-col w-full gap-3">
        <div className="flex justify-between w-full items-center">
          <div className="flex flex-col gap-1">
            <div className="flex items-center">
              {!editTitle && scenarioSummaryData?.title && (
                <>
                  <Typography size="md" weight="bold">
                    {scenarioSummaryData.title}
                  </Typography>
                  <Button
                    fill="clear"
                    className="!w-auto"
                    onClick={() => setEditTitle(true)}
                  >
                    <PencilSquareIcon className="h-5 w-5 text-neutral-100 hover:text-green-400" />
                  </Button>
                </>
              )}
              {editTitle && (
                <Input
                  id="edit-scenario-title"
                  state={scenarioTitleInput}
                  setState={setScenarioTitleInput}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      attemptSaveTitle();
                    }
                  }}
                  onBlur={attemptSaveTitle}
                />
              )}
            </div>
            {!expandedViewEnabled && (
              <div className="flex gap-2 items-center">
                <Typography>
                  {periodLabel[scenarioPeriod]} Difference:{" "}
                  {scenarioSummaryData?.expenseDifference &&
                    formatCurrency(
                      scenarioSummaryData.expenseDifference.differenceAmount,
                      true,
                    )}
                </Typography>
                <Divider orientation="vertical" />
                <Typography>
                  Adjustments: {scenarioSummaryData?.adjustments.total}
                </Typography>
              </div>
            )}
          </div>
          <Button
            fill="clear"
            className="flex items-center !w-auto"
            onClick={() => {
              setExpandedViewEnabled((prevState) => !prevState);
            }}
          >
            {expandedViewEnabled ? `Hide Details` : `Show Details`}
            {expandedViewEnabled ? (
              <ChevronDownIcon className="w-6 h-6 ml-2" />
            ) : (
              <ChevronUpIcon className="w-6 h-6 ml-2" />
            )}
          </Button>
        </div>
        {expandedViewEnabled && (
          <div className="flex justify-between w-full gap-2">
            <section className="bg-green-15 p-5 rounded-2xl w-full flex flex-col gap-1">
              <div className="flex justify-between items-center">
                <Typography size="sm" weight="semibold">
                  Expense Difference
                </Typography>
                <Segment
                  name="scenario-period"
                  value={scenarioPeriod}
                  thin
                  setValue={(val) => setScenarioPeriod(val as ScenarioPeriod)}
                  segments={[
                    {
                      label: "Month",
                      value: "month",
                    },
                    {
                      label: "Quarter",
                      value: "quarter",
                    },
                    {
                      label: "Year",
                      value: "year",
                    },
                  ]}
                />
              </div>
              <div className="flex justify-between">
                <Typography>Current</Typography>
                <Typography>
                  {scenarioSummaryData?.expenseDifference &&
                    formatCurrency(
                      scenarioSummaryData.expenseDifference.current,
                      false,
                    )}
                </Typography>
              </div>
              <div className="flex justify-between pb-1">
                <Typography>Adjusted</Typography>
                <Typography>
                  {scenarioSummaryData?.expenseDifference &&
                    formatCurrency(
                      scenarioSummaryData.expenseDifference.adjusted,
                      false,
                    )}
                </Typography>
              </div>
              <div className="flex justify-between border-t pt-1">
                <Typography>
                  Difference{" "}
                  <Typography className="!text-neutral-200">
                    {scenarioSummaryData?.expenseDifference &&
                      `(${formatPercent(
                        scenarioSummaryData.expenseDifference.differencePercent,
                      )})`}
                  </Typography>
                </Typography>
                <Typography>
                  {" "}
                  {scenarioSummaryData?.expenseDifference &&
                    formatCurrency(
                      scenarioSummaryData.expenseDifference.differenceAmount,
                      false,
                    )}
                </Typography>
              </div>
            </section>
            <section className="bg-green-15 p-5 rounded-2xl w-full flex flex-col gap-1">
              <div className="flex justify-between pt-1">
                <Typography size="sm" weight="semibold">
                  Adjustments
                </Typography>
              </div>
              <div className="flex justify-between pt-1">
                <Typography>New Roles</Typography>
                <Typography>
                  {scenarioSummaryData?.adjustments.newRoles}
                </Typography>
              </div>
              <div className="flex justify-between pt-1">
                <Typography>Adjustments (salary, start date, etc.)</Typography>
                <Typography>
                  {scenarioSummaryData?.adjustments.adjustments}
                </Typography>
              </div>
            </section>
          </div>
        )}
        <div className="flex justify-between w-full">
          <div>
            <Button
              onClick={deleteScenario}
              fill="clear"
              className="text-red-400 !w-auto pl-0"
            >
              Cancel & Revert All Changes
            </Button>
          </div>
          <div className="flex gap-4">
            <Button className="!w-auto" onClick={onSave}>
              Save as a Scenario
            </Button>
          </div>
        </div>
      </div>
    </Tray>
  );
};

export default ScenarioTray;
