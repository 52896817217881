import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { State } from "~/store";
import request from "~/utils/request";
import formatCurrency from "~/utils/formatCurrency";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import Card from "~/components/Card";
import Typography from "~/components/Typography";
import Skeleton from "~/components/Skeleton";
import { format } from "date-fns";
import { ProjectionsResponse } from "../Forecast/types";
import { DashboardContext } from "./context/DashboardContext";

interface Props {
  linkToVariances?: boolean;
}

const BudgetCard = ({ linkToVariances }: Props): React.ReactNode => {
  const dashboardContext = React.useContext(DashboardContext);
  if (!dashboardContext) {
    throw new Error("DashboardContext not provided");
  }
  const { planOptions, periodDates, departmentOptions, isLoadingPlans } =
    dashboardContext;
  const planToCompare = planOptions.selected?.value ?? undefined;
  const controllerRef = useRef<AbortController>();
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const [budgetData, setBudgetData] = React.useState<{
    isLoading: boolean;
    error?: string;
    data?: {
      workingModelSalaries?: number;
      workingModelExpenses: number;
      budgetSalaries: number;
      budgetExpenses: number;
    };
  }>({
    isLoading: true,
  });

  useEffect(() => {
    const getHeadcountData = async (): Promise<void> => {
      if (controllerRef.current) controllerRef.current.abort();
      controllerRef.current = new AbortController();
      const { signal } = controllerRef.current;
      setBudgetData({
        isLoading: true,
      });
      const projectionsResponse = (await request({
        url: `/organizations/${organizationUuid}/projections`,
        method: "GET",
        params: {
          startDate: format(periodDates.startDate, "yyyy-MM-dd"),
          endDate: format(periodDates.endDate, "yyyy-MM-dd"),
          format: "salaries",
          departments: departmentOptions.selected?.value
            ? [departmentOptions.selected.value]
            : undefined,
        },
        signal,
      })) as ProjectionsResponse;
      if (projectionsResponse.status !== 200)
        throw Error("Failed to fetch budget data");

      let budgetSalaries: number;
      let budgetExpenses: number;
      if (planToCompare) {
        const planProjectionResponse = (await request({
          url: `/organizations/${organizationUuid}/projections`,
          method: "GET",
          params: {
            startDate: format(periodDates.startDate, "yyyy-MM-dd"),
            endDate: format(periodDates.endDate, "yyyy-MM-dd"),
            format: "salaries",
            departments: departmentOptions.selected?.value
              ? [departmentOptions.selected.value]
              : undefined,
            planUuid: planToCompare,
          },
        })) as ProjectionsResponse;
        if (planProjectionResponse.status !== 200)
          throw Error("Failed to fetch budget data");

        budgetSalaries = planProjectionResponse.data.data.aggregatedSalaries;
        budgetExpenses = planProjectionResponse.data.data.aggregatedExpenses;
      }

      setBudgetData((prevState) => ({
        ...prevState,
        isLoading: false,
        data: {
          workingModelSalaries:
            projectionsResponse.data.data.aggregatedSalaries,
          workingModelExpenses:
            projectionsResponse.data.data.aggregatedExpenses,
          budgetSalaries,
          budgetExpenses,
        },
      }));
    };
    if (!isLoadingPlans) {
      getHeadcountData();
    }
  }, [
    periodDates.startDate,
    periodDates.endDate,
    isLoadingPlans,
    planToCompare,
    departmentOptions.selected?.value,
    organizationUuid,
    controllerRef,
  ]);

  let budgetPeriodLabel = "";
  switch (periodDates.mode) {
    case "month":
      budgetPeriodLabel = `${format(periodDates.startDate, "MMMM")} Salaries & Expense Models`;
      break;
    case "quarter":
      budgetPeriodLabel = `Quarter ${format(periodDates.startDate, "q")} Salaries & Expense Models`;
      break;
    default:
      budgetPeriodLabel = `${format(periodDates.startDate, "Y")} Salaries & Expense Models`;
  }

  let variance;
  if (
    budgetData.data?.budgetSalaries !== undefined &&
    budgetData.data.workingModelSalaries !== undefined
  ) {
    variance =
      budgetData.data.budgetSalaries +
      budgetData.data.budgetExpenses -
      (budgetData.data.workingModelSalaries +
        budgetData.data.workingModelExpenses);
  }

  const formattedVariance = variance ? formatCurrency(variance, false) : "-";

  const isOverBudget = variance && variance <= 0;

  const titleTag = ((): React.ReactNode => {
    if (planToCompare) {
      return isOverBudget ? (
        <div className="border border-neutral-50 py-1 px-2 rounded-full">
          <Typography size="xs" color="warning">
            Over Budget
          </Typography>
        </div>
      ) : (
        <div className="border border-neutral-50 py-1 px-2 rounded-full">
          <Typography size="xs" color="green">
            Within Budget
          </Typography>
        </div>
      );
    }
    return <p className="text-neutral-200">Locked Plan Required</p>;
  })();

  return (
    <Card>
      <>
        <div className="w-full flex flex-col">
          <div className="flex justify-between">
            <Typography tag="h3" color="subHead" size="lg" weight="medium">
              Budget
            </Typography>
            <div data-testid="budget-card-tag">
              {!isLoadingPlans &&
              !budgetData.isLoading &&
              (budgetData.data?.budgetSalaries !== undefined ||
                !planToCompare) ? (
                titleTag
              ) : (
                <Skeleton className="my-0.5" height={16} width={185} />
              )}
            </div>
          </div>
        </div>
        <div>
          {!isLoadingPlans &&
          !budgetData.isLoading &&
          (budgetData.data?.budgetSalaries !== undefined || !planToCompare) ? (
            <p
              className={`text-[2.5rem] py-2 ${
                planToCompare ? "" : "text-neutral-200"
              }`}
              data-testid="budget-card-total"
            >
              {formattedVariance}
            </p>
          ) : (
            <Skeleton
              baseColor="green"
              className="my-[25px]"
              height={26}
              width={148}
            />
          )}
        </div>
        <div className="bg-green-15 p-5 rounded-2xl w-full flex flex-col gap-1">
          <div
            className={`flex justify-between ${
              planToCompare ? "" : "text-neutral-200"
            }`}
          >
            <p
              className="text-start font-thin"
              data-testid="budget-card-plan-title"
            >
              {budgetPeriodLabel}
            </p>
            {!isLoadingPlans &&
            !budgetData.isLoading &&
            (budgetData.data?.budgetSalaries !== undefined ||
              !planToCompare) ? (
              <p
                className="text-end font-thin"
                data-testid="budget-card-plan-value"
              >
                {budgetData.data?.budgetSalaries !== undefined
                  ? formatCurrency(
                      budgetData.data.budgetSalaries +
                        budgetData.data.budgetExpenses,
                      false,
                    )
                  : "-"}
              </p>
            ) : (
              <Skeleton
                baseColor="white"
                className="my-0.5"
                height={16}
                width={108}
              />
            )}
          </div>
          <div className="flex justify-between pb-1">
            <p
              className="text-start font-thin"
              data-testid="budget-card-actual-title"
            >
              Current Working Model
            </p>
            {!isLoadingPlans && !budgetData.isLoading && budgetData.data ? (
              <p
                className="text-end font-thin"
                data-testid="budget-card-actual-value"
              >
                {budgetData.data.workingModelSalaries &&
                  formatCurrency(
                    budgetData.data.workingModelSalaries +
                      budgetData.data.workingModelExpenses,
                    false,
                  )}
              </p>
            ) : (
              <Skeleton className="my-0.5" height={16} width={108} />
            )}
          </div>
          <div
            className={`flex justify-between border-t pt-1 ${
              planToCompare ? "" : "text-neutral-200"
            }`}
          >
            <p
              className="text-start font-thin"
              data-testid="budget-card-variance-title"
            >
              Variance
            </p>
            {!isLoadingPlans &&
            !budgetData.isLoading &&
            (budgetData.data?.workingModelSalaries !== undefined ||
              !planToCompare) ? (
              <p
                className="text-end font-thin"
                data-testid="budget-card-variance-value"
              >
                {formattedVariance}
              </p>
            ) : (
              <Skeleton
                baseColor="white"
                className="my-0.5"
                height={16}
                width={108}
              />
            )}
          </div>
        </div>
        {linkToVariances && (
          <div className="w-full flex justify-end text-green">
            {!isLoadingPlans && !budgetData.isLoading && budgetData.data ? (
              <Link
                to={{
                  pathname: "/variances",
                  search: `?planUuid=${planToCompare ?? ""}&mode=${
                    periodDates.mode
                  }&startDate=${format(
                    periodDates.startDate,
                    "yyyy-MM-dd",
                  )}&endDate=${format(periodDates.endDate, "yyyy-MM-dd")}`,
                }}
                className="mt-3 flex items-center"
                data-testid="view-variances-link"
              >
                View Variances
                <ChevronRightIcon className="w-5 h-5 inline-block ml-3" />
              </Link>
            ) : (
              <Skeleton
                baseColor="green"
                className="mt-2.5"
                height={26}
                width={182}
              />
            )}
          </div>
        )}
      </>
    </Card>
  );
};

export default BudgetCard;
