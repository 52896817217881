import { z } from "zod";

export const patchForecastedPositionRequestSchema = z.object({
  title: z.string().optional(),
  groupUuid: z.string().nullable().optional(),
  managerUuid: z.string().nullable().optional(),
  employmentType: z.string().optional(),
  paymentUnit: z.string().optional(),
  compensationRate: z.number().optional(),
  currency: z.string().optional(),
  expectedWeeklyHours: z.number().optional(),
  bonus: z.number().nullable(),
  commission: z.number().nullable(),
  attainment: z.number().nullable(),
  effectiveAt: z.string().optional(),
  originalEffectiveAt: z.string().optional(),
});

export const patchActualPositionRequestSchema = z.object({
  expectedWeeklyHours: z.number().optional(),
  bonus: z.number().nullable(),
  commission: z.number().nullable(),
  attainment: z.number().nullable(),
  isActual: z.boolean().optional(),
});

export type IPatchActualPositionRequest = z.infer<
  typeof patchActualPositionRequestSchema
>;

export type IPatchForecastedPositionRequest = z.infer<
  typeof patchForecastedPositionRequestSchema
>;
