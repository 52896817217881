import React, { useEffect, useState } from "react";
import Card from "~/components/Card";
import request from "~/utils/request";
import { useSelector } from "react-redux";
import { State } from "~/store";
import useInput from "~/components/Input/useInput";
import Button from "~/components/Button";
import Input from "~/components/Input";
import Select, { useSelect } from "~/components/Select";
import Typography from "~/components/Typography";
import DepartmentListItem from "./DepartmentListItem";
import { IDepartment, IDepartmentHierarchy } from "~/types/department/types";
import { CommonlyFetchedValuesContext } from "~/context/CommonlyFetchedValuesContext";

const GroupsContainer = (): React.ReactNode => {
  const commonValues = React.useContext(CommonlyFetchedValuesContext);
  if (!commonValues) {
    throw new Error("CommonlyFetchedValuesContext not provided");
  }
  const { departments, getDepartments } = commonValues;
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const [addDepartment, setAddDepartment] = useState(false);
  const [newDepartmentName, setNewDepartmentName] = useInput({});

  const parentDefault = {
    label: "No Parent",
    value: "none",
  };
  const [newDepartmentParent, setNewDepartmentParent] = useSelect({
    options: [parentDefault],
    selected: parentDefault,
  });
  const [departmentHierarchy, setDepartmentHierarchy] = useState<
    IDepartmentHierarchy[]
  >([]);
  const [flatDepartments, setFlatDepartments] = useState<IDepartment[]>([]);

  const formatDepartments = async (): Promise<void> => {
    setNewDepartmentParent({
      ...newDepartmentParent,
      options: [
        parentDefault,
        ...departments.map((department) => ({
          label: department.name,
          value: department.uuid,
        })),
      ],
      selected: parentDefault,
    });
    const departmentDict = departments.reduce(
      (output: Record<string, IDepartment>, department) => {
        const modifiedOutput = output;
        modifiedOutput[department.uuid] = department;
        return modifiedOutput;
      },
      {},
    );

    const rootDepartments: IDepartmentHierarchy[] = [];

    const buildHierarchy = (department: IDepartment): IDepartmentHierarchy => {
      const childDepartments: IDepartment[] = [];

      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const departmentUuid in departmentDict) {
        const childDepartment = departmentDict[departmentUuid];
        if (childDepartment.parentUuid === department.uuid) {
          childDepartments.push(childDepartment);
        }
      }

      return {
        uuid: department.uuid,
        name: department.name,
        parentUuid: department.parentUuid ?? parentDefault.value,
        childDepartments: childDepartments.map(buildHierarchy),
      };
    };

    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const departmentUuid in departmentDict) {
      const department = departmentDict[departmentUuid];
      if (department.parentUuid === null) {
        rootDepartments.push(buildHierarchy(department));
      }
    }

    setDepartmentHierarchy(rootDepartments);
    setFlatDepartments(departments);
  };

  useEffect(() => {
    formatDepartments();
  }, [departments]);

  const attemptAddDepartment = async (): Promise<void> => {
    if (newDepartmentName.valid && newDepartmentParent.valid) {
      const selection = newDepartmentParent.selected?.value;
      await request({
        url: `/organizations/${organizationUuid}/groups`,
        method: "POST",
        body: {
          name: newDepartmentName.value,
          parentUuid: selection !== parentDefault.value ? selection : null,
        },
      });
      setNewDepartmentName({
        ...newDepartmentName,
        value: "",
      });
      setNewDepartmentParent({
        ...newDepartmentParent,
        selected: parentDefault,
      });
      setAddDepartment(false);
      formatDepartments();
    } else {
      setNewDepartmentName({
        ...newDepartmentName,
        pristine: false,
        touched: true,
      });
      setNewDepartmentParent({
        ...newDepartmentParent,
        pristine: false,
        touched: true,
      });
    }
  };

  return (
    <Card className="w-full flex flex-col gap-5">
      <div className="flex flex-row">
        <div className="flex flex-col md:w-[70%]">
          <Typography size="sm" weight="semibold">
            Departments
          </Typography>
          <Typography color="secondary" size="xs">
            Organize and create departments and nested departments here to be
            able to drill down in your projections.
          </Typography>
        </div>
      </div>
      <div className="flex flex-col w-full gap-5">
        {departmentHierarchy.map((department) => (
          <DepartmentListItem
            key={department.uuid}
            department={department}
            allDepartments={flatDepartments}
            onUpdate={async () => {
              await getDepartments();
              formatDepartments();
            }}
          />
        ))}
        {!addDepartment && (
          <Button
            fill="outline"
            className="!w-fit !px-12"
            onClick={() => setAddDepartment(true)}
          >
            Add department
          </Button>
        )}
      </div>
      {addDepartment && (
        <div className="flex flex-col w-full mt-5">
          <h2 className="text-xl font-bold mb-2">Add a new department</h2>
          <Input
            label="Department name"
            className="w-full"
            state={newDepartmentName}
            setState={setNewDepartmentName}
            id="department-name"
            placeholder="Enter department name"
          />
          <Select
            id="department-parent"
            label="Parent Department"
            className="w-full"
            state={newDepartmentParent}
            setState={setNewDepartmentParent}
          />
          <Button className="mt-3" onClick={attemptAddDepartment}>
            Add
          </Button>
          <Button
            className="mt-3"
            fill="outline"
            onClick={() => {
              setNewDepartmentName({
                ...newDepartmentName,
                value: "",
              });
              setNewDepartmentParent({
                ...newDepartmentParent,
                selected: parentDefault,
              });
              setAddDepartment(false);
            }}
          >
            Cancel
          </Button>
        </div>
      )}
    </Card>
  );
};

export default GroupsContainer;
