import React, { useEffect } from "react";
import { Filter } from "~/components/SearchFilters/useSearchFilters";
import Button from "~/components/Button";
import { FilterType } from "~/components/SearchFilters/index";
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/outline";
import useCombobox from "~/components/Combobox/useCombobox";
import Combobox from "~/components/Combobox/Combobox";
import useDebounce from "~/utils/hooks/useDebounce";
import request from "~/utils/request";
import { useSelector } from "react-redux";
import { State } from "~/store";

interface Props {
  activeFilters: Filter[];
  onSave: (filter: Filter[]) => void;
  onClose: () => void;
}

const JobTitleFilter = ({ activeFilters, onSave, onClose }: Props) => {
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const [selectJobTitle, setSelectJobTitle] = useCombobox({
    options: [],
  });
  const debouncedJobTitleQuery = useDebounce(selectJobTitle.query, 300);

  useEffect(() => {
    const fetchJobTitles = async () => {
      const jobTitleResponse = await request({
        url: `/organizations/${organizationUuid}/positions/search`,
        params: {
          query: selectJobTitle.query,
        },
        method: "GET",
      });
      setSelectJobTitle((prevState) => ({
        ...prevState,
        options: jobTitleResponse.data.data.map((jobTitle: string) => ({
          label: jobTitle,
          value: jobTitle,
        })),
      }));
    };
    if (debouncedJobTitleQuery.length > 0) {
      fetchJobTitles();
    } else {
      setSelectJobTitle((prevState) => ({
        ...prevState,
        options: [],
      }));
    }
  }, [debouncedJobTitleQuery]);
  const attemptSave = () => {
    if (selectJobTitle.selected?.value) {
      const activeFilter = activeFilters.find(
        (filter) => filter.id === FilterType.JOB_TITLE,
      );
      if (activeFilter) {
        // TODO - Update existing filter
      } else {
        const newFilter: Filter = {
          id: FilterType.JOB_TITLE,
          label: (
            <div className="flex items-center gap-2 whitespace-nowrap">
              <ChatBubbleOvalLeftEllipsisIcon className="w-5 h-5" />{" "}
              <p
                title={selectJobTitle.selected.label}
                className="max-w-[125px] truncate"
              >
                {selectJobTitle.selected.label}
              </p>
            </div>
          ),
          queryParam: {
            title: selectJobTitle.selected.value,
          },
        };
        onSave([...activeFilters, newFilter]);
        onClose();
      }
    } else {
      setSelectJobTitle({
        ...selectJobTitle,
        pristine: false,
        touched: true,
      });
    }
  };

  return (
    <div className="text-neutral-900 text-left my-5">
      <Combobox
        id="select-job-title"
        label="Job Title"
        state={selectJobTitle}
        setState={setSelectJobTitle}
      />
      <div className="flex justify-end gap-3 my-3">
        <Button
          fill="outline"
          className="text-red border-red !w-auto"
          id="filter-cancel"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button id="filter-save" className="!w-auto" onClick={attemptSave}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default JobTitleFilter;
