import React from "react";
import Card from "~/components/Card";
import request from "~/utils/request";
import { useSelector, useDispatch } from "react-redux";
import { State } from "~/store";
import useInput from "~/components/Input/useInput";
import Input from "~/components/Input";
import Button from "~/components/Button";
import Skeleton from "~/components/Skeleton";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { CommonlyFetchedValuesContext } from "~/context/CommonlyFetchedValuesContext";

const PlansContainer = (): React.ReactNode => {
  const dispatch = useDispatch();
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const [name, setName] = useInput({});
  const commonValues = React.useContext(CommonlyFetchedValuesContext);
  if (!commonValues) {
    throw new Error("CommonlyFetchedValuesContext not provided");
  }
  const { plans, isLoadingPlans, getPlans } = commonValues;

  const attemptCreatePlan = async (): Promise<void> => {
    if (name.valid) {
      const response: ICreatePlanResponse = await request({
        url: `/organizations/${organizationUuid}/plans`,
        method: "POST",
        body: {
          label: name.value,
        },
      });
      if (response.status >= 400) return;
      getPlans();
      toast.success("Plan created successfully");
      setName({ ...name, value: "", pristine: true, touched: false });
      if (!organization.configuration.planCreated) {
        dispatch(
          organizationSlice.actions.update({
            ...organization,
            configuration: {
              ...organization.configuration,
              planCreated: true,
            },
          }),
        );
      }
    } else {
      setName({ ...name, pristine: false, touched: true });
    }
  };

  return (
    <Card className="w-full">
      <p className="text-xl mt-5 font-bold">Lock down a new version</p>
      <p className="italic text-sm mb-2">
        Previous versions will still be available to compare against
      </p>
      <Input
        id="plan-name"
        className="mb-5"
        placeholder="Plan name (e.g. Q3 2023)"
        state={name}
        setState={setName}
      />
      <Button onClick={attemptCreatePlan} id="create-plan-button">
        {" "}
        Lock down new plan
      </Button>

      <p className="text-xl mt-5 font-bold mb-2">Plan History</p>
      <ul data-testid="plan-history-list">
        {!isLoadingPlans ? (
          plans.map((plan) => (
            <li key={plan.uuid} className="mb-1">
              {plan.label}{" "}
              <span className="text-neutral-300">
                {`(Created: ${dayjs(plan.createdAt).format("MM/DD/YYYY")})`}
              </span>
            </li>
          ))
        ) : (
          <Skeleton
            id="plan-history-skeleton"
            width={468}
            height={24}
            baseColor="green"
          />
        )}
      </ul>
    </Card>
  );
};

export default PlansContainer;
