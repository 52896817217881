import React, { useEffect } from "react";
import Header from "~/components/Header";
import Typography from "~/components/Typography";
import { useSelector } from "react-redux";
import { State } from "~/store";
import permissionsCheck from "~/utils/permissionsCheck";
import { useNavigate } from "react-router-dom";
import Scenarios from "./Scenarios";
import Plans from "./Plans";

const ScenariosPage = (): React.ReactNode => {
  const navigate = useNavigate();
  const {
    permissions: { role, departmentAccessList },
  } = useSelector((state: State) => state.user);

  useEffect(() => {
    if (!permissionsCheck(role, departmentAccessList)) navigate("/dashboard");
  }, []);

  return (
    <>
      <Header title="Plans And Scenarios" />
      <div className="max-w-xl py-10 flex flex-col !items-start mx-auto">
        <Typography size="2xl" weight="bold" className="mb-3">
          Plans
        </Typography>
        <Plans />

        <Typography size="2xl" weight="bold" className="mb-3 mt-10">
          Scenarios
        </Typography>
        <Scenarios />
      </div>
    </>
  );
};

export default ScenariosPage;
