import toast from "react-hot-toast";
import zod from "zod";
import iso8601Validator from "~/utils/zodValidationHelpers/iso8601Validator";
import request from "~/utils/request";
import { IPositionsImportedDetails } from "./SummaryStep";
import { isAfter, startOfDay } from "date-fns";

const manualUploadRequestSchema = zod.array(
  zod.object({
    employeeNumber: zod
      .union([zod.string(), zod.number()])
      .transform((val) => val.toString()),
    employeeFirstName: zod.string(),
    employeeLastName: zod.string(),
    hireDate: iso8601Validator,
    jobTitle: zod.string(),
    employmentType: zod
      .string()
      .transform((val) => val.toUpperCase().replace(" ", "_").replace("-", "_"))
      .transform((val) => {
        const acceptedValues: string[] = ["FULL_TIME", "PART_TIME"];
        return (
          acceptedValues.find((acceptedValue) => val.includes(acceptedValue)) ||
          val
        );
      })
      .refine((val) => ["FULL_TIME", "PART_TIME"].includes(val)),
    compensationRate: zod
      .union([zod.string(), zod.number()])
      .transform((value) => {
        // Convert the value to a number if it's a string, remove any commas and other characters that are not numbers or decimals
        if (typeof value === "string") {
          const parsedNumber = parseFloat(value.replace(/[^\d.]/g, ""));
          if (!Number.isNaN(parsedNumber)) {
            return parsedNumber;
          }
        }
        // Return the value as is if it's already a number or couldn't be converted
        return value;
      }),
    paymentType: zod
      .string()
      .transform((val) => val.toUpperCase().replace(" ", "_").replace("-", "_"))
      .refine((val) => ["SALARY", "HOURLY"].includes(val)),
    terminationDate: iso8601Validator.nullable().optional(),
    department: zod.string().nullable().optional(),
    managerName: zod.string().nullable().optional(),
    employeeEmail: zod.string().nullable().optional(),
    currency: zod.string().nullable().optional(),
    country: zod.string().nullable().optional(),
    state: zod.string().nullable().optional(),
  }),
);

const integrationSchema = zod
  .object({
    name: zod.string(),
    type: zod.string().optional(),
    token: zod.string().optional().nullable(),
    connectionStatus: zod.string(),
    lastSync: iso8601Validator.optional().nullable(),
    mergeId: zod.string().optional().nullable(),
    organizationUuid: zod.string(),
    isManual: zod.boolean().default(false),
  })
  .transform((data) => {
    if (data.isManual) {
      return {
        ...data,
        token: data.token ?? null, // Makes token nullable
        mergeId: data.mergeId ?? null, // Makes mergeId nullable
      };
    }
    return data;
  })
  .refine(
    (data) => {
      // Check if type is 'manual'
      if (data.isManual) {
        // Ensure token and mergeId are either strings or null
        return (
          (typeof data.token === "string" || data.token === null) &&
          (typeof data.mergeId === "string" || data.mergeId === null)
        );
      }
      // For other types, ensure token and mergeId are not null
      return typeof data.token === "string" && typeof data.mergeId === "string";
    },
    {
      message:
        "For isManual set to true, token and mergeId must be strings or null. When set to true, they must be strings.",
      path: ["token", "mergeId"],
    },
  );

const manualUploadResponseSchema = zod.object({
  status: zod.number(),
  data: zod.object({
    data: zod.object({
      integration: integrationSchema,
      metadata: zod.union([
        zod.object({
          positionsCreated: zod.number(),
        }),
        zod.object({
          terminations: zod.number(),
          newEmployees: zod.number(),
          updatedEmployees: zod.number(),
        }),
      ]),
    }),
  }),
});

const replaceNullValuesWithUndefined = (data: Record<string, unknown>[]) =>
  data.map((obj) =>
    Object.keys(obj).reduce((newObj, key) => {
      const result: Record<string, unknown> = newObj;
      result[key] = obj[key] === null ? undefined : obj[key];
      return result;
    }, {}),
  );

export default async ({
  integrationUuid,
  organizationUuid,
  hrisName,
  data,
}: {
  integrationUuid?: string;
  organizationUuid: string;
  hrisName: string;
  data: Record<string, unknown>[];
}): Promise<IPositionsImportedDetails> => {
  /**
   * Map the data to the parallel headers
   */

  /**
   * Upload data to the API
   */
  console.log({ data });
  const validatedMappedData = manualUploadRequestSchema.safeParse(data);

  // eslint-disable-next-line no-console -- Logging while this feature is in "beta"
  console.log({ data, validatedMappedData });
  if (!validatedMappedData.success) {
    toast.error("Unable to upload file");
    throw new Error("Unable to upload file");
  }

  const filteredData = validatedMappedData.data.filter((row) => {
    const today = startOfDay(new Date());
    const hireDate = startOfDay(new Date(row.hireDate));
    const terminationDate = row.terminationDate
      ? startOfDay(new Date(row.terminationDate))
      : null;

    return (
      isAfter(today, hireDate) &&
      (terminationDate === null || isAfter(today, terminationDate))
    );
  });

  let manualUploadResponseRaw;

  if (!integrationUuid) {
    manualUploadResponseRaw = await request({
      url: `/organizations/${organizationUuid}/integrations/manual`,
      method: "POST",
      body: {
        type: "hris",
        name: hrisName,
        data: replaceNullValuesWithUndefined(filteredData),
      },
    });
  } else {
    manualUploadResponseRaw = await request({
      url: `/organizations/${organizationUuid}/integrations/${integrationUuid}/manual`,
      method: "PATCH",
      body: replaceNullValuesWithUndefined(filteredData),
    });
  }

  /**
   * Parse and validate response
   */
  const manualUploadResponse = manualUploadResponseSchema.safeParse(
    manualUploadResponseRaw,
  );

  if (
    !manualUploadResponse.success ||
    manualUploadResponse.data.status >= 400
  ) {
    toast.error("There was an error uploading your file");
    throw new Error("There was an error uploading your file");
  }

  return manualUploadResponse.data.data.data.metadata;
};
