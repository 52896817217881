import React from "react";
import Checkbox from "~/components/Checkbox";
import useHover from "~/utils/hooks/useHover";

interface Props {
  isSelected: boolean;
  isIndeterminate?: boolean;
  toggleSelected: () => void;
  className?: string;
}

const SelectRow = ({
  isSelected,
  isIndeterminate,
  toggleSelected,
  className,
}: Props) => {
  const [ref] = useHover();

  return (
    <div
      ref={ref}
      onClick={toggleSelected}
      className="cursor-pointer flex items-center justify-center w-full h-full"
    >
      <Checkbox
        checked={isSelected}
        isIndeterminate={isIndeterminate}
        toggleValue={toggleSelected}
        className={`cursor-pointer ${className ?? ""}`}
      />
    </div>
  );
};

export default SelectRow;
