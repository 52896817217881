import Modal from "~/components/Modal";
import React from "react";
import Button from "~/components/Button";
import Typography from "~/components/Typography";

interface Props {
  isOpen: boolean;
  setModal: () => void;
  submitFunction: () => void;
}

const DeleteRequest = ({
  isOpen,
  setModal,
  submitFunction,
}: Props): React.ReactNode => (
  <Modal isOpen={isOpen} size="xs">
    <div
      data-testid="withdraw-request-modal"
      className="flex flex-col gap-4 justify-center"
    >
      <div>
        <Typography size="lg" weight="bold">
          Withdraw Request
        </Typography>
      </div>
      <div>
        <Typography size="sm" color="secondary">
          The request will be deleted and any associated approvals will be
          dismissed. This action cannot be undone.
        </Typography>
      </div>
      <div className="flex flex-row justify-between">
        <Button onClick={setModal} fill="clear" className="!w-fit !px-0">
          Cancel
        </Button>
        <Button
          id="submit-withdraw-request"
          onClick={submitFunction}
          fill="destructive"
          className="!w-fit"
        >
          Withdraw
        </Button>
      </div>
    </div>
  </Modal>
);

export default DeleteRequest;
