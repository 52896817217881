import React, { useEffect, useState } from "react";
import Card from "~/components/Card";
import request from "~/utils/request";
import { useDispatch, useSelector } from "react-redux";
import { State } from "~/store";
import Button from "~/components/Button";
import dayjs from "dayjs";
import Typography from "~/components/Typography";
import { scenarioSlice } from "~/store/scenarioSlice";
import toast from "react-hot-toast";
import scenariosEmptyState from "../../../assets/scenarios-empty-state.png";

interface Scenario {
  uuid: string;
  changeDescription: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  organizationUuid: string;
}
const ScenarioContainer = (): React.ReactNode => {
  const { inEditMode: inScenarioEditMode } = useSelector(
    (state: State) => state.scenario,
  );
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const [scenarios, setScenarios] = useState<Scenario[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const getScenarios = async (): Promise<void> => {
      const fetchScenariosResponse = await request({
        url: `/organizations/${organizationUuid}/scenarios`,
        method: "GET",
      });
      if (fetchScenariosResponse.status >= 400) return;
      setScenarios(
        fetchScenariosResponse.data.data.sort(
          (a: Scenario, b: Scenario) =>
            dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix(),
        ),
      );
    };
    getScenarios();
  }, [inScenarioEditMode]);

  const createNewScenario = async (): Promise<void> => {
    const createScenarioResponse = await request({
      url: `/organizations/${organizationUuid}/scenarios`,
      method: "POST",
    });
    if (createScenarioResponse.status === 201) {
      dispatch(
        scenarioSlice.actions.update({
          inEditMode: true,
          activeScenarioUuid: createScenarioResponse.data.data.uuid,
        }),
      );
    } else {
      toast.error("Unable to create scenario");
    }
  };

  const setActiveScenario = (uuid: string): void => {
    dispatch(
      scenarioSlice.actions.update({
        inEditMode: true,
        activeScenarioUuid: uuid,
      }),
    );
  };

  return (
    <Card className="w-full">
      <Typography weight="semibold" className="mb-2">
        Scenarios
      </Typography>
      <div data-testid="scenario-history-list" className="w-full flex flex-col">
        {scenarios.length === 0 && (
          <div className="text-center flex flex-col">
            <img
              src={scenariosEmptyState}
              alt="illustration representing scenarios"
              className="w-1/2 mx-auto"
            />
            <Typography weight="semibold" className="mt-4">
              Explore ‘What if’ Scenarios
            </Typography>
            <Typography className=" text-neutral-400">
              Adjust roles to model alternate outcomes, view potential impact,
              and make informed decisions
            </Typography>
          </div>
        )}

        {scenarios.map((scenario) => (
          <div
            key={scenario.uuid}
            className="mb-1 flex justify-between w-full items-center"
          >
            <Typography>
              {scenario.changeDescription}{" "}
              <span className="text-neutral-300">
                {`(Created: ${dayjs(scenario.createdAt).format("MM/DD/YYYY")})`}
              </span>
            </Typography>
            <Button
              fill="clear"
              className="!w-auto"
              onClick={() => setActiveScenario(scenario.uuid)}
            >
              View
            </Button>
          </div>
        ))}
      </div>
      <Button
        className="mt-5"
        onClick={createNewScenario}
        id="create-scenario-button"
        disabled
      >
        Create new scenario (Coming Soon)
      </Button>
    </Card>
  );
};

export default ScenarioContainer;
