import React, { useEffect, useState } from "react";
import Header from "~/components/Header";
import Table from "~/components/Table";
import Typography from "~/components/Typography";
import Modal from "~/components/Modal";
import { useSelector } from "react-redux";
import { State } from "~/store";
import formatPercent from "~/utils/formatPercent";
import formatCurrency from "~/utils/formatCurrency";
import permissionsCheck from "~/utils/permissionsCheck";
import { format } from "date-fns";
import {
  Link,
  useLoaderData,
  useNavigate,
  useRevalidator,
} from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import ManageExpenseModel from "./ManageExpenseModel";
import CreateManualAdjustments from "./CreateManualAdjustments";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import Button from "~/components/Button";
import Popover from "~/components/Popover";
import expenseModelEmptyState from "~/assets/expenseModelEmptyState.svg";

import { z } from "zod";
import { IExpense } from "../../types/expenseModel/types";
import { expenseSchema } from "../../types/expenseModel/schemas";

export interface IListExpenseModelsResponse {
  data: {
    data: IExpense[];
  };
  status: number;
}

const AllExpenseModels = (): React.ReactNode => {
  const expenseModels = useLoaderData();
  const parsedExpenseModels = z.array(expenseSchema).parse(expenseModels);

  const expenseModelManualAdjustmentsEnabled = Boolean(
    useFeatureFlag("expenseModelManualAdjustments"),
  );
  const navigate = useNavigate();
  const {
    permissions: { role, departmentAccessList },
  } = useSelector((state: State) => state.user);
  const [createExpenseModalOpen, setCreateExpenseModalOpen] = useState(false);
  const [createManualModalOpen, setCreateManualModalOpen] = useState(false);

  const revalidator = useRevalidator();

  useEffect(() => {
    if (!permissionsCheck(role, departmentAccessList)) navigate("/dashboard");
  }, []);

  const tableData = parsedExpenseModels.map(
    ({ expenseUuid, current, lastModified }) => {
      const minValue = current.expenseModelSegments.reduce<number | undefined>(
        (output, segment) => {
          if (output === undefined) return segment.value;
          return Math.min(output, segment.value);
        },
        undefined,
      );
      const maxValue = current.expenseModelSegments.reduce<number | undefined>(
        (output, segment) => {
          if (output === undefined) return segment.value;
          return Math.max(output, segment.value);
        },
        undefined,
      );

      let type = "Expense Model";

      switch (current.type) {
        case "PERCENT_EMPLOYEE_SALARY":
          type = "Percentage of salary";
          break;
        case "FIXED_PER_EMPLOYEE":
          type = "Fixed, per employee";
          break;
        case "MANUAL_ADJUSTMENT":
          type = "Manual Adjustment";
          break;
        default:
          break;
      }

      let valueRange;

      if (
        minValue !== undefined &&
        maxValue !== undefined &&
        minValue !== maxValue
      ) {
        if (current.type === "PERCENT_EMPLOYEE_SALARY") {
          valueRange = `${formatPercent(minValue / 10000)} - ${formatPercent(
            maxValue / 10000,
          )}`;
        } else {
          valueRange = `${formatCurrency(minValue, true)} - ${formatCurrency(
            maxValue,
            true,
          )}`;
        }
      } else if (current.type === "PERCENT_EMPLOYEE_SALARY") {
        valueRange = formatPercent(
          current.expenseModelSegments[0].value / 10000,
        );
      } else if (current.type === "MANUAL_ADJUSTMENT") {
        valueRange = "-";
      } else {
        valueRange = formatCurrency(
          current.expenseModelSegments[0].value,
          true,
        );
      }

      return {
        id: current.expenseUuid,
        values: [
          {
            value: (
              <Link
                key={`name-${expenseUuid}`}
                title={current.name}
                to={`/expense-models/${expenseUuid}`}
                className="hover:underline underline-offset-4 hover:text-green-500 cursor-pointer "
              >
                {current.name}
              </Link>
            ),
          },
          { value: valueRange },
          { value: type },
          { value: format(new Date(lastModified), "MM/dd/yyyy") },
          {
            value: (
              <Link
                key={`edit-${expenseUuid}`}
                to={`/expense-models/${expenseUuid}`}
              >
                <ChevronRightIcon className="h-5 w-5" />
              </Link>
            ),
          },
        ],
      };
    },
  );

  return (
    <>
      <Header title="Expense Models" />
      <div className="container flex flex-col">
        <div className="flex justify-end w-full mt-10">
          {expenseModelManualAdjustmentsEnabled ? (
            <Popover
              options={[
                {
                  label: "Headcount Driven",
                  onClick: (): void => {
                    setCreateExpenseModalOpen(true);
                  },
                },
                {
                  label: "Manual Adjustments",
                  onClick: (): void => {
                    setCreateManualModalOpen(true);
                  },
                },
              ]}
            >
              Create Model
            </Popover>
          ) : (
            <Button
              className="!w-auto"
              onClick={() => {
                setCreateExpenseModalOpen(true);
              }}
            >
              New Expense Model
            </Button>
          )}
        </div>
        <Table
          id="headcount-table"
          className="mt-5"
          headers={[
            <Typography
              color="empty"
              className="whitespace-nowrap"
              key="header-name"
            >
              Name
            </Typography>,
            <Typography
              color="empty"
              className="whitespace-nowrap"
              key="header-current-value"
            >
              Current Value
            </Typography>,
            <Typography
              color="empty"
              className="whitespace-nowrap"
              key="header-type"
            >
              Type
            </Typography>,
            <Typography
              color="empty"
              className="whitespace-nowrap"
              key="header-last-edit"
            >
              Last Modified
            </Typography>,
            <span key="header-view-details" />,
          ]}
          headerClassName="px-2 py-4"
          data={tableData}
          emptyState={
            <div className="flex flex-col items-center gap-2 py-16">
              <img
                src={expenseModelEmptyState}
                alt="Empty Tasks Illustration"
                className="w-64 h-auto"
              />
              <div className="max-w-[512px]">
                <Typography color="empty" size="sm">
                  This will assist your organization in managing costs
                  effectively, highlight the total cost of headcount, and
                  facilitate informed decision-making regarding your financial
                  resources.
                </Typography>
              </div>
            </div>
          }
        />
      </div>
      <Modal
        size="lg"
        isOpen={createExpenseModalOpen}
        onClose={() => setCreateExpenseModalOpen(false)}
      >
        <ManageExpenseModel
          onSave={() => {
            setCreateExpenseModalOpen(false);
            revalidator.revalidate();
          }}
        />
      </Modal>
      <Modal
        size="xs"
        isOpen={createManualModalOpen}
        onClose={() => setCreateManualModalOpen(false)}
      >
        <CreateManualAdjustments
          onClose={() => {
            setCreateManualModalOpen(false);
          }}
        />
      </Modal>
    </>
  );
};

export default AllExpenseModels;
