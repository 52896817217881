import React, { useEffect, useState } from "react";
import request from "~/utils/request";
import { TaskInterface, Change } from "~/components/Tasks";
import { useDispatch, useSelector } from "react-redux";
import { State } from "~/store";
import Button from "~/components/Button";
import Typography from "~/components/Typography";
import useQueryParams from "~/utils/hooks/useQueryParams";
import toast from "react-hot-toast";
import { Transition } from "@headlessui/react";
import { tasksSlice } from "~/store/tasksSlice";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import TransferEmployee from "~/components/TransferEmployee";
import ChangeListItem from "./ChangeListItem";

interface Props {
  task: TaskInterface;
}

const PotentialTransferTask = ({ task }: Props): React.ReactNode => {
  const dispatch = useDispatch();
  const organizationUuid = useSelector(
    (state: State) => state.organization.uuid,
  );

  const { total } = useSelector((state: State) => state.tasks);
  const [isCompleted, setIsCompleted] = useState(task.completedAt !== null);

  const [queryParams, setQueryParams] = useQueryParams();
  const [assignEmployeeUuid, setAssignEmployeeUuid] = useState(
    queryParams.get("employeeUuid") ?? null,
  );
  const [displayChangelog, setDisplayChangelog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const newQueryParams: {
      assignEmployeeUuid?: string;
    } = {};

    if (assignEmployeeUuid) {
      newQueryParams.assignEmployeeUuid = assignEmployeeUuid;
    }

    setQueryParams(newQueryParams);
  }, [assignEmployeeUuid]);

  const closeTask = async (): Promise<void> => {
    const taskCompletionResponse = await request({
      method: "POST",
      url: `/organizations/${organizationUuid}/tasks/${task.uuid}/complete`,
    });

    if (taskCompletionResponse.status >= 400)
      throw Error("Error completing task");

    dispatch(tasksSlice.actions.update({ total: total - 1 }));

    // Wait for transition animation to run before setting it to completed
    setTimeout(() => {
      setIsCompleted(true);
    }, 300);
  };

  const updateCurrentPosition = async (): Promise<void> => {
    setIsLoading(true);
    const transferResponse = await request({
      url: `/organizations/${organizationUuid}/tasks/${task.uuid}/process-potential-transfer`,
      method: "POST",
      body: {
        updateCurrentPosition: true,
      },
    });
    setIsLoading(false);
    if (transferResponse.status === 201) {
      toast.success("Updated position");
      closeTask();
    } else {
      toast.error("Error updating position");
    }
  };

  const changeList = task.metadata.potentialTransfer?.changelog.map(
    (change) => (
      <ChangeListItem
        key={change.label}
        label={change.label}
        changedFrom={change.changedFrom}
        changedTo={change.changedTo}
        versionUuid={task.uuid}
      />
    ),
  );

  const employeeDataForAssignEmployee = {
    employeeUuid: task.metadata.potentialTransfer?.employeeUuid,
    title: task.metadata.potentialTransfer?.hrisData.title,
    effectiveAt: task.createdAt,
    manager: task.metadata.potentialTransfer?.managerName,
    department: task.metadata.potentialTransfer?.departmentName,
    compensationRate:
      task.metadata.potentialTransfer?.hrisData.compensationRate,
    paymentUnit: task.metadata.potentialTransfer?.hrisData.paymentUnit,
    startDate: task.createdAt,
    employmentType: task.metadata.potentialTransfer?.hrisData.employmentType,
    employeeName: task.metadata.potentialTransfer?.employeeName,
  };

  const formatTitleFromChangelog = (changelog: Change[]): string => {
    const changes = changelog.map((change) => change.label);

    const titlePrefix = "Potential Transfer:";
    const titleSuffix = "Change";

    if (changes.length > 2) {
      // Join all but the last element with a comma, then add ', and ' before the last element
      return `${titlePrefix} ${changes
        .slice(0, -1)
        .join(", ")}, and ${changes.slice(-1)} ${titleSuffix}`;
    }

    if (changes.length === 2) {
      return `${titlePrefix} ${changes.join(" & ")} ${titleSuffix}`;
    }

    if (changes.length === 1) {
      return `${titlePrefix} ${changes[0]} ${titleSuffix}`;
    }

    return `${titlePrefix} Change`;
  };

  return (
    <Transition
      show={!isCompleted}
      as={React.Fragment}
      enter="transition ease-linear duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-linear duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        data-testid={`${task.type}-task-${task.uuid}-container`}
        className={`flex flex-col gap-4 py-4 px-6 border border-neutral-50 rounded-lg bg-white w-full ${
          task.completedBy ? "opacity-50" : ""
        }`}
      >
        <div className="flex flex-row justify-between w-full">
          <TransferEmployee
            isOpen={!!assignEmployeeUuid}
            setModal={() => setAssignEmployeeUuid(null)}
            employeeData={employeeDataForAssignEmployee}
            successCallback={closeTask}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            taskUuid={task.uuid}
          />
          <div key={task.uuid} className="col-span-7 flex flex-col items-start">
            <Typography
              id={`${task.type}-task-title-${task.uuid}`}
              tag="h3"
              size="md"
              weight="semibold"
              className="mb-1"
            >
              {formatTitleFromChangelog(
                task.metadata.potentialTransfer
                  ? task.metadata.potentialTransfer.changelog
                  : [],
              )}
            </Typography>
            <div
              data-testid={`${task.type}-metadata-${task.uuid}`}
              className="flex flex-row"
            >
              {task.metadata.potentialTransfer?.employeeName && (
                <div className="flex flex-col">
                  <Typography size="xs" color="secondary">
                    Employee
                  </Typography>
                  <Typography size="xs" className="max-w-48 truncate">
                    {task.metadata.potentialTransfer.employeeName}
                  </Typography>
                </div>
              )}
              <Button
                fill="clear"
                onClick={() => setDisplayChangelog(!displayChangelog)}
                className="align-bottom text-red-400 hover:text-red-600"
              >
                {displayChangelog ? "Hide Changes" : "View Changes"}
                {displayChangelog ? (
                  <ChevronUpIcon className="h-4 w-4" />
                ) : (
                  <ChevronDownIcon className="h-4 w-4" />
                )}
              </Button>
            </div>
          </div>
          {task.completedAt === null && (
            <div className="col-span-2 flex flex-row gap-4 items-center max-xl:flex-col">
              <Button
                fill="outline"
                className="!w-auto max-xl:!w-full"
                id={`potentialTransfer-task-button-transfer-${task.uuid}`}
                onClick={() => {
                  setAssignEmployeeUuid(
                    task.metadata.potentialTransfer?.employeeUuid ?? "",
                  );
                }}
              >
                Transfer to New Position
              </Button>
              <Button
                className="!w-auto max-xl:!w-full"
                id={`potentialTransfer-task-button-edit-${task.uuid}`}
                onClick={updateCurrentPosition}
              >
                Update Current Position
              </Button>
            </div>
          )}
        </div>
        {displayChangelog && (
          <div className="flex flex-col gap-4 w-full">{changeList}</div>
        )}
      </div>
    </Transition>
  );
};

export default PotentialTransferTask;
