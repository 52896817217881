import React, { ReactNode, useEffect, useState } from "react";
import Drawer from "~/components/Drawer";
import useDrilldownDrawer, {
  IDrilldownDetails,
} from "~/components/Drawer/DrilldownDrawer/useDrilldownDrawer";
import { TableData } from "~/components/Table";
import Typography from "~/components/Typography";
import Skeleton from "~/components/Skeleton";
import Modal from "~/components/Modal";
import SalaryCalculationContainer from "./DisplayMath/SalaryCalculationContainer";
import { useInput } from "~/components/Input";
import CollapsibleTable from "~/components/CollapsibleTable";
import formatSalaryDrilldownForExport from "~/utils/exportData/formatSalaryDrilldownForExport";
import getDrilldownTableValues, {
  CalculationData,
} from "./getDrilldownTableValues";
import formatHeadcountDrilldownForExport from "~/utils/exportData/formatHeadcountDrilldownForExport";
import formatBridgeDrilldownForExport from "~/utils/exportData/formatBridgeDrilldownForExport";
import { v4 as uuid } from "uuid";
import { ForecastPageContext } from "~/pages/Forecast/context/ForecastPageContext";

const makeTextHeader = ({
  text,
  title,
}: {
  text?: string;
  title?: string;
}): React.ReactNode => (
  <div key={`${text}-${title}`}>
    <Typography color="lightGray">{text}</Typography>
  </div>
);

const DrilldownDrawer = (): React.ReactNode => {
  const forecastContext = React.useContext(ForecastPageContext);
  if (!forecastContext) {
    throw new Error("Forecast page missing data to provide context");
  }
  const { headcountTotalParity, drilldownDrawerState } = forecastContext;
  const { isOpen, onClose, data, modalOpen, toggleModalOpen } =
    drilldownDrawerState;
  const [tableContents, setTableContents] = useState<
    {
      tableTitle?: string;
      columns: ReactNode[];
      data: TableData[];
    }[]
  >([
    {
      columns: ["EMPLOYEE", "POSITIONS", "CALC.", "MO. IMPACT"],
      data: [],
    },
  ]);
  const [calculationData, setCalculationData] = useState<CalculationData>({
    impact: 0,
    calculations: [],
    employeeName: "",
    employeeNumber: "",
    positions: [],
  });
  const [search, setSearch, resetSearch] = useInput({ validation: /^.*$/ });
  const [expandedTables, setExpandedTables] = useState({});

  const handleClickView = (userData: CalculationData): void => {
    setCalculationData(userData);
    toggleModalOpen(true);
  };

  const formatDataForTable = ({
    title,
    rawData,
    drilldownHeaders,
    formatCents,
  }: {
    title?: string;
    rawData: IDrilldownDetails[];
    drilldownHeaders?: string[];
    formatCents?: boolean;
  }): { tableTitle?: string; columns: ReactNode[]; data: TableData[] } => {
    let tableRows: TableData[] = [];

    const filteredData = rawData.filter(
      ({ employeeName, positions, impact }) => {
        if (search.value.toLowerCase().trim() === "") return true;
        if (employeeName?.toLowerCase().includes(search.value.toLowerCase()))
          return true;
        if (impact && impact.toString().includes(search.value)) return true;
        let positionIncluded = false;
        positions.forEach((position) => {
          if (
            position.employmentType
              .replace(/_/g, " ")
              .toLowerCase()
              .includes(search.value.toLowerCase()) ||
            position.title.toLowerCase().includes(search.value.toLowerCase())
          ) {
            positionIncluded = true;
          }
        });
        return positionIncluded;
      },
    );

    if (drilldownHeaders) {
      tableRows = filteredData.map(
        ({
          employeeName,
          employeeNumber,
          positions,
          impact,
          calculations,
        }) => ({
          id: `${title}-${employeeNumber}-${uuid()}`,
          values: drilldownHeaders.map((header) =>
            getDrilldownTableValues({
              title,
              header,
              employeeName,
              employeeNumber,
              positions,
              impact,
              calculations,
              handleClickView,
              formatCents,
            }),
          ),
        }),
      );
    }

    const tableHeaders = drilldownHeaders?.map((header) =>
      makeTextHeader({ text: header, title }),
    );

    if (!tableHeaders) {
      throw new Error("Drilldown headers are required");
    }

    return {
      tableTitle: title ? title : undefined,
      columns: tableHeaders,
      data: tableRows,
    };
  };

  useEffect(() => {
    if (data?.data) {
      const formattedTableContents = data.data.map((drilldownData) => {
        let headers;
        if (!headcountTotalParity) {
          headers = [
            `EMPLOYEE (${drilldownData.drilldownData.length})`,
            "POSITIONS",
            "CALC.",
            "MO. IMPACT",
          ];
        } else {
          headers = drilldownData.drilldownHeaders;
        }
        return formatDataForTable({
          title: drilldownData.drilldownTitle,
          rawData: drilldownData.drilldownData,
          drilldownHeaders: headers,
          formatCents: data.expenseModelUuid ? true : false,
        });
      });
      setTableContents(formattedTableContents);
    } else {
      setTableContents([]);
    }
  }, [data, search.value]);

  useEffect(() => {
    resetSearch();
  }, [drilldownDrawerState.data]);

  const renderTables = () => {
    const toggleTableExpanded = (title?: string): void => {
      if (title) {
        setExpandedTables((prev) => ({
          ...prev,
          [title]: !prev[title],
        }));
      }
    };

    if (drilldownDrawerState.data?.isLoading) {
      return (
        <CollapsibleTable
          title=""
          headers={[`EMPLOYEE`, "POSITIONS", "CALC.", "MO. IMPACT"]}
          data={[]}
          loadingState={true}
          isExpanded={true}
          toggleExpanded={() => {}}
        />
      );
    }

    return tableContents.map((drilldownDetailsData) => (
      <CollapsibleTable
        key={`${drilldownDetailsData.tableTitle}-${uuid()}`}
        title={drilldownDetailsData.tableTitle}
        headers={drilldownDetailsData.columns}
        data={drilldownDetailsData.data}
        loadingState={!!drilldownDrawerState.data?.isLoading}
        isExpanded={expandedTables[drilldownDetailsData.tableTitle] || false}
        toggleExpanded={() =>
          toggleTableExpanded(drilldownDetailsData.tableTitle)
        }
      />
    ));
  };

  let csvToExport;
  switch (true) {
    case data?.title === "Headcount":
      csvToExport = formatHeadcountDrilldownForExport({
        data: data.data ?? [],
      });
      break;
    case data?.title === "Employee Bridge":
      csvToExport = formatBridgeDrilldownForExport({
        data: data.data ?? [],
      });
      break;
    default:
      csvToExport = formatSalaryDrilldownForExport({
        data: data?.data ?? [],
      });
  }

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      title={data?.title ?? <Skeleton width={150} height={16} />}
      subtitle={data?.subtitle ?? <Skeleton width={150} height={16} />}
      exportData={csvToExport}
      inputState={search}
      setInputState={setSearch}
      inputPlaceholder="Search"
      stickyHeader
    >
      <div
        data-testid={`drilldown-tables-${drilldownDrawerState.data?.title.toLowerCase()}`}
      >
        {renderTables()}
      </div>
      <Modal
        id="drilldown-calculation-modal"
        isOpen={modalOpen}
        onClose={() => toggleModalOpen(false)}
        title={calculationData.employeeName}
        position="center"
        size="xl"
        showClose={true}
      >
        <SalaryCalculationContainer
          calculations={calculationData.calculations}
          sumTotal={calculationData.impact}
          positions={calculationData.positions}
        />
      </Modal>
    </Drawer>
  );
};

export { useDrilldownDrawer };

export default DrilldownDrawer;
