import React from "react";
import { useSelector } from "react-redux";
import { State } from "~/store";
import Typography from "~/components/Typography";
import Button from "~/components/Button";
import FieldMapping, { useFieldMapping } from "~/components/FieldMapping";
import { IPositionsImportedDetails } from "./SummaryStep";
import handleUpload from "./handleUpload";

interface Props {
  hrisName: string;
  parsedFileData: {
    headers: string[];
    data: Record<string, unknown>[];
  };
  setPositionsImportedDetails: React.Dispatch<
    React.SetStateAction<IPositionsImportedDetails | undefined>
  >;
  onNext: () => void;
  onBack: () => void;
  integrationUuid?: string;
}

const MappingStep = ({
  hrisName,
  parsedFileData,
  setPositionsImportedDetails,
  onNext,
  onBack,
  integrationUuid,
}: Props) => {
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );

  /**
   * Create a state object that maps parallel headers as the keys to the headers in the file
   */
  const requiredFields = [
    "employeeNumber",
    "employeeFirstName",
    "employeeLastName",
    "hireDate",
    "jobTitle",
    "employmentType",
    "compensationRate",
    "paymentType",
  ];
  const optionalFields = [
    "department",
    "managerName",
    "employeeEmail",
    "currency",
    "country",
    "state",
  ];
  const [fieldMappingState, setFieldMappingState] = useFieldMapping({
    requiredFields,
    optionalFields,
    parsedFileData,
  });

  const attemptSubmit = async () => {
    if (!fieldMappingState.valid) {
      return setFieldMappingState((prevState) => ({
        ...prevState,
        touched: true,
        pristine: false,
      }));
    }

    const mappedData = parsedFileData.data.map((row) => {
      const mappedRow: Record<string, unknown> = {};
      Object.entries(fieldMappingState.fieldMappings).forEach(
        ([parallelKey, providedKey]) => {
          if (providedKey) {
            mappedRow[parallelKey] = row[providedKey];
          }
        },
      );
      return mappedRow;
    });

    const summaryDetails = await handleUpload({
      integrationUuid,
      organizationUuid,
      hrisName,
      data: mappedData,
    });

    setPositionsImportedDetails(summaryDetails);

    return onNext();
  };

  return (
    <div className="flex flex-col gap-3">
      <Typography color="secondary">
        Please map the imported columns to the appropriate data types within our
        system. This step is crucial for accurate data processing and analysis.
      </Typography>
      <Typography>Employee Information</Typography>
      <FieldMapping
        state={fieldMappingState}
        setState={setFieldMappingState}
        options={[
          { value: undefined, label: "- Skip column -" },
          { value: "employeeNumber", label: "Employee Number" },
          {
            value: "employmentType",
            label: "Employment Type (Full Part/Time)",
          },
          { value: "employeeFirstName", label: "First Name" },
          { value: "employeeLastName", label: "Last Name" },
          { value: "hireDate", label: "Hire Date" },
          { value: "terminationDate", label: "Termination Date" },
          { value: "jobTitle", label: "Job Title" },
          { value: "compensationRate", label: "Compensation Rate" },
          { value: "paymentType", label: "Payment Type (Hourly/Salary)" },
          { value: "department", label: "Department" },
          { value: "managerName", label: "Manager Name" },
          { value: "employeeEmail", label: "Employee Email" },
          { value: "currency", label: "Payment Currency" },
          { value: "country", label: "Location: Country" },
          { value: "State", label: "Location: State" },
        ]}
      />
      <div className="w-full flex justify-between">
        <Button className="!w-auto" fill="clear" onClick={onBack}>
          Back
        </Button>
        <Button
          id="submit-hris-data"
          className="!w-auto"
          disabled={!parsedFileData}
          onClick={attemptSubmit}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default MappingStep;
