import React, { useEffect, useState } from "react";
import { IForecastRequest, TaskInterface } from "~/components/Tasks";
import Typography from "~/components/Typography";
import Button from "~/components/Button";
import { Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "~/store";
import request from "~/utils/request";
import { tasksSlice } from "~/store/tasksSlice";
import permissionsCheck from "~/utils/permissionsCheck";
import date from "~/utils/dates/date";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { toast } from "react-hot-toast";
import {
  Position,
  ICurrentPosition,
} from "../../pages/Headcount/headcount.types";
import DeleteRequest from "./DeleteRequest";
import ViewForecastRequest, {
  LABEL_MAP,
  EXCLUDED_KEYS_FROM_CHANGE_LIST,
} from "./ViewRequest/ViewForecastRequest";
import useRejectionState from "./ViewRequest/useRejectionState";
import RejectRequest from "./ViewRequest/RejectRequest";
import ChangeListItem from "../../pages/Headcount/PositionDetails/ChangeListItem";

interface Props {
  requestType: "newPosition" | "forecast";
  requestInformation: IForecastRequest;
  task: TaskInterface;
  successCallback: () => void;
}

export interface PositionResponse {
  data: {
    data: Position;
  } | null;
  status: number;
}

const ForecastRequestTask = ({
  requestType,
  requestInformation,
  task,
  successCallback,
}: Props): React.ReactNode => {
  const dispatch = useDispatch();
  const [displayDeleteRequest, setDisplayDeleteRequest] = useState(false);
  const [displayViewRequest, setDisplayViewRequest] = useState(false);
  const [displayRejectRequest, setDisplayRejectRequest] = useState(false);
  const [positionData, setPositionData] = useState<Position>();
  const [displayChangelog, setDisplayChangelog] = useState(false);
  const organizationUuid = useSelector(
    (state: State) => state.organization.uuid,
  );
  const {
    name: userName,
    uuid: userUuid,
    permissions: { role, departmentAccessList },
  } = useSelector((state: State) => state.user);
  const [isCompleted, setIsCompleted] = useState(
    (requestInformation.requestorUuid === userUuid &&
      !!requestInformation.deletedBy) ||
      ((requestInformation.requestorUuid !== userUuid &&
        requestInformation.status) === "WITHDRAWN" &&
        task.completedAt !== null),
  );
  const { total } = useSelector((state: State) => state.tasks);
  const { reason, setReason, resetFormState } = useRejectionState();
  const isAdmin = permissionsCheck(role, departmentAccessList);
  const isWithdrawn = requestInformation.status === "WITHDRAWN";

  const fetchPositionInformation = async (): Promise<void> => {
    const positionResponse = (await request({
      url: `/organizations/${organizationUuid}/positions/${requestInformation.positionUuid}`,
      method: "GET",
    })) as PositionResponse;

    if (positionResponse.data?.data) {
      setPositionData(positionResponse.data.data);
    }
  };

  useEffect(() => {
    fetchPositionInformation();
  }, []);

  const isRespondedTo =
    requestInformation.status !== "PENDING" &&
    requestInformation.status !== "WITHDRAWN" &&
    task.completedAt !== null;

  const handleDeleteRequest = async (): Promise<void> => {
    const taskCompletionResponse = await request({
      method: "DELETE",
      url: `/organizations/${organizationUuid}/requests/${task.uuid}`,
    });

    if (taskCompletionResponse.status >= 400)
      throw Error("Error Withdrawning Request");

    if (isAdmin) {
      toast.success("Request Dismissed Successfully");
    } else {
      toast.success("Request Withdrawn Successfully");
    }
    setDisplayDeleteRequest(false);
    setDisplayRejectRequest(false);
    // Wait for transition animation to run before setting it to completed
    setTimeout(() => {
      setIsCompleted(true);
    }, 300);

    dispatch(tasksSlice.actions.update({ total: total - 1 }));
  };

  const handleStatus = (status: string): { color: string; message: string } => {
    switch (status) {
      case "PENDING":
        return { color: "neutral-200", message: "Pending" };
      case "APPROVED":
        return { color: "green-400", message: "Approved" };
      case "REJECTED":
        return { color: "red-500", message: "Rejected" };
      default:
        return { color: "neutral-200", message: "Pending" };
    }
  };

  const determineStatusMessage = ((): string => {
    if (isAdmin) {
      if (isWithdrawn) {
        return `Withdrawn by ${requestInformation.requestor}`;
      }
      return `Requested by ${requestInformation.requestor}`;
    }
    // Default status handling for non-admin users
    return handleStatus(requestInformation.status || "PENDING").message;
  })();

  const determineStatusColor = ((): string => {
    if (isAdmin) {
      if (isWithdrawn) {
        return "neutral-400";
      }
      return "neutral-800";
    }
    return handleStatus(requestInformation.status || "PENDING").color;
  })();

  const approveRequest = async (): Promise<void> => {
    const taskCompletionResponse = await request({
      method: "POST",
      url: `/organizations/${organizationUuid}/requests/${task.uuid}/approve`,
      body: { requestType, requestData: requestInformation },
    });

    if (taskCompletionResponse.status >= 400)
      throw Error("Error Approving Request");

    toast.success("Request Approved Successfully");
    dispatch(tasksSlice.actions.update({ total: total - 1 }));
    setDisplayViewRequest(false);

    // Wait for transition animation to run before setting it to completed
    setTimeout(() => {
      setIsCompleted(true);
    }, 300);
  };

  const rejectRequest = async (): Promise<void> => {
    const taskCompletionResponse = await request({
      method: "POST",
      url: `/organizations/${organizationUuid}/requests/${task.uuid}/reject`,
      body: {
        rejectionReason: reason.value,
      },
    });

    if (taskCompletionResponse.status >= 400)
      throw Error("Error Rejecting Request");

    toast.success("Request Rejected Successfully");
    dispatch(tasksSlice.actions.update({ total: total - 1 }));
    setDisplayViewRequest(false);
    setDisplayRejectRequest(false);
    resetFormState();

    // Wait for transition animation to run before setting it to completed
    setTimeout(() => {
      setIsCompleted(true);
    }, 300);
  };

  const handleButtons = ((): JSX.Element => {
    if (requestInformation.status === "REJECTED") {
      return (
        <div className="flex flex-row gap-4">
          <Button
            id={`view-modification-rejection-${task.uuid}`}
            onClick={() => {
              setDisplayRejectRequest(true);
            }}
          >
            View Response
          </Button>
        </div>
      );
    }

    return (
      <Button
        id={`dismiss-modification-request-${task.uuid}`}
        onClick={handleDeleteRequest}
      >
        Dismiss
      </Button>
    );
  })();

  const changeList = Object.keys(requestInformation).map((requestKey) => {
    if (EXCLUDED_KEYS_FROM_CHANGE_LIST.includes(requestKey)) return null;
    return (
      <ChangeListItem
        key={requestKey}
        label={LABEL_MAP[requestKey]}
        changedFrom={
          positionData?.current[requestKey as keyof ICurrentPosition]
        }
        changedTo={requestInformation[requestKey as keyof IForecastRequest]}
        versionUuid={task.uuid}
        currentCurrency={positionData?.current.currency}
        changedCurrency={requestInformation.currency}
      />
    );
  });

  return (
    <Transition
      show={!isCompleted}
      enter="transition ease-in duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-in duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      afterLeave={successCallback}
    >
      <div
        data-testid={`position-modification-request-${task.uuid}`}
        className={`flex flex-col justify-between py-4 px-6 border border-neutral-50 rounded-lg w-full ${
          task.completedBy ? "opacity-50" : ""
        }${isWithdrawn ? " bg-neutral-25" : "bg-white"}`}
      >
        <DeleteRequest
          isOpen={displayDeleteRequest}
          setModal={() => setDisplayDeleteRequest(false)}
          submitFunction={handleDeleteRequest}
        />
        {positionData && (
          <ViewForecastRequest
            isOpen={displayViewRequest}
            setModal={() => setDisplayViewRequest(false)}
            position={positionData}
            request={requestInformation}
            approveFunction={approveRequest}
            rejectFunction={() => {
              setDisplayViewRequest(false);
              setDisplayRejectRequest(true);
            }}
            createdAt={task.createdAt}
            isAdmin={isAdmin}
          />
        )}
        <RejectRequest
          isOpen={displayRejectRequest}
          backFunction={() => {
            if (isAdmin) {
              setDisplayRejectRequest(false);
              setDisplayViewRequest(true);
              resetFormState();
            }
            setDisplayRejectRequest(false);
          }}
          reason={reason}
          setReason={setReason}
          submitFunction={isAdmin ? rejectRequest : handleDeleteRequest}
          isAdmin={isAdmin}
          task={task}
          rejectReason={requestInformation.rejectionReason}
        />
        <div className="flex flex-row justify-between">
          <div key={task.uuid} className="flex flex-col items-start">
            <Typography
              id="unassigned-employee-task-title"
              tag="h3"
              size="md"
              weight="semibold"
              className="mb-1"
            >
              Future Position Modification
              <span
                className={`text-${determineStatusColor} font-normal`}
              >{` (${determineStatusMessage})`}</span>
            </Typography>
            <div
              data-testid={`modification-request-metadata-${task.uuid}`}
              className="flex flex-row gap-4"
            >
              {positionData?.current.title && (
                <div className="flex flex-col">
                  <Typography size="xs" color="secondary">
                    Title
                  </Typography>
                  <Typography
                    tag="h2"
                    size="xs"
                    id="position-title-for-request"
                    className="max-w-32 truncate"
                  >{`${positionData.current.title}`}</Typography>
                </div>
              )}
              {positionData?.currentEmployee && (
                <div className="flex flex-col">
                  <Typography size="xs" color="secondary">
                    Employee
                  </Typography>
                  <Typography
                    tag="h2"
                    size="xs"
                    id="employee-name-for-request"
                    className="max-w-32 truncate"
                  >{`${positionData.currentEmployee.firstName} ${positionData.currentEmployee.lastName}`}</Typography>
                </div>
              )}
              {requestInformation.effectiveAt && (
                <div className="flex flex-col">
                  <Typography size="xs" color="secondary">
                    Effective Date
                  </Typography>
                  <Typography
                    tag="h2"
                    size="xs"
                    id="effective-date-for-request"
                    className="max-w-32 truncate"
                  >{`${date(requestInformation.effectiveAt).format(
                    "MM/DD/YYYY",
                  )}`}</Typography>
                </div>
              )}
              <Button
                fill="clear"
                onClick={() => setDisplayChangelog(!displayChangelog)}
                className="align-bottom text-red-400 hover:text-red-600 text-sm !w-auto"
              >
                {displayChangelog ? "Hide Changes" : "View Changes"}
                {displayChangelog ? (
                  <ChevronUpIcon className="h-4 w-4" />
                ) : (
                  <ChevronDownIcon className="h-4 w-4" />
                )}
              </Button>
            </div>
          </div>
          {isWithdrawn || isRespondedTo ? (
            <div className="flex flex-row gap-4 items-center max-xl:flex-col">
              {handleButtons}
            </div>
          ) : (
            <div className="flex flex-row gap-4 items-center max-xl:flex-col">
              <Button
                id={`withdraw-modification-request-${task.uuid}`}
                fill="destructiveOutline"
                onClick={() => {
                  if (requestInformation.requestor === userName) {
                    setDisplayDeleteRequest(true);
                  }
                }}
                className={`"!w-auto max-xl:!w-full ${isAdmin ? "hidden" : ""}`}
              >
                Withdraw
              </Button>
              <Button
                id={`view-modification-request-${task.uuid}`}
                onClick={() => setDisplayViewRequest(true)}
              >
                View
              </Button>
            </div>
          )}
        </div>
        {displayChangelog && (
          <div className="flex flex-col gap-4 w-full mt-4">{changeList}</div>
        )}
      </div>
    </Transition>
  );
};

export default ForecastRequestTask;
