import { useInput } from "~/components/Input";

const useRejectionState = (): {
  reason: Types.InputState;
  setReason: React.Dispatch<React.SetStateAction<Types.InputState>>;
  resetFormState: () => void;
} => {
  const [reason, setReason] = useInput({
    validation: /.+/,
    errorMessage: "Reason is required",
  });

  const resetFormState = (): void => {
    setReason({
      ...reason,
      value: "",
      touched: false,
      pristine: true,
      valid: false,
    });
  };

  return {
    reason,
    setReason,
    resetFormState,
  };
};

export default useRejectionState;
