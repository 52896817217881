import React, { useEffect } from "react";
import request from "~/utils/request";
import date from "~/utils/dates/date";
import { capitalCase } from "change-case";
import { useSelector } from "react-redux";
import { State } from "~/store";
import permissionsCheck from "~/utils/permissionsCheck";
import usePositionFormState from "../PositionForm/usePositionFormState";
import PositionForm from "../PositionForm";
import { isAfter, isSameDay } from "date-fns";
import { PositionResponse } from "../PositionDetails";

interface Props {
  onComplete: () => void;
  onClose: () => void;
  positionUuid: string;
  originalVersionEffectiveAt: string;
  modalSource?: string;
  isForecast?: boolean;
}
const ModifyFutureVersion = ({
  onComplete,
  onClose,
  positionUuid,
  originalVersionEffectiveAt,
  modalSource,
  isForecast,
}: Props): React.ReactNode => {
  const {
    errorMessage,
    setErrorMessage,
    organizationUuid,
    jobTitle,
    setJobTitle,
    startDateState,
    setStartDateState,
    employmentType,
    setEmploymentType,
    selectedPaymentType,
    setSelectedPaymentType,
    paymentAmountState,
    setPaymentAmountState,
    bonusAmountState,
    setBonusAmountState,
    commissionAmountState,
    setCommissionAmountState,
    attainmentState,
    setAttainmentState,
    currencyTypeState,
    setCurrencyTypeState,
    selectDepartment,
    setSelectDepartment,
    managerState,
    setManagerState,
    changeDescription,
    setChangeDescription,
    expectedHoursState,
    setExpectedHoursState,
    createNewScenario,
    savingPosition,
    setSavingPosition,
  } = usePositionFormState();

  const { inEditMode: inScenarioEditMode, activeScenarioUuid } = useSelector(
    (state: State) => state.scenario,
  );
  const {
    permissions: { role, departmentAccessList },
  } = useSelector((state: State) => state.user);
  const isAdmin = permissionsCheck(role, departmentAccessList);

  useEffect(() => {
    const populatePositionDetails = async (): Promise<void> => {
      const getPositionDetailsResponse = (await request({
        url: `/organizations/${organizationUuid}/positions/${positionUuid}`,
        params: {
          currentAsOfDate: originalVersionEffectiveAt,
        },
        method: "GET",
      })) as PositionResponse;
      if (getPositionDetailsResponse.data) {
        const positionDetails = getPositionDetailsResponse.data.data;
        setJobTitle({
          ...jobTitle,
          value: positionDetails.current.title,
          valid: true,
        });
        const effectiveAtDate = date(
          positionDetails.current.effectiveAt,
        ).isSameOrAfter(date())
          ? positionDetails.current.effectiveAt
          : date().toISOString();

        if (date(positionDetails.current.effectiveAt).isBefore(date())) {
          setStartDateState({
            ...startDateState,
            value: {
              startDate: null,
              endDate: null,
            },
            minDate: date().toISOString(),
            valid: false,
            pristine: false,
            touched: true,
            errorMessage: "This date has passed",
          });
        } else {
          setStartDateState({
            ...startDateState,
            value: {
              startDate: effectiveAtDate,
              endDate: effectiveAtDate,
            },
            minDate: date().toISOString(),
            valid: true,
          });
        }
        setEmploymentType({
          ...employmentType,
          selected: {
            label: capitalCase(positionDetails.current.employmentType),
            value: positionDetails.current.employmentType,
          },
          valid: true,
        });
        setSelectedPaymentType({
          ...selectedPaymentType,
          selected: {
            label: capitalCase(positionDetails.current.paymentUnit),
            value: positionDetails.current.paymentUnit,
          },
          valid: true,
        });
        setPaymentAmountState({
          ...paymentAmountState,
          value: positionDetails.current.compensationRate
            ? (positionDetails.current.compensationRate / 100).toString()
            : "",
          valid: true,
        });
        setBonusAmountState({
          ...bonusAmountState,
          value:
            positionDetails.current.bonus !== null
              ? (positionDetails.current.bonus / 100).toString()
              : "",
          valid: true,
          disabled: isForecast ? false : true,
        });
        setCommissionAmountState({
          ...commissionAmountState,
          value:
            positionDetails.current.commission !== null
              ? (positionDetails.current.commission / 100).toString()
              : "",
          valid: true,
          disabled: isForecast ? false : true,
        });
        setAttainmentState({
          ...attainmentState,
          value:
            positionDetails.current.attainment !== null
              ? (positionDetails.current.attainment * 100).toString()
              : "",
          valid: true,
          disabled: isForecast ? false : true,
        });
        setCurrencyTypeState({
          ...currencyTypeState,
          selected: {
            label: positionDetails.current.currency,
            value: positionDetails.current.currency,
          },
          valid: true,
        });
        setSelectDepartment((prevState) => ({
          ...prevState,
          selected: {
            label: positionDetails.currentDepartment?.name,
            value: positionDetails.currentDepartment?.uuid,
          },
          valid: true,
        }));
        setManagerState({
          ...managerState,
          selected: {
            label: positionDetails.currentManager
              ? `${positionDetails.currentManager.firstName} ${positionDetails.currentManager.lastName}`
              : null,
            value: positionDetails.currentManager?.uuid ?? null,
          },
          valid: true,
        });
        setChangeDescription({
          ...changeDescription,
          value: positionDetails.current.changeDescription ?? "",
          valid: true,
        });
        if (positionDetails.current.paymentUnit === "HOURLY") {
          setExpectedHoursState({
            ...expectedHoursState,
            value: positionDetails.current.expectedWeeklyHours,
            valid: true,
            disabled: isForecast ? false : true,
          });
        }
      }
    };
    populatePositionDetails();
  }, []);
  const attemptSubmit = async (): Promise<void> => {
    setErrorMessage("");
    setSavingPosition(true);
    const startDate = startDateState.value.startDate
      ? date(startDateState.value.startDate).toDate()
      : date().toDate();

    const isSameDayOrAfter =
      isSameDay(startDate, date().toDate()) ||
      isAfter(startDate, date().toDate());
    if (
      jobTitle.value &&
      employmentType.selected?.value &&
      selectedPaymentType.selected?.value &&
      paymentAmountState.value &&
      currencyTypeState.selected?.value &&
      startDateState.value.startDate &&
      isSameDayOrAfter &&
      changeDescription.value &&
      (selectedPaymentType.selected.value === "SALARY" ||
        expectedHoursState.value)
    ) {
      const createPositionResponse = await request({
        url: `/organizations/${organizationUuid}/positions/${positionUuid}`,
        method: "PATCH",
        body: {
          groupUuid: selectDepartment.selected?.value ?? null,
          managerUuid: managerState.selected?.value ?? null,
          employmentType: employmentType.selected.value,
          compensationRate: Number(paymentAmountState.value) * 100,
          bonus: bonusAmountState.value
            ? Number(bonusAmountState.value) * 100
            : null,
          commission: commissionAmountState.value
            ? Number(commissionAmountState.value) * 100
            : null,
          attainment: attainmentState.value
            ? Number(attainmentState.value) / 100
            : null,
          currency: currencyTypeState.selected.value,
          paymentUnit: selectedPaymentType.selected.value,
          title: jobTitle.value,
          effectiveAt: date(startDateState.value.startDate).format(
            "YYYY-MM-DD",
          ),
          changeDescription: changeDescription.value,
          expectedWeeklyHours:
            selectedPaymentType.selected.value === "HOURLY"
              ? expectedHoursState.value
              : undefined,
          scenarioUuid: activeScenarioUuid,
          originalEffectiveAt: originalVersionEffectiveAt,
        },
      });
      if (createPositionResponse.status >= 400) {
        setErrorMessage(
          "There was an error creating the position. Please try again.",
        );
      } else {
        onClose();
        onComplete();
      }
    } else {
      setJobTitle({ ...jobTitle, pristine: false, touched: true });
      setEmploymentType({ ...employmentType, pristine: false, touched: true });
      setSelectedPaymentType({
        ...selectedPaymentType,
        pristine: false,
        touched: true,
      });
      setSelectDepartment({
        ...selectDepartment,
        pristine: false,
        touched: true,
      });
      setPaymentAmountState({
        ...paymentAmountState,
        pristine: false,
        touched: true,
      });
      setBonusAmountState({
        ...bonusAmountState,
        pristine: false,
        touched: true,
      });
      setCommissionAmountState({
        ...commissionAmountState,
        pristine: false,
        touched: true,
      });
      setAttainmentState({
        ...attainmentState,
        pristine: false,
        touched: true,
      });
      setCurrencyTypeState({
        ...currencyTypeState,
        pristine: false,
        touched: true,
      });
      setStartDateState({ ...startDateState, pristine: false, touched: true });
      setChangeDescription({
        ...changeDescription,
        pristine: false,
        touched: true,
      });
      if (selectedPaymentType.selected?.value === "HOURLY") {
        setExpectedHoursState({
          ...expectedHoursState,
          pristine: false,
          touched: true,
        });
      }
    }
    setSavingPosition(false);
  };

  return (
    <div className="w-full mt-4" data-testid="modify-position-version-modal">
      <div className="flex justify-end w-full gap-2">
        <PositionForm
          mode="edit"
          isAdmin={isAdmin}
          onClose={onClose}
          jobTitle={jobTitle}
          setJobTitle={setJobTitle}
          managerState={managerState}
          setManagerState={setManagerState}
          selectDepartment={selectDepartment}
          setSelectDepartment={setSelectDepartment}
          startDateState={startDateState}
          setStartDateState={setStartDateState}
          employmentType={employmentType}
          setEmploymentType={setEmploymentType}
          selectedPaymentType={selectedPaymentType}
          setSelectedPaymentType={setSelectedPaymentType}
          paymentAmountState={paymentAmountState}
          setPaymentAmountState={setPaymentAmountState}
          bonusAmountState={bonusAmountState}
          setBonusAmountState={setBonusAmountState}
          commissionAmountState={commissionAmountState}
          setCommissionAmountState={setCommissionAmountState}
          attainmentState={attainmentState}
          setAttainmentState={setAttainmentState}
          currencyTypeState={currencyTypeState}
          setCurrencyTypeState={setCurrencyTypeState}
          errorMessage={errorMessage}
          savePosition={attemptSubmit}
          changeDescription={changeDescription}
          setChangeDescription={setChangeDescription}
          expectedHoursState={expectedHoursState}
          setExpectedHoursState={setExpectedHoursState}
          createNewScenario={createNewScenario}
          inScenarioEditMode={inScenarioEditMode}
          modalSource={modalSource}
          savingPosition={savingPosition}
        />
      </div>
    </div>
  );
};

export default ModifyFutureVersion;
