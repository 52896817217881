import React from "react";
import { persistor, store } from "~/store";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { FFContextProvider } from "@harnessio/ff-react-client-sdk";
import { PersistGate } from "redux-persist/integration/react";
import ParallelDevTools from "~/components/ParallelDevTools";
import { Toaster } from "react-hot-toast";

import router from "./Router";
import "./styles.css";

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <FFContextProvider
          apiKey={import.meta.env.VITE_HARNESS_API_KEY}
          target={{ name: "Parallel Web App", identifier: "reactclientsdk" }}
        >
          <Toaster toastOptions={{ className: "bg-neutral-700 text-white" }} />
          {!import.meta.env.PROD && <ParallelDevTools />}
          <RouterProvider router={router} />
        </FFContextProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
