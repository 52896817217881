import React from "react";
import formatCurrency from "~/utils/formatCurrency";

export interface Datum {
  timeFrame: string;
  actual: number | null;
  plan: number | null;
}

const TooltipContent = ({
  payload,
  format,
}: {
  payload?: { payload: Datum }[];
  format: Types.DataSubject;
}): React.ReactNode => {
  if (!payload || payload.length === 0) return null;
  const { actual, plan } = payload[0].payload;
  const formatLabel = (value: number | null): string => {
    if (value === null) return "-";
    if (format === "headcount") return `${value}`;
    return formatCurrency(value, false);
  };
  return (
    <div className="bg-white rounded-lg shadow-md p-4">
      <div className="flex flex-col gap-2">
        <div className="w-full flex justify-between gap-3">
          <span
            className="text-neutral-900 font-semibold"
            data-testid="analytics-card-tooltip-plan-label"
          >
            Plan
          </span>
          <span
            className="font-thin"
            data-testid="analytics-card-tooltip-plan-value"
          >
            {formatLabel(plan)}
          </span>
        </div>
        <div className="w-full flex justify-between gap-3">
          <span
            className="text-neutral-900 font-semibold"
            data-testid="analytics-card-tooltip-actual-label"
          >
            Actual
          </span>
          <span
            className="font-thin"
            data-testid="analytics-card-tooltip-actual-value"
          >
            {formatLabel(actual)}
          </span>
        </div>
        <div className="w-full flex justify-between gap-3 border-t pt-1">
          <span
            className="text-neutral-900 font-semibold"
            data-testid="analytics-card-tooltip-variance-label"
          >
            Variance
          </span>
          <span
            className="font-thin"
            data-testid="analytics-card-tooltip-variance-value"
          >
            {formatLabel(
              plan !== null && actual !== null ? plan - actual : null,
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TooltipContent;
