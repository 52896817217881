import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IUserPreferences {
  primaryOrganizationUuid: string;
  sideMenuExpanded: boolean;
  showDeleteTaskConfirmationPrompt: boolean;
}

export interface UserState {
  uuid: string;
  name: string;
  email: string;
  createdAt?: Date;
  preferences: IUserPreferences;
  permissions: {
    role: string;
    departmentAccessList: string[];
  };
}

const initialState = {
  uuid: "",
  name: "",
  email: "",
  preferences: {
    primaryOrganizationUuid: "",
    sideMenuExpanded: true,
    showDeleteTaskConfirmationPrompt: true,
  },
  permissions: {
    role: "",
    departmentAccessList: [],
  },
} as UserState;

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    update: (state: UserState, action: PayloadAction<UserState>) =>
      action.payload,
    setPreferences: (
      state: UserState,
      action: PayloadAction<UserState["preferences"]>,
    ) => ({
      ...state,
      preferences: {
        ...state.preferences,
        ...action.payload,
      },
    }),
    setPermissions: (
      state: UserState,
      action: PayloadAction<UserState["permissions"]>,
    ) => ({
      ...state,
      permissions: {
        ...state.permissions,
        ...action.payload,
      },
    }),
    reset: () => initialState,
  },
});

export const { update, setPreferences, setPermissions, reset } =
  userSlice.actions;
export default userSlice.reducer;
