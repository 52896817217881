import React, { useEffect } from "react";
import {
  SelectMultipleState,
  SelectMultipleType,
} from "../SelectMultiple/SelectMultiple.types";
import SelectMultiple from "../SelectMultiple";
import { CommonlyFetchedValuesContext } from "~/context/CommonlyFetchedValuesContext";

interface Props {
  departmentOptions: SelectMultipleState;
  setDepartmentOptions: React.Dispatch<
    React.SetStateAction<SelectMultipleState>
  >;
  className?: string;
  listBoxClassName?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
}

const SelectMultipleDepartments = ({
  departmentOptions,
  setDepartmentOptions,
  className = "",
  listBoxClassName = "",
  label,
  placeholder,
  disabled = false,
}: Props): React.ReactNode => {
  const commonValues = React.useContext(CommonlyFetchedValuesContext);
  if (!commonValues) {
    throw new Error("CommonlyFetchedValuesContext not provided");
  }
  const { departments } = commonValues;
  const [disabledOptions, setDisabledOptions] = React.useState<string[]>([]);

  useEffect(() => {
    const updateOptionsForSelectedParents = (): void => {
      const selectedUuids = departmentOptions.selected?.map(
        (item) => item.value,
      );

      const isAncestorSelected = (departmentUuid: string): boolean => {
        let currentUuid: string | null | undefined = departmentUuid;
        while (currentUuid) {
          const parentUuid = departments.find(
            (dept) => dept.uuid === currentUuid,
          )?.parentUuid;
          if (selectedUuids?.includes(parentUuid)) {
            return true;
          }
          currentUuid = parentUuid;
        }
        return false;
      };

      const recursivelyDisableOptions = (nodes: SelectMultipleType[]): void => {
        nodes.map((node) => {
          const disabled = isAncestorSelected(node.value ?? "");

          if (disabled) {
            setDisabledOptions((prevState) => {
              const updatedDisabledOptions = [
                ...prevState,
                node.value as string,
              ];
              return updatedDisabledOptions;
            });
          } else {
            setDisabledOptions((prevState) => {
              const updatedDisabledOptions = prevState.filter(
                (option) => option !== node.value,
              );
              return updatedDisabledOptions;
            });
          }
        });
      };

      if (departmentOptions.selected && departments.length > 0) {
        recursivelyDisableOptions(departmentOptions.options);
      }
    };

    if (departmentOptions.selected) {
      updateOptionsForSelectedParents();
    }
  }, [departmentOptions.selected, departmentOptions.options, departments]);

  return (
    <SelectMultiple
      id="department-select"
      label={label}
      state={departmentOptions}
      setState={setDepartmentOptions}
      className={className}
      placeholder={placeholder}
      disabled={disabled}
      listBoxClassName={listBoxClassName}
      disabledOptions={disabledOptions}
    />
  );
};

export default SelectMultipleDepartments;
