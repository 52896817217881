import React from "react";
import Button from "~/components/Button";
import { useMergeLink } from "@mergeapi/react-merge-link";
import { State } from "~/store";
import request from "~/utils/request";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

interface Props {
  category: string;
  onComplete: () => void;
  children: React.ReactNode;
}

const IntegrationLink = ({ category, onComplete, children }: Props) => {
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const [linkToken, setLinkToken] = useState();

  const onSuccess = useCallback(async (publicToken: string) => {
    await request({
      url: `/organizations/${organizationUuid}/integrations`,
      method: "POST",
      body: {
        publicToken,
        type: category,
      },
    });
    onComplete();
  }, []);

  useEffect(() => {
    const getLinkToken = async () => {
      const linkTokenResponse = await request({
        url: `/organizations/${organizationUuid}/integrations/link-token`,
        method: "GET",
        params: {
          category,
        },
      });
      setLinkToken(linkTokenResponse.data.data.link_token);
    };
    getLinkToken();
  }, []);

  const { open: openMergeConnect, isReady } = useMergeLink({
    linkToken: linkToken as string | undefined,
    onSuccess,
  });

  if (!isReady) return null;
  return (
    <Button className="!w-fit !px-5" onClick={openMergeConnect}>
      {children}
    </Button>
  );
};

export default IntegrationLink;
