import React, { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  ArrowPathRoundedSquareIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import { usePopper } from "react-popper";
import Typography from "~/components/Typography";
import useHover from "~/utils/hooks/useHover";

interface Props {
  state?: "forecastedChange" | "outdatedForecast";
}

const HeadcountTagPopover = ({ state }: Props): React.ReactNode => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement);
  const [ref, hovering] = useHover();

  const headcountTag =
    state === "forecastedChange"
      ? {
          button: (
            <div className="w-fit h-fit p-[2px] bg-green-400 rounded-full">
              <ArrowPathRoundedSquareIcon className="h-5 w-5 text-white" />
            </div>
          ),
          panel: (
            <div className="flex flex-col max-w-[256px] bg-white border-2 py-4 px-6 rounded-lg gap-2">
              <Typography size="xs" weight="semibold">
                Forecasted Change
              </Typography>
              <Typography size="xs">
                The details of this position have a forecasted change upcoming
              </Typography>
            </div>
          ),
        }
      : {
          button: (
            <div className="w-fit h-fit p-[2px] bg-red-400 rounded-full">
              <ClockIcon className="h-5 w-5 text-white" />
            </div>
          ),
          panel: (
            <div className="flex flex-col max-w-[256px] bg-white border-2 py-4 px-6 rounded-lg gap-2 mt-2">
              <Typography size="xs" weight="semibold">
                Outdated Forecast
              </Typography>
              <Typography size="xs">
                There is a forecast associated to this position that is outdated
              </Typography>
            </div>
          ),
        };

  if (!state) return null;

  return (
    <div ref={ref}>
      <Popover className="relative h-6 w-6">
        <Popover.Button
          ref={setReferenceElement}
          style={{ pointerEvents: "none" }}
        >
          {headcountTag.button}
        </Popover.Button>
        <Transition
          show={Boolean(hovering)}
          as={Fragment}
          enter="transition-opacity ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-in duration-50"
          leaveTo="opacity-0"
        >
          <Popover.Panel
            ref={setPopperElement}
            style={{ ...styles.popper, zIndex: 20, position: "fixed" }}
            {...attributes.popper}
          >
            {headcountTag.panel}
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
};

export default HeadcountTagPopover;
