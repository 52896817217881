import Button from "~/components/Button";
import Card from "~/components/Card";
import Input from "~/components/Input";
import React from "react";

interface Props {
  password: Types.InputState;
  setPassword: React.Dispatch<React.SetStateAction<Types.InputState>>;
  updatePassword: () => void;
  errorMessage: string;
}

const ResetPassword = ({
  password,
  setPassword,
  updatePassword,
  errorMessage,
}: Props) => (
  <div className="flex flex-col items-center justify-start w-screen h-screen">
    <div className="flex flex-col w-[95vw] max-w-[500px] mt-[10vh]">
      <h1>Reset your password</h1>
      <Card className="w-full mt-15">
        <Input
          id="password"
          type="password"
          state={password}
          setState={setPassword}
          label="Password"
          placeholder="Enter your new password here"
        />
        <Button id="submit-reset-password" onClick={updatePassword}>
          Save
        </Button>
        <p className="text-red-500">{errorMessage}</p>
      </Card>
    </div>
  </div>
);

export default ResetPassword;
