import request from "~/utils/request";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "~/store";
import Modal from "~/components/Modal";
import permissionsCheck from "~/utils/permissionsCheck";
import { tasksSlice } from "~/store/tasksSlice";
import toast from "react-hot-toast";
import PositionForm from "../PositionForm";
import usePositionFormState from "../PositionForm/usePositionFormState";
import { organizationSlice } from "~/store/organizationSlice";

interface Props {
  isOpen: boolean;
  setModal: (isOpen: boolean) => void;
  modalSource?: string;
  successCallback?: () => void;
}

const CreatePositionContainer = ({
  isOpen,
  setModal,
  modalSource,
  successCallback,
}: Props): React.ReactNode => {
  const dispatch = useDispatch();
  const {
    errorMessage,
    setErrorMessage,
    organizationUuid,
    jobTitle,
    setJobTitle,
    startDateState,
    setStartDateState,
    employmentType,
    setEmploymentType,
    selectedPaymentType,
    setSelectedPaymentType,
    paymentAmountState,
    setPaymentAmountState,
    bonusAmountState,
    setBonusAmountState,
    commissionAmountState,
    setCommissionAmountState,
    attainmentState,
    setAttainmentState,
    currencyTypeState,
    setCurrencyTypeState,
    selectDepartment,
    setSelectDepartment,
    managerState,
    setManagerState,
    expectedHoursState,
    setExpectedHoursState,
    resetFormState,
    savingPosition,
    setSavingPosition,
    numberToCreate,
    setNumberToCreate,
    changeDescription,
    setChangeDescription,
  } = usePositionFormState({ isNewPosition: true });

  const {
    uuid: userUuid,
    permissions: { role, departmentAccessList },
  } = useSelector((state: State) => state.user);
  const organization = useSelector((state: State) => state.organization);
  const isAdmin = permissionsCheck(role, departmentAccessList);
  const { total } = useSelector((state: State) => state.tasks);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent): void => {
      if (event.key === "Escape") {
        setModal(false);
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleEscape);
    } else {
      document.removeEventListener("keydown", handleEscape);
    }

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [isOpen, setModal]);

  const attemptCreatePosition = async (): Promise<void> => {
    setErrorMessage("");
    setSavingPosition(true);
    if (
      jobTitle.value &&
      employmentType.selected?.value &&
      selectedPaymentType.selected?.value &&
      paymentAmountState.value &&
      startDateState.value.startDate &&
      numberToCreate.value &&
      currencyTypeState.selected?.value &&
      (selectedPaymentType.selected.value === "SALARY" ||
        expectedHoursState.value) &&
      (isAdmin || changeDescription.value)
    ) {
      const requestUrl = permissionsCheck(role, departmentAccessList)
        ? `/organizations/${organizationUuid}/positions`
        : `/organizations/${organizationUuid}/requests`;

      const mainBody = {
        groupUuid: selectDepartment.selected?.value ?? null,
        managerUuid: managerState.selected?.value ?? null,
        employmentType: employmentType.selected.value,
        compensationRate: Number(paymentAmountState.value) * 100,
        bonus: bonusAmountState.value.length
          ? Number(bonusAmountState.value) * 100
          : null,
        commission: commissionAmountState.value.length
          ? Number(commissionAmountState.value) * 100
          : null,
        attainment: attainmentState.value.length
          ? Number(attainmentState.value) / 100
          : null,
        paymentUnit: selectedPaymentType.selected.value,
        title: jobTitle.value,
        effectiveAt: new Date(startDateState.value.startDate),
        expectedWeeklyHours:
          selectedPaymentType.selected.value === "HOURLY"
            ? expectedHoursState.value
            : undefined,
        currency: currencyTypeState.selected.value,
        numberToCreate: Number(numberToCreate.value),
      };

      const requestBody = isAdmin
        ? mainBody
        : {
            requestType: "requestNewPosition",
            requestData: {
              ...mainBody,
              requestorUuid: userUuid,
              changeDescription: changeDescription.value,
              status: "PENDING",
            },
          };
      const createPositionResponse = await request({
        url: requestUrl,
        method: "POST",
        body: requestBody,
      });
      if (createPositionResponse.status >= 400) {
        setErrorMessage(
          `There was an error creating the ${
            isAdmin ? "position" : "request"
          }. Please try again.`,
        );
      } else {
        setModal(false);
        resetFormState();
        if (!isAdmin) {
          dispatch(tasksSlice.actions.update({ total: total + 1 }));
        }
        if (!organization.configuration.positionsCreated) {
          dispatch(
            organizationSlice.actions.update({
              ...organization,
              configuration: {
                ...organization.configuration,
                positionsCreated: true,
              },
            }),
          );
        }
        toast.success(
          `${numberToCreate.value} ${isAdmin ? "Position" : "Request"}${
            Number(numberToCreate.value) > 1 ? "s" : ""
          }  created successfully!`,
        );
        if (modalSource === "forecast" && successCallback) {
          successCallback();
        }
      }
    } else {
      setJobTitle({ ...jobTitle, pristine: false, touched: true });
      setEmploymentType({ ...employmentType, pristine: false, touched: true });
      setSelectedPaymentType({
        ...selectedPaymentType,
        pristine: false,
        touched: true,
      });
      setSelectDepartment({
        ...selectDepartment,
        pristine: false,
        touched: true,
      });
      setPaymentAmountState({
        ...paymentAmountState,
        pristine: false,
        touched: true,
      });
      setCurrencyTypeState({
        ...currencyTypeState,
        pristine: false,
        touched: true,
      });
      setNumberToCreate({
        ...numberToCreate,
        pristine: false,
        touched: true,
      });
      setStartDateState({ ...startDateState, pristine: false, touched: true });
      if (!isAdmin) {
        setChangeDescription({
          ...changeDescription,
          pristine: false,
          touched: true,
        });
      }
      if (selectedPaymentType.selected?.value === "HOURLY") {
        setExpectedHoursState({
          ...expectedHoursState,
          pristine: false,
          touched: true,
        });
      }
    }
    setSavingPosition(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      title={isAdmin ? "Create Position" : "Request New Position"}
      size="md"
    >
      <div data-testid="create-position-modal" className="mt-4 w-full">
        <PositionForm
          mode="create"
          onClose={() => {
            setModal(false);
            resetFormState();
          }}
          isAdmin={isAdmin}
          jobTitle={jobTitle}
          setJobTitle={setJobTitle}
          managerState={managerState}
          setManagerState={setManagerState}
          selectDepartment={selectDepartment}
          setSelectDepartment={setSelectDepartment}
          startDateState={startDateState}
          setStartDateState={setStartDateState}
          employmentType={employmentType}
          setEmploymentType={setEmploymentType}
          selectedPaymentType={selectedPaymentType}
          setSelectedPaymentType={setSelectedPaymentType}
          paymentAmountState={paymentAmountState}
          setPaymentAmountState={setPaymentAmountState}
          bonusAmountState={bonusAmountState}
          setBonusAmountState={setBonusAmountState}
          commissionAmountState={commissionAmountState}
          setCommissionAmountState={setCommissionAmountState}
          attainmentState={attainmentState}
          setAttainmentState={setAttainmentState}
          currencyTypeState={currencyTypeState}
          setCurrencyTypeState={setCurrencyTypeState}
          errorMessage={errorMessage}
          savePosition={attemptCreatePosition}
          expectedHoursState={expectedHoursState}
          setExpectedHoursState={setExpectedHoursState}
          savingPosition={savingPosition}
          numberToCreate={numberToCreate}
          setNumberToCreate={setNumberToCreate}
          changeDescription={changeDescription}
          setChangeDescription={setChangeDescription}
        />
      </div>
    </Modal>
  );
};

export default CreatePositionContainer;
