import React, { useState, useEffect } from "react";
import Button from "~/components/Button";
import Typography from "~/components/Typography";
import Divider from "~/components/Divider";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import formatCurrency from "~/utils/formatCurrency";
import { EmploymentTypes } from "~/utils/enums";
import formatPercent from "~/utils/formatPercent";
import { useSelector } from "react-redux";
import { State } from "~/store";
import date from "~/utils/dates/date";
import request from "~/utils/request";
import ConfirmPrompt from "~/components/ConfirmPrompt";
import { useBlocker } from "react-router-dom";
import positionVersionsHaveChanged from "~/utils/positionVersion/positionVersionsHaveChanged";
import permissionsCheck from "~/utils/permissionsCheck";
import usePositionFormState from "../../PositionForm/usePositionFormState";
import { Position } from "../../headcount.types";
import "../positionDetails.css";
import CurrentPositionDetailsForm from "./CurrentPositionDetailsForm";
import ManuallyTrackedDataForm from "./ManuallyTrackedDataForm";
import ExplainManualFieldsPopover from "./ExplainManualFieldsPopover";
import setPositionFormState from "./setPositionFormState";
import UpdatePositionDetailsButtons from "./UpdatePositionDetailsButtons";
import {
  patchActualPositionRequestSchema,
  patchForecastedPositionRequestSchema,
  IPatchActualPositionRequest,
  IPatchForecastedPositionRequest,
} from "./positionPatchRequest.types";

const CurrentPositionDetails = ({
  positionData,
  fetchPositionData,
}: {
  positionData: Position;
  fetchPositionData: () => void;
}): React.ReactNode => {
  const {
    permissions: { role, departmentAccessList },
  } = useSelector((state: State) => state.user);
  const [compensationExpanded, setCompensationExpanded] = useState(false);
  const [detailsExpanded, setDetailsExpanded] = useState(false);
  const [manuallyTrackedExpanded, setManuallyTrackedExpanded] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const isForecasted = !positionData.history[0].isActual;
  const isAdmin = permissionsCheck(role, departmentAccessList);

  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      editMode && currentLocation.pathname !== nextLocation.pathname,
  );

  const {
    errorMessage,
    setErrorMessage,
    jobTitle,
    setJobTitle,
    employmentType: employmentTypeState,
    setEmploymentType,
    selectedPaymentType,
    setSelectedPaymentType,
    paymentAmountState,
    setPaymentAmountState,
    currencyTypeState,
    setCurrencyTypeState,
    selectDepartment,
    setSelectDepartment,
    managerState,
    setManagerState,
    expectedHoursState,
    setExpectedHoursState,
    bonusAmountState,
    setBonusAmountState,
    commissionAmountState,
    setCommissionAmountState,
    attainmentState,
    setAttainmentState,
    startDateState,
    setStartDateState,
  } = usePositionFormState();

  const {
    positionUuid,
    title,
    employmentType,
    compensationRate,
    bonus,
    commission,
    attainment,
    paymentUnit,
    expectedWeeklyHours,
    currency,
  } = positionData.current;

  const setFormState = (): void => {
    setPositionFormState({
      positionFormState: {
        setErrorMessage,
        jobTitle,
        setJobTitle,
        employmentType: employmentTypeState,
        setEmploymentType,
        selectedPaymentType,
        setSelectedPaymentType,
        paymentAmountState,
        setPaymentAmountState,
        currencyTypeState,
        setCurrencyTypeState,
        setSelectDepartment,
        managerState,
        setManagerState,
        expectedHoursState,
        setExpectedHoursState,
        bonusAmountState,
        setBonusAmountState,
        commissionAmountState,
        setCommissionAmountState,
        attainmentState,
        setAttainmentState,
        startDateState,
        setStartDateState,
      },
      positionData,
    });
  };

  useEffect(() => {
    setFormState();
  }, [positionData]);

  const managerName = positionData.currentManager?.firstName
    ? `${positionData.currentManager.firstName} ${positionData.currentManager.lastName}`
    : "No manager assigned";

  const editActual = !isForecasted && editMode;
  const editForecast = isForecasted && editMode;

  const patchPosition = async (
    data: IPatchActualPositionRequest | IPatchForecastedPositionRequest,
  ): Promise<void> => {
    const response = await request({
      url: `/organizations/${organizationUuid}/positions/${positionUuid}`,
      method: "PATCH",
      body: { ...data, originalEffectiveAt: positionData.current.effectiveAt },
    });
    if (response.status >= 400) {
      setErrorMessage(
        "There was an error creating the position. Please try again.",
      );
    } else {
      setEditMode(false);
      fetchPositionData();
    }
  };

  const attemptModifyPosition = async (): Promise<void> => {
    if (isForecasted) {
      const forecastedPositionData = {
        title: jobTitle.value,
        groupUuid: selectDepartment.selected?.value ?? null,
        managerUuid: managerState.selected?.value ?? null,
        employmentType: employmentTypeState.selected?.value,
        paymentUnit: selectedPaymentType.selected?.value,
        compensationRate: parseFloat(paymentAmountState.value) * 100,
        currency: currencyTypeState.selected?.value,
        expectedWeeklyHours:
          selectedPaymentType.selected?.value === "HOURLY"
            ? Number(expectedHoursState.value)
            : undefined,
        bonus: bonusAmountState.value
          ? Number(bonusAmountState.value) * 100
          : null,
        commission: commissionAmountState.value
          ? Number(commissionAmountState.value) * 100
          : null,
        attainment: attainmentState.value
          ? Number(attainmentState.value) / 100
          : null,
        effectiveAt: date(startDateState.value.startDate ?? "").toISOString(),
        originalEffectiveAt: positionData.current.effectiveAt,
      };
      const parsedForecastedPositionData =
        patchForecastedPositionRequestSchema.parse(forecastedPositionData);

      const originalPositionData = {
        title: positionData.current.title,
        groupUuid: positionData.current.groupUuid,
        managerUuid: positionData.current.managerUuid,
        employmentType: positionData.current.employmentType,
        paymentUnit: positionData.current.paymentUnit,
        compensationRate: positionData.current.compensationRate,
        currency: positionData.current.currency,
        expectedWeeklyHours: positionData.current.expectedWeeklyHours,
        bonus: positionData.current.bonus,
        commission: positionData.current.commission,
        attainment: positionData.current.attainment,
        effectiveAt: positionData.current.effectiveAt,
        originalEffectiveAt: positionData.current.effectiveAt,
      };

      if (
        positionVersionsHaveChanged(
          originalPositionData,
          parsedForecastedPositionData,
        )
      ) {
        await patchPosition(parsedForecastedPositionData);
      } else {
        setEditMode(false);
      }
    } else {
      const actualPositionData = {
        expectedWeeklyHours:
          selectedPaymentType.selected?.value === "HOURLY"
            ? Number(expectedHoursState.value)
            : undefined,
        bonus: bonusAmountState.value
          ? Number(bonusAmountState.value) * 100
          : null,
        commission: commissionAmountState.value
          ? Number(commissionAmountState.value) * 100
          : null,
        attainment: attainmentState.value
          ? Number(attainmentState.value) / 100
          : null,
        isActual: true,
      };
      const parsedActualPositionData =
        patchActualPositionRequestSchema.parse(actualPositionData);

      const actualOriginalPositionData = {
        expectedWeeklyHours:
          positionData.current.expectedWeeklyHours ?? undefined,
        bonus: positionData.current.bonus,
        commission: positionData.current.commission,
        attainment: positionData.current.attainment,
        isActual: true,
      };

      if (
        positionVersionsHaveChanged(
          actualOriginalPositionData,
          actualPositionData,
        )
      ) {
        await patchPosition(parsedActualPositionData);
      } else {
        setEditMode(false);
      }
    }
  };

  return (
    <div className="flex flex-col">
      <ConfirmPrompt
        isOpen={blocker.state === "blocked"}
        onClose={blocker.reset}
        title="Leave Without Saving Changes"
        message="All modifications that have not been saved will be lost."
        confirmButtonText="Leave & Discard Changes"
        onConfirm={blocker.proceed}
      />
      <div className="flex flex-row justify-between">
        <Typography weight="bold" size="lg" tag="h2" className="my-2">
          Current Position
        </Typography>
        {isForecasted && isAdmin && (
          <UpdatePositionDetailsButtons
            id="forecasted"
            editMode={editMode}
            handleEdit={() => {
              setEditMode(true);
            }}
            handleCancel={() => {
              setEditMode(false);
              setFormState();
            }}
            handleSave={attemptModifyPosition}
          />
        )}
      </div>
      {!editForecast ? (
        <div data-testid="current-position-details-display">
          <div className="flex flex-col gap-3">
            <div className="flex flex-row justify-between">
              <Typography weight="semibold" tag="h3">
                Role Details
              </Typography>
              <Button
                fill="clear"
                onClick={() => setDetailsExpanded((prev) => !prev)}
                className="!w-auto !p-0 sm:hidden"
              >
                View
                <ChevronDownIcon className="h-4 w-4 ml-1" />
              </Button>
            </div>
            <div
              className={`flex flex-col gap-3 ${
                detailsExpanded ? "" : "max-sm:hidden"
              }`}
            >
              <div className="flex flex-col gap-3">
                <div className="flex flex-col">
                  <Typography size="xs" color="empty">
                    Job Title
                  </Typography>
                  <Typography id="current-position-title">{title}</Typography>
                </div>
                <div className="flex flex-col">
                  <Typography size="xs" color="empty">
                    Department
                  </Typography>
                  <Typography id="current-position-department">
                    {positionData.currentDepartment?.name ?? "No Department"}
                  </Typography>
                </div>
                <div className="flex flex-col">
                  <Typography size="xs" color="empty">
                    Manager
                  </Typography>
                  <Typography id="current-position-manager">
                    {managerName}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <Divider className="my-6" />
          <div className="flex flex-col gap-3">
            <div className="flex flex-row justify-between">
              <Typography weight="semibold" tag="h3">
                Compensation
              </Typography>
              <Button
                fill="clear"
                onClick={() => setCompensationExpanded((prev) => !prev)}
                className="!w-auto !p-0 sm:hidden"
              >
                View
                <ChevronDownIcon className="h-4 w-4 ml-1" />
              </Button>
            </div>
            <div
              className={`flex flex-col gap-3 ${
                compensationExpanded ? "" : "max-sm:hidden"
              }`}
            >
              <div className="flex flex-col gap-3">
                <div className="flex flex-col">
                  <Typography size="xs" color="empty">
                    Employment Type
                  </Typography>
                  <Typography id="current-position-employment-type">
                    {
                      EmploymentTypes[
                        employmentType as keyof typeof EmploymentTypes
                      ]
                    }
                  </Typography>
                </div>
                <div className="flex flex-col">
                  <Typography size="xs" color="empty">
                    Pay Rate
                  </Typography>
                  <Typography id="current-position-compensation-rate">
                    {formatCurrency(
                      compensationRate,
                      paymentUnit === "HOURLY" ? true : false,
                      currency,
                    )}{" "}
                    / {paymentUnit === "SALARY" ? "year" : "hour"}
                  </Typography>
                </div>
                <div className="flex flex-col">
                  <Typography size="xs" color="empty">
                    Payment Type
                  </Typography>
                  <Typography id="current-position-payment-unit">
                    {paymentUnit === "SALARY" ? "Salary" : "Hourly"}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <Divider className="my-6" />
          <div className="flex flex-col gap-3">
            <div className="flex flex-row justify-between">
              <div className="flex flex-row gap-2">
                <Typography weight="semibold" tag="h3">
                  Manually Tracked
                </Typography>
                {isAdmin && <ExplainManualFieldsPopover />}
              </div>
              {!isForecasted && isAdmin && (
                <UpdatePositionDetailsButtons
                  id="actual"
                  editMode={editMode}
                  handleEdit={() => {
                    setEditMode(true);
                  }}
                  handleCancel={() => {
                    setEditMode(false);
                    setFormState();
                  }}
                  handleSave={attemptModifyPosition}
                />
              )}
              <Button
                fill="clear"
                onClick={() => setManuallyTrackedExpanded((prev) => !prev)}
                className="!w-auto !p-0 sm:hidden"
              >
                View
                <ChevronDownIcon className="h-4 w-4 ml-1" />
              </Button>
            </div>
            <div
              className={`flex flex-col gap-3 ${
                manuallyTrackedExpanded ? "" : "max-sm:hidden"
              }`}
            >
              {editActual ? (
                <ManuallyTrackedDataForm
                  selectedPaymentType={selectedPaymentType}
                  expectedHoursState={expectedHoursState}
                  setExpectedHoursState={setExpectedHoursState}
                  bonusAmountState={bonusAmountState}
                  setBonusAmountState={setBonusAmountState}
                  commissionAmountState={commissionAmountState}
                  setCommissionAmountState={setCommissionAmountState}
                  attainmentState={attainmentState}
                  setAttainmentState={setAttainmentState}
                  currencyTypeState={currencyTypeState}
                  setCurrencyTypeState={setCurrencyTypeState}
                />
              ) : (
                <div className="flex flex-col gap-3">
                  {paymentUnit === "HOURLY" && (
                    <div className="flex flex-col">
                      <Typography size="xs" color="empty">
                        Expected Weekly Hours
                      </Typography>
                      <Typography id="current-position-hours">
                        {expectedWeeklyHours ?? "Unknown"} hours a week
                      </Typography>
                    </div>
                  )}
                  <div className="flex flex-col">
                    <Typography size="xs" color="empty">
                      Bonus
                    </Typography>
                    <Typography
                      id="current-position-bonus-rate"
                      color={bonus !== null && bonus > 0 ? "primary" : "empty"}
                    >
                      {bonus !== null
                        ? formatCurrency(bonus, false, currency)
                        : "-"}
                    </Typography>
                  </div>
                  <div className="flex flex-col">
                    <Typography size="xs" color="empty">
                      Commission
                    </Typography>
                    <Typography
                      id="current-position-commission-rate"
                      color={
                        commission !== null && commission > 0
                          ? "primary"
                          : "empty"
                      }
                    >
                      {commission !== null
                        ? formatCurrency(commission, false, currency)
                        : "-"}
                    </Typography>
                  </div>
                  <div className="flex flex-col">
                    <Typography size="xs" color="empty">
                      Attainment
                    </Typography>
                    <Typography
                      id="current-position-attainment"
                      color={
                        attainment !== null && attainment > 0
                          ? "primary"
                          : "empty"
                      }
                    >
                      {attainment !== null ? formatPercent(attainment) : "-"}
                    </Typography>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <CurrentPositionDetailsForm
          errorMessage={errorMessage}
          jobTitle={jobTitle}
          setJobTitle={setJobTitle}
          employmentType={employmentTypeState}
          setEmploymentType={setEmploymentType}
          selectedPaymentType={selectedPaymentType}
          setSelectedPaymentType={setSelectedPaymentType}
          paymentAmountState={paymentAmountState}
          setPaymentAmountState={setPaymentAmountState}
          currencyTypeState={currencyTypeState}
          setCurrencyTypeState={setCurrencyTypeState}
          selectDepartment={selectDepartment}
          setSelectDepartment={setSelectDepartment}
          managerState={managerState}
          setManagerState={setManagerState}
          expectedHoursState={expectedHoursState}
          setExpectedHoursState={setExpectedHoursState}
          bonusAmountState={bonusAmountState}
          setBonusAmountState={setBonusAmountState}
          commissionAmountState={commissionAmountState}
          setCommissionAmountState={setCommissionAmountState}
          attainmentState={attainmentState}
          setAttainmentState={setAttainmentState}
          startDateState={startDateState}
          setStartDateState={setStartDateState}
        />
      )}
    </div>
  );
};

export default CurrentPositionDetails;
