import { State } from "~/components/Combobox/Combobox.types";
import React, { useState } from "react";

const useCombobox = ({
  options,
  selected,
}: Partial<State>): [
  value: State,
  setState: React.Dispatch<React.SetStateAction<State>>,
] => {
  const initialState: State = {
    query: "",
    options: options || [],
    selected: selected || { label: null, value: null },
    valid: false,
    touched: false,
    pristine: true,
    disabled: false,
  };
  const [selectState, setState] = useState(initialState);
  return [selectState, setState];
};

export default useCombobox;
