import React from "react";
import { Filter } from "~/components/SearchFilters/useSearchFilters";
import Button from "~/components/Button";
import { FilterType } from "~/components/SearchFilters/index";
import { TagIcon } from "@heroicons/react/24/outline";
import Input, { useInput } from "~/components/Input";

interface Props {
  activeFilters: Filter[];
  onSave: (filter: Filter[]) => void;
  onClose: () => void;
}

const EmployeeNameFilter = ({ activeFilters, onSave, onClose }: Props) => {
  const [employeeName, setEmployeeName] = useInput({});

  const attemptSave = () => {
    if (employeeName.valid) {
      const activeFilter = activeFilters.find(
        (filter) => filter.id === FilterType.EMPLOYEE_NAME,
      );
      if (activeFilter) {
        // TODO - Update existing filter
      } else {
        const newFilter: Filter = {
          id: FilterType.EMPLOYEE_NAME,
          label: (
            <div className="flex items-center gap-2 whitespace-nowrap">
              <TagIcon className="w-5 h-5" />
              <p title={employeeName.value} className="max-w-[125px] truncate">
                {employeeName.value}
              </p>
            </div>
          ),
          queryParam: {
            employeeName: employeeName.value,
          },
        };
        onSave([...activeFilters, newFilter]);
        onClose();
      }
    } else {
      setEmployeeName((prevState) => ({
        ...prevState,
        pristine: false,
        touched: true,
      }));
    }
  };

  return (
    <div className="text-neutral-900 text-left my-5">
      <Input
        id="employee-name"
        label="Employee Name"
        state={employeeName}
        setState={setEmployeeName}
      />
      <div className="flex justify-end gap-3 my-3">
        <Button
          fill="outline"
          className="text-red border-red !w-auto"
          id="filter-cancel"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button id="filter-save" className="!w-auto" onClick={attemptSave}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default EmployeeNameFilter;
