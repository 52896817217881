import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { buttonFillStyles } from "~/components/Button";

interface IProps {
  fill?: "clear" | "outline" | "solid" | "destructive" | "default";
  children: React.ReactNode;
  className?: string;
  options: { label: string; onClick: () => void; disabled?: boolean }[];
  ref?: React.RefObject<HTMLElement>;
  direction?: "below" | "above";
  maxPanelHeight?: string;
  align?: "left" | "right";
}
const PopoverComponent = ({
  fill = "solid",
  children,
  className = "",
  options,
  ref,
  direction,
  maxPanelHeight,
  align = "right",
}: IProps): React.ReactNode => (
  <Popover className="relative">
    {({ close }) => (
      <>
        <Popover.Button
          className={`py-2 px-5 w-full rounded ${buttonFillStyles[fill]} ${className}`}
          ref={ref}
        >
          {children}
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel
            className={`absolute z-10 transform ${align === "right" ? "right-0" : "left-0"} mt-1  
          ${
            direction === "above" ? "bottom-full" : ""
          } ${maxPanelHeight ? maxPanelHeight : ""}
          ${options.length > 4 ? "overflow-y-scroll" : ""}`}
          >
            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="relative bg-white">
                {options.map((option) => (
                  <button
                    type="button"
                    disabled={option.disabled ?? false}
                    key={option.label}
                    className="flex justify-between items-center px-5 py-4 cursor-pointer hover:bg-gray-100 min-w-[250px] disabled:bg-transparent disabled:hover:bg-transparent disabled:cursor-default disabled:text-neutral-200"
                    onClick={() => {
                      close();
                      option.onClick();
                    }}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </>
    )}
  </Popover>
);

export default PopoverComponent;
