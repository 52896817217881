import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface SettingsState {
  showSidebar: boolean;
  isMobile: boolean;
}

const initialState = {
  showSidebar: false,
  isMobile: false,
} as SettingsState;

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    update: (state, action: PayloadAction<SettingsState>) => action.payload,
    mutate: (state, action: PayloadAction<Partial<SettingsState>>) => ({
      ...state,
      ...action.payload,
    }),
    reset: () => initialState,
  },
});

export const { update, mutate, reset } = settingsSlice.actions;
export const settings = settingsSlice.actions;
export default settingsSlice.reducer;
