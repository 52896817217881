import React, { useEffect, useRef, useState, ReactNode } from "react";

interface Props {
  name: string;
  segments: {
    value: string;
    label: ReactNode | string;
    ref?: unknown;
  }[];
  value: string;
  setValue: (value: string) => void;
  thin?: boolean;
  disabled?: boolean;
}

const Segment = ({
  name,
  segments,
  setValue,
  value,
  thin = false,
  disabled = false,
}: Props) => {
  const foundIndex =
    segments.findIndex((segment) => segment.value === value) || 0;
  const activeIndex = foundIndex > -1 ? foundIndex : 0;
  // const [activeIndex, setActiveIndex] = useState(found || 0);
  const [enableTransitioning, setEnableTransitioning] = useState(false);
  const transitionClasses =
    "before:transition-transform before:duration-300 before:ease-in-out before:transition-width before:duration-300 before:ease-in-out";
  const controlRef = useRef<HTMLDivElement | null>(null);

  const setHighlighterPosition = (segment: HTMLElement) => {
    if (!controlRef.current?.firstChild) return;
    const { style } = controlRef.current;
    style.setProperty("--highlight-width", `${segment.offsetWidth}px`);
    style.setProperty("--highlight-x-pos", `${segment.offsetLeft}px`);
  };

  const onInputChange = (
    val: string,
    index: number,
    segment: HTMLDivElement,
  ) => {
    setHighlighterPosition(segment);
    setValue(val);
  };

  useEffect(() => {
    setHighlighterPosition(
      controlRef.current?.children[activeIndex] as HTMLDivElement,
    );
    setEnableTransitioning(true);
  }, []);

  return (
    <div
      className={`controls-container flex my-100 mt-0 mb-25${
        disabled ? " opacity-50 cursor-default" : ""
      }`}
    >
      <div
        className={`controls before:absolute before:top-1 before:bottom-1 before:left-0 before:z-0 before inline-flex justify-between relative border rounded-lg overflow-hidden bg-green-50 m-auto p-1 before:bg-white before:rounded idle ${
          enableTransitioning ? transitionClasses : "idle"
        }}`}
        ref={controlRef}
      >
        {segments.map((item, i) => {
          const active = i === activeIndex;
          return (
            <div
              key={item.value}
              className={`segment z-10 text-center relative min-w-[100px] ${
                active ? "active" : "inactive"
              }`}
            >
              <input
                data-testid={`segment-${item.value.toLowerCase()}`}
                className={`absolute opacity-0 w-full h-full${
                  disabled ? " cursor-default" : " cursor-pointer"
                } m-0 inset-0`}
                type="radio"
                value={item.value}
                id={item.value}
                name={name}
                checked={i === activeIndex}
                onChange={(e) => {
                  if (!disabled) {
                    onInputChange(
                      item.value,
                      i,
                      e.currentTarget.parentElement as HTMLDivElement,
                    );
                  }
                }}
              />
              <label
                className={`${
                  active ? "text-neutral-900" : "text-green-500"
                } cursor-pointer items-center block text-bold transition-color duration-500 ease-in-out ${
                  thin ? "px-1" : "p-1"
                }`}
                htmlFor={item.value}
              >
                {item.label}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Segment;
