import { Combobox } from "@headlessui/react";
import React from "react";
import Typography from "~/components/Typography";
import { Option, Props } from "./Combobox.types";

export default ({
  className,
  id,
  label,
  placeholder,
  state,
  setState,
  errorMessage = "Please make a selection",
  emptyStateMessage = "No results found",
  leftIcon,
  inputClassName = "",
  disabled = false,
  required = false,
}: Props) => {
  const onSelect = (option: Option) => {
    setState({
      ...state,
      valid: true,
      pristine: false,
      touched: true,
      selected: option,
    });
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const onQuery = (event) => {
    if (event.target.value !== emptyStateMessage) {
      setState({
        ...state,
        query: event.target.value,
      });
    }
  };

  const showError = state.touched && !state.pristine && !state.valid;
  return (
    <div className={`w-full ${className}`} data-testid={id}>
      <div
        className={`text-sm font-medium mb-1 ${
          state.disabled ? "text-neutral-75" : ""
        }`}
      >
        <div className="flex flex-row">
          <Typography
            tag="span"
            size="xs"
            className={`${
              state.disabled || disabled ? " text-neutral-75" : ""
            }${label && " mb-1"}`}
          >
            {label}
          </Typography>
          {required && (
            <Typography
              tag="span"
              size="2xs"
              className={`${
                state.disabled || disabled ? " text-neutral-75" : ""
              }`}
            >
              *
            </Typography>
          )}
        </div>
      </div>
      <Combobox
        value={state.selected}
        disabled={state.disabled || disabled}
        onChange={onSelect}
      >
        <div className="w-full relative">
          {leftIcon && (
            <Combobox.Button
              className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
              data-testid={`${id}-icon`}
            >
              {leftIcon}
            </Combobox.Button>
          )}
          <Combobox.Input
            disabled={disabled}
            data-testid={`${id}-input`}
            placeholder={placeholder}
            autoComplete="off"
            className={`block w-full px-3 h-[42px] border rounded shadow-sm disabled:bg-neutral-25 disabled:text-neutral-75 placeholder-gray-400 sm:text-sm  focus:outline-none focus-visible:border-green-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-green-300${
              leftIcon ? " pl-[50px]" : ""
            }${
              showError ? " border-red-300" : " border-gray-300"
            } ${inputClassName}`}
            onChange={onQuery}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            displayValue={(selection: Option) => selection.label}
          />
        </div>
        {state.query.length >= 3 && (
          <Combobox.Options className="mt-1 block bg-white border border-gray-300 rounded shadow-sm">
            {state.options.map((option) => (
              <Combobox.Option
                key={option.value}
                value={option}
                className="cursor-pointer hover:bg-gray-100 py-2 px-3 hover:rounded w-full"
              >
                {option.component || option.label}
              </Combobox.Option>
            ))}
            {state.options.length === 0 && (
              <p className="text-gray-500 text-xs italic p-1">
                {emptyStateMessage}
              </p>
            )}
          </Combobox.Options>
        )}
      </Combobox>
      {showError && (
        <p
          className="text-red-500 text-xs italic p-1"
          data-testid={`${id}-select-error`}
        >
          {errorMessage}
        </p>
      )}
    </div>
  );
};
