import { TableData } from "~/components/Table";
import parseCurrencyToNumber from "./parseCurrencyToNumber";

export default (
  data: TableData[] | undefined,
): {
  Department?: string;
  Modifications?: string;
  "Total Impact"?: number;
}[] => {
  if (!data) {
    return [];
  }
  return data.map((row) => ({
    Department:
      row.values[0].value === "-" ? undefined : (row.values[0].value as string),
    Modifications:
      row.values[1].value === "-" ? undefined : (row.values[1].value as string),
    "Total Impact":
      row.values[2].value === "-"
        ? undefined
        : parseCurrencyToNumber(row.values[2].value as string),
  }));
};
