import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface AuthState {
  isLoggedIn: boolean;
  accessToken: string;
}

const initialState = {
  isLoggedIn: false,
  accessToken: "",
} as AuthState;

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<string>) => ({
      isLoggedIn: true,
      accessToken: action.payload,
    }),
    update: (state, action: PayloadAction<AuthState>) => action.payload,
    reset: () => initialState,
  },
});

export const { update, reset } = authSlice.actions;
export default authSlice.reducer;
