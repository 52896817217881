import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import request from "~/utils/request";
import useQueryParams from "~/utils/hooks/useQueryParams";
import "dayjs/locale/en";
import { IPeriodPickerState } from "~/components/PeriodPicker/usePeriodPicker";
import { format, getQuarter } from "date-fns";
import { CommonlyFetchedValuesContext } from "~/context/CommonlyFetchedValuesContext";
import { State } from "~/store";
import { IHeadcount } from "~/types/headcount/types";
import { z } from "zod";
import { aggregatedHeadcountForDashboardSchema } from "~/types/headcount/schemas";
import { SelectState } from "~/components/Select/Select.types";

interface IAPIResponse {
  data: {
    data: unknown;
  };
}

interface IPageDataState {
  isLoading: boolean;
  data?: {
    plannedHires: number;
    headcount: IHeadcount;
  };
  error?: string;
}

interface IDashboardContext {
  snapshotPeriodLabel: string;
  firstName: string;
  pageData: IPageDataState;
  periodDates: IPeriodPickerState;
  setPeriodDates: React.Dispatch<React.SetStateAction<IPeriodPickerState>>;
  planOptions: SelectState;
  setPlanOptions: React.Dispatch<React.SetStateAction<SelectState>>;
  departmentOptions: SelectState;
  setDepartmentOptions: React.Dispatch<React.SetStateAction<SelectState>>;
  firstTimeLoginModal: boolean | null;
  setFirstTimeLoginModal: React.Dispatch<React.SetStateAction<boolean | null>>;
  total: number;
  isLoadingPlans: boolean;
}

export const DashboardContext = React.createContext<IDashboardContext | null>(
  null,
);

export const DashboardProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactNode => {
  const commonValues = React.useContext(CommonlyFetchedValuesContext);
  if (!commonValues) {
    throw new Error("CommonlyFetchedValuesContext not provided");
  }
  const {
    periodDates,
    setPeriodDates,
    isLoadingPlans,
    departmentOptions,
    setDepartmentOptions,
    planOptions,
    setPlanOptions,
  } = commonValues;
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const { name } = useSelector((state: State) => state.user);
  const { total } = useSelector((state: State) => state.tasks);
  const controllerRef = useRef<AbortController>();
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [queryParams, setQueryParams] = useQueryParams();
  const [firstTimeLoginModal, setFirstTimeLoginModal] = useState<
    boolean | null
  >(queryParams.get("firstLogin") === "true" || null);
  const [pageData, setPageData] = useState<IPageDataState>({
    isLoading: true,
  });

  useEffect(() => {
    const getPageData = async (): Promise<void> => {
      try {
        if (controllerRef.current) controllerRef.current.abort();
        controllerRef.current = new AbortController();
        const { signal } = controllerRef.current;
        setPageData((prevState) => ({
          ...prevState,
          isLoading: true,
        }));

        const response = (await request({
          url: `/organizations/${organizationUuid}/aggregated-data/dashboard`,
          method: "GET",
          params: {
            startDate: format(periodDates.startDate, "yyyy-MM-dd"),
            endDate: format(periodDates.endDate, "yyyy-MM-dd"),
            departments: departmentOptions.selected?.value
              ? [departmentOptions.selected.value]
              : null,
            planUuid: planOptions.selected?.value,
          },
          signal,
        })) as IAPIResponse;

        const aggregatedData = aggregatedHeadcountForDashboardSchema.parse(
          response.data.data,
        );

        setPageData({
          isLoading: false,
          data: {
            plannedHires: aggregatedData.futureHeadcount,
            headcount: aggregatedData.headcount,
          },
        });
      } catch (error) {
        if (error instanceof z.ZodError) {
          console.error("Validation error:", error.issues);
        } else if (error.name !== "CanceledError" && error instanceof Error) {
          setPageData({
            isLoading: false,
            error: error.message,
          });
        }
      }
    };
    if (!isLoadingPlans) {
      getPageData();
    }
  }, [
    organizationUuid,
    isLoadingPlans,
    planOptions.selected,
    periodDates,
    departmentOptions.selected,
  ]);

  let snapshotPeriodLabel = "";

  switch (periodDates.mode) {
    case "month":
      snapshotPeriodLabel = format(periodDates.startDate, "MMMM yyyy");
      break;
    case "quarter":
      snapshotPeriodLabel = `Q${getQuarter(periodDates.startDate)} ${format(
        periodDates.startDate,
        "yyyy",
      )}`;
      break;
    case "year":
      snapshotPeriodLabel = format(periodDates.startDate, "yyyy");
      break;
    default:
      // Handle default case or error
      snapshotPeriodLabel = "Invalid period mode";
  }

  const firstName = name.split(" ")[0];

  return (
    <DashboardContext.Provider
      value={{
        snapshotPeriodLabel,
        firstName,
        pageData,
        periodDates,
        setPeriodDates,
        planOptions,
        setPlanOptions,
        departmentOptions,
        setDepartmentOptions,
        firstTimeLoginModal,
        setFirstTimeLoginModal,
        total,
        isLoadingPlans,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
