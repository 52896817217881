import React, { useEffect, useRef } from "react";
import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Typography from "~/components/Typography";
import ExportData from "~/components/ExportData";
import InputWrapper from "../Input";

interface IProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  exportData?: React.ReactNode[];
  inputState?: Types.InputState;
  setInputState?: React.Dispatch<React.SetStateAction<Types.InputState>>;
  inputPlaceholder?: string;
  stickyHeader?: boolean;
}
const Drawer = ({
  children,
  isOpen,
  onClose,
  title,
  subtitle,
  exportData,
  inputState,
  setInputState,
  inputPlaceholder,
  stickyHeader,
}: IProps) => {
  const drawerRef = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        onClose();
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <Transition show={isOpen} ref={drawerRef}>
      <section
        className="fixed w-[800px] right-0 top-0 z-30 pl-5"
        data-testid="drawer"
      >
        <Transition.Child
          enter="transition-transform duration-300"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition-transform duration-300"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
          className={`px-5 pb-5 ${
            !stickyHeader && "pt-5"
          } bg-white h-screen shadow-[0px_0px_12px_0px_rgba(0,_0,_0,_0.20)] overflow-y-scroll`}
        >
          <header
            className={`${
              stickyHeader
                ? "flex w-full mt-5 justify-between items-center sticky top-0 bg-white z-50 py-4 px-5"
                : "flex w-full justify-between items-center"
            }`}
          >
            <article className="flex flex-col">
              {typeof title === "string" ? (
                <Typography size="md" weight="bold" id="drawer-title">
                  {title}
                </Typography>
              ) : (
                title
              )}
              {typeof subtitle === "string" ? (
                <Typography size="2xs" color="lightGray" id="drawer-subtitle">
                  {subtitle}
                </Typography>
              ) : (
                subtitle
              )}
            </article>
            <div className="flex flex-row gap-4">
              {inputState && setInputState && (
                <div className="w-[280px]">
                  <InputWrapper
                    id="drilldown-drawer-input"
                    state={inputState}
                    setState={setInputState}
                    placeholder={inputPlaceholder ?? ""}
                    type="search"
                  />
                </div>
              )}
              <ExportData
                id="drilldown-drawer-export"
                data={exportData}
                filename={`${title}-${subtitle}.csv`}
              />
              <button
                onClick={onClose}
                type="button"
                data-testid="drilldown-drawer-close"
              >
                <XMarkIcon className="w-6 h-6 text-neutral-100" />
              </button>
            </div>
          </header>
          {children}
        </Transition.Child>
      </section>
    </Transition>
  );
};

export default Drawer;
