import React, { ReactNode } from "react";
import Modal from "~/components/Modal";
import Button from "~/components/Button";
import Typography from "~/components/Typography";
import Checkbox from "~/components/Checkbox";

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  title?: string;
  message?: string | ReactNode;
  confirmButtonText?: string;
  onConfirm?: () => void;
  isChecked?: boolean;
  setIsChecked?: () => void;
  modalSize?: "xs" | "sm" | "md" | "lg" | "xl";
}
const ConfirmPrompt = ({
  isOpen,
  onClose,
  title,
  message = "Are you sure?",
  confirmButtonText = "Confirm",
  onConfirm,
  isChecked,
  setIsChecked,
  modalSize = "xs",
}: Props) => (
  <Modal title={title} isOpen={isOpen} onClose={onClose} size={modalSize}>
    <div className="flex-col w-full" data-testid="block-navigation-modal">
      <Typography color="secondary" className="mt-3">
        {message}
      </Typography>
      {setIsChecked && isChecked !== undefined && (
        <label className="flex flex-row gap-2 items-center mt-2">
          <Checkbox checked={isChecked} toggleValue={setIsChecked} />
          <Typography color="secondary" className="text-sm">
            Do not display this warning again
          </Typography>
        </label>
      )}
      <div className="flex justify-between w-full gap-3 mt-5">
        <Button
          onClick={onClose}
          id="cancel-navigate-away-button"
          fill="clear"
          className="!w-fit !px-0"
        >
          Cancel
        </Button>
        <Button
          id="continue-to-next-page-button"
          fill="destructiveOutline"
          className="!w-auto"
          onClick={onConfirm}
        >
          {confirmButtonText}
        </Button>
      </div>
    </div>
  </Modal>
);

export default ConfirmPrompt;
