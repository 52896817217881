import React, { useEffect, useState } from "react";
import SideMenu from "~/components/SideMenu";
import { State } from "~/store";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { datadogRum } from "@datadog/browser-rum";
import dayjs from "dayjs";
import request from "~/utils/request";
import ScenarioTray from "~/components/ScenarioTray";
import Pusher from "pusher-js";
import CommonlyFetchedValuesProvider from "~/context/CommonlyFetchedValuesContext";

const ProtectedRoute = (): React.ReactNode => {
  const isLoggedIn = useSelector((state: State) => state.auth.isLoggedIn);
  const user = useSelector((state: State) => state.user);
  const inScenarioEditMode = useSelector(
    (state: State) => state.scenario.inEditMode,
  );
  const location = useLocation();
  const [showRefresh, setShowRefresh] = useState<boolean>(false);

  useEffect(() => {
    const {
      VITE_DATADOG_APPLICATION_ID,
      VITE_DATADOG_CLIENT_TOKEN,
      VITE_DATADOG_SITE,
    } = import.meta.env;
    if (
      VITE_DATADOG_APPLICATION_ID &&
      VITE_DATADOG_CLIENT_TOKEN &&
      VITE_DATADOG_SITE &&
      isLoggedIn &&
      user.uuid
    ) {
      datadogRum.init({
        applicationId: VITE_DATADOG_APPLICATION_ID,
        clientToken: VITE_DATADOG_CLIENT_TOKEN,
        site: VITE_DATADOG_SITE,
        service: "parallel",
        env: import.meta.env.PROD ? "production" : "development",
        sessionSampleRate: 100,
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        defaultPrivacyLevel: "allow",
      });
      datadogRum.startSessionReplayRecording();

      datadogRum.setUser({
        id: user.uuid,
        name: user.name,
        email: user.email,
      });
    }

    const getIntercomHash = async () => {
      const intercomTokenResponse = await request({
        url: "/auth/tokens/intercom",
        method: "GET",
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: import.meta.env.VITE_INTERCOM_APP_ID,
        name: user.name,
        email: user.email,
        user_id: user.uuid,
        created_at: dayjs(user.createdAt).unix(),
        user_hash: intercomTokenResponse.data.data.userHash,
        company: {
          id: intercomTokenResponse.data.data.companyId,
        },
      });

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      amplitude.setUserId(user.uuid);
    };
    getIntercomHash();
  }, []);

  useEffect(() => {
    const pusher = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
      cluster: "us2",
    });
    const channel = pusher.subscribe("ALL");
    channel.bind("UPDATE", () => {
      setShowRefresh(true);
    });

    return () => {
      channel.disconnect();
    };
  }, []);

  const blockList = ["/oauth"];

  const isBlockListed = blockList.some((blocked) =>
    location.pathname.startsWith(blocked),
  );
  const queryParams = isBlockListed
    ? `method=standard&redirectUrl=${encodeURIComponent(
        `${location.pathname}${location.search}${location.hash}`,
      )}`
    : `redirectUrl=${encodeURIComponent(
        `${location.pathname}${location.search}${location.hash}`,
      )}`;

  const Container = isBlockListed ? "div" : SideMenu;

  if (!user.permissions) return <Navigate to="/auth/logout" />;

  if (isLoggedIn) {
    return (
      <CommonlyFetchedValuesProvider>
        <Container showRefresh={showRefresh}>
          <Outlet />
          {inScenarioEditMode && <div className="mt-[300px]" />}
          <ScenarioTray />
        </Container>
      </CommonlyFetchedValuesProvider>
    );
  }

  return <Navigate to={`/auth/login?${queryParams}`} replace />;
};

export default ProtectedRoute;
