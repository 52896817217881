/*
 *renderedWidth was very inconsistent in the width it would say for an element and
 *naturalWidth appears to actually be getting the renderedWidth so to not spend too
 *much time on this I included a maxWidth arg which should be the maximum width in
 *pixels set on the truncation element and it works for now
 */
export default (element: HTMLElement, maxWidth: number): boolean => {
  /*
   * const renderedWidth = element.getBoundingClientRect().width;
   *  Create a clone of the element
   */
  const clone = element.cloneNode(true) as HTMLElement;
  clone.style.position = "absolute";
  clone.style.whiteSpace = "nowrap";
  clone.style.overflow = "visible";
  clone.style.width = "auto";
  clone.style.visibility = "hidden";

  // Append the clone to the body and wait for it to be rendered
  document.body.appendChild(clone);
  // Force a reflow by accessing a property that requires layout calculation
  const naturalWidth = clone.offsetWidth;
  // Clean up: remove the clone
  document.body.removeChild(clone);

  return naturalWidth >= maxWidth;
};
