import Button from "~/components/Button";
import Card from "~/components/Card";
import Input from "~/components/Input";
import React from "react";
import { NavigateFunction } from "react-router-dom";

interface Props {
  email: Types.InputState;
  setEmail: React.Dispatch<React.SetStateAction<Types.InputState>>;
  forgotPassword: () => void;
  navigate: NavigateFunction;
  errorMessage: string;
  successMessage: string;
}

const ForgotPassword = ({
  email,
  setEmail,
  navigate,
  forgotPassword,
  successMessage,
  errorMessage,
}: Props) => (
  <div className="flex flex-col items-center justify-start w-screen h-screen">
    <div className="flex flex-col w-[95vw] max-w-[500px] mt-[10vh]">
      <h1>Reset your password</h1>
      <Card className="w-full mt-5">
        {!successMessage ? (
          <>
            {" "}
            <Input
              id="email"
              state={email}
              setState={setEmail}
              label="Email address"
              placeholder="Enter your email address here"
            />
            <Button
              id="submit-forgot-password"
              onClick={forgotPassword}
              className="mt-5"
            >
              Submit
            </Button>
          </>
        ) : (
          <p>{successMessage}</p>
        )}
        <p className="text-red-500">{errorMessage}</p>
      </Card>
      <h5 className="mt-10">{`Don't need to reset your password?`}</h5>
      <Button
        id="back-to-login"
        fill="outline"
        onClick={() => navigate("/auth/login")}
      >
        Back to login
      </Button>
    </div>
  </div>
);

export default ForgotPassword;
