import React, { useState } from "react";
import { TaskInterface } from "~/components/Tasks";
import date from "~/utils/dates/date";
import Typography from "~/components/Typography";
import Button from "~/components/Button";
import formatCurrency from "~/utils/formatCurrency";
import { Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "~/store";
import request from "~/utils/request";
import { tasksSlice } from "~/store/tasksSlice";
import permissionsCheck from "~/utils/permissionsCheck";
import { toast } from "react-hot-toast";
import DeleteRequest from "./DeleteRequest";
import ViewPositionRequest from "./ViewRequest/ViewPositionRequest";
import useRejectionState from "./ViewRequest/useRejectionState";
import RejectRequest from "./ViewRequest/RejectRequest";

interface Props {
  task: TaskInterface;
  successCallback: () => void;
}

const PositionRequestTask = ({
  task,
  successCallback,
}: Props): React.ReactNode => {
  const dispatch = useDispatch();
  const [displayDeleteRequest, setDisplayDeleteRequest] = useState(false);
  const [displayViewRequest, setDisplayViewRequest] = useState(false);
  const [displayRejectRequest, setDisplayRejectRequest] = useState(false);
  const organizationUuid = useSelector(
    (state: State) => state.organization.uuid,
  );
  const {
    name: userName,
    uuid: userUuid,
    permissions: { role, departmentAccessList },
  } = useSelector((state: State) => state.user);
  const [isCompleted, setIsCompleted] = useState(
    (task.metadata.requestNewPosition?.requestorUuid === userUuid &&
      !!task.metadata.requestNewPosition.deletedBy) ||
      ((task.metadata.requestNewPosition?.requestorUuid !== userUuid &&
        task.metadata.requestNewPosition?.status) === "WITHDRAWN" &&
        task.completedAt !== null),
  );
  const { total } = useSelector((state: State) => state.tasks);
  const { reason, setReason, resetFormState } = useRejectionState();
  const isAdmin = permissionsCheck(role, departmentAccessList);
  const isWithdrawn = task.metadata.requestNewPosition?.status === "WITHDRAWN";

  const isRespondedTo =
    task.metadata.requestNewPosition?.status !== "PENDING" &&
    task.metadata.requestNewPosition?.status !== "WITHDRAWN" &&
    task.completedAt !== null;

  if (!task.metadata.requestNewPosition) {
    return null;
  }
  const handleDeleteRequest = async (): Promise<void> => {
    const taskCompletionResponse = await request({
      method: "DELETE",
      url: `/organizations/${organizationUuid}/requests/${task.uuid}`,
    });

    if (taskCompletionResponse.status >= 400)
      throw Error("Error Withdrawning Request");

    if (isAdmin) {
      toast.success("Request Dismissed Successfully");
    } else {
      toast.success("Request Withdrawn Successfully");
    }
    setDisplayDeleteRequest(false);
    setDisplayRejectRequest(false);
    // Wait for transition animation to run before setting it to completed
    setTimeout(() => {
      setIsCompleted(true);
    }, 300);

    dispatch(tasksSlice.actions.update({ total: total - 1 }));
  };

  let compensationRate = "-";
  if (task.metadata.requestNewPosition.compensationRate) {
    compensationRate = formatCurrency(
      task.metadata.requestNewPosition.compensationRate,
      task.metadata.requestNewPosition.paymentUnit === "HOURLY" ? true : false,
      task.metadata.requestNewPosition.currency || undefined,
    );
  }

  const handleStatus = (status: string): { color: string; message: string } => {
    switch (status) {
      case "PENDING":
        return { color: "neutral-200", message: "Pending" };
      case "APPROVED":
        return { color: "green-400", message: "Approved" };
      case "REJECTED":
        return { color: "red-500", message: "Rejected" };
      default:
        return { color: "neutral-200", message: "Pending" };
    }
  };

  const determineStatusMessage = ((): string => {
    if (isAdmin) {
      if (isWithdrawn) {
        return `Withdrawn by ${task.metadata.requestNewPosition.requestor}`;
      }

      return `Requested by ${task.metadata.requestNewPosition.requestor}`;
    }
    // Default status handling for non-admin users
    return handleStatus(task.metadata.requestNewPosition.status || "PENDING")
      .message;
  })();

  const determineStatusColor = ((): string => {
    if (isAdmin) {
      if (isWithdrawn) {
        return "neutral-400";
      }
    }
    return handleStatus(task.metadata.requestNewPosition.status || "PENDING")
      .color;
  })();

  const approveRequest = async (): Promise<void> => {
    const taskCompletionResponse = await request({
      method: "POST",
      url: `/organizations/${organizationUuid}/requests/${task.uuid}/approve`,
      body: {
        requestType: "newPosition",
        requestData: task.metadata.requestNewPosition,
      },
    });

    if (taskCompletionResponse.status >= 400)
      throw Error("Error Approving Request");

    toast.success("Request Approved Successfully");
    dispatch(tasksSlice.actions.update({ total: total - 1 }));
    setDisplayViewRequest(false);

    // Wait for transition animation to run before setting it to completed
    setTimeout(() => {
      setIsCompleted(true);
    }, 300);
  };

  const rejectRequest = async (): Promise<void> => {
    const taskCompletionResponse = await request({
      method: "POST",
      url: `/organizations/${organizationUuid}/requests/${task.uuid}/reject`,
      body: {
        rejectionReason: reason.value,
      },
    });

    if (taskCompletionResponse.status >= 400)
      throw Error("Error Rejecting Request");

    toast.success("Request Rejected Successfully");
    dispatch(tasksSlice.actions.update({ total: total - 1 }));
    setDisplayViewRequest(false);
    setDisplayRejectRequest(false);
    resetFormState();

    // Wait for transition animation to run before setting it to completed
    setTimeout(() => {
      setIsCompleted(true);
    }, 300);
  };

  const handleButtons = ((): JSX.Element => {
    if (task.metadata.requestNewPosition.status === "REJECTED") {
      return (
        <div className="flex flex-row gap-4">
          <Button
            id={`view-reject-position-request-${task.uuid}`}
            onClick={() => {
              setDisplayRejectRequest(true);
            }}
          >
            View Response
          </Button>
        </div>
      );
    }

    return (
      <Button
        id={`dismiss-position-request-${task.uuid}`}
        onClick={handleDeleteRequest}
      >
        Dismiss
      </Button>
    );
  })();

  return (
    <Transition
      show={!isCompleted}
      as={React.Fragment}
      enter="transition ease-in duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-in duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      afterLeave={successCallback}
    >
      <div
        data-testid={`position-request-${task.uuid}`}
        className={`flex flex-row justify-between py-4 px-6 border border-neutral-50 rounded-lg w-full ${
          task.completedBy ? "opacity-50" : ""
        }${isWithdrawn ? " bg-neutral-25" : "bg-white"}`}
      >
        <DeleteRequest
          isOpen={displayDeleteRequest}
          setModal={() => setDisplayDeleteRequest(false)}
          submitFunction={handleDeleteRequest}
        />
        <ViewPositionRequest
          isOpen={displayViewRequest}
          setModal={() => setDisplayViewRequest(false)}
          request={task.metadata.requestNewPosition}
          approveFunction={approveRequest}
          rejectFunction={() => {
            setDisplayViewRequest(false);
            setDisplayRejectRequest(true);
          }}
          createdAt={task.createdAt}
          isAdmin={isAdmin}
        />
        <RejectRequest
          isOpen={displayRejectRequest}
          backFunction={() => {
            if (isAdmin) {
              setDisplayRejectRequest(false);
              setDisplayViewRequest(true);
              resetFormState();
            }
            setDisplayRejectRequest(false);
          }}
          reason={reason}
          setReason={setReason}
          submitFunction={isAdmin ? rejectRequest : handleDeleteRequest}
          isAdmin={isAdmin}
          rejectReason={task.metadata.requestNewPosition.rejectionReason}
          task={task}
        />
        <div key={task.uuid} className="col-span-7 flex flex-col items-start">
          <Typography
            id="unassigned-employee-task-title"
            tag="h3"
            size="md"
            weight="semibold"
            className="mb-1"
          >
            New Position{" "}
            <span
              className={`text-${determineStatusColor} font-normal`}
            >{`(${determineStatusMessage})`}</span>
          </Typography>
          <div
            data-testid={`position-request-metadata-${task.uuid}`}
            className="flex flex-row justify-between gap-6"
          >
            <div className="flex flex-col">
              <Typography size="xs" color="secondary">
                Title
              </Typography>
              <Typography size="xs" className="max-w-32 truncate">
                {task.metadata.requestNewPosition.title}
              </Typography>
            </div>
            <div className="flex flex-col">
              <Typography size="xs" color="secondary">
                Compensation
              </Typography>
              <Typography size="xs" className="max-w-32 truncate">
                {compensationRate}
              </Typography>
            </div>
            {task.metadata.requestNewPosition.group && (
              <div className="flex flex-col">
                <Typography size="xs" color="secondary">
                  Department
                </Typography>
                <Typography size="xs" className="max-w-32 truncate">
                  {task.metadata.requestNewPosition.group}
                </Typography>
              </div>
            )}
            {task.metadata.requestNewPosition.manager && (
              <div className="flex flex-col">
                <Typography size="xs" color="secondary">
                  Manager
                </Typography>
                <Typography size="xs" className="max-w-32 truncate">
                  {task.metadata.requestNewPosition.manager}
                </Typography>
              </div>
            )}
            <div className="flex flex-col">
              <Typography size="xs" color="secondary">
                Start Date
              </Typography>
              <Typography size="xs" className="max-w-32 truncate">
                {task.metadata.requestNewPosition.effectiveAt
                  ? date(task.metadata.requestNewPosition.effectiveAt).format(
                      "MM/DD/YYYY",
                    )
                  : "-"}
              </Typography>
            </div>
          </div>
        </div>
        {isWithdrawn || isRespondedTo ? (
          <div className="col-span-2 flex flex-row gap-4 items-center max-xl:flex-col">
            {handleButtons}
          </div>
        ) : (
          <div className="col-span-2 flex flex-row gap-4 items-center max-xl:flex-col">
            <Button
              id={`withdraw-position-request-${task.uuid}`}
              fill="destructiveOutline"
              onClick={() => {
                if (task.metadata.requestNewPosition?.requestor === userName) {
                  setDisplayDeleteRequest(true);
                }
              }}
              className={`"!w-auto !text-nowrap ${isAdmin ? "hidden" : ""}`}
            >
              Withdraw
            </Button>
            <Button
              id={`view-position-request-${task.uuid}`}
              onClick={() => setDisplayViewRequest(true)}
            >
              View
            </Button>
          </div>
        )}
      </div>
    </Transition>
  );
};

export default PositionRequestTask;
