import React, { ReactNode } from "react";
import TableRow from "~/components/Table/TableRow";
import { ICellValue, ISelectedCellState } from "~/components/Table/TableCell";
import SkeletonRow from "./SkeletonRow";
import useSort from "./useSort";
import { v4 } from "uuid";

interface KeyLabel {
  key: string;
  label: string;
}

export interface TableData {
  id: string;
  values: ICellValue[];
  isSelected?: boolean;
  metadata?: Record<string, unknown>;
  exportLabel?: string;
  onClick?: () => void;
  expandedContent?: React.ReactNode;
  rowColor?: "white" | "green" | "neutral" | "inactive";
}

interface Props {
  id?: string;
  headers: string[] | KeyLabel[] | React.ReactNode[];
  headerClassName?: string;
  columnAlignment?: (
    | "center"
    | "left"
    | "right"
    | "justify"
    | "char"
    | undefined
  )[];
  data: TableData[] | null;
  loadingState?: {
    isLoading: boolean;
    skeletonRows?: number;
    widths?: number[] | string[];
  };
  emptyState?: React.ReactNode;
  rowClasses?: string;
  tableRowDataClasses?: string | string[];
  className?: string;
  autoSpacing?: boolean;
  tableClassName?: string;
  editMode?: boolean;
  edits?: Record<
    string,
    { amount: number; departmentUuid: string; effectiveAt: Date }
  >;
  setEdits?: React.Dispatch<
    React.SetStateAction<
      Record<
        string,
        { amount: number; departmentUuid: string; effectiveAt: Date }
      >
    >
  >;
  selectedCell?: ISelectedCellState;
  setSelectedCell?: React.Dispatch<
    React.SetStateAction<ISelectedCellState | undefined>
  >;
}

const Table = ({
  id,
  headers,
  headerClassName = "",
  tableClassName = "",
  data,
  loadingState,
  emptyState,
  rowClasses,
  tableRowDataClasses,
  className,
  columnAlignment,
  autoSpacing = true,
  editMode = false,
  edits,
  setEdits,
  selectedCell,
  setSelectedCell,
}: Props) => {
  const { isLoading = false, skeletonRows = 5, widths } = loadingState || {};
  const headerLabels: string[] | ReactNode[] = headers.map((header) => {
    if (header && typeof header === "object" && "label" in header)
      return header.label;
    return header;
  });
  const getPaddingClass = (
    index: number,
    totalColumns: number,
    basePadding = "py-4",
  ) => {
    if (index === 0) return `pl-5 pr-2 ${basePadding}`;
    if (index === totalColumns - 1) return `pl-2 pr-5 ${basePadding}`;
    return `px-2 ${basePadding}`;
  };

  return (
    <div className={`w-full overflow-x-auto ${className}`}>
      <div className="min-w-full inline-block align-middle">
        <div className="overflow-hidden">
          <table
            id={id}
            className={`w-full divide-y divide-gray-200 border-separate border-spacing-0 bg-white ${tableClassName}`}
            data-testid={id}
          >
            <thead>
              <tr data-testid="table-header-row">
                {headerLabels.map((value, index) => (
                  <th
                    align={columnAlignment?.[index] || "left"}
                    data-testid="table-header-column"
                    key={
                      typeof value === "string" && value.length > 0
                        ? value
                        : index
                    }
                    scope="col"
                    className={`border-b text-xs text-neutral-200 font-medium uppercase truncate ${
                      autoSpacing
                        ? getPaddingClass(index, headerLabels.length)
                        : ""
                    }${
                      widths?.[index] ? ` w-${widths[index]}` : ""
                    } ${headerClassName}`}
                  >
                    {value}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-gray-200">
              {isLoading &&
                Array.from({ length: skeletonRows }).map(() => (
                  <SkeletonRow
                    key={`table-skeleton-row-${v4()}`}
                    colCount={headers.length}
                    widths={widths}
                  />
                ))}
              {data &&
                !isLoading &&
                data.map((row) => (
                  <TableRow
                    key={row.id}
                    id={row.id}
                    isSelected={row.isSelected}
                    className={rowClasses}
                    tableDataClassName={tableRowDataClasses}
                    onClick={row.onClick}
                    values={row.values}
                    columnAlignment={columnAlignment}
                    expandedContent={row.expandedContent}
                    autoSpacing={autoSpacing}
                    rowColor={row.rowColor}
                    widths={widths}
                    editMode={editMode}
                    selectedCell={selectedCell}
                    setSelectedCell={setSelectedCell}
                    edits={edits}
                    setEdits={setEdits}
                    headerLabels={headerLabels}
                  />
                ))}
              {!data?.length && !isLoading && (
                <tr data-testid="table-empty-state">
                  <td
                    colSpan={headers.length}
                    className="text-center justify-center"
                  >
                    {emptyState}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export { useSort };
export default Table;
