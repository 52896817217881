import React from "react";

export interface IPeriodPickerState {
  startDate: Date;
  endDate: Date;
  mode: "month" | "quarter" | "year";
}

const usePeriodPicker = ({ startDate, endDate, mode }: IPeriodPickerState) =>
  React.useState<IPeriodPickerState>({
    startDate,
    endDate,
    mode,
  });

export default usePeriodPicker;
