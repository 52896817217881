import React, { createContext, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import useDatePicker, {
  IDatePickerState,
} from "~/components/DatePicker/useDatePicker";
import { State } from "~/store";
import request from "~/utils/request";
import useDrilldownDrawer, {
  IUseDrilldownDrawer,
} from "~/components/Drawer/DrilldownDrawer/useDrilldownDrawer";
import months from "~/utils/dates/months";
import date from "~/utils/dates/date";
import { IOrganizationConfigurationResponse } from "~/components/SideMenu";
import { HeadcountOrSalary, ProjectionsResponse } from "../types";
import useQueryParams from "~/utils/hooks/useQueryParams";
import generateTableData from "../utils/generateTableData";
import { TableData } from "~/components/Table";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import { CommonlyFetchedValuesContext } from "~/context/CommonlyFetchedValuesContext";
import { IDepartment } from "~/types/department/types";

interface IForecastPageContext {
  selectYearState: IDatePickerState;
  setSelectYearState: React.Dispatch<React.SetStateAction<IDatePickerState>>;
  departments: IDepartment[];
  drilldownDrawerState: IUseDrilldownDrawer;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  total: number;
  headers?: string[];
  organizationUuid: string;
  projections:
    | {
        currencyBasedProjections: {
          title: string;
          data: TableData[];
        }[];
        headcountBasedProjections: {
          title: string;
          data: TableData[];
        }[];
      }
    | undefined;
  headcountOrSalary: HeadcountOrSalary;
  setHeadcountOrSalary: React.Dispatch<React.SetStateAction<HeadcountOrSalary>>;
  headcountTotalParity: boolean;
}

export const ForecastPageContext = createContext<IForecastPageContext | null>(
  null,
);

export const ForecastPageProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactNode => {
  const commonValues = React.useContext(CommonlyFetchedValuesContext);
  if (!commonValues) {
    throw new Error("CommonlyFetchedValuesContext not provided");
  }
  const { departments } = commonValues;
  const controllerRef = useRef<AbortController>();
  const [isLoading, setIsLoading] = useState(true);
  const [selectYearState, setSelectYearState] = useDatePicker({});
  const [headers, setHeaders] = useState<string[]>([]);
  const drilldownDrawerState = useDrilldownDrawer();
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const { total } = useSelector((state: State) => state.tasks);
  const [prevScrollPosition, setPrevScrollPosition] = useState<number>(0);
  const [projections, setProjections] = useState<{
    currencyBasedProjections: {
      title: string;
      data: TableData[];
    }[];
    headcountBasedProjections: {
      title: string;
      data: TableData[];
    }[];
  }>();
  const headcountTotalParity = Boolean(useFeatureFlag("headcountTotalParity"));
  const [queryParams, setQueryParams] = useQueryParams();
  const [headcountOrSalary, setHeadcountOrSalary] = useState<HeadcountOrSalary>(
    (queryParams.get("format") as HeadcountOrSalary | undefined) ?? "salaries",
  );

  // When opening the drilldown drawer, save the current scroll position
  useEffect(() => {
    const handleScroll = (): void => {
      setPrevScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (drilldownDrawerState.isOpen) {
      window.scrollTo(0, prevScrollPosition);
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [drilldownDrawerState.isOpen]);

  // Get the date range for the fiscal year
  const getDateRange = async (): Promise<void> => {
    try {
      const { data, status } = (await request({
        url: `/organizations/${organizationUuid}/settings`,
        method: "GET",
      })) as IOrganizationConfigurationResponse;
      if (status === 200) {
        if (data.data?.fiscalYearStart) {
          const startDate = date().lastOccurrence(
            "month",
            months.indexOf(data.data.fiscalYearStart.toLowerCase()),
          );
          const endDate = date()
            .lastOccurrence(
              "month",
              months.indexOf(data.data.fiscalYearStart.toLowerCase()),
            )
            .add(1, "year")
            .subtract(1, "day");

          setSelectYearState((prevState) => ({
            ...prevState,
            value: {
              startDate: startDate.format("YYYY-MM-DD"),
              endDate: endDate.format("YYYY-MM-DD"),
            },
          }));
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDateRange();
  }, []);

  // Fetch the projections for the departments and selected date range
  const fetchProjections = async (): Promise<void> => {
    if (!selectYearState.value.endDate || !selectYearState.value.startDate)
      throw Error("Missing date range");

    /**
     * Set headers based on data range provided
     */
    const numberOfMonthsInReport =
      date(selectYearState.value.endDate).diff(
        selectYearState.value.startDate,
        "month",
      ) + 1;
    const monthsToDisplay = Array.from(
      { length: numberOfMonthsInReport },
      (_, index) => {
        if (!selectYearState.value.startDate) throw Error("Missing start date");
        const month = date(selectYearState.value.startDate).add(index, "month");
        return month.format("MMM");
      },
    );
    setHeaders(["Department", ...monthsToDisplay]);

    /**
     * Make request to get projections
     */
    try {
      if (controllerRef.current) controllerRef.current.abort();
      controllerRef.current = new AbortController();
      const { signal } = controllerRef.current;

      const projectionsResponse = (await request({
        url: `/organizations/${organizationUuid}/projections`,
        method: "GET",
        params: {
          startDate: date(selectYearState.value.startDate).format("YYYY-MM-DD"),
          endDate: date(selectYearState.value.endDate).format("YYYY-MM-DD"),
          departments: departments.map((department) => department.uuid),
        },
        signal,
      })) as ProjectionsResponse;
      // Top level iterates over all the projections available
      const currencyBasedTableData = generateTableData({
        headcountTotalParity: headcountTotalParity as boolean,
        projections: projectionsResponse.data.data.currencyProjections,
        selectYearState,
        departments,
        drilldownDrawerState,
      });
      let numberBasedTableData = generateTableData({
        headcountTotalParity: headcountTotalParity as boolean,
        projections: [projectionsResponse.data.data.headcountProjections[0]],
        selectYearState,
        departments,
        drilldownDrawerState,
      });

      // Conditionally add the second table data based on the feature flag
      if (
        headcountTotalParity &&
        projectionsResponse.data.data.headcountProjections[1]
      ) {
        numberBasedTableData = [
          ...numberBasedTableData,
          ...generateTableData({
            headcountTotalParity: headcountTotalParity as boolean,
            projections: [
              projectionsResponse.data.data.headcountProjections[1],
            ],
            includeTotalRow: false,
            selectYearState,
            departments,
            drilldownDrawerState,
          }),
        ];
      }
      setProjections({
        currencyBasedProjections: currencyBasedTableData,
        headcountBasedProjections: numberBasedTableData,
      });
      setIsLoading(false);
    } catch (error) {
      if (error.name !== "CanceledError") {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (
      departments.length > 0 &&
      selectYearState.value.startDate &&
      selectYearState.value.endDate
    ) {
      fetchProjections();
    }
  }, [departments, selectYearState.value]);

  // Set query params
  useEffect(() => {
    setQueryParams({
      format: headcountOrSalary,
    });
  }, [headcountOrSalary]);

  return (
    <ForecastPageContext.Provider
      value={{
        selectYearState,
        setSelectYearState,
        departments,
        drilldownDrawerState,
        isLoading,
        setIsLoading,
        total,
        headers,
        organizationUuid,
        projections,
        headcountOrSalary,
        setHeadcountOrSalary,
        headcountTotalParity,
      }}
    >
      {children}
    </ForecastPageContext.Provider>
  );
};
