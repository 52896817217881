import { SelectType } from "~/components/Select/Select.types";
import { IPlan } from "~/types/plans/types";

export default ({
  plans,
}: {
  plans: IPlan[];
}): {
  selected:
    | {
        label: string;
        value: string;
      }
    | undefined;
  options: SelectType[];
} => {
  if (plans.length) {
    const firstOption = plans[plans.length - 1];
    const selected = {
      label: firstOption.label,
      value: firstOption.uuid,
    };
    const options = plans.map((plan) => ({
      label: plan.label,
      value: plan.uuid,
    }));
    return { selected, options };
  } else {
    return {
      selected: undefined,
      options: [],
    };
  }
};
