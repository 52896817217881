import React, { useState } from "react";
import { Popover } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { usePopper } from "react-popper";

interface Props {
  id?: string;
  options: Option[];
  onClick: () => void;
}

interface Option {
  label: string;
  onClick: () => void;
  className?: string;
  id?: string;
}

const EllipsisDropdown = ({ id, options, onClick }: Props) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end",
  });

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button
            as="div"
            onClick={onClick}
            className="cursor-pointer text-neutral-400"
            data-testid={id ?? ""}
            ref={setReferenceElement}
          >
            <EllipsisVerticalIcon className="h-6 w-6" />
          </Popover.Button>
          <Popover.Panel
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            as="div"
            className={`${
              open ? "block" : "hidden"
            } absolute bg-white shadow-md rounded-md z-10 border-none outline-none`}
          >
            {options.map((option, index) => (
              <button
                key={index}
                className={`
                  block px-4 py-2 w-full text-left outline-none border-none
                  ${option.className} 
                `}
                onClick={option.onClick}
                data-testid={id ? id + "-" + option.label : ""}
              >
                {option.label}
              </button>
            ))}
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default EllipsisDropdown;
