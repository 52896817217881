import request from "~/utils/request";
import React, { useEffect, useState } from "react";
import date from "~/utils/dates/date";
import { capitalCase } from "change-case";
import { useDispatch, useSelector } from "react-redux";
import { State } from "~/store";
import Modal from "~/components/Modal";
import positionVersionsHaveChanged from "~/utils/positionVersion/positionVersionsHaveChanged";
import permissionsCheck from "~/utils/permissionsCheck";
import { tasksSlice } from "~/store/tasksSlice";
import { isAfter, isSameDay } from "date-fns";
import PositionForm from "../PositionForm";
import usePositionFormState from "../PositionForm/usePositionFormState";
import { PositionResponse } from "../PositionDetails";
import { ICurrentPosition } from "../headcount.types";

interface Props {
  positionUuid?: string | null;
  isOpen: boolean;
  setModal: (isOpen: boolean) => void;
  setPositionUuid: (uuid: string | null) => void;
  successCallback?: () => void;
  modalSource?: string;
}

const ModifyPositionContainer = ({
  positionUuid,
  isOpen,
  setModal,
  setPositionUuid,
  successCallback,
  modalSource,
}: Props) => {
  const dispatch = useDispatch();
  const {
    errorMessage,
    setErrorMessage,
    organizationUuid,
    jobTitle,
    setJobTitle,
    startDateState,
    setStartDateState,
    employmentType,
    setEmploymentType,
    selectedPaymentType,
    setSelectedPaymentType,
    paymentAmountState,
    setPaymentAmountState,
    bonusAmountState,
    setBonusAmountState,
    commissionAmountState,
    setCommissionAmountState,
    attainmentState,
    setAttainmentState,
    currencyTypeState,
    setCurrencyTypeState,
    selectDepartment,
    setSelectDepartment,
    managerState,
    setManagerState,
    changeDescription,
    setChangeDescription,
    expectedHoursState,
    setExpectedHoursState,
    createNewScenario,
    savingPosition,
    setSavingPosition,
  } = usePositionFormState();
  const [originalPosition, setOriginalPosition] = useState<ICurrentPosition>();

  const {
    uuid: userUuid,
    permissions: { role, departmentAccessList },
  } = useSelector((state: State) => state.user);
  const isAdmin = permissionsCheck(role, departmentAccessList);
  const { total } = useSelector((state: State) => state.tasks);
  const { inEditMode: inScenarioEditMode, activeScenarioUuid } = useSelector(
    (state: State) => state.scenario,
  );

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setModal(false);
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleEscape);
    } else {
      document.removeEventListener("keydown", handleEscape);
    }

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [isOpen]);

  useEffect(() => {
    const getPositionDetails = async (): Promise<void> => {
      const positionDetailsResponse = (await request({
        url: `/organizations/${organizationUuid}/positions/${positionUuid}`,
        method: "GET",
      })) as PositionResponse;
      if (
        positionDetailsResponse.status === 200 &&
        positionDetailsResponse.data?.data
      ) {
        const positionDetails = positionDetailsResponse.data.data;
        setOriginalPosition(positionDetails.current);

        setJobTitle({
          ...jobTitle,
          value: positionDetails.current.title,
          valid: true,
        });
        const effectiveAtDate = date(
          positionDetails.current.effectiveAt,
        ).isSameOrAfter(date())
          ? positionDetails.current.effectiveAt
          : date().toISOString();

        if (
          date(positionDetails.current.effectiveAt).isBefore(date(), "day") &&
          !positionDetails.current.employeeUuid
        ) {
          setStartDateState({
            ...startDateState,
            value: {
              startDate: null,
              endDate: null,
            },
            minDate: date().toISOString(),
            valid: false,
            pristine: false,
            touched: true,
            errorMessage: "This date has passed",
          });
        } else {
          setStartDateState({
            ...startDateState,
            value: {
              startDate: effectiveAtDate,
              endDate: effectiveAtDate,
            },
            minDate: date().toISOString(),
            valid: true,
          });
        }
        setEmploymentType({
          ...employmentType,
          selected: {
            label: capitalCase(positionDetails.current.employmentType),
            value: positionDetails.current.employmentType,
          },
          valid: true,
        });
        setSelectedPaymentType({
          ...selectedPaymentType,
          selected: {
            label: capitalCase(positionDetails.current.paymentUnit),
            value: positionDetails.current.paymentUnit,
          },
          valid: true,
        });

        setPaymentAmountState({
          ...paymentAmountState,
          value: (positionDetails.current.compensationRate / 100).toString(),
          valid: true,
        });
        setBonusAmountState({
          ...bonusAmountState,
          value:
            positionDetails.current.bonus !== null
              ? (positionDetails.current.bonus / 100).toString()
              : "",
          valid: true,
          disabled: true,
        });
        setCommissionAmountState({
          ...commissionAmountState,
          value:
            positionDetails.current.commission !== null
              ? (positionDetails.current.commission / 100).toString()
              : "",
          valid: true,
          disabled: true,
        });
        setAttainmentState({
          ...attainmentState,
          value:
            positionDetails.current.attainment !== null
              ? (positionDetails.current.attainment * 100).toString()
              : "",
          valid: true,
          disabled: true,
        });
        setCurrencyTypeState({
          ...currencyTypeState,
          selected: {
            label: positionDetails.current.currency,
            value: positionDetails.current.currency,
          },
          valid: true,
        });
        setSelectDepartment((prevState) => ({
          ...prevState,
          selected: {
            label: positionDetails.currentDepartment?.name ?? null,
            value: positionDetails.currentDepartment?.uuid ?? null,
          },
          valid: true,
        }));
        setManagerState({
          ...managerState,
          selected: {
            label: positionDetails.currentManager
              ? `${positionDetails.currentManager.firstName} ${positionDetails.currentManager.lastName}`
              : null,
            value: positionDetails.currentManager?.uuid ?? null,
          },
          valid: true,
        });
        setExpectedHoursState({
          ...expectedHoursState,
          value: positionDetails.current.expectedWeeklyHours?.toString() ?? "",
          valid:
            positionDetails.current.expectedWeeklyHours !== null &&
            positionDetails.current.expectedWeeklyHours >= 0,
          disabled: true,
        });
        setChangeDescription({
          ...changeDescription,
          value: modalSource === "backfill-task" ? "Back-filling position" : "",
          valid: true,
        });
      }
    };
    if (isOpen && positionUuid) {
      getPositionDetails();
    }
  }, [isOpen, positionUuid]);
  const attemptModifyPosition = async () => {
    const startDate = startDateState.value.startDate
      ? date(startDateState.value.startDate).toDate()
      : date().toDate();
    const isSameDayOrAfter =
      isSameDay(startDate, date().toDate()) ||
      isAfter(startDate, date().toDate());

    if (
      originalPosition &&
      jobTitle.value &&
      employmentType.selected?.value &&
      selectedPaymentType.selected?.value &&
      paymentAmountState.value &&
      currencyTypeState.selected?.value &&
      startDateState.value.startDate &&
      isSameDayOrAfter &&
      (isAdmin || changeDescription.value) &&
      (selectedPaymentType.selected.value === "SALARY" ||
        expectedHoursState.valid)
    ) {
      const dataToUpdate = {
        groupUuid: selectDepartment.selected?.value ?? null,
        managerUuid: managerState.selected?.value ?? null,
        employmentType: employmentType.selected.value,
        compensationRate: Number(paymentAmountState.value) * 100,
        bonus: bonusAmountState.value
          ? Number(bonusAmountState.value) * 100
          : null,
        commission: commissionAmountState.value
          ? Number(commissionAmountState.value) * 100
          : null,
        attainment: attainmentState.value
          ? Number(attainmentState.value) / 100
          : null,
        currency: currencyTypeState.selected.value,
        paymentUnit: selectedPaymentType.selected.value,
        title: jobTitle.value,
        effectiveAt: date(startDateState.value.startDate).format("YYYY-MM-DD"),
        changeDescription: changeDescription.value,
        expectedWeeklyHours:
          selectedPaymentType.selected.value === "HOURLY"
            ? expectedHoursState.value
            : undefined,
        scenarioUuid: activeScenarioUuid,
      };

      if (!positionVersionsHaveChanged(dataToUpdate, originalPosition)) {
        setErrorMessage("Please make a change first to save a new forecast");
      } else {
        const requestUrl = isAdmin
          ? `/organizations/${organizationUuid}/positions/${positionUuid}`
          : `/organizations/${organizationUuid}/requests`;

        const requestBody = isAdmin
          ? dataToUpdate
          : {
              requestType: "requestPositionForecast",
              requestData: {
                ...dataToUpdate,
                positionUuid,
                requestorUuid: userUuid,
                status: "PENDING",
              },
            };
        const createPositionResponse = await request({
          url: requestUrl,
          method: isAdmin ? "PATCH" : "POST",
          body: requestBody,
        });
        if (createPositionResponse.status >= 400) {
          setErrorMessage(
            "There was an error creating the position. Please try again.",
          );
        } else {
          setModal(false);
          setPositionUuid(null);
          if (!isAdmin) {
            dispatch(tasksSlice.actions.update({ total: total + 1 }));
          }
          if (successCallback) {
            successCallback();
          }
        }
      }
    } else {
      setJobTitle({ ...jobTitle, pristine: false, touched: true });
      setEmploymentType({ ...employmentType, pristine: false, touched: true });
      setSelectedPaymentType({
        ...selectedPaymentType,
        pristine: false,
        touched: true,
      });
      setSelectDepartment({
        ...selectDepartment,
        pristine: false,
        touched: true,
      });
      setPaymentAmountState({
        ...paymentAmountState,
        pristine: false,
        touched: true,
      });
      setBonusAmountState({
        ...bonusAmountState,
        pristine: false,
        touched: true,
      });
      setCommissionAmountState({
        ...commissionAmountState,
        pristine: false,
        touched: true,
      });
      setAttainmentState({
        ...attainmentState,
        pristine: false,
        touched: true,
      });
      setCurrencyTypeState({
        ...currencyTypeState,
        pristine: false,
        touched: true,
      });
      setStartDateState({ ...startDateState, pristine: false, touched: true });
      setChangeDescription((prevState) => ({
        ...prevState,
        valid: !isAdmin
          ? changeDescription.validation.test(prevState.value)
          : true,
        pristine: false,
        touched: true,
      }));
      if (selectedPaymentType.selected?.value === "HOURLY") {
        setExpectedHoursState({
          ...expectedHoursState,
          pristine: false,
          touched: true,
        });
      }
    }
    setSavingPosition(false);
  };

  const chooseModalTitle = (() => {
    if (modalSource === "backfill-task") {
      return "Backfill Position";
    }
    return "Modify Position";
  })();

  return (
    <Modal isOpen={isOpen} title={chooseModalTitle} size="md">
      <div data-testid="modify-position-modal" className="mt-4 w-full">
        <PositionForm
          mode="edit"
          isAdmin={isAdmin}
          onClose={() => {
            setModal(false);
            setPositionUuid(null);
            setErrorMessage("");
          }}
          jobTitle={jobTitle}
          setJobTitle={setJobTitle}
          managerState={managerState}
          setManagerState={setManagerState}
          selectDepartment={selectDepartment}
          setSelectDepartment={setSelectDepartment}
          startDateState={startDateState}
          setStartDateState={setStartDateState}
          employmentType={employmentType}
          setEmploymentType={setEmploymentType}
          selectedPaymentType={selectedPaymentType}
          setSelectedPaymentType={setSelectedPaymentType}
          paymentAmountState={paymentAmountState}
          setPaymentAmountState={setPaymentAmountState}
          bonusAmountState={bonusAmountState}
          setBonusAmountState={setBonusAmountState}
          commissionAmountState={commissionAmountState}
          setCommissionAmountState={setCommissionAmountState}
          attainmentState={attainmentState}
          setAttainmentState={setAttainmentState}
          currencyTypeState={currencyTypeState}
          setCurrencyTypeState={setCurrencyTypeState}
          errorMessage={errorMessage}
          savePosition={attemptModifyPosition}
          changeDescription={changeDescription}
          setChangeDescription={setChangeDescription}
          expectedHoursState={expectedHoursState}
          setExpectedHoursState={setExpectedHoursState}
          createNewScenario={createNewScenario}
          inScenarioEditMode={inScenarioEditMode}
          modalSource={modalSource}
          savingPosition={savingPosition}
        />
      </div>
    </Modal>
  );
};

export default ModifyPositionContainer;
