import useInput from "~/components/Input/useInput";
import { State } from "~/store";
import { passwordValidator } from "~/utils/validators";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import request from "~/utils/request";
import { userSlice } from "~/store/userSlice";
import { organizationSlice } from "~/store/organizationSlice";
import ResetPassword from "./ResetPassword";

const ResetPasswordContainer = (): React.ReactNode => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { uuid: userUuid } = useSelector((state: State) => state.user);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [password, setPassword] = useInput({
    validation: passwordValidator,
    errorMessage:
      "Please enter a valid password (8 characters, a number, and a special character)",
  });
  useEffect(() => {
    setErrorMessage("");
  }, [password.value]);

  const getUser = async () => {
    const userResponse = await request({
      url: "/users/me",
      method: "GET",
    });

    dispatch(
      userSlice.actions.update({
        uuid: userResponse.data.data.uuid,
        name: userResponse.data.data.name,
        email: userResponse.data.data.email,
        preferences: userResponse.data.data.preferences,
        createdAt: new Date(userResponse.data.data.createdAt),
      }),
    );
    const primaryOrg = userResponse.data.data.organizations.find(
      (org: { uuid: string }) =>
        org.uuid === userResponse.data.data.preferences.primaryOrganizationUuid,
    );
    dispatch(organizationSlice.actions.update(primaryOrg));
  };

  useEffect(() => {
    const token = searchParams.get("token");
    if (token) {
      localStorage.setItem("accessToken", token);
      localStorage.setItem("refreshToken", token);
      setSearchParams({});
      getUser();
    }
  }, [searchParams]);

  const attemptUpdatePassword = async () => {
    if (password.valid) {
      const { status } = await request({
        url: `/users/${userUuid}`,
        method: "PATCH",
        body: {
          password: password.value,
        },
      });
      if (status >= 400) return setErrorMessage("Unable to update password");
      return navigate("/onboarding");
    }
    return setPassword({ ...password, pristine: false, touched: true });
  };
  return (
    <ResetPassword
      password={password}
      setPassword={setPassword}
      updatePassword={attemptUpdatePassword}
      errorMessage={errorMessage}
    />
  );
};

export default ResetPasswordContainer;
