import React, { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { usePopper } from "react-popper";
import useHover from "~/utils/hooks/useHover";

interface Props {
  popoverMessage: string;
  children: React.ReactNode | string;
  className?: string;
}

const DrilldownMathPopover = ({
  popoverMessage,
  children,
  className,
}: Props): JSX.Element => {
  const [popperElement, setPopperElement] = useState(undefined);
  const [ref, hovering] = useHover();
  const { styles, attributes } = usePopper(ref, popperElement);

  return (
    <div ref={ref} className={className ? className : ""}>
      <Popover className="relative w-fit">
        <Popover.Button
          style={{ pointerEvents: "none" }}
          className="text-xs flex items-center"
        >
          {children}
        </Popover.Button>
        <Transition
          show={hovering as boolean}
          as={Fragment}
          enter="transition-opacity ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-in duration-50"
          leaveTo="opacity-0"
        >
          <Popover.Panel
            static
            ref={setPopperElement}
            style={{
              ...styles.popper,
              zIndex: 20,
              top: "-40px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "max-content",
            }}
            {...attributes.popper}
            className="bg-black text-white px-2 py-1 rounded shadow-xl"
          >
            {popoverMessage}
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
};

export default DrilldownMathPopover;
