import React, { useEffect } from "react";
import Select, { useSelect } from "~/components/Select";

interface IProps {
  fieldMappings: Record<string, string | null>;
  options: { label: string; value?: string }[];
  setFieldMappings: (mappings: Record<string, string | null>) => void;
  providedHeader: string;
  direction?: "below" | "above";
}

const SelectFieldMapping = ({
  fieldMappings,
  options,
  setFieldMappings,
  providedHeader,
  direction = "below",
}: IProps): React.ReactNode => {
  const [selection, setSelection] = useSelect({
    options,
  });

  /**
   * React to changes in the field mappings
   *
   * Set the relevant options based on the current field mappings.
   * We don't want top show an option that has already been selected
   * And we want to sort already selected options to the bottom
   */
  useEffect(() => {
    const selectedFields = Object.keys(fieldMappings).filter(
      (key) => fieldMappings[key] !== null,
    );
    setSelection((prevState) => ({
      ...prevState,
      options: options
        .map((option) => ({
          ...option,
          disabled:
            option.value !== undefined &&
            selectedFields.includes(option.value) &&
            option.value !== selection.selected?.value,
        }))
        .sort((a, b) => {
          if (a.disabled && !b.disabled) return 1;
          if (!a.disabled && b.disabled) return -1;
          return 0;
        }),
    }));
  }, [fieldMappings]);

  /**
   * React to changes in the selection
   *
   * Update field mappings when a new selection is made
   */
  useEffect(() => {
    const newFieldMappings = { ...fieldMappings };
    Object.entries(newFieldMappings).forEach(([key, value]) => {
      if (providedHeader === value && selection.selected?.value !== key)
        newFieldMappings[key] = null;
    });
    if (!selection.selected?.value) return;
    newFieldMappings[selection.selected.value] = providedHeader;
    setFieldMappings(newFieldMappings);
  }, [selection.selected]);

  return (
    <div className="min-w-[275px]">
      <Select
        id={`field-mapping-${providedHeader}`}
        state={selection}
        setState={setSelection}
        placeholder="- Skip column -"
        direction={direction}
      />
    </div>
  );
};

export default SelectFieldMapping;
