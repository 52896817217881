import React, { useEffect, useState } from "react";
import request from "~/utils/request";
import { TaskInterface } from "~/components/Tasks";
import { useDispatch, useSelector } from "react-redux";
import { State } from "~/store";
import Button from "~/components/Button";
import Typography from "~/components/Typography";
import formatCurrency from "~/utils/formatCurrency";
import useQueryParams from "~/utils/hooks/useQueryParams";
import toast from "react-hot-toast";
import { Transition } from "@headlessui/react";
import { tasksSlice } from "~/store/tasksSlice";
import ModifyPositionContainer from "../../pages/Headcount/ModifyPosition";
import ConfirmPrompt from "../ConfirmPrompt";
import { userSlice, IUserPreferences } from "~/store/userSlice";

interface IUserPreferencesResponse {
  data: {
    data: IUserPreferences;
  };
  status: number;
}

interface Props {
  task: TaskInterface;
}

const BackfillPositionTask = ({ task }: Props): React.ReactNode => {
  const dispatch = useDispatch();
  const organizationUuid = useSelector(
    (state: State) => state.organization.uuid,
  );

  const { total } = useSelector((state: State) => state.tasks);
  const [isCompleted, setIsCompleted] = useState(task.completedAt !== null);

  const [queryParams, setQueryParams] = useQueryParams();
  const [modifyPositionModalIsOpen, setModifyPositionModalIsOpen] = useState(
    queryParams.get("modifyPosition") !== null,
  );
  const [modifyPositionUuid, setModifyPositionUuid] = useState(
    queryParams.get("positionUuid") ?? null,
  );
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
  const [doNotDisplayDeleteModalAgain, setDoNotDisplayDeleteModalAgain] =
    useState(false);
  const {
    uuid: userUuid,
    preferences: { showDeleteTaskConfirmationPrompt },
  } = useSelector((state: State) => state.user);

  useEffect(() => {
    const newQueryParams: {
      modifyPosition?: string;
      positionUuid?: string;
    } = {};

    if (modifyPositionUuid) {
      newQueryParams.modifyPosition = "true";
      newQueryParams.positionUuid = modifyPositionUuid;
    }

    setQueryParams(newQueryParams);
  }, [modifyPositionModalIsOpen, modifyPositionUuid]);

  const updateConfirmPromptPreference = async (
    updatedValue: boolean,
  ): Promise<void> => {
    try {
      const userPreferenceResponse = (await request({
        url: `/users/${userUuid}/preferences`,
        method: "PATCH",
        body: {
          showDeleteTaskConfirmationPrompt: updatedValue,
        },
      })) as IUserPreferencesResponse;

      if (userPreferenceResponse.status >= 400)
        throw Error("Error updating user preferences");

      dispatch(
        userSlice.actions.setPreferences(userPreferenceResponse.data.data),
      );
    } catch (error) {
      console.error(error);
    }
  };

  const closeTask = async (): Promise<void> => {
    const taskCompletionResponse = await request({
      method: "POST",
      url: `/organizations/${organizationUuid}/tasks/${task.uuid}/complete`,
    });

    if (taskCompletionResponse.status >= 400)
      throw Error("Error completing task");

    toast.success("Backfill Task Completed Successfully");
    dispatch(tasksSlice.actions.update({ total: total - 1 }));

    // Wait for transition animation to run before setting it to completed
    setTimeout(() => {
      setIsCompleted(true);
    }, 300);
  };

  return (
    <Transition
      show={!isCompleted}
      as={React.Fragment}
      enter="transition ease-in duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-in duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        id={`backfill-position-task-${task.uuid}`}
        className={`flex flex-row justify-between py-4 px-6 border border-neutral-50 rounded-lg bg-white w-full ${
          task.completedBy ? "opacity-50" : ""
        }`}
      >
        <ModifyPositionContainer
          isOpen={modifyPositionModalIsOpen}
          setModal={setModifyPositionModalIsOpen}
          positionUuid={modifyPositionUuid}
          setPositionUuid={setModifyPositionUuid}
          successCallback={closeTask}
          modalSource="backfill-task"
        />
        <div key={task.uuid} className="col-span-7 flex flex-col items-start">
          <Typography
            id="backfill-task-title"
            tag="h3"
            size="md"
            weight="semibold"
            className="mb-1"
          >
            {`Open Position: ${
              task.metadata.backfillPosition.backfillOrigin === "termination"
                ? "Employee Term"
                : "Employee Transfer"
            }`}
          </Typography>
          <div
            data-testid="task-details-metadata"
            className="flex flex-row justify-between gap-6"
          >
            {task.metadata.backfillPosition.title && (
              <div className="flex flex-col">
                <Typography size="xs" color="secondary">
                  Title
                </Typography>
                <Typography size="xs" className="max-w-48 truncate">
                  {task.metadata.backfillPosition.title}
                </Typography>
              </div>
            )}
            {task.metadata.backfillPosition.lastFilled && (
              <div className="flex flex-col">
                <Typography size="xs" color="secondary">
                  Last Filled
                </Typography>
                <Typography size="xs" className="max-w-32 truncate">
                  {task.metadata.backfillPosition.lastFilled}
                </Typography>
              </div>
            )}
            {task.metadata.backfillPosition.department && (
              <div className="flex flex-col">
                <Typography size="xs" color="secondary">
                  Department
                </Typography>
                <Typography size="xs" className="max-w-32 truncate">
                  {task.metadata.backfillPosition.department}
                </Typography>
              </div>
            )}
            {task.metadata.backfillPosition.paymentUnit && (
              <div className="flex flex-col max-2xl:hidden">
                <Typography size="xs" color="secondary">
                  Payment Type
                </Typography>
                <Typography size="xs" className="max-w-32 truncate">
                  {task.metadata.backfillPosition.paymentUnit === "HOURLY"
                    ? "Hourly"
                    : "Salary"}
                </Typography>
              </div>
            )}
            {task.metadata.backfillPosition.compensationRate && (
              <div className="flex flex-col max-xl:hidden">
                <Typography size="xs" color="secondary">
                  Compensation
                </Typography>
                <Typography size="xs" className="max-w-32 truncate">
                  {formatCurrency(
                    task.metadata.backfillPosition.compensationRate.toString(),
                    task.metadata.backfillPosition.paymentUnit === "HOURLY"
                      ? true
                      : false,
                    task.metadata.backfillPosition.currency || undefined,
                  )}
                </Typography>
              </div>
            )}
          </div>
        </div>
        {task.completedAt === null && (
          <div className="col-span-2 flex flex-row gap-4 items-center max-xl:flex-col">
            <Button
              fill="destructiveOutline"
              className="!w-auto max-xl:!w-full"
              id={`deactivate-backfill-task-${task.uuid}`}
              onClick={() => {
                if (showDeleteTaskConfirmationPrompt) {
                  setDisplayDeleteModal(true);
                } else {
                  closeTask();
                }
              }}
            >
              Deactivate Position
            </Button>
            <Button
              className="!w-auto max-xl:!w-full"
              id="backfill-task-button-confirm"
              onClick={() => {
                setModifyPositionModalIsOpen(true);
                setModifyPositionUuid(
                  task.metadata.backfillPosition.positionUuid || "",
                );
              }}
            >
              Backfill
            </Button>
          </div>
        )}
        <ConfirmPrompt
          isOpen={displayDeleteModal}
          onClose={() => setDisplayDeleteModal(false)}
          title="Deactivate Position"
          message="This position will be deleted and any impact on the budget will be removed. Are you sure you want to do this?"
          confirmButtonText="Confirm, Deactivate"
          onConfirm={() => {
            closeTask();
            setDisplayDeleteModal(false);
            if (doNotDisplayDeleteModalAgain) {
              updateConfirmPromptPreference(false);
            }
          }}
          isChecked={doNotDisplayDeleteModalAgain}
          setIsChecked={() => setDoNotDisplayDeleteModalAgain((prev) => !prev)}
          modalSize="lg"
        />
      </div>
    </Transition>
  );
};

export default BackfillPositionTask;
