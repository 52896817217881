import { isSameDay } from "date-fns";

const positionVersionsHaveChanged = (
  a: Record<string, unknown>,
  b: Record<string, unknown>,
): boolean => {
  const keysToIgnore = ["changeDescription"]; // Add keys to ignore here

  return Object.keys(a).some((key) => {
    if (keysToIgnore.includes(key)) {
      return false; // Skip the comparison for keys in keysToIgnore
    }

    if (
      (a[key] === null || a[key] === undefined) &&
      (b[key] === null || b[key] === undefined)
    ) {
      return false;
    }

    if (typeof a[key] === "object" && a[key] !== null) {
      return positionVersionsHaveChanged(
        a[key] as Record<string, unknown>,
        b[key] as Record<string, unknown>,
      );
    }

    if (key === "compensationRate") {
      return Number(a[key]) !== Number(b[key]);
    }
    if (
      key === "effectiveAt" &&
      typeof a.effectiveAt === "string" &&
      typeof b.effectiveAt === "string"
    ) {
      return !isSameDay(new Date(a.effectiveAt), new Date(b.effectiveAt));
    }
    return a[key] !== b[key];
  });
};

export default positionVersionsHaveChanged;
