import Button from "~/components/Button";
import { Link } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import request from "~/utils/request";
import { useSelector } from "react-redux";
import { State } from "~/store";
import Typography from "~/components/Typography";
import { format } from "date-fns-tz";
import ManualUploadModal from "./ManualUpload/ModalContainer";

const ConnectHris = (): React.ReactElement => {
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const [hrisIntegration, setHrisIntegration] = useState<Types.Integration>();
  const [manualHrisUploadInProgress, setManualHrisUploadInProgress] =
    useState(false);

  const getIntegrations = useCallback(async () => {
    const integrationsResponse = await request({
      url: `/organizations/${organizationUuid}/integrations`,
      method: "GET",
    });
    if (integrationsResponse.status >= 400) return;
    const integrations = integrationsResponse.data.data as Types.Integration[];
    setHrisIntegration(
      integrations.find((integration) => integration.type === "hris"),
    );
  }, []);

  useEffect(() => {
    getIntegrations();
  }, [getIntegrations, manualHrisUploadInProgress]);

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col gap-2">
        {hrisIntegration && (
          <>
            {hrisIntegration.isManual ? (
              <Button disabled>Integrate an HRIS</Button>
            ) : (
              <Button disabled>HRIS Linked</Button>
            )}
            {hrisIntegration.isManual ? (
              <Button
                fill="outline"
                onClick={() => setManualHrisUploadInProgress(true)}
              >
                Manual File Upload
              </Button>
            ) : (
              <Button fill="outline" disabled>
                Manual File Upload
              </Button>
            )}
          </>
        )}
        {!hrisIntegration && (
          <>
            <Link
              to="/onboarding"
              className="w-full"
              data-testid="connect-an-hris-button"
            >
              <Button className="my-2" fill="outline">
                Integrate an HRIS
              </Button>
            </Link>
            <Button
              className="my-2"
              fill="outline"
              onClick={() => setManualHrisUploadInProgress(true)}
            >
              Manual File Upload
            </Button>
          </>
        )}
      </div>
      {hrisIntegration && (
        <Typography size="xs" className="text-center text-neutral-200 mt-4">
          {`Employee Data Last Updated: `}
          {format(
            new Date(hrisIntegration.lastSyncSuccess),
            "MM/dd/yy hh:mm a zzz",
          )}
        </Typography>
      )}

      <ManualUploadModal
        isOpen={manualHrisUploadInProgress}
        integrationUuid={hrisIntegration?.uuid}
        onClose={() => setManualHrisUploadInProgress(false)}
      />
    </div>
  );
};

export default ConnectHris;
