import {
  ArrowDownOnSquareIcon as ArrowDownOnSquareIconOutline,
  DocumentCheckIcon,
} from "@heroicons/react/24/outline";
import Button from "~/components/Button";
import { ArrowDownOnSquareIcon as ArrowDownOnSquareIconSolid } from "@heroicons/react/24/solid";
import React, { useState } from "react";

export interface FileState {
  selectedFile: File | null;
  onFileDrag: boolean;
  fileError: string | null;
  pristine?: boolean;
  touched?: boolean;
  valid?: boolean;
}
export const useFile = (): [
  FileState,
  React.Dispatch<React.SetStateAction<FileState>>,
  () => void,
] => {
  const initialState = {
    selectedFile: null,
    onFileDrag: false,
    fileError: "A file must be selected",
    pristine: true,
    touched: false,
    valid: false,
  };
  const [state, setState] = useState<FileState>(initialState);
  const resetState = () => {
    setState(initialState);
  };
  return [state, setState, resetState];
};
interface Props {
  id: string;
  state: FileState;
  setState: React.Dispatch<React.SetStateAction<FileState>>;
}
const FileUpload = ({ id, state, setState }: Props) => {
  const clearSelectedFile = () => {
    setState((prevState) => ({
      ...prevState,
      selectedFile: null,
      fileError: "A file must be selected",
      pristine: false,
      touched: true,
      valid: false,
    }));
  };

  const onFileDragOverInput = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      onFileDrag: true,
    }));
  };

  const onFileDragLeaveInput = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setState((prevState) => ({
      ...prevState,
      onFileDrag: false,
    }));
  };

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.name.endsWith(".csv")) {
        setState((prevState) => ({
          ...prevState,
          selectedFile: file,
          pristine: false,
          touched: true,
          valid: true,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          pristine: false,
          touched: true,
          valid: false,
        }));
      }
    }
  };

  const onFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      onFileDrag: false,
    }));
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      if (file.name.endsWith(".csv")) {
        setState((prevState) => ({
          ...prevState,
          selectedFile: file,
          pristine: false,
          touched: true,
          valid: true,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          pristine: false,
          touched: true,
          valid: false,
        }));
      }
    }
  };

  const fileDropColor = () => {
    if (!state.selectedFile) {
      return state.onFileDrag
        ? "bg-green-25 border-green-200"
        : "bg-green-15 border-green-100";
    }
    return "bg-green-50 border-green-50";
  };

  return (
    <>
      <div
        className={`group flex w-full h-56 ${fileDropColor()} justify-center items-center border-2 ${
          state.selectedFile ? "border-solid" : "border-dashed"
        } rounded-lg ${
          !state.selectedFile ? "cursor-pointer" : null
        } shadow-sm ${!state.selectedFile ? "hover:border-green-100" : null}`}
        onClick={() => {
          const fileInput = document.getElementById("hris-import-file-input");
          if (fileInput) {
            fileInput.click();
          }
        }}
        onDragOver={onFileDragOverInput}
        onDrop={onFileDrop}
        onDragLeave={onFileDragLeaveInput}
        data-testid="hris-import-file-dropzone"
      >
        {state.selectedFile ? (
          <div className="flex flex-col group-hover justify-center items-center">
            <DocumentCheckIcon className="my-2 h-8 w-8 stroke-green-500" />
            <p>File Ready: {state.selectedFile.name}</p>
            <Button
              onClick={(e) => {
                if (!e) {
                  return;
                }
                e.stopPropagation();
                clearSelectedFile();
              }}
              fill="clear"
              className="text-red-500 mt-2"
            >
              Remove
            </Button>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center">
            {state.onFileDrag ? (
              <>
                <ArrowDownOnSquareIconSolid className="my-2 h-8 w-8 fill-green-500" />
                <p className="text-green-500">Upload File</p>
              </>
            ) : (
              <>
                <ArrowDownOnSquareIconOutline className="my-2 h-8 w-8 stroke-green-500" />
                <p>
                  {`Drag and drop or `}
                  <span className="group-hover:underline text-green-400">
                    choose a file
                  </span>
                  {` to update your headcount.`}
                </p>
                <p>.csv file compatible</p>
              </>
            )}
            {!state.pristine && state.touched && !state.valid && (
              <p className="text-red-500 mt-2" data-testid={`${id}-file-error`}>
                {state.fileError}
              </p>
            )}
          </div>
        )}
      </div>
      <input
        type="file"
        data-testid="hris-import-file-input"
        id="hris-import-file-input"
        className="hidden"
        onChange={onFileChange}
        accept=".csv"
      />
    </>
  );
};

export default FileUpload;
