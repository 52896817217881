import React, { useEffect } from "react";

interface IFieldMappingParams {
  parsedFileData: {
    headers: string[];
    data: Record<string, unknown>[];
  };
  requiredFields: string[];
  optionalFields: string[];
}

export interface IFieldMappingState {
  valid: boolean;
  pristine: boolean;
  touched: boolean;
  rawFileData: {
    headers: string[];
    data: Record<string, unknown>[];
  };
  requiredFields: string[];
  missingFields: string[];
  fieldMappings: Record<string, string | null>;
}
const useFieldMapping = ({
  parsedFileData,
  requiredFields,
  optionalFields,
}: IFieldMappingParams): [
  IFieldMappingState,
  React.Dispatch<React.SetStateAction<IFieldMappingState>>,
  () => void,
] => {
  const requiredFieldMappings: Record<string, null> = requiredFields.reduce(
    (obj: Record<string, null>, key: string) => {
      const newObj = { ...obj };
      newObj[key] = null;
      return newObj;
    },
    {},
  );

  const optionalFieldMappings: Record<string, null> = optionalFields.reduce(
    (obj: Record<string, null>, key: string) => {
      const newObj = { ...obj };
      newObj[key] = null;
      return newObj;
    },
    {},
  );

  const initialState: IFieldMappingState = {
    valid: false,
    pristine: true,
    touched: false,
    rawFileData: parsedFileData,
    requiredFields,
    missingFields: requiredFields,
    fieldMappings: { ...requiredFieldMappings, ...optionalFieldMappings },
  };
  const [state, setState] = React.useState<IFieldMappingState>(initialState);

  const resetState = () => {
    setState(initialState);
  };

  useEffect(() => {
    const hasAllRequiredFields = requiredFields.reduce(
      (acc, key) =>
        acc &&
        Object.prototype.hasOwnProperty.call(state.fieldMappings, key) &&
        state.fieldMappings[key] !== null,
      true,
    );

    const missingFields = requiredFields.filter(
      (key) => state.fieldMappings[key] === null,
    );

    setState((prevState) => ({
      ...prevState,
      valid: hasAllRequiredFields,
      missingFields,
    }));
  }, [state.fieldMappings]);

  return [state, setState, resetState];
};

export default useFieldMapping;
