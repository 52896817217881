import React from "react";
import Input from "~/components/Input";
import { SelectState } from "~/components/Select/Select.types";
import CurrencyInput from "../../../pages/Headcount/PositionForm/CurrencyInput";
import InputState = Types.InputState;

interface Props {
  id?: string;
  showExpectedHours: boolean;
  currencyTypeState: SelectState;
  setCurrencyTypeState: React.Dispatch<React.SetStateAction<SelectState>>;
  expectedHoursState: InputState;
  setExpectedHoursState: React.Dispatch<React.SetStateAction<InputState>>;
  bonusAmountState: InputState;
  setBonusAmountState: React.Dispatch<React.SetStateAction<InputState>>;
  commissionAmountState: InputState;
  setCommissionAmountState: React.Dispatch<React.SetStateAction<InputState>>;
  attainmentState: InputState;
  setAttainmentState: React.Dispatch<React.SetStateAction<InputState>>;
}

const NonHrisValidation = ({
  id,
  showExpectedHours,
  currencyTypeState,
  setCurrencyTypeState,
  expectedHoursState,
  setExpectedHoursState,
  bonusAmountState,
  setBonusAmountState,
  commissionAmountState,
  setCommissionAmountState,
  attainmentState,
  setAttainmentState,
}: Props): React.ReactNode => (
  <div data-testid={id} className="flex flex-col w-full items-start gap-4">
    {showExpectedHours && (
      <div className="flex-row w-full">
        <Input
          id="expectedHours"
          type="text"
          label="Expected Hours Per Week"
          state={expectedHoursState}
          setState={setExpectedHoursState}
          placeholder="Expected hours"
        />
      </div>
    )}
    <div className="flex flex-row w-full items-start gap-4">
      <div className="w-5/12">
        <CurrencyInput
          paymentAmountState={bonusAmountState}
          currencyTypeState={currencyTypeState}
          setPaymentAmountState={setBonusAmountState}
          setCurrencyTypeState={setCurrencyTypeState}
          label="Bonus"
          id="bonus"
          hideCurrencyType
        />
      </div>
      <div className="border br-0 border-neutral-50 h-[72px]" />
      <div className="w-5/12">
        <CurrencyInput
          paymentAmountState={commissionAmountState}
          currencyTypeState={currencyTypeState}
          setPaymentAmountState={setCommissionAmountState}
          setCurrencyTypeState={setCurrencyTypeState}
          label="Commission"
          id="commission"
          hideCurrencyType
        />
      </div>
      <div className="w-2/12">
        <Input
          label="Attainment"
          id="attainment-percentage"
          state={attainmentState}
          setState={setAttainmentState}
          type="percentage"
        />
      </div>
    </div>
  </div>
);

export default NonHrisValidation;
