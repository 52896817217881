import { LoaderFunctionArgs } from "react-router-dom";
import { IFetchExpenseModelsResponse } from "~/pages/AllExpenseModels/ManageExpenseModel";
import { store } from "~/store";
import { IExpense } from "~/types/expenseModel/types";
import request from "~/utils/request";
import waitForStoreRehydration from "~/utils/waitForStoreRehydration";

export default async ({ params }: LoaderFunctionArgs): Promise<IExpense> => {
  await waitForStoreRehydration(store);

  const {
    organization: { uuid: organizationUuid },
  } = store.getState();

  const {
    data: { data: expenseModel },
  } = (await request({
    url: `/organizations/${organizationUuid}/expense-models/${params.expenseUuid}`,
    method: "GET",
  })) as IFetchExpenseModelsResponse;

  return expenseModel;
};
