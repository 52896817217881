import React from "react";
import { SortType } from "~/components/Table/useSort";
import Button from "~/components/Button";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/outline";

interface Props {
  id: string;
  sort: SortType;
  setSort: React.Dispatch<React.SetStateAction<SortType>>;
  children: React.ReactNode;
  className?: string;
}

const SortableHeader = ({ id, sort, setSort, children, className }: Props) => {
  const onClick = () => {
    setSort((prevState: SortType) => {
      if (prevState?.key !== id) {
        return {
          key: id,
          direction: "asc",
        };
      }

      if (prevState.direction === "desc") return null;

      return {
        key: id,
        direction: prevState.direction === "asc" ? "desc" : "asc",
      };
    });
  };

  return (
    <Button
      fill="clear"
      onClick={onClick}
      className={`!w-auto text-left text-xs font-medium uppercase text-neutral-900 flex items-center ${className}`}
    >
      {children}
      {sort && sort.key === id && sort.direction && (
        <span className="ml-2">
          {sort.direction === "asc" && <ArrowDownIcon className="w-4 h-4" />}
          {sort.direction === "desc" && <ArrowUpIcon className="w-4 h-4" />}
        </span>
      )}
    </Button>
  );
};

export default SortableHeader;
