import React from "react";
import formatCurrency from "~/utils/formatCurrency";
import currencySymbols from "~/utils/currencySymbols";
import Typography from "~/components/Typography";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import formatPercent from "~/utils/formatPercent";
import { ILocation } from "~/pages/Headcount/headcount.types";

const LABELS_FOR_CURRENCY = ["Compensation", "Bonus", "Commission"];

interface Props {
  label: string;
  changedFrom?: string | number | ILocation | boolean | null;
  changedTo?: string | number | ILocation | boolean | null;
  versionUuid: string;
  currentCurrency?: string;
}

const ChangeListItem = ({
  label,
  changedFrom,
  changedTo,
  versionUuid,
  currentCurrency,
}: Props): React.ReactNode => {
  const formatValue = (
    value: string | number | ILocation | boolean | null | undefined,
    changeLabel: string,
  ): string => {
    if (value === null || value === undefined) {
      return "-";
    }

    if (changeLabel === "Currency" && typeof value === "string") {
      const getCurrencySymbol = (currencyType: string): string =>
        currencySymbols[currencyType] || "$";
      return `${getCurrencySymbol(value)} ${value}`;
    }
    if (
      LABELS_FOR_CURRENCY.includes(changeLabel) &&
      (typeof value === "number" || typeof value === "string")
    ) {
      /**
       * Assuming you have access to the current currency in context or props
       * No great way to see if the value is for an hourly employee or salary
       * so made the assumption that it is very unlikely for an hourly employee
       * to get paid more than 200 per hour and still have a cent value in their
       * rate and no one on salary is making less than 200 per year
       */
      const stringedValue = value.toString();
      return formatCurrency(
        value,
        parseInt(stringedValue) <= 20000,
        currentCurrency,
      );
    }
    if (changeLabel === "Attainment" && typeof value === "number") {
      return formatPercent(value);
    }

    return value.toString();
  };

  const formattedChangedFrom = formatValue(changedFrom, label);
  const formattedChangedTo = formatValue(changedTo, label);

  return (
    <div
      className="flex max-xl:flex-col gap-2 max-xl:gap-1 justify-start bg-neutral-15 items-center border border-neutral-50 py-3 px-6 rounded-xl"
      data-testid={`expanded-content-${versionUuid}-${label}`}
    >
      <div className="w-1/3 max-xl:w-full capitalize pr-5">
        <Typography id={`change-label-${label}-${versionUuid}`}>
          {label}
        </Typography>
      </div>
      <div className="flex flex-row w-2/3 max-xl:w-full justify-between">
        <div className="capitalize w-[45%] truncate !max-xl:font-semibold">
          <Typography
            id={`changed-from-${formattedChangedFrom}-${versionUuid}`}
          >
            {formattedChangedFrom}
          </Typography>
        </div>
        <div className="text-neutral-200 w-[10%] mx-2 my-auto p-auto">
          <ChevronRightIcon className="w-4 h-4" />
        </div>
        <div className="capitalize w-[45%] truncate">
          <Typography id={`changed-to-${formattedChangedTo}-${versionUuid}`}>
            {formattedChangedTo}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default ChangeListItem;
