import {
  SelectMultipleState,
  SelectMultipleType,
} from "~/components/SelectMultiple/SelectMultiple.types";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import Typography from "~/components/Typography";
import React, { Fragment } from "react";
import useSelectMultiple from "./useSelectMultiple";

interface Props {
  id: string;
  state: SelectMultipleState;
  setState: React.Dispatch<React.SetStateAction<SelectMultipleState>>;
  label?: string;
  className?: string;
  errorMessage?: string;
  placeholder?: string;
  disabled?: boolean;
  checkmark?: boolean;
  direction?: "below" | "above";
  required?: boolean;
  listBoxClassName?: string;
  disabledOptions?: string[];
}

const SelectMultiple = ({
  id,
  label,
  state,
  setState,
  className,
  errorMessage = "Please make a selection",
  placeholder = "None selected",
  disabled = false,
  checkmark = true,
  direction = "below",
  required = false,
  listBoxClassName = "",
  disabledOptions = [],
}: Props): React.ReactNode => {
  const showError = state.touched && !state.pristine && !state.valid;

  let spanClass = "truncate block truncate text-base";
  if (state.disabled) {
    spanClass += " text-neutral-75";
  } else if (state.selected && state.selected.length > 0) {
    spanClass += " text-neutral-900";
  } else {
    spanClass += " text-neutral-200";
  }

  return (
    <div className={`w-full ${className ?? ""}`} data-testid={id}>
      {label && (
        <div className="flex flex-row mb-1">
          <Typography
            tag="span"
            size="xs"
            className={`${state.disabled ? " !text-neutral-75" : ""}`}
          >
            {label}
          </Typography>
          {required && (
            <Typography
              tag="span"
              size="2xs"
              className={`${state.disabled ? " !text-neutral-75" : ""}`}
            >
              *
            </Typography>
          )}
        </div>
      )}
      <Listbox
        disabled={state.disabled ?? disabled}
        value={state.selected}
        onChange={(selections: SelectMultipleType[]) => {
          const selected = state.options.filter((option) =>
            selections.some(({ value }) => value === option.value),
          );

          setState((prevState) => ({
            ...prevState,
            valid: true,
            pristine: false,
            touched: true,
            selected,
          }));
        }}
        multiple
      >
        <div className="relative">
          <Listbox.Button
            data-testid={`${id}-button`}
            className={`relative w-full max-w-full truncate cursor-default border border-solid ${
              showError ? "border-red-300" : "border-gray-300"
            }
            ${!state.selected?.length ? "!text-neutral-75" : ""}
            rounded shadow-sm bg-white disabled:bg-neutral-25 disabled:text-neutral-75 py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-green-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-green-300 sm:text-sm`}
          >
            <span data-testid={`${id}-selection`} className={spanClass}>
              {!state.selected?.length
                ? placeholder
                : state.selected.map((item) => item.label).join(", ")}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className={`${
                direction === "below" ? "mt-1" : "mt-[-286px]"
              } absolute max-h-60 z-20 rounded w-full overflow-auto bg-white text-base shadow-sm ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ${listBoxClassName}`}
            >
              {state.options.map((option) => {
                const isSelected =
                  state.selected?.some(
                    (selected) => selected.value === option.value,
                  ) ?? false;

                if (option.value === "divider") {
                  return (
                    <Listbox.Option
                      key={option.value}
                      disabled
                      className="relative cursor-default select-none py-2 pl-3 pr-4 text-xs"
                      value={option}
                    >
                      <hr className="border-gray-300" />
                    </Listbox.Option>
                  );
                }

                let isDisabled = false;
                if (option.value) {
                  isDisabled = disabledOptions.includes(option.value);
                }

                if (isDisabled) {
                  if (isSelected) {
                    setState((prevState) => ({
                      ...prevState,
                      selected: prevState.selected?.filter(
                        (selected) => selected.value !== option.value,
                      ),
                    }));
                  }
                }

                return (
                  <Listbox.Option
                    data-testid={`${id}-option-${option.value}`}
                    key={option.value}
                    disabled={option.disabled ?? isDisabled}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2${
                        checkmark ? " pl-10" : " pl-3"
                      } pr-4${active ? " bg-green-100 text-green-900" : ""}${
                        isSelected ? " text-green-300" : " text-gray-900"
                      }${isDisabled ? " bg-neutral-25" : ""}`
                    }
                    value={option}
                  >
                    <span
                      className={`block truncate ${
                        option.value ? "font-medium" : "font-normal"
                      }`}
                    >
                      <Typography
                        tag="span"
                        size="xs"
                        className={`block truncate${
                          option.value ? " font-medium" : " font-normal"
                        }${isDisabled ? " opacity-50" : ""}`}
                        color={isSelected ? "green" : "primary"}
                      >
                        {option.node ?? option.label}
                      </Typography>
                    </span>
                    {(isSelected || isDisabled) && checkmark && (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-green-600">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </Listbox.Option>
                );
              })}
            </Listbox.Options>
          </Transition>
          {showError && (
            <p
              className="text-red-500 text-xs italic p-1"
              data-testid={`${id}-select-error`}
            >
              {errorMessage}
            </p>
          )}
        </div>
      </Listbox>
    </div>
  );
};

export { useSelectMultiple };
export default SelectMultiple;
