import { reviver } from "mathjs";
import formatCurrency from "~/utils/formatCurrency";
import { EquationElement } from "./SalaryCalculation";

interface MathJsHtml {
  name: string;
  className: string;
  value: string;
  index?: number;
}

interface ExpressionDetails {
  calculationValues: any;
  expressionTree: any;
  total: number;
}

const formatValues = (data: any, currency?: string): EquationElement => {
  const newData = { ...data };

  for (const key in newData) {
    if (Object.prototype.hasOwnProperty.call(newData, key)) {
      if (
        key.toLowerCase().includes("salary") ||
        key.toLowerCase().includes("hourlyrate") ||
        key.toLowerCase().includes("bonus") ||
        key.toLowerCase().includes("commission") ||
        key.toLowerCase().includes("expense")
      ) {
        newData[key] = formatCurrency(
          newData[key],
          true,
          key.toLowerCase().includes("expense") ? "USD" : currency,
        );
      } else if (!isNaN(newData[key])) {
        const roundedNumber = Math.round(newData[key] * 10000) / 10000;
        newData[key] = roundedNumber.toString();
      } else {
        newData[key] = newData[key].toString();
      }
    }
  }

  return newData;
};

export default (
  expressionDetails: ExpressionDetails,
  extraInserts: MathJsHtml[],
  currency?: string,
): MathJsHtml[] => {
  const stringedTree = JSON.stringify(expressionDetails.expressionTree);
  const organizedTree = JSON.parse(stringedTree, reviver);
  const htmlTree = organizedTree.toHTML();
  const formattedValues = formatValues(
    expressionDetails.calculationValues,
    currency,
  );

  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlTree, "text/html");
  const spans = doc.querySelectorAll("span");

  const result: MathJsHtml[] = [];

  spans.forEach((span) => {
    const name = span.innerHTML.trim();
    const classNameArray = span.className
      .trim()
      .split(" ")
      .filter((cls) => cls !== "");
    let className = classNameArray.join(" ");
    let value = name;
    for (const key in formattedValues) {
      if (key === name && key in formattedValues) {
        value = formattedValues[key as keyof typeof formattedValues].toString();
      }
    }
    if (value === "(" || value === ")") className = "math-operator";
    result.push({ name, className, value });
  });

  for (const extraInsert of extraInserts) {
    if (extraInsert.index !== undefined) {
      result.splice(extraInsert.index, 0, extraInsert);
    }
  }

  result.push({ name: "=", className: "math-operator", value: "=" });
  result.push({
    name: "Total",
    className: "",
    value: expressionDetails.total.toString(),
  });

  return result;
};
