import React, { useEffect } from "react";

const useHover = () => {
  const [hovering, setHovering] = React.useState(false);
  const ref = React.useRef(null);

  useEffect(() => {
    const node = ref.current;

    const handleMouseEnter = () => {
      setHovering(true);
    };

    const handleMouseLeave = () => {
      setHovering(false);
    };

    node.addEventListener("mouseenter", handleMouseEnter);
    node.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      node.removeEventListener("mouseenter", handleMouseEnter);
      node.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  return [ref, hovering, setHovering];
};

export default useHover;
