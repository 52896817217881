import React from "react";
import { SelectType } from "~/components/Select/Select.types";
import { IDepartment, IDepartmentHierarchy } from "~/types/department/types";
import arrowTurnRight from "~/assets/arrowTurnRight.svg";
import Typography from "~/components/Typography";

/**
 * Undefined is the value for the first 2 because no parent means no padding necessary, if you have 1 level of parenting
 * the 24px of padding that we need to add is taken up by the arrow right icon
 */
const labelPaddingArray = [
  undefined,
  undefined,
  "pl-[24px]",
  "pl-[48px]",
  "pl-[72px]",
  "pl-[96px]",
  "pl-[120px]",
  "pl-[144px]",
  "pl-[168px]",
];

const buildHierarchy = ({
  departments,
  parentId = null,
}: {
  departments: IDepartment[];
  parentId?: string | null;
}): IDepartmentHierarchy[] => {
  return departments
    .filter((department) => department.parentUuid === parentId)
    .map((department) => ({
      ...department,
      childDepartments: buildHierarchy({
        departments: departments,
        parentId: department.uuid,
      }),
    }));
};

const flattenHierarchyToOptions = ({
  departments,
  depth = 0,
}: {
  departments: IDepartmentHierarchy[];
  depth?: number;
}): SelectType[] => {
  let options: SelectType[] = [];
  departments.forEach((department) => {
    // Adjust label padding based on depth
    const label =
      depth === 0 ? (
        department.name
      ) : (
        <div className={`flex items-center gap-2 ${labelPaddingArray[depth]}`}>
          <img src={arrowTurnRight} alt="" className="w-4 h-auto" />
          <Typography size="xs">{department.name}</Typography>
        </div>
      );

    options.push({
      label: typeof label === "string" ? label : department.name, // For compatibility with non-react Select components
      value: department.uuid,
      node: label,
    });

    if (department.childDepartments.length > 0) {
      options = options.concat(
        flattenHierarchyToOptions({
          departments: department.childDepartments,
          depth: depth + 1,
        }),
      );
    }
  });
  return options;
};

// Main utility function to generate department options
export default (departments: IDepartment[]): SelectType[] => {
  const hierarchicalDepartments = buildHierarchy({ departments });
  return flattenHierarchyToOptions({ departments: hierarchicalDepartments });
};
