import React, { useRef, useEffect } from "react";

interface Props {
  checked: boolean;
  isIndeterminate?: boolean;
  toggleValue: () => void;
  className?: string;
  id?: string;
}

const Checkbox = ({
  checked,
  isIndeterminate,
  toggleValue,
  className,
  id,
}: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = !!isIndeterminate;
    }
  }, [isIndeterminate]);
  return (
    <input
      data-testid={id}
      checked={checked}
      onChange={toggleValue}
      className={`border border-green-400 rounded 
      hover:bg-neutral-15 hover:border-green-500 
      indeterminate:bg-green-400 indeterminate:hover:bg-green-500 indeterminate:focus:bg-green-500
      checked:bg-green-400 checked:hover:bg-green-500 checked:focus:bg-green-500 
      focus:ring-green-500 w-4 h-4 ${className ?? ""}`}
      type="checkbox"
      ref={inputRef}
    />
  );
};

export default Checkbox;
