import React from "react";
import Button from "~/components/Button";
import date from "~/utils/dates/date";
import EmployeeSearch, { useEmployeeSearch } from "~/components/EmployeeSearch";
import Typography from "~/components/Typography";
import request from "~/utils/request";
import { useSelector } from "react-redux";
import { State } from "~/store";
import toast from "react-hot-toast";
import permissionsCheck from "~/utils/permissionsCheck";
import { Position } from "../headcount.types";
import "./positionDetails.css";

const EmployeeDetails = ({
  positionData,
  organizationUuid,
  positionUuid,
  fetchPositionData,
}: {
  positionData: Position;
  organizationUuid: string;
  positionUuid?: string;
  fetchPositionData: () => Promise<void>;
}): React.ReactNode => {
  const {
    permissions: { role, departmentAccessList },
  } = useSelector((state: State) => state.user);
  const [employeeSearchState, setEmployeeSearchState] = useEmployeeSearch({
    organizationUuid,
  });
  const isAdmin = permissionsCheck(role, departmentAccessList);

  const attemptAssignRole = async (): Promise<void> => {
    if (employeeSearchState.valid && employeeSearchState.selected?.value) {
      const assignRoleResponse = await request({
        url: `/organizations/${organizationUuid}/positions/${positionUuid}/assignEmployee`,
        method: "POST",
        body: {
          employeeUuid: employeeSearchState.selected.value,
        },
      });
      if (assignRoleResponse.status === 201) {
        fetchPositionData();
      } else {
        toast.error("Unable to assign employee", {
          style: {
            maxWidth: "500px",
          },
        });
        throw Error("Unable to assign employee");
      }
    } else {
      setEmployeeSearchState({
        ...employeeSearchState,
        touched: true,
        pristine: false,
      });
    }
  };

  let hireDate = date(positionData.current.effectiveAt).format("MM/DD/YYYY");
  /**
   * Override the hireDate if the position is a backfill
   */
  if (
    !positionData.currentEmployee &&
    positionData.hasFutureChanges &&
    positionData.history.length > 1
  ) {
    const currentIndex = positionData.history.findIndex(
      (history) => history.uuid === positionData.current.uuid,
    );
    hireDate = date(positionData.history[currentIndex + 1].effectiveAt).format(
      "MM/DD/YYYY",
    );
  }

  return !positionData.currentEmployee ? (
    <>
      <Typography tag="h2" weight="bold" size="xl" id="employee-name">
        No Assigned Employee
      </Typography>
      <div className="flex flex-row w-full gap-6">
        <div className="flex flex-col">
          <Typography size="xs" color="empty">
            Start Date
          </Typography>
          <Typography>{hireDate}</Typography>
        </div>
        <div className="flex flex-col">
          <Typography size="xs" color="empty">
            State
          </Typography>
          <Typography>
            {positionData.current.isActive ? "Active" : "Inactive"}
          </Typography>
        </div>
      </div>
      {isAdmin && (
        <>
          <EmployeeSearch
            className="mt-5"
            state={employeeSearchState}
            setState={setEmployeeSearchState}
          />
          <div className="flex justify-center mt-5">
            <Button
              fill="outline"
              onClick={attemptAssignRole}
              id="assign-employee-button"
            >
              Assign Employee
            </Button>
          </div>
        </>
      )}
    </>
  ) : (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col">
        <Typography
          tag="h2"
          weight="bold"
          size="xl"
          id="employee-name"
        >{`${positionData.currentEmployee.firstName} ${positionData.currentEmployee.lastName}`}</Typography>
        <Typography size="xs" id="employee-email">
          {positionData.currentEmployee.workEmail}
        </Typography>
      </div>
      <div className="flex flex-row w-full gap-6">
        <div className="flex flex-col">
          <Typography size="xs" color="empty">
            Employee #
          </Typography>
          <Typography>{positionData.currentEmployee.employeeNumber}</Typography>
        </div>
        <div className="flex flex-col">
          <Typography size="xs" color="empty">
            Start Date
          </Typography>
          <Typography>
            {date(positionData.currentEmployee.startDate).format("MM/DD/YYYY")}
          </Typography>
        </div>
      </div>
      {positionData.currentEmployee.terminationDate && (
        <div className="flex flex-col">
          <Typography size="xs" color="empty">
            Term Date
          </Typography>
          <Typography color="warning">
            {date(positionData.currentEmployee.terminationDate).format(
              "MM/DD/YYYY",
            )}
          </Typography>
        </div>
      )}
    </div>
  );
};
export default EmployeeDetails;
