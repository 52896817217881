import Button from "~/components/Button";
import Divider from "~/components/Divider";
import Input, { useInput } from "~/components/Input";
import Modal from "~/components/Modal";
import Typography from "~/components/Typography";
import formatCurrency from "~/utils/formatCurrency";
import React from "react";
import request from "~/utils/request";
import toast from "react-hot-toast";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  adjustments: Record<
    string,
    { amount: number; departmentUuid: string; effectiveAt: Date }
  >;
  onSave: () => void;
  organizationUuid: string;
  expenseUuid: string;
}

const SaveAdjustments = ({
  isOpen,
  onClose,
  onSave,
  adjustments,
  organizationUuid,
  expenseUuid,
}: IProps): React.ReactNode => {
  const [adjustmentReason, setAdjustmentReason] = useInput({
    errorMessage: "Please provide a reason",
    validation: /.*/,
  });

  const resetAdjustmentReason = (): void => {
    setAdjustmentReason({
      ...adjustmentReason,
      value: "",
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
  };

  let negativeTotal = 0;
  let negativeCount = 0;
  let positiveTotal = 0;
  let positiveCount = 0;
  let total = 0;

  Object.entries(adjustments).forEach(([, { amount }]) => {
    if (amount < 0) {
      negativeTotal += amount;
      negativeCount += 1;
    } else if (amount > 0) {
      positiveTotal += amount;
      positiveCount += 1;
    }
    total += amount * -1;
  });

  const attemptApply = async (): Promise<void> => {
    if (adjustmentReason.value) {
      const createExpenseModelResponse = await request({
        url: `/organizations/${organizationUuid}/expense-models/${expenseUuid}/adjustments`,
        method: "POST",
        body: {
          comment: adjustmentReason.value,
          adjustments: Object.values(adjustments),
        },
      });
      if (createExpenseModelResponse.status === 201) {
        onSave();
        toast.success("Adjustments applied");
        resetAdjustmentReason();
      } else {
        toast.error("Unable to save adjustments");
      }
    } else {
      setAdjustmentReason({
        ...adjustmentReason,
        pristine: false,
        touched: true,
      });
    }
  };

  return (
    <Modal title="Adjustment Reason" isOpen={isOpen} size="xxs">
      <div className="flex flex-col gap-4" data-testid="save-adjustments-modal">
        <Typography color="secondary" size="xs">
          This will be used in the history log and to communicate variances to
          locked plans
        </Typography>
        <Input
          id="adjustmentReason"
          label="Reason"
          state={adjustmentReason}
          setState={setAdjustmentReason}
          required
        />
        <div
          className="flex flex-col gap-2 bg-green-15 py-4 px-6 rounded-lg"
          data-testid="cost-change-display"
        >
          <Typography size="sm" weight="semibold">
            Cost
          </Typography>
          <div
            className={`flex flex-row justify-between ${
              positiveCount > 0 ? "" : "hidden"
            }`}
          >
            <Typography size="sm">
              Increases{" "}
              <span className="text-neutral-200">
                {"("}
                {positiveCount}
                {")"}
              </span>
            </Typography>
            <Typography size="sm">
              {formatCurrency(positiveTotal, false)}
            </Typography>
          </div>
          <div
            className={`flex flex-row justify-between ${
              negativeCount > 0 ? "" : "hidden"
            }`}
          >
            <Typography size="sm">
              Decreases{" "}
              <span className="text-neutral-200">
                {"("}
                {negativeCount}
                {")"}
              </span>
            </Typography>
            <Typography size="sm">
              {formatCurrency(Math.abs(negativeTotal), false)}
            </Typography>
          </div>
          <Divider
            className={`${
              negativeCount > 0 && positiveCount > 0 ? "" : "hidden"
            }`}
          />
          <div
            className={`flex flex-row justify-between ${
              negativeCount > 0 && positiveCount > 0 ? "" : "hidden"
            }`}
          >
            <Typography size="sm">
              Total Impact{" "}
              <span className="text-neutral-200">
                {"("}
                {negativeCount + positiveCount}
                {")"}
              </span>
            </Typography>
            <Typography color={total < 0 ? "warning" : "green"} size="sm">
              {formatCurrency(total, false)}
            </Typography>
          </div>
        </div>
        <div className="w-full flex justify-between">
          <Button
            id="cancel-saving-adjustments-button"
            className="!w-auto !px-0"
            fill="clear"
            onClick={() => {
              onClose();
              resetAdjustmentReason();
            }}
          >
            Cancel
          </Button>
          <Button
            id="save-adjustment-changes-button"
            className="!w-auto"
            onClick={attemptApply}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SaveAdjustments;
