import React, { useEffect, useRef, useState } from "react";
import {
  endOfMonth,
  endOfQuarter,
  endOfYear,
  getYear,
  isEqual,
  startOfMonth,
  startOfQuarter,
  startOfYear,
} from "date-fns";
import Button from "~/components/Button";
import Typography from "~/components/Typography";
import { CalendarIcon } from "@heroicons/react/24/outline";
import { IPeriodPickerState } from "./usePeriodPicker";
import {
  getGridDisplayValues,
  getInputDisplayValue,
  getStartAndEndDatesFromSelection,
} from "./pickerModeHelpers";

const PeriodPicker = ({
  label,
  minYear = 2015,
  maxYear = 2030,
  state,
  setState,
  pickerAlignment = "left",
}: {
  label?: string;
  minYear?: number;
  maxYear?: number;
  state: IPeriodPickerState;
  setState: React.Dispatch<React.SetStateAction<IPeriodPickerState>>;
  pickerAlignment?: "left" | "right";
}) => {
  const pickerRef = useRef(null);
  const [showPicker, setShowPicker] = useState(false);
  const [pendingYearDate, setPendingYearDate] = useState<number>(
    state.startDate ? getYear(state.startDate) : getYear(new Date()),
  );
  const [displayState, setDisplayState] = useState<{
    gridDisplayValues: (string | number)[];
    gridDisplay: string;
  }>({
    gridDisplayValues: [],
    gridDisplay: "",
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };

    if (showPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPicker]);

  useEffect(() => {
    setDisplayState({
      gridDisplayValues: getGridDisplayValues(
        state.mode,
        pendingYearDate,
        minYear,
        maxYear,
      ),
      gridDisplay: getInputDisplayValue(state.mode, state.startDate),
    });
  }, [state.startDate, state.endDate, pendingYearDate]);

  useEffect(() => {
    let { startDate, endDate } = state;
    switch (state.mode) {
      case "month": {
        startDate = startOfMonth(startDate);
        endDate = endOfMonth(startDate);
        break;
      }
      case "quarter": {
        startDate = startOfQuarter(startDate);
        endDate = endOfQuarter(startDate);
        break;
      }
      case "year": {
        startDate = startOfYear(startDate);
        endDate = endOfYear(startDate);
        break;
      }
      default:
        throw Error("Invalid mode");
    }
    if (
      !isEqual(startDate, state.startDate) ||
      !isEqual(endDate, state.endDate)
    ) {
      setState({
        ...state,
        startDate,
        endDate,
      });
    }
  }, [state.mode]);

  const togglePicker = () => {
    setShowPicker(!showPicker);
    setPendingYearDate(getYear(state.startDate));
  };

  const handleGridSelection = (selection: string | number) => {
    setShowPicker(false);
    const { startDate, endDate } = getStartAndEndDatesFromSelection(
      state.mode,
      selection,
      pendingYearDate,
    );
    setState({
      ...state,
      startDate,
      endDate,
    });
  };

  const pickerPositionLocation =
    pickerAlignment === "left" ? "left-0" : "right-0";

  const getDisplayGrid = () => {
    const gridColumns = (() => {
      switch (state.mode) {
        case "quarter":
          return 1;
        case "month":
        case "year":
        default:
          return 3;
      }
    })();
    return (
      <div className={`grid grid-cols-${gridColumns} gap-2`}>
        {displayState.gridDisplayValues.map((gridValue) => (
          <Button
            key={`${state.mode}-key-${gridValue}`}
            fill="outline"
            className="!w-full text-center p-2 border rounded cursor-pointer hover:bg-gray-200 !text-black !border-gray-300 !hover:text-black"
            id={`${gridValue}`}
            onClick={() => handleGridSelection(gridValue)}
          >
            {gridValue}
          </Button>
        ))}
      </div>
    );
  };

  return (
    <div>
      {label && (
        <label htmlFor="period-picker-button" className="inline-flex flex-col">
          <Typography tag="span" size="xs" className={`${label && " mb-1"}`}>
            {label}
          </Typography>
        </label>
      )}
      <button
        id="period-picker-button"
        data-testid="period-picker-button"
        type="button"
        className="w-full text-left border border-gray-300 rounded p-2 cursor-pointer flex items-center"
        onClick={togglePicker}
      >
        <CalendarIcon className="w-4 h-4 inline-block mr-2" />
        <span className="whitespace-nowrap">{displayState.gridDisplay}</span>
      </button>

      {showPicker && (
        <div
          ref={pickerRef}
          className={`min-w-[280px] absolute z-10 top-full mt-2 ${pickerPositionLocation} bg-white border border-gray-300 rounded p-4 shadow-lg`}
        >
          <div
            className={`grid grid-cols-3 justify-around items-center ${
              state.mode === "year" ? "" : "mb-4"
            }`}
          >
            {/* 
              Year mode will display 3 years at a time. 
              The magic numbers provide the correct increments for this mode until we make them customizable.
             */}
            <Button
              fill="clear"
              className={`p-1 text-lg !text-black ${
                pendingYearDate - (state.mode === "year" ? 2 : 1) < minYear
                  ? "hidden"
                  : ""
              }`}
              onClick={() =>
                setPendingYearDate((prevDate) => {
                  const newYear = prevDate - 1;
                  if (newYear < minYear) return minYear;
                  return newYear;
                })
              }
            >
              -
            </Button>
            <span className="text-center col-start-2">{pendingYearDate}</span>
            <Button
              fill="clear"
              className={`p-1 text-lg !text-black ${
                pendingYearDate + (state.mode === "year" ? 2 : 1) > maxYear
                  ? "hidden"
                  : ""
              }`}
              onClick={() =>
                setPendingYearDate((prevDate) => {
                  const newYear = prevDate + 1;
                  if (newYear > maxYear) return maxYear;
                  return newYear;
                })
              }
            >
              +
            </Button>
          </div>
          {getDisplayGrid()}
        </div>
      )}
    </div>
  );
};

export default PeriodPicker;
