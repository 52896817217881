import React from "react";

const LegendContent = ({
  payload,
}: {
  payload?: { value: string; color: string }[];
}): React.ReactNode => (
  <div className="flex gap-5">
    <div className="flex items-center gap-2">
      <div className="h-3 w-3 rounded-xl bg-neutral-200" />
      <span className="text-neutral-900 font-thin">Past</span>
    </div>
    {payload?.map((entry) => (
      <div key={`legend-${entry.value}`} className="flex items-center gap-2">
        <div
          className="h-3 w-3 rounded-xl"
          style={{ backgroundColor: entry.color }}
        />
        <span className="text-neutral-900 font-thin pl-1">{entry.value}</span>
      </div>
    ))}
  </div>
);

export default LegendContent;
