import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "~/store";
import { OrganizationState, update } from "~/store/organizationSlice";
import request from "~/utils/request";
import toast from "react-hot-toast";
import PopoverComponent from "~/components/Popover";

interface IOrganizationResponse {
  data: {
    data?: OrganizationState[];
  };
  status: number;
}

interface IGetOrgDetailsResponse {
  data: {
    data?: OrganizationState;
  };
  status: number;
}

interface IHandleSelectOrgParams {
  orgUuid: string;
  orgName: string;
}

interface IOption {
  onClick: () => void;
  label: string;
}

const SwitchOrgs = () => {
  const dispatch = useDispatch();

  const selectedOrg = useSelector((state: State) => state.organization);
  const [userOrgs, setUserOrgs] = useState<IOption[]>([]);
  const menuRef = useRef<HTMLDivElement>(null);

  const getUserOrgs = async () => {
    const response = (await request({
      url: "/organizations",
      method: "GET",
    })) as IOrganizationResponse;

    if (response.data.data) {
      const formattedOrgs = response.data.data.map((org) => {
        return {
          onClick: () =>
            handleSelectOrg({ orgName: org.name, orgUuid: org.uuid }),
          label: org.name,
        };
      });
      setUserOrgs(formattedOrgs);
    }
  };

  const getOrgDetails = async (
    selectParams: IHandleSelectOrgParams,
  ): Promise<IGetOrgDetailsResponse> => {
    try {
      const response = (await request({
        url: `/organizations/${selectParams.orgUuid}`,
        method: "GET",
      })) as IGetOrgDetailsResponse;

      return response;
    } catch (error) {
      return { status: 401, data: {} };
    }
  };

  const handleSelectOrg = async (
    selectParams: IHandleSelectOrgParams,
  ): Promise<void> => {
    const orgDetails = await getOrgDetails(selectParams);

    if (orgDetails.status === 200 && orgDetails.data.data) {
      dispatch(update(orgDetails.data.data));
      window.location.reload();
    } else {
      toast.error("You are not an admin on this org");
    }
  };

  useEffect(() => {
    getUserOrgs();
  }, []);

  if (userOrgs.length <= 1) return null;

  return (
    <PopoverComponent
      fill="clear"
      options={userOrgs}
      ref={menuRef}
      className="max-w-[280px] truncate"
      direction="above"
      maxPanelHeight="max-h-96"
    >
      {selectedOrg.name}
    </PopoverComponent>
  );
};

export default SwitchOrgs;
