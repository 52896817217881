import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "~/components/Button";
import Typography from "~/components/Typography";
import request from "~/utils/request";
import toast from "react-hot-toast";

interface IDeleteModel {
  onClose: () => void;
  expenseUuid: string;
  organizationUuid: string;
}

const DeleteModel = ({
  onClose,
  expenseUuid,
  organizationUuid,
}: IDeleteModel): React.ReactNode => {
  const navigate = useNavigate();

  const handleDelete = async (): Promise<void> => {
    const response = await request({
      url: `/organizations/${organizationUuid}/expense-models/${expenseUuid}`,
      method: "DELETE",
    });

    if (response.status !== 204) {
      toast.error("Failed to delete");
      onClose();
    } else {
      toast.success("Expense model deleted");
      onClose();
      navigate("/expense-models");
    }
  };

  return (
    <div className="flex flex-col p-xl">
      <Typography size="lg" weight="bold" className="mb-1">
        Permanently Delete This Model
      </Typography>
      <Typography size="xs" color="lightGray" className="mb-8">
        This will delete this model and all adjustments associated with it. This
        action cannot be undone.
      </Typography>
      <div className="flex items-center justify-between">
        <Button fill="clear" className="w-[53px]" onClick={onClose}>
          Cancel
        </Button>
        <Button
          fill="destructiveOutline"
          className="w-[160px]"
          onClick={handleDelete}
          id="delete-manual-expense-model-button"
        >
          Yes, Delete
        </Button>
      </div>
    </div>
  );
};

export default DeleteModel;
