import { z } from "zod";

export const planSchema = z.object({
  uuid: z.string(),
  label: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  organizationUuid: z.string(),
  createdBy: z.string(),
});
