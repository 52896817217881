import Modal from "~/components/Modal";
import Typography from "~/components/Typography";
import React, { useEffect, useState } from "react";
import date from "~/utils/dates/date";
import formatCurrency from "~/utils/formatCurrency";
import { useSelector } from "react-redux";
import { State } from "~/store";
import request from "~/utils/request";
import Button from "~/components/Button";
import Skeleton from "~/components/Skeleton";
import toast from "react-hot-toast";
import useNonHrisFieldValidationState from "~/components/Position/ParallelFieldValidation/useNonHrisFieldValidationState";
import { useSelect } from "~/components/Select";
import ParallelFieldValidation from "~/components/Position/ParallelFieldValidation";
import MiniPositionCard from "./PositionCard";
import { Position } from "../headcount.types";
import { useInput } from "~/components/Input";

interface Props {
  isOpen: boolean;
  setModal: () => void;
  successCallback?: () => void;
  employeeUuid: string | null;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  employeeData: {
    employeeUuid?: string | null;
    title?: string | null;
    effectiveAt?: string | null;
    manager?: string | null;
    department?: string | null;
    compensationRate?: number | null;
    currency?: string | null;
    paymentUnit?: string | null;
    startDate?: string | null;
    employmentType?: string | null;
    employeeName?: string | null;
  } | null;
  modalSource?: string;
}

interface PositionResponse {
  request: unknown;
  data: {
    data: Position[];
  } | null;
  status: number;
}

const sortPositions = (positions: Position[], match: string): Position[] => {
  const sortedPositions = positions.sort((a, b) => {
    if (a.currentDepartment && b.currentDepartment) {
      // Calculate the distances from `a` and `b` to the `match` string
      const distanceA = Math.abs(a.currentDepartment.name.localeCompare(match));
      const distanceB = Math.abs(b.currentDepartment.name.localeCompare(match));

      // Compare the distances
      if (distanceA !== distanceB) {
        // If distances are different, sort based on distance
        return distanceA - distanceB;
      }

      // if distance are the same, sort alphabetically
      return a.currentDepartment.name.localeCompare(b.currentDepartment.name);
    }

    const dateComparison = a.current.effectiveAt.localeCompare(
      b.current.effectiveAt,
    );
    if (dateComparison !== 0) {
      return dateComparison;
    }
    return 0;
  });

  return sortedPositions;
};

const AssignEmployeeContainer = ({
  isOpen,
  setModal,
  employeeData,
  successCallback,
  employeeUuid,
  isLoading,
  setIsLoading,
  modalSource,
}: Props): React.ReactNode => {
  const organizationUuid = useSelector(
    (state: State) => state.organization.uuid,
  );
  const [positions, setPositions] = useState<Position[]>([]);
  const [filteredPositions, setFilteredPositions] = useState<Position[]>([]);
  const [selectedPosition, setSelectedPosition] = useState<Position | null>(
    null,
  );
  const [showParallelFieldValidation, setShowParallelFieldValidation] =
    useState(false);
  const [isLoadingPositions, setIsLoadingPositions] = useState(true);
  const [currencyTypeState, setCurrencyTypeState] = useSelect({
    selected: {
      value: employeeData?.currency ?? "USD",
      label: employeeData?.currency ?? "USD",
    },
  });
  const [creationChoice, setCreationChoice] = useState<"new" | "existing">(
    "new",
  );
  const [search, setSearch] = useInput({ validation: /.*/ });
  const searchInput: {
    id: string;
    placeholder: string;
    state: Types.InputState;
    setState: React.Dispatch<React.SetStateAction<Types.InputState>>;
    className?: string;
    type?: "search";
  } = {
    id: "assign-employee-search",
    placeholder: "Search Title",
    state: search,
    setState: setSearch,
    className: "w-[200px]",
    type: "search",
  };

  const {
    expectedHoursState,
    setExpectedHoursState,
    bonusAmountState,
    setBonusAmountState,
    commissionAmountState,
    setCommissionAmountState,
    attainmentState,
    setAttainmentState,
  } = useNonHrisFieldValidationState();

  const getUnfilledPositionsData = async (): Promise<void> => {
    try {
      const positionsResponse = (await request({
        url: `/organizations/${organizationUuid}/aggregated-data/open-positions`,
        method: "GET",
        params: {
          asOfDate: employeeData?.startDate ?? undefined,
        },
      })) as PositionResponse;

      if (positionsResponse.data?.data) {
        setPositions(positionsResponse.data.data);
      }
    } catch {
      throw Error("Error fetching positions");
    } finally {
      setIsLoadingPositions(false);
    }
  };

  useEffect(() => {
    const newFilteredPositions = positions.filter((position) =>
      position.current.title.toLowerCase().includes(search.value.toLowerCase()),
    );
    setFilteredPositions(newFilteredPositions);
  }, [search.value, positions]);

  const attemptAssignRole = async (): Promise<void> => {
    setIsLoading(true);
    if (employeeUuid) {
      const url =
        creationChoice === "new" && !selectedPosition
          ? `/organizations/${organizationUuid}/positions`
          : `/organizations/${organizationUuid}/positions/${selectedPosition?.current.positionUuid}/assignEmployee`;

      const assignRoleResponse = await request({
        url,
        method: "POST",
        body: {
          employeeUuid,
          changeDescription: "Assigned employee",
          parallelTrackedFields: {
            bonus: bonusAmountState.value
              ? Number(bonusAmountState.value) * 100
              : null,
            commission: commissionAmountState.value
              ? Number(commissionAmountState.value) * 100
              : null,
            attainment: attainmentState.value
              ? Number(attainmentState.value) / 100
              : null,
            expectedWeeklyHours: expectedHoursState.value
              ? expectedHoursState.value
              : undefined,
          },
        },
      });
      if (assignRoleResponse.status === 201) {
        setShowParallelFieldValidation(false);
        setSelectedPosition(null);
        toast.success(
          creationChoice === "new"
            ? "New position generated"
            : "Assigned employee",
        );
        if (successCallback) {
          successCallback();
        }
      } else {
        setIsLoading(false);
        toast.error("Error assigning employee");
      }
    } else {
      setIsLoading(false);
      toast.error("Error assigning employee");
    }
  };

  const validateNonHrisFields = (): void => {
    if (selectedPosition !== null) {
      if (employeeData?.paymentUnit === "HOURLY") {
        setExpectedHoursState({
          ...expectedHoursState,
          value:
            selectedPosition.current.expectedWeeklyHours?.toString() ??
            selectedPosition.current.employmentType === "FULL_TIME"
              ? "40"
              : "20",
          valid: true,
        });
      }

      setBonusAmountState({
        ...bonusAmountState,
        value:
          selectedPosition.current.bonus !== null
            ? (selectedPosition.current.bonus / 100).toString()
            : "",
        valid: true,
      });

      setCommissionAmountState({
        ...commissionAmountState,
        value:
          selectedPosition.current.commission !== null
            ? (selectedPosition.current.commission / 100).toString()
            : "",
        valid: true,
      });

      setAttainmentState({
        ...attainmentState,
        value:
          selectedPosition.current.attainment !== null
            ? (selectedPosition.current.attainment * 100).toString()
            : "",
        valid: true,
      });
    }

    setShowParallelFieldValidation(true);
  };

  useEffect(() => {
    if (isOpen) {
      getUnfilledPositionsData();
    }
  }, [isOpen]);

  const formatEmploymentType = (value: string): string =>
    value
      .replace("_", " ")
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

  let paymentUnit = "-";
  if (employeeData?.paymentUnit) {
    paymentUnit = employeeData.paymentUnit === "HOURLY" ? "Hourly" : "Salary";
  }

  if (!employeeData) return null;

  const modalDisplayState = ((): React.ReactNode => {
    if (isLoadingPositions) {
      // Render the loading state
      return Array.from({ length: 5 }).map((_, index) => (
        <Skeleton
          key={
            /* eslint-disable-next-line react/no-array-index-key */
            `y-axis-skeleton-${index}`
          }
          height={52}
          width={1070}
          baseColor="white"
        />
      ));
    }
    if (positions.length) {
      // Render the positions if available
      return sortPositions(
        filteredPositions,
        employeeData.department ?? "a",
      ).map((position) => (
        <MiniPositionCard
          isSelected={selectedPosition?.current.uuid === position.current.uuid}
          onSelect={setSelectedPosition}
          position={position}
          id={`assign-employee-position-${position.current.positionUuid}`}
          key={position.current.uuid}
        />
      ));
    }

    // Render the message if there are no positions
    return (
      <Typography color="empty" className="m-auto">
        No Forecasted Positions
      </Typography>
    );
  })();

  const handleModalState = ((): {
    title: string;
    size: "lg" | "xxl";
  } => ({
    title: showParallelFieldValidation
      ? "Validate New Position Data"
      : modalSource === "AssignEmployee"
        ? "Assign Employee"
        : "Transfer Employee",
    size: showParallelFieldValidation ? "lg" : "xxl",
  }))();

  return (
    <Modal
      title={handleModalState.title}
      isOpen={isOpen}
      size={handleModalState.size}
      onClose={() => {
        if (!showParallelFieldValidation) {
          setSelectedPosition(null);
          setModal();
        }
      }}
      textInput={!showParallelFieldValidation ? searchInput : undefined}
    >
      {!showParallelFieldValidation ? (
        <div
          data-testid="assign-employee-modal"
          className="col-span-7 flex flex-col items-start w-full"
        >
          <div className="pl-6 pr-10 w-full mt-6 mb-4">
            <div className="flex flex-row gap-3 items-baseline">
              <Typography tag="h3" size="md" weight="medium">
                {employeeData.employeeName}
              </Typography>
              <Typography
                tag="p"
                size="xs"
                weight="medium"
                color="special"
                className="text-orange"
              >
                HRIS DATA
              </Typography>
            </div>
            <div
              data-testid="task-employee-metadata"
              className="w-full flex flex-row justify-between mt-2"
            >
              <div>
                <Typography color="secondary">Title</Typography>
                <p
                  className="text-sm w-64 truncate"
                  title={employeeData.title ?? "-"}
                >
                  {employeeData.title ?? "-"}
                </p>
              </div>
              <div>
                <Typography color="secondary">Department</Typography>
                <p
                  className="text-sm w-32 truncate"
                  title={employeeData.department ?? "-"}
                >
                  {employeeData.department ?? "-"}
                </p>
              </div>
              <div>
                <Typography color="secondary">Employment Type</Typography>
                <p
                  className="text-sm w-32 truncate"
                  title={employeeData.employmentType ?? "-"}
                >
                  {employeeData.employmentType
                    ? formatEmploymentType(employeeData.employmentType)
                    : "-"}
                </p>
              </div>
              <div>
                <Typography color="secondary">Payment Unit</Typography>
                <p className="text-sm w-32 truncate" title={paymentUnit}>
                  {paymentUnit}
                </p>
              </div>
              <div>
                <Typography color="secondary">Compensation</Typography>
                <p
                  className="text-sm w-32 truncate"
                  title={
                    employeeData.compensationRate
                      ? formatCurrency(
                          employeeData.compensationRate,
                          true,
                          employeeData.currency ?? undefined,
                        )
                      : "-"
                  }
                >
                  {employeeData.compensationRate
                    ? formatCurrency(
                        employeeData.compensationRate,
                        employeeData.paymentUnit === "HOURLY" ? true : false,
                        employeeData.currency ?? undefined,
                      )
                    : "-"}
                </p>
              </div>
              <div>
                <Typography color="secondary">Start Date</Typography>
                <p className="text-sm w-32 truncate">
                  {employeeData.startDate
                    ? date(employeeData.startDate).format("MM/DD/YYYY")
                    : "-"}
                </p>
              </div>
            </div>
          </div>
          <div className="relative w-full">
            <div className="w-full h-[475px] bg-neutral-15 flex flex-col gap-3 my-4 px-2 py-5 rounded-xl overflow-auto border border-neutral-50">
              {modalDisplayState}
              <div className="absolute top-1 left-3 bg-neutral-15 rounded-full border border-neutral-50 px-4">
                <Typography className="text-xs" color="secondary">
                  FORECASTED POSITIONS
                </Typography>
              </div>
            </div>
          </div>

          <div className="w-full flex justify-between">
            <Button
              fill="clear"
              className="!w-auto !px-0"
              id="assign-employee-button-cancel"
              onClick={() => {
                setSelectedPosition(null);
                setModal();
              }}
            >
              Cancel
            </Button>
            <div className="flex gap-4">
              <Button
                id={`generate-position-${employeeUuid}-button`}
                fill="outline"
                className="!w-auto"
                disabled={isLoading}
                onClick={validateNonHrisFields}
                loading={isLoading}
              >
                Generate Position
              </Button>
              <Button
                className="!w-auto"
                id={`assign-employee-button-${employeeUuid}`}
                disabled={selectedPosition === null || isLoading}
                onClick={() => {
                  validateNonHrisFields();
                  setCreationChoice("existing");
                }}
              >
                Assign
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <ParallelFieldValidation
          onSubmit={attemptAssignRole}
          onBack={() => {
            setShowParallelFieldValidation(false);
          }}
          currencyTypeState={currencyTypeState}
          setCurrencyTypeState={setCurrencyTypeState}
          expectedHoursState={expectedHoursState}
          setExpectedHoursState={setExpectedHoursState}
          bonusAmountState={bonusAmountState}
          setBonusAmountState={setBonusAmountState}
          commissionAmountState={commissionAmountState}
          setCommissionAmountState={setCommissionAmountState}
          attainmentState={attainmentState}
          setAttainmentState={setAttainmentState}
          paymentUnit={paymentUnit}
          isLoading={isLoading}
        />
      )}
    </Modal>
  );
};

export default AssignEmployeeContainer;
