import React, { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { usePopper } from "react-popper";
import Typography from "~/components/Typography";
import List, { ListItem } from "~/components/List";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";

interface Props {
  deleteFunction: () => void;
  editFunction: () => void;
}

const ForecastOptionsPopover = ({ deleteFunction, editFunction }: Props) => {
  const [referenceElement, setReferenceElement] = useState(undefined);
  const [popperElement, setPopperElement] = useState(undefined);
  const { styles, attributes } = usePopper(referenceElement, popperElement);

  return (
    <Popover className="relative">
      <Popover.Button
        ref={setReferenceElement}
        data-testid="deactivatePositionDropdown"
      >
        <EllipsisVerticalIcon className="w-10 h-10 p-2" />
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white w-[170px]">
            <div className="p-2">
              <List>
                <ListItem
                  noBorder
                  showChevron={false}
                  onClick={editFunction}
                  className="hover:bg-neutral-50 rounded px-3 py-1"
                >
                  <Typography>Edit Forecast</Typography>
                </ListItem>
                <ListItem
                  noBorder
                  showChevron={false}
                  onClick={deleteFunction}
                  className="hover:bg-neutral-50 rounded px-3 py-1"
                >
                  <Typography color="warning">Delete Forecast</Typography>
                </ListItem>
              </List>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default ForecastOptionsPopover;
