import Header from "~/components/Header";
import SegmentedControl from "~/components/SegmentedControl";
import React from "react";
import DatePicker from "~/components/DatePicker";
import DrilldownDrawer from "~/components/Drawer/DrilldownDrawer";

import { HeadcountOrSalary } from "./types";
import ForecastTableRenderer from "./ForecastTableRenderer";
import {
  ForecastPageProvider,
  ForecastPageContext,
} from "./context/ForecastPageContext";

const ForecastContainerContent = (): React.ReactNode => {
  const forecastContext = React.useContext(ForecastPageContext);
  if (!forecastContext) {
    throw new Error("Forecast page missing data to provide context");
  }
  const {
    selectYearState,
    setSelectYearState,
    setIsLoading,
    headcountOrSalary,
    setHeadcountOrSalary,
  } = forecastContext;
  return (
    <div>
      <Header title="Forecast">
        <div className="w-50 flex justify-center gap-2">
          <DatePicker
            label=""
            labelPlacement="left"
            id="select-year"
            state={selectYearState}
            setState={(val) => {
              setIsLoading(true);
              setSelectYearState(val);
            }}
            range
            className="min-w-[275px]"
          />
          <SegmentedControl
            name="headcount-salaries-view"
            value={headcountOrSalary}
            setValue={(val) => {
              setHeadcountOrSalary(
                val as React.SetStateAction<HeadcountOrSalary>,
              );
            }}
            segments={[
              {
                label: "Salaries",
                value: "salaries",
              },
              {
                label: "Headcount",
                value: "headcount",
              },
            ]}
          />
        </div>
      </Header>
      <div className="container !max-w-full flex flex-col !m-0 !pl-10 !pr-0 pt-10">
        <ForecastTableRenderer />
      </div>
      <DrilldownDrawer />
    </div>
  );
};

const ForecastContainer = (): React.ReactNode => (
  <ForecastPageProvider>
    <ForecastContainerContent />
  </ForecastPageProvider>
);

export default ForecastContainer;
