import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { State } from "~/store";
import request from "~/utils/request";
import UnassignedEmployeeTask from "~/components/Tasks/UnassignedEmployeeTask";
import BackfillPositionTask from "~/components/Tasks/BackfillPositionTask";
import Typography from "~/components/Typography";
import emptyTasksStateIllustration from "~/assets/emptyTasksStateIllustration.svg";
import toast from "react-hot-toast";
import permissionsCheck from "~/utils/permissionsCheck";
import Pusher from "pusher-js";
import PositionRequestTask from "./PositionRequestTask";
import OutdatedForecastTask from "./OutdatedForecastTask";
import PotentialTransferTask from "./PotentialTransferTask";
import ForecastRequestTask from "./ForecastRequestTask";

export interface IBackfillPosition {
  backfillOrigin: "termination" | "transfer";
  employeeUuid: string;
  positionUuid: string;
  terminationDate: Date;
  title: string;
  lastFilled: string;
  department?: string;
  paymentUnit: string;
  compensationRate: number;
  bonus?: number | null;
  commission?: number | null;
  attainment?: number | null;
  currency: string;
  manager?: string | null;
}

export interface IPositionRequest {
  requestorUuid: string;
  compensationRate: number;
  commission?: number;
  bonus?: number;
  attainment?: number;
  currency: string;
  paymentUnit: string;
  employmentType: string;
  managerUuid: string;
  expectedWeeklyHours?: number;
  title: string;
  effectiveAt: string;
  groupUuid: string;
  numberToCreate: number;
  changeDescription: string;
  status: string;
  requestor?: string | null;
  manager?: string | null;
  group?: string | null;
  deletedAt?: string | null;
  deletedBy?: string | null;
  rejectionReason?: string | null;
}

export interface IPotentialTransfer {
  positionUuid: string;
  positionVersionUuid: string;
  employeeUuid: string;
  employeeName?: string;
  managerName?: string;
  departmentName?: string;
  changelog: Change[];
  hrisData: {
    title?: string | null;
    compensationRate?: number | null;
    paymentUnit?: string | null;
    groupUuid?: string | null;
    employmentType?: string | null;
    managerUuid?: string | null;
  };
}

export interface IForecastRequest {
  requestorUuid: string;
  positionUuid: string;
  compensationRate?: number;
  commission?: number | null;
  bonus?: number | null;
  attainment?: number | null;
  currency?: string;
  paymentUnit?: string;
  employmentType?: string;
  managerUuid?: string | null;
  expectedWeeklyHours?: number;
  title?: string;
  effectiveAt: string;
  groupUuid?: string;
  changeDescription?: string;
  deletedAt?: Date | null;
  deletedBy?: string | null;
  status: string;
  requestor?: string | null;
  manager?: string | null;
  group?: string | null;
  rejectionReason?: string | null;
}

export interface TaskInterface {
  uuid: string;
  type: string;
  description: string;
  createdAt: string;
  metadata: {
    employeeUuid?: string | null;
    positionUuid?: string | null;
    employeeName?: string | null;
    compensationRate?: number | null;
    currency?: string | null;
    positionVersionUuid?: string | null;
    title?: string | null;
    employeeStartDate?: string | null;
    lastFilled?: string | null;
    department?: string | null;
    paymentUnit?: string | null;
    modification?: string | null;
    originalChangeDate?: Date | null;
    manager?: string | null;
    terminationDate?: string | null;
    effectiveAt?: string | null;
    changeDescription?: string | null;
    expectedWeeklyHours?: number | null;
    employmentType?: string | null;
    startDate?: string | null;
    backfillPosition: IBackfillPosition;
    requestNewPosition?: IPositionRequest;
    requestPositionForecast?: IForecastRequest;
    potentialTransfer?: IPotentialTransfer;
  };
  completedAt: string | null;
  completedBy: string | null;
}
export interface TasksResponse {
  data: {
    data: TaskInterface[];
  };
  status: number;
}

type ChangedType = string | number | boolean | null;

export interface Change {
  label: string;
  changedFrom: ChangedType;
  changedTo: ChangedType;
}

const sortTasksByType = (tasks: TaskInterface[]): TaskInterface[] => {
  const typeOrder: Record<string, number> = {
    unassignedEmployee: 1,
    backfillPosition: 2,
    potentialTransfer: 3,
    requestPositionForecast: 4,
    requestNewPosition: 5,
    outdatedPosition: 6,
    outdatedTermination: 7,
    oudatedChange: 8,
  };

  return tasks.sort((taskA, taskB) => {
    const orderA = typeOrder[taskA.type] || Number.MAX_VALUE;
    const orderB = typeOrder[taskB.type] || Number.MAX_VALUE;
    return orderA - orderB;
  });
};

const Tasks = (): React.ReactNode => {
  const organizationUuid = useSelector(
    (state: State) => state.organization.uuid,
  );
  const {
    name: userName,
    permissions: { role, departmentAccessList },
  } = useSelector((state: State) => state.user);
  const { total } = useSelector((state: State) => state.tasks);
  const [isLoading, setIsLoading] = React.useState(true);
  const [tasks, setTasks] = React.useState<TaskInterface[]>([]);
  const isAdmin = permissionsCheck(role, departmentAccessList);

  const fetchTasks = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const response = (
        isAdmin
          ? await request({
              url: `/organizations/${organizationUuid}/tasks`,
              method: "GET",
              params: {
                isCompleted: false,
              },
            })
          : await request({
              url: `/organizations/${organizationUuid}/requests`,
              method: "GET",
            })
      ) as TasksResponse;

      if (response.status >= 400) throw Error("Error fetching tasks");
      const { data } = response.data;

      setTasks(sortTasksByType(data));
    } catch (error) {
      toast.error("Error fetching tasks");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const pusher = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
      cluster: "us2",
    });
    const channel = pusher.subscribe(organizationUuid);
    channel.bind("task-update", async () => {
      fetchTasks();
    });
    fetchTasks();

    return () => {
      pusher.unsubscribe(organizationUuid);
    };
  }, []);

  return (
    <div className="w-full my-6">
      <Typography tag="h2" size="xl" weight="bold" className="mb-3">
        Tasks
      </Typography>
      {isLoading && (
        <div>
          <div data-testid="task-head-explain" className="w-2/3">
            <Typography tag="p" size="sm" color="secondary" className="mb-3">
              This is where you will be assigning an employee to a forecasted
              role, verifying if a position should be backfilled, and keeping
              forecasted changes up to date.
            </Typography>
          </div>
          <div className="flex justify-center">Loading...</div>
        </div>
      )}
      {!isLoading && total === 0 && tasks.length === 0 && (
        <div className="flex flex-col items-center text-center">
          <img
            src={emptyTasksStateIllustration}
            alt="Empty Tasks Illustration"
            className="w-64 h-auto"
          />
          <Typography tag="h3" size="md" weight="semibold" className="mt-4">
            All Tasks Completed
          </Typography>
          <Typography tag="p" size="md" color="secondary">
            More tasks will appear when actionable data comes from the <br />{" "}
            HRIS or when a forecast needs to be updated
          </Typography>
        </div>
      )}
      {!isLoading && tasks.length > 0 && (
        <div className="flex flex-col gap-2 w-full">
          <div data-testid="task-head-explain" className="w-2/3">
            <Typography tag="p" size="sm" color="secondary" className="mb-3">
              This is where you will be assigning an employee to a forecasted
              role, verifying if a position should be backfilled, and keeping
              forecasted changes up to date.
            </Typography>
          </div>
          {tasks.map((task) => {
            switch (task.type) {
              case "requestNewPosition":
                if (
                  !task.metadata.requestNewPosition ||
                  (!isAdmin &&
                    task.metadata.requestNewPosition.requestor !== userName)
                )
                  return null;
                return (
                  <PositionRequestTask
                    task={task}
                    successCallback={fetchTasks}
                  />
                );
              case "requestPositionForecast":
                if (
                  !task.metadata.requestPositionForecast ||
                  (!isAdmin &&
                    task.metadata.requestPositionForecast.requestor !==
                      userName)
                )
                  return null;
                return (
                  <ForecastRequestTask
                    requestType="forecast"
                    requestInformation={task.metadata.requestPositionForecast}
                    task={task}
                    successCallback={fetchTasks}
                  />
                );
              case "unassignedEmployee":
                if (!task.metadata.employeeUuid) return null;
                return <UnassignedEmployeeTask key={task.uuid} task={task} />;
              case "outdatedPosition":
              case "outdatedTermination":
              case "outdatedChange":
                if (!task.metadata.positionUuid) return null;
                return <OutdatedForecastTask key={task.uuid} task={task} />;
              case "backfillPosition":
                if (
                  !task.metadata.backfillPosition.employeeUuid ||
                  !task.metadata.backfillPosition.positionUuid
                )
                  return null;
                return <BackfillPositionTask key={task.uuid} task={task} />;
              case "potentialTransfer":
                if (!task.metadata.potentialTransfer) return null;
                return <PotentialTransferTask key={task.uuid} task={task} />;
              default:
                return null;
            }
          })}
        </div>
      )}
    </div>
  );
};

export default Tasks;
