import Button from "~/components/Button";
import Card from "~/components/Card";
import Input from "~/components/Input";
import React from "react";
import { NavigateFunction } from "react-router-dom";

interface Props {
  name: Types.InputState;
  setName: React.Dispatch<React.SetStateAction<Types.InputState>>;
  email: Types.InputState;
  setEmail: React.Dispatch<React.SetStateAction<Types.InputState>>;
  companyName: Types.InputState;
  setCompanyName: React.Dispatch<React.SetStateAction<Types.InputState>>;
  signUp: () => void;
  navigate: NavigateFunction;
}

const SignUp = ({
  name,
  setName,
  email,
  setEmail,
  companyName,
  setCompanyName,
  signUp,
  navigate,
}: Props): React.ReactNode => (
  <div className="flex flex-col items-center justify-start w-screen h-screen">
    <div className="flex flex-col w-[95vw] max-w-[500px] mt-[10vh]">
      <h1>Sign Up</h1>
      <Card className="w-full mt-5">
        <Input
          id="name"
          state={name}
          setState={setName}
          label="Full name"
          placeholder="Enter your full name here"
        />
        <Input
          id="email"
          state={email}
          setState={setEmail}
          label="Email address"
          placeholder="Enter your email address here"
        />
        <Input
          id="company-name"
          state={companyName}
          setState={setCompanyName}
          label="Company Name"
          placeholder="Enter your company name here"
        />
        <Button id="create-account" onClick={signUp} className="mt-4 w-full">
          Create Account
        </Button>
      </Card>
      <h5 className="mt-10">Already have an account?</h5>
      <Button
        id="log-in"
        fill="outline"
        onClick={() => navigate("/auth/login")}
      >
        Log In
      </Button>
    </div>
  </div>
);

export default SignUp;
