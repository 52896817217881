import { Dayjs } from "dayjs";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export default (option, dayjsClass, dayjsFactory) => {
  // eslint-disable-next-line no-param-reassign,func-names
  dayjsClass.prototype.lastOccurrence = function (
    this: Dayjs,
    unit: "month",
    value: number,
  ): Dayjs {
    switch (unit) {
      case "month": {
        if (value < 0 || value > 11) {
          throw new Error(
            "Invalid month number. Month should be between 1 and 12.",
          );
        }

        const today = dayjsFactory(this);
        const currentYear = today.year();
        const currentMonth = today.month();

        let lastOccurrenceYear = currentYear;
        if (currentMonth < value) {
          lastOccurrenceYear -= 1; // If the current month is before the target month, last occurrence was in the previous year
        }

        // Setting the day to 1 for the first day of the month
        return dayjsFactory(new Date(lastOccurrenceYear, value, 1));
      }
      // eslint-disable-next-line no-fallthrough
      default:
        return this;
    }
  };
};
