import React from "react";
import Button from "~/components/Button";
import blackLogo from "~/assets/logo-black.svg";
import { Link } from "react-router-dom";

interface Props {
  handleDecline: () => void;
  handleAccept: () => void;
  clientName: string | undefined;
  logo: string | undefined;
}

const OAuthAuthorization = ({
  handleDecline,
  handleAccept,
  clientName,
  logo,
}: Props): React.ReactNode => (
  <div className="h-screen w-full bg-green-15 flex flex-col items-center justify-center">
    <div className="mb-3">
      <img src={blackLogo} alt="Parallel logo" className="w-48 h-auto" />
    </div>
    <div
      data-testid="oauth-connection-div"
      className="h-auto max-w-[350px] py-6 px-8 bg-neutral-15 rounded-2xl flex flex-col gap-2 shadow"
    >
      <img src={logo} alt={`${clientName} logo`} className="w-32 h-auto" />{" "}
      <p className="fontSize-sm">
        {`${clientName} wants to access your Parallel data`}
      </p>
      <div className="text-neutral-400">
        <p>{`This will allow ${clientName} to:`}</p>
        <ul className="list-disc list-inside">
          <li>View salaries by department</li>
          <li>View headcount by department</li>
          <li>View additional expense models by department</li>
        </ul>
      </div>
      <div className="flex flex-row mt-3 w-full justify-between">
        <Button
          fill="destructive"
          className="!w-auto !px-10"
          onClick={handleDecline}
        >
          Decline
        </Button>
        <Button className="!w-auto !px-10" onClick={handleAccept}>
          Accept
        </Button>
      </div>
    </div>
    <Link to="/auth/logout" className="py-6">
      <span className="text-base text-[#7F9376]">Log Out</span>
    </Link>
  </div>
);

export default OAuthAuthorization;
