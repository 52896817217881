import React, { useRef, ChangeEvent, KeyboardEvent } from "react";
import Typography from "../Typography";

interface OtpInputWithValidationProps {
  numberOfDigits: number;
  errorMessage?: string;
  label?: string;
  errorActive?: boolean;
  onChange: (value: string, index: number) => void;
  onPaste: (value: string[]) => void;
  otp: string[];
}

const OtpInput: React.FC<OtpInputWithValidationProps> = ({
  numberOfDigits,
  errorMessage,
  label,
  errorActive,
  onChange,
  onPaste,
  otp,
}) => {
  const otpBoxReference = useRef<HTMLInputElement[]>([]);

  const handleChange = (value: string, index: number): void => {
    onChange(value, index);

    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  };

  const handleBackspaceAndEnter = (
    e: KeyboardEvent<HTMLInputElement>,
    index: number,
  ): void => {
    if (e.key === "Backspace" && !e.currentTarget.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (
      e.key === "Enter" &&
      e.currentTarget.value &&
      index < numberOfDigits - 1
    ) {
      otpBoxReference.current[index + 1].focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>): void => {
    e.preventDefault();
    const pastedValueArr = e.clipboardData.getData("text/plain").split("");
    onPaste(pastedValueArr);
  };

  return (
    <div className="w-fit flex flex-col items-center justify-center mb-1">
      <div className="flex items-center gap-4">
        {otp.map((digit, index) => (
          <input
            key={index}
            value={digit}
            maxLength={1}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.value, index)
            }
            onKeyUp={(e: KeyboardEvent<HTMLInputElement>) =>
              handleBackspaceAndEnter(e, index)
            }
            onPaste={(e: React.ClipboardEvent<HTMLInputElement>) =>
              handlePaste(e)
            }
            ref={(reference) =>
              (otpBoxReference.current[index] = reference as HTMLInputElement)
            }
            className={`h-[45px] w-[45px] block bg-white focus:outline-none rounded-[3px] appearance-none 
              focus:ring-offset-green-200 text-center 
              ${
                errorActive
                  ? "border-red-500 focus:border-red-500"
                  : "focus:border-green-400"
              }`}
          />
        ))}
      </div>
      {errorActive && (
        <Typography color="warning" className="mt-2">
          {errorMessage ?? "Incorrect Code"}
        </Typography>
      )}
      <Typography
        className={`text-lg max-w-full text-center mt-2`}
        color="secondary"
      >
        {label}
      </Typography>
    </div>
  );
};

export default OtpInput;
