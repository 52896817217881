import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { State } from "~/store";
import { useLoaderData, useNavigate } from "react-router-dom";
import permissionsCheck from "~/utils/permissionsCheck";
import ExpenseModelDetails from "./ExpenseModelDetails";
import ManualAdjustmentDetails from "./ManualAdjustments/ManualAdjustmentDetails";
import { expenseSchema } from "../../types/expenseModel/schemas";

const ExpenseModel = (): React.ReactNode | null => {
  const expenseModel = useLoaderData();
  const parsedExpenseModel = expenseSchema.parse(expenseModel);
  const navigate = useNavigate();
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const {
    permissions: { role, departmentAccessList },
  } = useSelector((state: State) => state.user);

  useEffect(() => {
    if (!permissionsCheck(role, departmentAccessList)) navigate("/dashboard");
  }, []);

  switch (parsedExpenseModel.current.type) {
    case "PERCENT_EMPLOYEE_SALARY":
    case "FIXED_PER_EMPLOYEE":
      return (
        <ExpenseModelDetails
          expenseModel={parsedExpenseModel}
          organizationUuid={organizationUuid}
        />
      );
    case "MANUAL_ADJUSTMENT":
      return (
        <ManualAdjustmentDetails
          expenseModel={parsedExpenseModel}
          organizationUuid={organizationUuid}
          canBeDeleted={parsedExpenseModel.canBeDeleted}
        />
      );
    default:
      return null;
  }
};

export default ExpenseModel;
