import Button from "~/components/Button";
import Header from "~/components/Header";
import request from "~/utils/request";
import React, { useEffect, useState } from "react";
import useQueryParams from "~/utils/hooks/useQueryParams";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { State } from "~/store";
import DropdownButton from "~/components/DropdownButton";
import toast from "react-hot-toast";
import Modal from "~/components/Modal";
import Divider from "~/components/Divider";
import { TasksResponse, TaskInterface } from "~/components/Tasks";
import permissionsCheck from "~/utils/permissionsCheck";
import ModifyPositionContainer from "../ModifyPosition";
import { Position, IPositionHistoryVersion } from "../headcount.types";
import ModifyFutureVersion from "../UpdateFutureVersion";
import "./positionDetails.css";
import ForecastTermination from "../ForecastTermination";
import PositionHistoryList from "./PositionHistoryList";
import CurrentPositionDetails from "./CurrentPositionDetails";
import EmployeeDetails from "./EmployeeDetails";

export interface PositionResponse {
  data: {
    data: Position;
  } | null;
  status: number;
}

const PositionDetails = (): React.ReactNode => {
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const {
    permissions: { role, departmentAccessList },
  } = useSelector((state: State) => state.user);
  const [queryParams, setQueryParams] = useQueryParams();
  const [modifyPositionModalIsOpen, setModifyPositionModalIsOpen] = useState(
    queryParams.get("modifyPosition") !== null,
  );
  const [type] = useState(queryParams.get("type") || null);
  const [forecastTerminationModalIsOpen, setForecastTerminationModalIsOpen] =
    useState(queryParams.get("forecastTermination") !== null);
  const [modifyPositionUuid, setModifyPositionUuid] = useState(
    queryParams.get("positionUuid") || null,
  );
  const { positionUuid } = useParams();
  const [positionData, setPositionData] = useState<Position>();
  const [requestData, setRequestData] = useState<TaskInterface[]>();
  const [positionHistoryData, setPositionHistoryData] = useState<
    IPositionHistoryVersion[]
  >([]);
  const [
    positionVersionInEditModeEffectiveAt,
    setPositionVersionInEditModeEffectiveAt,
  ] = useState<string | null>(null);

  const isInitialForecast = !positionData?.history[0].isActual;

  const navigate = useNavigate();

  const fetchPositionData = async (): Promise<void> => {
    const positionResponse = (await request({
      url: `/organizations/${organizationUuid}/positions/${positionUuid}`,
      method: "GET",
    })) as PositionResponse;
    if (positionResponse.data?.data) {
      setPositionData(positionResponse.data.data);
      const { auditLog } = positionResponse.data.data;
      setPositionHistoryData(auditLog);
    } else {
      navigate("/headcount?type=forecast");
    }
  };

  const fetchPositionRequests = async (): Promise<void> => {
    const requestResponse = (await request({
      url: `/organizations/${organizationUuid}/requests`,
      method: "GET",
      params: {
        positionUuid,
      },
    })) as TasksResponse;
    if (requestResponse.data.data) {
      setRequestData(requestResponse.data.data);
    } else {
      navigate("/headcount?type=forecast");
    }
  };

  useEffect(() => {
    fetchPositionData();
    fetchPositionRequests();
  }, [positionVersionInEditModeEffectiveAt]);

  useEffect(() => {
    const newQueryParams: {
      modifyPosition?: string;
      positionUuid?: string;
      forecastTermination?: string;
      type?: string;
    } = {};

    if (modifyPositionModalIsOpen) newQueryParams.modifyPosition = "true";
    if (forecastTerminationModalIsOpen)
      newQueryParams.forecastTermination = "true";
    if (modifyPositionUuid) newQueryParams.positionUuid = modifyPositionUuid;
    if (type) newQueryParams.type = type;

    setQueryParams(newQueryParams);
  }, [modifyPositionModalIsOpen, modifyPositionUuid]);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent): void => {
      if (event.key === "Escape") {
        setPositionVersionInEditModeEffectiveAt(null);
      }
    };

    if (positionVersionInEditModeEffectiveAt) {
      document.addEventListener("keydown", handleEscape);
    } else {
      document.removeEventListener("keydown", handleEscape);
    }

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [positionVersionInEditModeEffectiveAt]);

  const attemptDeactivatePosition = async (): Promise<void> => {
    const requests = positionData?.history
      .filter(
        (history) =>
          history.isActual === false && history.employeeUuid === null,
      )
      .map((history) =>
        request({
          url: `/organizations/${organizationUuid}/positions/${positionUuid}`,
          params: {
            effectiveAt: history.effectiveAt,
          },
          method: "DELETE",
        }),
      );
    const responses = await Promise.all(requests ?? []);
    if (responses.every((obj) => obj.status === 204)) {
      toast.success("Position deactivated");
      navigate(-1);
    } else {
      toast.error("Something went wrong");
    }
  };

  const activeChange = positionData?.auditLog[0].changes.filter(
    (change) => change.label === "Position Active",
  )[0];
  const latestVersionIsActive = !activeChange
    ? true
    : activeChange.changedTo === "Active";

  const ableToDeactivatePosition = positionData?.history.reduce(
    (output, history) => {
      // Keep things false if it's already set
      if (!output) return output;
      // Should return true if there is no employee assigned to all position forecasts
      return !history.employeeUuid && !history.isActual;
    },
    true,
  );

  const positionForecasts = positionHistoryData
    .filter((version) => !version.isActual)
    .reverse();

  const positionHistory = positionHistoryData
    .filter((version) => version.isActual)
    .reverse();

  const dropDownOptions = (
    position: Position,
  ): {
    label: string;
    onClick: () => void;
  }[] => {
    const defaultOptions = [
      {
        label: "Forecast Termination",
        onClick: (): void => {
          setForecastTerminationModalIsOpen(true);
          setModifyPositionUuid(position.current.positionUuid);
        },
      },
    ];

    return defaultOptions;
  };

  return (
    <div className="w-full h-full flex flex-col">
      <Modal
        isOpen={positionVersionInEditModeEffectiveAt !== null}
        title="Modify Position Forecast"
        size="md"
      >
        <ModifyFutureVersion
          positionUuid={positionData?.current.positionUuid ?? ""}
          originalVersionEffectiveAt={positionVersionInEditModeEffectiveAt}
          onClose={() => setPositionVersionInEditModeEffectiveAt(null)}
          onComplete={() => setPositionVersionInEditModeEffectiveAt(null)}
          isForecast={!positionData?.history[0].isActual}
        />
      </Modal>
      <ModifyPositionContainer
        isOpen={modifyPositionModalIsOpen}
        setModal={setModifyPositionModalIsOpen}
        positionUuid={modifyPositionUuid}
        setPositionUuid={setModifyPositionUuid}
        successCallback={() => {
          fetchPositionData();
          fetchPositionRequests();
        }}
      />
      <ForecastTermination
        isOpen={forecastTerminationModalIsOpen}
        setModal={setForecastTerminationModalIsOpen}
        positionUuid={modifyPositionUuid}
        setPositionUuid={setModifyPositionUuid}
        successCallback={fetchPositionData}
      />
      {positionData && (
        <>
          <Header
            title={positionData.current.title}
            breadCrumbs={[
              {
                label: type === "forecast" ? "Future" : "Headcount",
                url:
                  type === "forecast"
                    ? "/headcount?type=forecast"
                    : "/headcount",
              },
            ]}
          >
            {permissionsCheck(role, departmentAccessList) ? (
              <div className="flex gap-3">
                {latestVersionIsActive && !isInitialForecast && (
                  <DropdownButton
                    onClick={() => {
                      setModifyPositionModalIsOpen(true);
                      setModifyPositionUuid(positionData.current.positionUuid);
                    }}
                    label="Forecast Change"
                    options={dropDownOptions(positionData)}
                    id="modify-position-details-button"
                    className="!w-auto"
                  />
                )}
                {ableToDeactivatePosition && (
                  <Button
                    onClick={attemptDeactivatePosition}
                    fill="destructiveOutline"
                    className="!w-auto"
                    id="deactivate-position-button"
                  >
                    Deactivate Position
                  </Button>
                )}
              </div>
            ) : (
              <div>
                <Button
                  onClick={() => {
                    setModifyPositionModalIsOpen(true);
                    setModifyPositionUuid(positionData.current.positionUuid);
                  }}
                  className={`!w-auto ${isInitialForecast ? "hidden" : ""}`}
                  id="request-modification-button"
                >
                  Request Modification
                </Button>
              </div>
            )}
          </Header>
          <div className="w-full h-full bg-green-15 flex flex-row max-sm:flex-col">
            <div className="w-1/3 max-sm:w-full min-w-[365px] sm:max-w-[400px] border-t border-r border-neutral-50 h-full p-6 max-xl:p-4 flex flex-row gap-6 bg-white">
              <div className="w-full p-6 max-sm:py-2">
                <EmployeeDetails
                  positionData={positionData}
                  organizationUuid={organizationUuid}
                  positionUuid={positionUuid}
                  fetchPositionData={fetchPositionData}
                />
                <Divider className="my-6" />
                <CurrentPositionDetails
                  fetchPositionData={fetchPositionData}
                  positionData={positionData}
                />
              </div>
            </div>
            <PositionHistoryList
              positionForecasts={positionForecasts}
              positionHistory={positionHistory}
              fetchPositionData={fetchPositionData}
              positionData={positionData}
              organizationUuid={organizationUuid}
              setPositionVersionInEditModeEffectiveAt={
                setPositionVersionInEditModeEffectiveAt
              }
              requestData={requestData}
              fetchPositionRequests={fetchPositionRequests}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PositionDetails;
