import React from "react";
import Button from "~/components/Button";

interface Props {
  id: string;
  editMode: boolean;
  handleEdit: () => void;
  handleSave: () => void;
  handleCancel: () => void;
}

const UpdatePositionDetailsButtons = ({
  id,
  editMode,
  handleEdit,
  handleSave,
  handleCancel,
}: Props): React.ReactNode =>
  !editMode ? (
    <Button
      fill="clear"
      className="!w-fit !px-0 !py-0"
      onClick={handleEdit}
      id={`edit-${id}-current-position`}
    >
      Edit
    </Button>
  ) : (
    <div className="flex flex-row gap-3">
      <Button
        fill="clear"
        className="!text-red-400 !hover:text-red-600 !w-fit !px-0 !py-0"
        onClick={handleCancel}
      >
        Cancel
      </Button>
      <Button
        id={`save-modification-to-${id}-position`}
        fill="clear"
        className="!w-fit !px-0 !py-0"
        onClick={handleSave}
      >
        Save
      </Button>
    </div>
  );

export default UpdatePositionDetailsButtons;
