import React, { useEffect, useState } from "react";
import Header from "~/components/Header";
import Modal from "~/components/Modal";
import { useSelector } from "react-redux";
import { State } from "~/store";
import request from "~/utils/request";
import { useParams, useNavigate } from "react-router-dom";
import Button from "~/components/Button";
import date from "~/utils/dates/date";
import formatCurrency from "~/utils/formatCurrency";
import Table from "~/components/Table";
import formatPercent from "~/utils/formatPercent";
import Typography from "~/components/Typography";
import permissionsCheck from "~/utils/permissionsCheck";
import ConfirmPrompt from "~/components/ConfirmPrompt";
import toast from "react-hot-toast";
import ManageExpenseModel from "./ManageExpenseModel";
import {
  IExpense,
  ICurrentExpense,
  IChange,
} from "../../types/expenseModel/types";

interface IProps {
  expenseModel: IExpense;
  organizationUuid: string;
}

const ExpenseModelDetails = ({
  expenseModel,
  organizationUuid,
}: IProps): React.ReactNode => {
  const navigate = useNavigate();
  const { expenseUuid } = useParams<{ expenseUuid: string }>();
  const {
    permissions: { role, departmentAccessList },
  } = useSelector((state: State) => state.user);
  const [updateExpenseModalOpen, setUpdateExpenseModalOpen] = useState(false);
  const [expenseDetails, setExpenseDetails] = useState<ICurrentExpense>();
  const [auditLogData, setAuditLogData] = useState<
    {
      id: string;
      values: {
        value: JSX.Element;
      }[];
      expandedContent: JSX.Element | undefined;
    }[]
  >([]);
  const [canBeDeleted, setCanBeDeleted] = useState(false);
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);

  useEffect(() => {
    if (!permissionsCheck(role, departmentAccessList)) navigate("/dashboard");
  }, []);

  const formatExpenseDetails = async (
    expenseModelToFormat: IExpense,
  ): Promise<void> => {
    setExpenseDetails(expenseModelToFormat.current);

    const { canBeDeleted: responseCanBeDeleted } = expenseModelToFormat;
    setCanBeDeleted(responseCanBeDeleted);

    const { auditLog } = expenseModelToFormat;
    const auditLogOutput = auditLog?.reverse().map((log) => {
      const changedBy = log.changedBy ? ` by ${log.changedBy}` : "";
      return {
        id: log.versionUuid,
        values: [
          {
            value: (
              <Typography
                key={`${log.effectiveAt}-change-description`}
                className="whitespace-pre-line"
              >
                {log.changeDescription}
              </Typography>
            ),
          },
          {
            value: (
              <p key={`${log.effectiveAt}`}>
                Changed on{" "}
                {date(log.changedOn).startOf("day").format("MM/DD/YYYY")}{" "}
                {changedBy} <br /> Effective on{" "}
                {date(log.effectiveAt).startOf("day").format("MM/DD/YYYY")}
              </p>
            ),
          },
        ],
        expandedContent: log.changes.length ? (
          <div className="w-full bg-white">
            {log.changes.map((change: IChange) => {
              let changedFrom =
                change.changedFrom === null
                  ? "-"
                  : change.changedFrom?.toString();
              let changedTo =
                change.changedTo === null ? "-" : change.changedTo?.toString();

              if (change.changedFromFormat === "FIXED") {
                changedFrom = formatCurrency(
                  change.changedFrom as number,
                  true,
                );
              } else if (change.changedFromFormat === "PERCENT") {
                changedFrom = formatPercent(
                  (change.changedFrom as number) / 10000,
                );
              }
              if (change.changedToFormat === "FIXED") {
                changedTo = formatCurrency(change.changedTo as number, true);
              } else if (change.changedToFormat === "PERCENT") {
                changedTo = formatPercent((change.changedTo as number) / 10000);
              }

              return (
                <div
                  key={`${log.versionUuid}-${change.label}`}
                  className="flex gap-3 justify-start items-center border-b border-green-25 py-3 px-6"
                >
                  <div className="capitalize pr-5">{change.label}</div>
                  <div className="capitalize font-bold">{changedFrom}</div>
                  <div>{` changed to `}</div>
                  <div className="capitalize font-bold rounded px-3 py-1 bg-gray-100">
                    {changedTo}
                  </div>
                </div>
              );
            })}
          </div>
        ) : undefined,
      };
    });
    if (auditLogOutput) setAuditLogData(auditLogOutput);
  };

  const deleteExpenseModel = async (): Promise<void> => {
    const deleteExpenseModelResponse = await request({
      url: `/organizations/${organizationUuid}/expense-models/${expenseUuid}`,
      method: "DELETE",
    });

    if (deleteExpenseModelResponse.status !== 204) {
      throw new Error("Failed to delete expense model");
    }
    toast.success("Expense model deleted");
    setDisplayDeleteModal(false);
    navigate("/expense-models");
  };

  useEffect(() => {
    formatExpenseDetails(expenseModel);
  }, [updateExpenseModalOpen, expenseModel]);

  return (
    <>
      <ConfirmPrompt
        isOpen={displayDeleteModal}
        onClose={() => setDisplayDeleteModal(false)}
        title="Delete Model Confirmation"
        message="Are you sure you want to delete this model? This action cannot be undone."
        confirmButtonText="Delete Model"
        onConfirm={deleteExpenseModel}
      />
      <Header
        title={expenseDetails?.name ?? "Details"}
        breadCrumbs={[{ label: "Expense Models", url: "/expense-models" }]}
      >
        <div className="flex gap-3">
          {canBeDeleted && (
            <Button
              className="!w-auto"
              fill="destructiveOutline"
              id="delete-expense-model-button"
              onClick={() => setDisplayDeleteModal(true)}
            >
              Delete Expense Model
            </Button>
          )}
          <Button
            className="!w-auto"
            onClick={() => setUpdateExpenseModalOpen(true)}
          >
            Edit Expense Model
          </Button>
        </div>
      </Header>
      <div className="container flex flex-col lg:max-w-2xl pt-10">
        <h2 className="text-xl text-gray-900 font-semibold mb-4">
          Expense Change History
        </h2>
        <Table
          id="forecasted-changes-table"
          headers={["Event", "", ""]}
          headerClassName="px-6 py-4"
          columnAlignment={["left", "right"]}
          data={auditLogData}
        />
      </div>
      <Modal
        size="lg"
        isOpen={updateExpenseModalOpen}
        onClose={() => setUpdateExpenseModalOpen(false)}
      >
        <ManageExpenseModel
          expenseModelToUpdate={expenseUuid}
          onSave={() => {
            setUpdateExpenseModalOpen(false);
          }}
        />
      </Modal>
    </>
  );
};

export default ExpenseModelDetails;
