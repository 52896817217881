import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MathNode } from "mathjs";
import useDrawer from "~/components/Drawer/useDrawer";
import request from "~/utils/request";
import { State } from "~/store";
import isValidUuid from "~/utils/isValidUuid";

export interface IUseDrilldownDrawerData {
  isLoading: boolean;
  title: string;
  subtitle: string;
  startDate: string;
  endDate: string;
  departmentUuids: string[];
  category: string;
  subCategory?: string;
  expenseModelUuid?: string;
  data?: IDrilldownDetailsData[];
}

export interface ICompensation {
  total: number;
  expressionTree: MathNode;
  calculationValues: {
    conversionRate: number;
    activeDays: number;
    totalDaysInMonth: number;
    monthsInYear: number;
    annualSalary?: number;
    hourlyRate?: number;
    hoursPerWeek?: number;
    weeksPerYear?: number;
    annualBonus?: number;
    attainmentRate?: number;
    annualCommission?: number;
  };
}

export interface IDrilldownDetailsData {
  drilldownTitle?: string;
  drilldownData: IDrilldownDetails[];
  drilldownHeaders?: string[];
}

export interface IDrilldownDetails {
  employeeName?: string;
  employeeNumber?: string;
  impact: number;
  positions: IDrilldownPosition[];
  calculations?: ICompCalcDrilldownDetails[];
}

export interface IDrilldownPosition {
  title: string;
  employmentType: string;
  positionUuid: string;
  effectiveAt?: string;
  impact?: number;
  departmentUuid?: string;
  departmentName?: string;
  currency?: string;
}

export interface ICompCalcDrilldownDetails {
  startDate: string;
  endDate: string;
  positionTitle: string;
  positionVersionUuid: string;
  expressionDetails: ICompensation;
}

export interface IUseDrilldownDrawer {
  open: (params: {
    title: string;
    subCategory?: string;
    subtitle: string;
    startDate: string;
    endDate: string;
    departmentUuids: string[];
    category: string;
    expenseModelUuid?: string;
  }) => Promise<void>;
  isOpen: boolean;
  onClose: () => void;
  data?: IUseDrilldownDrawerData;
  modalOpen: boolean;
  toggleModalOpen: (value: boolean) => void;
}

const getCorrespondingCategory = (category: string): string => {
  switch (category) {
    case "Salaries":
      return "salary";
    case "Bonuses":
      return "bonus";
    case "Commissions":
      return "commission";
    case "Headcount":
      return "headcount";
    case "Expense":
      return "expenseModel";
    case "Employee Bridge":
      return "employeeBridge";
    default:
      return "expenseModel";
  }
};

export default (): IUseDrilldownDrawer => {
  const { isOpen, onClose, openDrawer, setCloseOnEscape } = useDrawer();
  const [useDrilldownDrawerState, setUseDrilldownDrawerState] = useState<
    IUseDrilldownDrawerData | undefined
  >();
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const toggleModalOpen = (value: boolean): void => {
    setCloseOnEscape(!value);
    setModalOpen(value);
  };

  const closeDrilldownDrawer = (): void => {
    if (!modalOpen) {
      onClose();
      setUseDrilldownDrawerState(undefined);
    }
  };

  const updateData = async (): Promise<void> => {
    const {
      startDate,
      endDate,
      departmentUuids,
      category,
      expenseModelUuid,
      subCategory,
    } = useDrilldownDrawerState ?? {};
    if (!startDate || !endDate || !departmentUuids || !category)
      throw new Error("Missing required data");

    const getForecastDetailsResponse = (await request({
      url: `/organizations/${organizationUuid}/projections/drilldown-details`,
      method: "GET",
      params: {
        startDate,
        endDate,
        departments: departmentUuids,
        type: getCorrespondingCategory(
          isValidUuid(expenseModelUuid) ? "Expense" : category,
        ),
        subCategory,
        expenseModelUuid,
      },
    })) as {
      status: number;
      data: {
        data: IDrilldownDetailsData[];
      };
    };

    if (getForecastDetailsResponse.status >= 400)
      throw new Error("Failed to fetch data");

    setUseDrilldownDrawerState((prevState) => {
      if (prevState) {
        return {
          ...prevState,
          isLoading: false,
          data: getForecastDetailsResponse.data.data,
        };
      }
      return prevState;
    });
  };

  const openDrilldownDrawer = async (params: {
    title: string;
    subCategory?: string;
    subtitle: string;
    startDate: string;
    endDate: string;
    departmentUuids: string[];
    category: string;
  }): Promise<void> => {
    setUseDrilldownDrawerState((prevState) => ({
      ...prevState,
      ...params,
      isLoading: true,
      data: [],
    }));
    openDrawer();
  };

  useEffect(() => {
    const { startDate, endDate, departmentUuids, category } =
      useDrilldownDrawerState ?? {};
    if (startDate && endDate && departmentUuids && category) {
      updateData();
    }
  }, [
    useDrilldownDrawerState?.category,
    useDrilldownDrawerState?.departmentUuids,
    useDrilldownDrawerState?.startDate,
    useDrilldownDrawerState?.endDate,
  ]);

  return {
    open: openDrilldownDrawer,
    isOpen,
    onClose: closeDrilldownDrawer,
    data: useDrilldownDrawerState,
    modalOpen: modalOpen,
    toggleModalOpen,
  };
};
