const currencySymbols: Record<string, string> = {
  XUA: "XUA", // ADB Unit of Account
  AFN: "؋", // Afghan Afghani
  AFA: "AFA", // Old Afghan Afghani (obsolete)
  ALL: "L", // Albanian Lek
  ALK: "ALK", // Old Albanian Lek (obsolete)
  DZD: "د.ج", // Algerian Dinar
  ADP: "ADP", // Andorran Peseta (obsolete)
  AOA: "Kz", // Angolan Kwanza
  AOK: "AOK", // Angolan Kwanza (obsolete)
  AON: "AON", // Angolan New Kwanza (obsolete)
  AOR: "AOR", // Angolan Readjusted Kwanza (obsolete)
  ARA: "₳", // Argentine Austral (obsolete)
  ARS: "$", // Argentine Peso
  ARM: "ARM", // Argentine Peso Moneda Nacional (obsolete)
  ARP: "ARP", // Argentine Peso (obsolete)
  ARL: "ARL", // Argentine Peso Ley (obsolete)
  AMD: "֏", // Armenian Dram
  AWG: "ƒ", // Aruban Florin
  AUD: "$", // Australian Dollar
  ATS: "ATS", // Austrian Schilling (obsolete)
  AZN: "₼", // Azerbaijani Manat
  AZM: "AZM", // Azerbaijani Manat (obsolete)
  BSD: "$", // Bahamian Dollar
  BHD: ".د.ب", // Bahraini Dinar
  BDT: "৳", // Bangladeshi Taka
  BBD: "$", // Barbadian Dollar
  BYN: "Br", // Belarusian Ruble
  BYB: "BYB", // Belarusian Ruble (obsolete)
  BYR: "BYR", // Belarusian Ruble (obsolete)
  BEF: "BEF", // Belgian Franc (obsolete)
  BEC: "BEC", // Belgian Franc (convertible) (obsolete)
  BEL: "BEL", // Belgian Franc (financial) (obsolete)
  BZD: "BZ$", // Belize Dollar
  BMD: "$", // Bermudian Dollar
  BTN: "Nu.", // Bhutanese Ngultrum
  BOB: "Bs.", // Bolivian Boliviano
  BOL: "BOL", // Bolivian Boliviano (old, obsolete)
  BOV: "BOV", // Bolivian Mvdol
  BOP: "BOP", // Bolivian Peso (obsolete)
  BAM: "KM", // Bosnia and Herzegovina Convertible Mark
  BAD: "BAD", // Bosnian Dinar (obsolete)
  BAN: "BAN", // New Bosnian Dinar (obsolete)
  BWP: "P", // Botswana Pula
  BRC: "BRC", // Brazilian Cruzado (obsolete)
  BRZ: "BRZ", // Brazilian Cruzeiro (old, obsolete)
  BRE: "BRE", // Brazilian Cruzeiro (obsolete)
  BRR: "BRR", // Brazilian Cruzeiro Real (obsolete)
  BRN: "BRN", // Brazilian New Cruzado (obsolete)
  BRB: "BRB", // Brazilian New Cruzeiro (obsolete)
  BRL: "R$", // Brazilian Real
  GBP: "£", // British Pound
  BND: "$", // Brunei Dollar
  BGL: "BGL", // Bulgarian Lev (old, obsolete)
  BGN: "лв", // Bulgarian Lev
  BGO: "BGO", // Bulgarian Lev (old, obsolete)
  BGM: "BGM", // Bulgarian Socialist Lev (obsolete)
  BUK: "BUK", // Burmese Kyat (obsolete)
  BIF: "FBu", // Burundian Franc
  XPF: "₣", // CFP Franc
  KHR: "៛", // Cambodian Riel
  CAD: "$", // Canadian Dollar
  CVE: "Esc", // Cape Verdean Escudo
  KYD: "$", // Cayman Islands Dollar
  XAF: "FCFA", // Central African CFA Franc
  CLE: "CLE", // Chilean Escudo (obsolete)
  CLP: "$", // Chilean Peso
  CLF: "CLF", // Chilean Unidad de Fomento
  CNX: "CNX", // Chinese People's Bank Dollar (obsolete)
  CNY: "¥", // Chinese Yuan
  CNH: "¥", // Chinese Yuan (offshore)
  COP: "$", // Colombian Peso
  COU: "COU", // Colombian Real Value Unit
  KMF: "CF", // Comorian Franc
  CDF: "FC", // Congolese Franc
  CRC: "₡", // Costa Rican Colón
  HRD: "HRD", // Croatian Dinar (obsolete)
  HRK: "kn", // Croatian Kuna
  CUC: "$", // Cuban Convertible Peso
  CUP: "₱", // Cuban Peso
  CYP: "£", // Cypriot Pound (obsolete)
  CZK: "Kč", // Czech Koruna
  CSK: "Kčs", // Czechoslovak Koruna (obsolete)
  DKK: "kr", // Danish Krone
  DJF: "Fdj", // Djiboutian Franc
  DOP: "RD$", // Dominican Peso
  NLG: "ƒ", // Dutch Guilder (obsolete)
  XCD: "$", // East Caribbean Dollar
  DDM: "M", // East German Mark (obsolete)
  ECS: "S/.", // Ecuadorian Sucre (obsolete)
  ECV: "ECV", // Ecuadorian Unidad de Valor Constante (funds code) (obsolete)
  EGP: "£", // Egyptian Pound
  GQE: "GQE", // Equatorial Guinean Ekwele (obsolete)
  ERN: "Nfk", // Eritrean Nakfa
  EEK: "kr", // Estonian Kroon (obsolete)
  ETB: "Br", // Ethiopian Birr
  EUR: "€", // Euro
  XBA: "XBA", // European Composite Unit (EURCO) (bond market unit)
  XEU: "XEU", // European Currency Unit (ECU) (obsolete)
  XBB: "XBB", // European Monetary Unit (E.M.U.-6) (bond market unit)
  XBC: "XBC", // European Unit of Account 9 (E.U.A.-9) (bond market unit)
  XBD: "XBD", // European Unit of Account 17 (E.U.A.-17) (bond market unit)
  FKP: "£", // Falkland Islands Pound
  FJD: "$", // Fijian Dollar
  FIM: "mk", // Finnish Markka (obsolete)
  FRF: "₣", // French Franc (obsolete)
  XFO: "XFO", // French Gold Franc (obsolete)
  XFU: "XFU", // UIC-Franc (special settlement currency) (obsolete)
  GMD: "D", // Gambian Dalasi
  GEK: "GEK", // Georgian Kupon Larit (obsolete)
  GEL: "₾", // Georgian Lari
  DEM: "DM", // German Mark (obsolete)
  GHS: "GH₵", // Ghanaian Cedi
  GHC: "¢", // Ghanaian Cedi (old, obsolete)
  GIP: "£", // Gibraltar Pound
  XAU: "XAU", // Gold (one troy ounce)
  GRD: "₯", // Greek Drachma (obsolete)
  GTQ: "Q", // Guatemalan Quetzal
  GWP: "GWP", // Guinea-Bissau Peso (obsolete)
  GNF: "Fr", // Guinean Franc
  GNS: "GNS", // Guinean Syli (obsolete)
  GYD: "$", // Guyanese Dollar
  HTG: "G", // Haitian Gourde
  HNL: "L", // Honduran Lempira
  HKD: "$", // Hong Kong Dollar
  HUF: "Ft", // Hungarian Forint
  IMP: "£", // Isle of Man Pound
  ISK: "kr", // Icelandic Króna
  ISJ: "ISJ", // Old Icelandic Króna (obsolete)
  INR: "₹", // Indian Rupee
  IDR: "Rp", // Indonesian Rupiah
  IRR: "﷼", // Iranian Rial
  IQD: "ع.د", // Iraqi Dinar
  IEP: "£", // Irish Pound (obsolete)
  ILS: "₪", // Israeli New Shekel
  ILP: "£", // Israeli Pound (obsolete)
  ILR: "ILR", // Israeli Shekel (old, obsolete)
  ITL: "₤", // Italian Lira (obsolete)
  JMD: "J$", // Jamaican Dollar
  JPY: "¥", // Japanese Yen
  JOD: "د.ا", // Jordanian Dinar
  KZT: "₸", // Kazakhstani Tenge
  KES: "Sh", // Kenyan Shilling
  KWD: "د.ك", // Kuwaiti Dinar
  KGS: "лв", // Kyrgyzstani Som
  LAK: "₭", // Laotian Kip
  LVL: "Ls", // Latvian Lats (obsolete)
  LVR: "LVR", // Latvian Ruble (obsolete)
  LBP: "ل.ل", // Lebanese Pound
  LSL: "L", // Lesotho Loti
  LRD: "$", // Liberian Dollar
  LYD: "ل.د", // Libyan Dinar
  LTL: "Lt", // Lithuanian Litas (obsolete)
  LTT: "LTT", // Lithuanian Talonas (obsolete)
  LUL: "LUL", // Luxembourg Financial Franc (obsolete)
  LUC: "LUC", // Luxembourg Convertible Franc (obsolete)
  LUF: "F", // Luxembourg Franc (obsolete)
  MOP: "MOP$", // Macanese Pataca
  MKD: "ден", // Macedonian Denar
  MKN: "MKN", // Old Macedonian Denar (obsolete)
  MGA: "Ar", // Malagasy Ariary
  MGF: "MGF", // Malagasy Franc (obsolete)
  MWK: "MK", // Malawian Kwacha
  MYR: "RM", // Malaysian Ringgit
  MVR: "Rf", // Maldivian Rufiyaa
  MVP: "MVP", // Maldivian Rupee (obsolete)
  MLF: "MLF", // Malian Franc (obsolete)
  MTL: "₤", // Maltese Lira (obsolete)
  MTP: "MTP", // Maltese Pound (obsolete)
  MRU: "UM", // Mauritanian Ouguiya
  MRO: "UM", // Mauritanian Ouguiya (obsolete)
  MUR: "₨", // Mauritian Rupee
  MXV: "MXV", // Mexican Investment Unit
  MXN: "$", // Mexican Peso
  MXP: "MXP", // Mexican Silver Peso (obsolete)
  MDC: "MDC", // Moldovan Cupon (obsolete)
  MDL: "L", // Moldovan Leu
  MCF: "MCF", // Monegasque Franc (obsolete)
  MNT: "₮", // Mongolian Tugrik
  MAD: "د.م.", // Moroccan Dirham
  MAF: "MAF", // Moroccan Franc (obsolete)
  MZE: "MZE", // Mozambican Escudo (obsolete)
  MZN: "MT", // Mozambican Metical
  MZM: "MTn", // Old Mozambican Metical (obsolete)
  MMK: "K", // Myanma Kyat
  NAD: "$", // Namibian Dollar
  NPR: "₨", // Nepalese Rupee
  ANG: "ƒ", // Netherlands Antillean Guilder
  TWD: "$", // New Taiwan Dollar
  NZD: "$", // New Zealand Dollar
  NIO: "C$", // Nicaraguan Córdoba
  NIC: "NIC", // Nicaraguan Córdoba (obsolete)
  NGN: "₦", // Nigerian Naira
  KPW: "₩", // North Korean Won
  NOK: "kr", // Norwegian Krone
  OMR: "ر.ع.", // Omani Rial
  PKR: "₨", // Pakistani Rupee
  XPD: "XPD", // Palladium (one troy ounce)
  PAB: "B/.", // Panamanian Balboa
  PGK: "K", // Papua New Guinean Kina
  PYG: "₲", // Paraguayan Guarani
  PEI: "I/.", // Peruvian Inti (obsolete)
  PEN: "S/.", // Peruvian Sol
  PES: "PES", // Peruvian Sol (old, obsolete)
  PHP: "₱", // Philippine Peso
  XPT: "XPT", // Platinum (one troy ounce)
  PLN: "zł", // Polish Złoty
  PLZ: "PLZ", // Polish Zloty (obsolete)
  PTE: "Esc", // Portuguese Escudo (obsolete)
  GWE: "GWE", // Portuguese Guinea Escudo (obsolete)
  QAR: "ر.ق", // Qatari Riyal
  XRE: "XRE", // RINET Funds Code
  RHD: "RHD", // Rhodesian Dollar (obsolete)
  RON: "lei", // Romanian Leu
  ROL: "ROL", // Old Romanian Leu (obsolete)
  RUB: "₽", // Russian Ruble
  RUR: "р.", // Russian Ruble (old, obsolete)
  RWF: "FRw", // Rwandan Franc
  SVC: "₡", // Salvadoran Colón (obsolete)
  WST: "WS$", // Samoan Tala
  SAR: "ر.س", // Saudi Riyal
  RSD: "дин.", // Serbian Dinar
  CSD: "CSD", // Old Serbian Dinar (obsolete)
  SCR: "₨", // Seychellois Rupee
  SLL: "Le", // Sierra Leonean Leone
  XAG: "XAG", // Silver (one troy ounce)
  SGD: "$", // Singapore Dollar
  SKK: "Sk", // Slovak Koruna (obsolete)
  SIT: "SIT", // Slovenian Tolar (obsolete)
  SBD: "$", // Solomon Islands Dollar
  SOS: "Sh.So.", // Somali Shilling
  ZAR: "R", // South African Rand
  ZAL: "ZAL", // South African Rand (financial)
  KRH: "KRH", // South Korean Hwan (obsolete)
  KRW: "₩", // South Korean Won
  KRO: "KRO", // Old South Korean Won (obsolete)
  SSP: "£", // South Sudanese Pound
  SUR: "SUR", // Soviet Rouble (obsolete)
  ESP: "₧", // Spanish Peseta (obsolete)
  ESA: "ESA", // Spanish Peseta (account A) (obsolete)
  ESB: "ESB", // Spanish Peseta (account B) (obsolete)
  XDR: "XDR", // Special Drawing Rights
  LKR: "₨", // Sri Lankan Rupee
  SHP: "£", // Saint Helena Pound
  XSU: "XSU", // Sucre
  SDD: "LSd", // Sudanese Dinar (obsolete)
  SDG: "ج.س.", // Sudanese Pound
  SDP: "SDP", // Old Sudanese Pound (obsolete)
  SRD: "$", // Surinamese Dollar
  SRG: "ƒ", // Surinamese Guilder (obsolete)
  SZL: "L", // Swazi Lilangeni
  SEK: "kr", // Swedish Krona
  CHF: "CHF", // Swiss Franc
  SYP: "£", // Syrian Pound
  STN: "Db", // São Tomé and Príncipe Dobra (new)
  STD: "Db", // São Tomé and Príncipe Dobra (old, obsolete)
  TVD: "TVD", // Tuvaluan Dollar
  TJR: "TJR", // Tajikistani Ruble (obsolete)
  TJS: "ЅМ", // Tajikistani Somoni
  TZS: "Sh", // Tanzanian Shilling
  XTS: "XTS", // Code reserved for testing
  THB: "฿", // Thai Baht
  XXX: "XXX", // No currency
  TPE: "TPE", // Timorese Escudo (obsolete)
  TOP: "T$", // Tongan Paʻanga
  TTD: "TT$", // Trinidad and Tobago Dollar
  TND: "د.ت", // Tunisian Dinar
  TRY: "₺", // Turkish Lira
  TRL: "₤", // Old Turkish Lira (obsolete)
  TMT: "m", // Turkmenistani Manat
  TMM: "TMM", // Old Turkmenistani Manat (obsolete)
  USD: "$", // United States Dollar
  USN: "USN", // US Dollar (Next day)
  USS: "USS", // US Dollar (Same day)
  UGX: "USh", // Ugandan Shilling
  UGS: "UGS", // Ugandan Shilling (obsolete)
  UAH: "₴", // Ukrainian Hryvnia
  UAK: "UAK", // Ukrainian Karbovanets (obsolete)
  AED: "د.إ", // United Arab Emirates Dirham
  UYW: "UYW", // Uruguayan Nominal Wage Index Unit
  UYU: "$U", // Uruguayan Peso
  UYP: "UYP", // Uruguayan Peso (old, obsolete)
  UYI: "UYI", // Uruguay Peso en Unidades Indexadas
  UZS: "so'm", // Uzbekistani Som
  VUV: "Vt", // Vanuatu Vatu
  VES: "Bs.S", // Venezuelan Bolívar Soberano
  VEB: "Bs", // Venezuelan Bolívar (old, obsolete)
  VEF: "Bs.F", // Venezuelan Bolívar Fuerte (obsolete)
  VND: "₫", // Vietnamese Dong
  VNN: "VNN", // Vietnamese Dong (old, obsolete)
  CHE: "CHE", // WIR Euro (complementary currency)
  CHW: "CHW", // WIR Franc (complementary currency)
  XOF: "CFA", // West African CFA Franc
  YDD: "YDD", // Yemeni Dinar (obsolete)
  YER: "﷼", // Yemeni Rial
  YUN: "YUN", // Yugoslavian Convertible Dinar (obsolete)
  YUD: "YUD", // Yugoslavian Hard Dinar (obsolete)
  YUM: "YUM", // Yugoslavian New Dinar (obsolete)
  YUR: "YUR", // Yugoslavian Reformed Dinar (obsolete)
  ZWN: "ZWN", // Zimbabwean Dollar (new, obsolete)
  ZRN: "ZRN", // Zairean New Zaire (obsolete)
  ZRZ: "ZRZ", // Zairean Zaire (obsolete)
  ZMW: "ZK", // Zambian Kwacha
  ZMK: "ZK", // Zambian Kwacha (old, obsolete)
  ZWD: "Z$", // Zimbabwean Dollar (old, obsolete)
  ZWR: "ZWR", // Zimbabwean Dollar (second, obsolete)
  ZWL: "ZWL", // Zimbabwean Dollar (third, obsolete)
};

export default currencySymbols;
