import React from "react";
import { SelectState } from "~/components/Select/Select.types";
import Input from "~/components/Input";
import Select from "~/components/Select";
import currencySymbols from "~/utils/currencySymbols";
import Typography from "~/components/Typography";

interface CurrencyInputProps {
  id?: string;
  paymentAmountState: Types.InputState;
  currencyTypeState: SelectState;
  setPaymentAmountState: React.Dispatch<React.SetStateAction<Types.InputState>>;
  setCurrencyTypeState: React.Dispatch<React.SetStateAction<SelectState>>;
  label: string;
  hideCurrencyType?: boolean;
  required?: boolean;
}

const getCurrencySymbol = (currencyType: string): string =>
  currencySymbols[currencyType] || "$";

const CurrencyInput: React.FC<CurrencyInputProps> = ({
  id,
  paymentAmountState,
  setPaymentAmountState,
  currencyTypeState,
  setCurrencyTypeState,
  label,
  hideCurrencyType = false,
  required = false,
}) => {
  const currencySymbol = getCurrencySymbol(currencyTypeState.selected?.value);

  const isThreeLetterCurrency = currencySymbol.length === 3;

  return (
    <label className="flex flex-col justify-start w-full">
      <div className="flex flex-row">
        <Typography
          size="xs"
          color={
            currencyTypeState.disabled || paymentAmountState.disabled
              ? "disabled"
              : "primary"
          }
          className="mb-1"
        >
          {label}
        </Typography>
        {required && (
          <Typography
            tag="span"
            size="2xs"
            color={
              currencyTypeState.disabled || paymentAmountState.disabled
                ? "disabled"
                : "primary"
            }
          >
            *
          </Typography>
        )}
      </div>
      <div className="relative">
        <div
          className={`h-[42px] z-10 absolute flex justify-center p-2 bg-neutral-25 border border-solid border-gray-300 rounded-l shadow-sm text-neutral-200 ${
            currencyTypeState.disabled
              ? "bg-neutral-25 text-neutral-100"
              : "bg-gray-15"
          }`}
        >
          {currencySymbol}
        </div>
        <Input
          id={id ? `${id}-currency` : "currency"}
          type="currency"
          className={`w-full ${isThreeLetterCurrency ? "pl-[60px]" : "pl-10"}`}
          state={paymentAmountState}
          setState={setPaymentAmountState}
        />
        {!hideCurrencyType && (
          <Select
            id="currencyTypeSelect"
            className="!rounded-l-none rounded !bg-neutral-25 absolute z-10 !w-[100px] right-0 top-0"
            state={currencyTypeState}
            setState={setCurrencyTypeState}
            checkmark={false}
          />
        )}
      </div>
    </label>
  );
};

export default CurrencyInput;
