import React from "react";
import Header from "~/components/Header";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import utc from "dayjs/plugin/utc";
import Select from "~/components/Select";
import "dayjs/locale/en";
import Typography from "~/components/Typography";
import SelectDepartment from "~/components/SelectDepartment";
import DateRange from "~/components/DateRange";
import HeadcountCard from "./HeadcountCard";
import BudgetCard from "./BudgetCard";
import FutureHeadcountCard from "./FutureHeadcountCard";
import FirstTimeLogin from "../Onboarding/FirstTimeLogin";
import TasksCard from "./TasksCard";
import AnalyticsCard from "./AnalyticsCard";
import MetricWarning from "~/components/MetricWarning";
import {
  DashboardContext,
  DashboardProvider,
} from "./context/DashboardContext";

dayjs.extend(quarterOfYear);
dayjs.extend(utc);

const DashboardContainer = (): React.ReactNode => {
  const dashboardContext = React.useContext(DashboardContext);
  if (!dashboardContext) {
    throw new Error("Dashboard page missing data to provide context");
  }
  const {
    snapshotPeriodLabel,
    firstName,
    periodDates,
    setPeriodDates,
    planOptions,
    setPlanOptions,
    departmentOptions,
    setDepartmentOptions,
    firstTimeLoginModal,
    setFirstTimeLoginModal,
    total,
  } = dashboardContext;

  return (
    <div className="w-full">
      <FirstTimeLogin
        isOpen={firstTimeLoginModal ?? false}
        setModal={() => setFirstTimeLoginModal(null)}
      />
      <Header title="Dashboard">
        <div className="flex gap-5">
          <SelectDepartment
            departmentOptions={departmentOptions}
            setDepartmentOptions={setDepartmentOptions}
            allowSelectAll
            className="!w-[250px]"
            listBoxClassName="!max-w-[400px] !w-full"
          />
          <Select
            id="select-plan"
            className="!w-[200px]"
            state={planOptions}
            placeholder="No Locked Plans"
            setState={setPlanOptions}
            disabled={planOptions.options.length === 0}
          />
          <DateRange state={periodDates} setState={setPeriodDates} />
        </div>
      </Header>
      <div className="container py-11 px-5 2xl:px-0">
        <Typography id="headline-intro" tag="h2" size="xl" weight="bold">
          Welcome{firstName ? ` ${firstName}` : ""}
        </Typography>
        <Typography tag="p" size="md" color="secondary" className="mt-2">
          {`Here is a snapshot of your headcount for `}
          <span className="font-bold" data-testid="headline-current-date">
            {snapshotPeriodLabel}
          </span>
        </Typography>
        {total > 0 && <MetricWarning />}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
          <HeadcountCard />
          <BudgetCard linkToVariances />
          <FutureHeadcountCard />
          <TasksCard />
        </div>
        <div className="mt-10">
          <AnalyticsCard />
        </div>
      </div>
    </div>
  );
};

const Dashboard = (): React.ReactNode => (
  <DashboardProvider>
    <DashboardContainer />
  </DashboardProvider>
);

export default Dashboard;
