import Modal from "~/components/Modal";
import React from "react";
import Button from "~/components/Button";
import Typography from "~/components/Typography";
import { IForecastRequest } from "~/components/Tasks";
import Divider from "~/components/Divider";
import formatCurrency from "~/utils/formatCurrency";
import date from "~/utils/dates/date";
import { EmploymentTypes } from "~/utils/enums";
import { Position } from "pages/Headcount/headcount.types";

interface Props {
  isOpen: boolean;
  setModal: () => void;
  approveFunction: () => void;
  rejectFunction: () => void;
  position: Position;
  request: IForecastRequest;
  createdAt: string;
  isAdmin?: boolean;
}

export const LABEL_MAP: Record<string, string> = {
  title: "Title",
  manager: "Manager",
  group: "Department",
  employmentType: "Employment Type",
  compensationRate: "Compensation Rate",
  commission: "Commission",
  bonus: "Bonus",
  attainment: "Attainment",
  expectedWeeklyHours: "Expected Weekly Hours",
  paymentUnit: "Payment Unit",
  currency: "Currency",
};

export const EXCLUDED_KEYS_FROM_CHANGE_LIST = [
  "requestorUuid",
  "requestor",
  "rejectionReason",
  "positionUuid",
  "effectiveAt",
  "status",
  "changeDescription",
  "deletedAt",
  "deletedBy",
  "managerUuid",
  "groupUuid",
  "currency",
];

export const selectColor = (status: string): string => {
  switch (status) {
    case "APPROVED":
      return "green-400";
    case "REJECTED":
      return "red-500";
    case "PENDING":
      return "neutral-200";
    default:
      return "neutral-200";
  }
};

const Changes = ({
  requestData,
  positionData,
}: {
  requestData: IForecastRequest;
  positionData: Position;
}): React.ReactNode => (
  <div className="flex flex-col gap-2">
    <Typography size="sm" weight="semibold">
      Request Changes
    </Typography>
    {Object.keys(requestData).map((requestKey) => {
      if (EXCLUDED_KEYS_FROM_CHANGE_LIST.includes(requestKey)) return null;
      let value = requestData[requestKey as keyof IForecastRequest];
      let label = LABEL_MAP[requestKey] ?? requestKey;
      if (value instanceof Date) {
        value = value.toLocaleString(); // or any other formatting method
      }
      if (
        requestKey === "compensationRate" ||
        requestKey === "bonus" ||
        requestKey === "commission" ||
        requestKey === "currency"
      ) {
        const currency = requestData.currency ?? positionData.current.currency;
        const numberValue = requestData[
          requestKey as keyof IForecastRequest
        ] as number;

        value = formatCurrency(
          numberValue,
          positionData.current.paymentUnit === "HOURLY" ? true : false,
          currency,
        );
        label = "Salary";
      }
      return (
        <div className="flex flex-col" key={requestKey}>
          <Typography size="xs" color="secondary">
            {label}
          </Typography>
          <Typography size="sm">{value}</Typography>
        </div>
      );
    })}
    <div className="flex flex-col">
      <Typography size="xs" color="secondary">
        Change Reason
      </Typography>
      <Typography size="xs" className="italic">
        {`"${requestData.changeDescription}"`}
      </Typography>
    </div>
  </div>
);

const ViewForecastRequest = ({
  isOpen,
  setModal,
  approveFunction,
  rejectFunction,
  position,
  request,
  createdAt,
  isAdmin,
}: Props): React.ReactNode => (
  <Modal title="Requested Modification" isOpen={isOpen} size="sm">
    <div
      data-testid="view-modification-request-modal"
      className="w-full flex flex-col gap-4"
    >
      <div className="flex flex-row gap-6">
        <div className="flex flex-col">
          <Typography color="secondary" size="xs">
            Requested by
          </Typography>
          <Typography size="sm">{request.requestor}</Typography>
        </div>
        <div className="flex flex-col">
          <Typography color="secondary" size="xs">
            Date
          </Typography>
          <Typography size="sm">
            {date(createdAt).format("MM/DD/YYYY")}
          </Typography>
        </div>
        <div className="flex flex-col">
          <Typography color="secondary" size="xs">
            Status
          </Typography>
          <Typography
            size="sm"
            className={`text-${selectColor(request.status)}`}
          >
            {request.status}
          </Typography>
        </div>
      </div>
      <div>
        <Divider />
      </div>
      <div className="w-full grid grid-cols-2 gap-4">
        <div className="col-span-2">
          <Typography size="lg" weight="semibold">
            Current Position
          </Typography>
        </div>
        <div className="flex flex-col gap-2">
          <Typography size="sm" weight="semibold">
            Role Details
          </Typography>
          <div className="flex flex-col">
            <Typography size="xs" color="empty">
              Job Title
            </Typography>
            <Typography size="sm">{position.current.title}</Typography>
          </div>
          <div className="flex flex-col">
            <Typography size="xs" color="empty">
              Manager
            </Typography>
            <Typography size="sm">
              {position.currentManager
                ? `${position.currentEmployee?.firstName} ${position.currentEmployee?.lastName}`
                : "-"}
            </Typography>
          </div>
          <div className="flex flex-col">
            <Typography size="xs" color="empty">
              Department
            </Typography>
            <Typography size="sm">
              {position.currentDepartment?.name ?? "-"}
            </Typography>
          </div>
          <div className="flex flex-col">
            <Typography size="xs" color="empty">
              Hire Date
            </Typography>
            <Typography size="sm">
              {date(position.current.effectiveAt).format("MM/DD/YYYY")}
            </Typography>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <Typography size="sm" weight="semibold">
            Compensation
          </Typography>
          <div className="flex flex-col">
            <Typography size="xs" color="empty">
              Employment Type
            </Typography>
            <Typography size="sm">
              {
                EmploymentTypes[
                  position.current
                    .employmentType as keyof typeof EmploymentTypes
                ]
              }
            </Typography>
          </div>
          <div className="flex flex-col">
            <Typography size="xs" color="empty">
              Payment Type
            </Typography>
            <Typography size="sm">
              {position.current.paymentUnit === "HOURLY" ? "Hourly" : "Salary"}
            </Typography>
          </div>
          {position.current.expectedWeeklyHours && (
            <div className="flex flex-col">
              <Typography size="xs" color="empty">
                Expected Weekly Hours
              </Typography>
              <Typography size="sm">
                {position.current.expectedWeeklyHours}
              </Typography>
            </div>
          )}
          <div className="flex flex-col">
            <Typography size="xs" color="empty">
              Salary
            </Typography>
            <Typography size="sm">
              {formatCurrency(
                position.current.compensationRate.toString(),
                position.current.paymentUnit === "HOURLY" ? true : false,
                position.current.currency || undefined,
              )}
            </Typography>
          </div>
          <div className="flex flex-col">
            <Typography size="xs" color="empty">
              Bonus
            </Typography>
            <Typography size="sm">
              {position.current.bonus
                ? formatCurrency(
                    position.current.bonus,
                    false,
                    position.current.currency || undefined,
                  )
                : "-"}
            </Typography>
          </div>
          <div className="flex flex-col">
            <Typography size="xs" color="empty">
              Commission
            </Typography>
            <Typography size="sm">
              {position.current.commission
                ? formatCurrency(
                    position.current.commission,
                    false,
                    position.current.currency || undefined,
                  )
                : "-"}
            </Typography>
          </div>
          <div className="flex flex-col">
            <Typography size="xs" color="empty">
              Attainment
            </Typography>
            <Typography size="sm">
              {position.current.attainment ?? "-"}
            </Typography>
          </div>
        </div>
      </div>
      <div>
        <Divider />
      </div>
      <Changes requestData={request} positionData={position} />
      <div className="w-full flex flex-row justify-between">
        <Button onClick={setModal} fill="clear" className="!w-fit !px-0">
          Cancel
        </Button>
        {isAdmin && (
          <div className="flex flex-row gap-2">
            <Button
              id="submit-reject-modification"
              onClick={rejectFunction}
              fill="destructive"
              className="!w-fit"
            >
              Reject
            </Button>
            <Button
              id="submit-approve-modification"
              onClick={approveFunction}
              className="!w-fit"
            >
              Approve
            </Button>
          </div>
        )}
      </div>
    </div>
  </Modal>
);

export default ViewForecastRequest;
