import React from "react";
import { Filter } from "~/components/SearchFilters/useSearchFilters";
import Button from "~/components/Button";
import { FilterType } from "~/components/SearchFilters/index";
import { CalendarIcon } from "@heroicons/react/24/outline";
import DatePicker, { useDatePicker } from "~/components/DatePicker";
import date from "~/utils/dates/date";

interface Props {
  activeFilters: Filter[];
  onSave: (filter: Filter[]) => void;
  onClose: () => void;
}

const HireDateFilter = ({ activeFilters, onSave, onClose }: Props) => {
  const [hiredAfter, setHiredAfter] = useDatePicker();
  const [hiredBefore, setHiredBefore] = useDatePicker();

  const getLabel = () => {
    if (hiredAfter.value.startDate && !hiredBefore.value.startDate) {
      return ` Hired after ${date(hiredAfter.value.startDate).format(
        "MM/DD/YYYY",
      )}`;
    }

    if (hiredBefore.value.startDate && !hiredAfter.value.startDate) {
      return ` Hired before ${date(hiredBefore.value.startDate).format(
        "MM/DD/YYYY",
      )}`;
    }

    if (hiredAfter.value.startDate && hiredBefore.value.startDate) {
      return ` Hired ${date(hiredAfter.value.startDate).format(
        "MM/DD/YYYY",
      )} to ${date(hiredBefore.value.startDate).format("MM/DD/YYYY")}`;
    }

    return "";
  };

  const attemptSave = () => {
    if (hiredAfter.valid || hiredBefore.valid) {
      const activeFilter = activeFilters.find(
        (filter) => filter.id === FilterType.HIRE_DATE,
      );
      if (activeFilter) {
        // TODO - Update existing filter
      } else {
        const newFilter: Filter = {
          id: FilterType.HIRE_DATE,
          label: (
            <div className="flex items-center gap-2 whitespace-nowrap">
              <CalendarIcon className="w-5 h-5" />
              {getLabel()}
            </div>
          ),
          queryParam: {
            hiredBefore: hiredBefore.value.startDate
              ? date(hiredBefore.value.startDate).format("YYYY-MM-DD")
              : undefined,
            hiredAfter: hiredAfter.value.startDate
              ? date(hiredAfter.value.startDate).format("YYYY-MM-DD")
              : undefined,
          },
        };
        onSave([...activeFilters, newFilter]);
        onClose();
      }
    } else {
      setHiredAfter((prevState) => ({
        ...prevState,
        pristine: false,
        touched: true,
      }));
    }
  };

  return (
    <div className="text-neutral-900 text-left my-2">
      <div className="flex gap-2">
        <DatePicker
          id="hired-after"
          label="Hired After"
          state={hiredAfter}
          setState={setHiredAfter}
          maxDate={
            hiredBefore.value.startDate
              ? date(hiredBefore.value.startDate).toDate()
              : undefined
          }
        />
        <DatePicker
          minDate={
            hiredAfter.value.startDate
              ? date(hiredAfter.value.startDate).toDate()
              : undefined
          }
          id="hired-before"
          label="Hired Before"
          state={hiredBefore}
          setState={setHiredBefore}
        />
      </div>
      <div className="flex justify-end gap-3 my-3">
        <Button
          fill="outline"
          className="text-red border-red !w-auto"
          id="filter-cancel"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button id="filter-save" className="!w-auto" onClick={attemptSave}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default HireDateFilter;
