import React, { useState, useEffect } from "react";
import OtpInput from "~/components/OtpInput";
import Typography from "~/components/Typography";
import useQueryParams from "~/utils/hooks/useQueryParams";
import request from "~/utils/request";
import Button from "~/components/Button";
import { useNavigate } from "react-router-dom";
import { IExchangeOtpForTokensResponse } from "../Login";
import { useDispatch } from "react-redux";
import { authSlice } from "~/store/authSlice";
import userAgentIsBot from "~/utils/userAgentIsBot";

const Otp = (): React.ReactNode => {
  const otpLength = 6;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [queryParams] = useQueryParams();
  const emailValue = queryParams.get("emailValue");
  const otpFromParams = queryParams.get("otp");
  const [errorActive, setErrorActive] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("Invalid Code");
  const [otp, setOtp] = useState(new Array(otpLength).fill(""));
  const isBot = userAgentIsBot(navigator.userAgent);

  const exchangeCodeForTokens = async () => {
    try {
      const response = (await request({
        url: `/auth/otp`,
        method: "POST",
        body: {
          email: emailValue,
          oneTimePasscode: otp.join(""),
        },
      })) as IExchangeOtpForTokensResponse;

      if (response.status === 200 && response.data.data) {
        localStorage.setItem("accessToken", response.data.data.accessToken);
        localStorage.setItem("refreshToken", response.data.data.refreshToken);
        dispatch(authSlice.actions.login(response.data.data.accessToken));
        navigate("/auth/login");
      } else if (response.status === 410) {
        setErrorMessage("Code has expired");
        setErrorActive(true);
      }
      {
        setErrorMessage("Invalid Code");
        setErrorActive(true);
      }
    } catch (error) {
      setErrorMessage("Invalid Code");
      setErrorActive(true);
    }
  };

  const handleChangeOtp = (value: string, index: number): void => {
    const isValidNumber = /^\d*$/.test(value);

    if (isValidNumber) {
      const newArr: string[] = [...otp];
      newArr[index] = value;
      setOtp(newArr);
    }
  };

  const handlePaste = (value: string[]): void => {
    if (/^\d*$/.test(value.join(""))) {
      if (value.length < otpLength) {
        while (value.length < otpLength) {
          value.push("");
        }
      }

      setOtp(value.slice(0, otpLength));
    }
  };

  useEffect(() => {
    if (!isBot) {
      if (otp.every((item) => item.trim() !== "")) {
        exchangeCodeForTokens();
      } else {
        setErrorActive(false);
      }
    }
  }, [otp]);

  useEffect(() => {
    if (otpFromParams) {
      handlePaste(otpFromParams.split(""));
    }
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === "Enter") {
        exchangeCodeForTokens();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [otp]);

  return (
    <div className="w-screen h-screen flex flex-col items-center bg-green-15">
      <div className="mt-[10vh] flex flex-col items-center">
        <Typography weight="bold" size="lg" className="mb-5">
          Enter One-time Code
        </Typography>
        <div className="w-[370px]">
          <OtpInput
            numberOfDigits={otpLength}
            label="An email has been sent with the verification code included at the bottom"
            errorMessage={errorMessage}
            errorActive={errorActive}
            onChange={handleChangeOtp}
            onPaste={handlePaste}
            otp={otp}
          />
        </div>
        {isBot && (
          <div className="w-content mt-2 mb-2">
            <Button onClick={() => exchangeCodeForTokens()}>Submit</Button>
          </div>
        )}
        <div className="w-content">
          <Button fill="clear" onClick={() => navigate(-1)}>
            Go Back
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Otp;
