import { IDrilldownDetailsData } from "~/components/Drawer/DrilldownDrawer/useDrilldownDrawer";
import parseCurrencyToNumber from "../parseCurrencyToNumber";
import { format, utcToZonedTime } from "date-fns-tz";
import expressionDetailsToEquationArray from "~/components/Drawer/DrilldownDrawer/DisplayMath/expressionDetailsToEquationArray";

const transformDataForExportUsingReduce = (
  unformattedData: IDrilldownDetailsData[],
) =>
  unformattedData.reduce(
    (acc, drilldownDetailsData) => {
      // Iterate over each drilldownDetailsData.drilldownData (which is IDrilldownDetails[])
      const detailEntries = drilldownDetailsData.drilldownData.flatMap(
        (detail) =>
          detail.calculations?.map((calc) => {
            const expressionArray = expressionDetailsToEquationArray(
              calc.expressionDetails,
              [],
            );
            // Remove the = and answer so it can be calculated by spreadsheet
            expressionArray.splice(-2);
            let expressionString = "";
            expressionArray.forEach((expression) => {
              if (expression.value.includes("$")) {
                expressionString += parseCurrencyToNumber(
                  expression.value,
                ).toString();
              } else {
                expressionString += expression.value;
              }
            });

            return {
              "Employee Number": detail.employeeNumber ?? undefined,
              "Employee Name": detail.employeeName ?? undefined,
              "Position Title": calc.positionTitle,
              "Start Date": format(
                utcToZonedTime(calc.startDate, "UTC"),
                "MM/dd/yyyy",
              ),
              "End Date": format(
                utcToZonedTime(calc.endDate, "UTC"),
                "MM/dd/yyyy",
              ),
              Calculation: `=ROUND(${expressionString},2)`,
            };
          }) ?? [],
      );

      return [...acc, ...detailEntries];
    },
    [] as {
      "Employee Number": string | undefined;
      "Employee Name": string | undefined;
      "Position Title": string;
      "Start Date": string;
      "End Date": string;
      Calculation: string;
    }[],
  );

const formatSalaryDrilldownForExport = ({
  data,
}: {
  data: IDrilldownDetailsData[];
}) => {
  return transformDataForExportUsingReduce(data).reduce(
    (output, position, index) => {
      if (index === 0) {
        output.push(Object.keys(position));
      }
      output.push(Object.values(position));
      return output;
    },
    [] as (string | number | boolean | React.ReactNode)[],
  );
};

export default formatSalaryDrilldownForExport;
