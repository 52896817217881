import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "~/store";
import useInput from "~/components/Input/useInput";
import { useDatePicker } from "~/components/DatePicker";
import { useSelect } from "~/components/Select";
import useCombobox from "~/components/Combobox/useCombobox";
import request from "~/utils/request";
import date from "~/utils/dates/date";
import toast from "react-hot-toast";
import { scenarioSlice } from "~/store/scenarioSlice";
import currencySymbols from "~/utils/currencySymbols";
import { CommonlyFetchedValuesContext } from "~/context/CommonlyFetchedValuesContext";
import formatDepartmentOptions from "~/context/utils/formatDepartmentOptions";

const usePositionFormState = (config?: { isNewPosition: boolean }) => {
  const dispatch = useDispatch();
  const commonValues = useContext(CommonlyFetchedValuesContext);
  if (!commonValues) {
    throw new Error("CommonlyFetchedValuesContext not provided");
  }
  const { departments } = commonValues;

  const [errorMessage, setErrorMessage] = useState("");
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const [jobTitle, setJobTitle] = useInput({
    validation: /.+/,
    errorMessage: "Job title is required",
  });
  const [startDateState, setStartDateState] = useDatePicker({
    minDate: date().toISOString(),
  });
  const [employmentType, setEmploymentType] = useSelect({
    options: [
      { value: "FULL_TIME", label: "Full Time" },
      { value: "PART_TIME", label: "Part Time" },
      { value: "INTERN", label: "Intern" },
    ],
    valid: true,
    selected: { value: "FULL_TIME", label: "Full Time" },
  });
  const [selectedPaymentType, setSelectedPaymentType] = useSelect({
    options: [
      { value: "SALARY", label: "Salary" },
      { value: "HOURLY", label: "Hourly" },
    ],
    valid: true,
    selected: { value: "SALARY", label: "Salary" },
  });
  const [paymentAmountState, setPaymentAmountState] = useInput({
    validation: /^[0-9]*\.?[0-9]{0,2}$/,
    errorMessage: "Payment amount is required",
  });
  const [bonusAmountState, setBonusAmountState] = useInput({
    validation: /^([0-9]\d*)?$/,
  });
  const [commissionAmountState, setCommissionAmountState] = useInput({
    validation: /^([0-9]\d*)?$/,
  });
  const [attainmentState, setAttainmentState] = useInput({
    validation: /^(100(\.00?)?|(\d{1,2}(\.\d{1,2})?)%?)?$/,
    errorMessage: "Between 0 & 100",
  });
  const currencyOptions = Object.keys(currencySymbols).map((currency) => ({
    value: currency,
    label: currency,
  }));

  currencyOptions.sort((a, b) => a.label.localeCompare(b.label));

  const [currencyTypeState, setCurrencyTypeState] = useSelect({
    options: currencyOptions,
    valid: true,
    selected: { value: "USD", label: "USD" },
  });
  const [expectedHoursState, setExpectedHoursState] = useInput({
    // Allow 0 for existing positions
    validation: config?.isNewPosition ? /^[1-9]\d*$/ : /^[0-9]\d*$/,
    value: "20",
    errorMessage: config?.isNewPosition
      ? "Expected hours is required to be a number greater than zero"
      : "Expected hours is required to be a number",
  });
  const [selectDepartment, setSelectDepartment] = useSelect({
    options: [],
    isNullable: true,
  });
  const [managerState, setManagerState] = useCombobox({
    options: [],
  });
  const [changeDescription, setChangeDescription] = useInput({
    validation: /.+/,
    errorMessage: "A change description is required for audit purposes",
  });
  const [savingPosition, setSavingPosition] = useState(false);
  const [numberToCreate, setNumberToCreate] = useInput({
    validation: /^[1-9]\d*$/,
    valid: true,
    value: "1",
    errorMessage:
      "Number to create is required to be a number greater than zero",
  });

  const fetchUsedCurrencies = async (): Promise<void> => {
    const usedCurrencies = (await request({
      url: `/organizations/${organizationUuid}/currencies`,
      method: "GET",
    })) as { data: { data: string[] } };

    const usedCurrencyOptions = usedCurrencies.data.data.map((currency) => ({
      value: currency,
      label: currency,
    }));

    usedCurrencyOptions.sort((a, b) => a.label.localeCompare(b.label));

    const filteredCurrencyOptions = currencyOptions.filter(
      (currency) => !usedCurrencies.data.data.includes(currency.value),
    );

    setCurrencyTypeState((prevState) => ({
      ...prevState,
      options: [
        ...usedCurrencyOptions,
        { value: "divider", label: "divider" },
        ...filteredCurrencyOptions,
      ],
    }));
  };

  useEffect(() => {
    fetchUsedCurrencies();
  }, []);

  const searchManager = async (managerName: string): Promise<void> => {
    /**
     * Backend limitation. Search by one part of name only.
     * When near text searching and a more performant search is in place, this can be removed in favor of a more robust search.
     */
    const splitManagerName = managerName
      .split(" ")
      .reduce((longest, current) => {
        if (current.length > longest.length) return current;
        return longest;
      });

    const employees = (await request({
      url: `/organizations/${organizationUuid}/employees`,
      method: "GET",
      params: {
        name: `${splitManagerName}`,
      },
    })) as { data: { data?: Types.Employee[] } };
    if (employees.data.data) {
      setManagerState({
        ...managerState,
        options: employees.data.data.map((employee: Types.Employee) => {
          const employeeOrganization = employee.groups?.find(
            (organization: { type: string }) =>
              organization.type === "DEPARTMENT",
          );

          return {
            value: employee.uuid,
            label: `${employee.firstName} ${employee.lastName}${
              employeeOrganization ? ` - ${employeeOrganization.name}` : ""
            }`,
          };
        }),
      });
    }
  };

  const createNewScenario = async () => {
    const createScenarioResponse = await request({
      url: `/organizations/${organizationUuid}/scenarios`,
      method: "POST",
    });
    if (createScenarioResponse.status === 201) {
      dispatch(
        scenarioSlice.actions.update({
          inEditMode: true,
          activeScenarioUuid: createScenarioResponse.data.data.uuid,
        }),
      );
    } else {
      toast.error("Unable to create scenario");
    }
  };

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    const delayedCall = (): void => {
      clearTimeout(timeoutId);
      if (managerState.query.length >= 3) {
        timeoutId = setTimeout(() => searchManager(managerState.query), 300);
      }
    };
    delayedCall();
    return (): void => {
      clearTimeout(timeoutId);
    };
  }, [managerState.query]);

  useEffect(() => {
    if (departments.length) {
      const formattedDepartmentOptions = formatDepartmentOptions(departments);
      setSelectDepartment({
        ...selectDepartment,
        selected:
          formattedDepartmentOptions.length === 1
            ? formattedDepartmentOptions[0]
            : undefined,
        options: formattedDepartmentOptions,
      });
    }
  }, [departments]);

  const resetFormState = (): void => {
    setJobTitle({
      ...jobTitle,
      value: "",
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setChangeDescription({
      ...changeDescription,
      value: "",
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setStartDateState({
      ...startDateState,
      value: {
        startDate: null,
        endDate: null,
      },
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setEmploymentType({
      ...employmentType,
      valid: true,
      selected: { value: "FULL_TIME", label: "Full Time" },
      pristine: true,
      touched: false,
      disabled: false,
    });
    setSelectedPaymentType({
      ...selectedPaymentType,
      valid: true,
      selected: { value: "SALARY", label: "Salary" },
      pristine: true,
      touched: false,
      disabled: false,
    });
    setPaymentAmountState({
      ...paymentAmountState,
      value: "",
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setBonusAmountState({
      ...bonusAmountState,
      value: "",
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setCommissionAmountState({
      ...commissionAmountState,
      value: "",
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setAttainmentState({
      ...attainmentState,
      value: "",
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setCurrencyTypeState({
      ...currencyTypeState,
      valid: true,
      selected: { value: "USD", label: "USD" },
      pristine: true,
      touched: false,
      disabled: false,
    });
    setExpectedHoursState({
      ...expectedHoursState,
      value: "",
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
    const formattedDepartmentOptions = formatDepartmentOptions(departments);
    setSelectDepartment({
      ...selectDepartment,
      selected:
        formattedDepartmentOptions.length === 1
          ? formattedDepartmentOptions[0]
          : undefined,
      valid: true,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setManagerState({
      ...managerState,
      selected: undefined,
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setNumberToCreate({
      ...numberToCreate,
      value: "1",
      valid: true,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setSavingPosition(false);
    fetchUsedCurrencies();
  };

  return {
    errorMessage,
    setErrorMessage,
    organizationUuid,
    jobTitle,
    setJobTitle,
    startDateState,
    setStartDateState,
    employmentType,
    setEmploymentType,
    selectedPaymentType,
    setSelectedPaymentType,
    paymentAmountState,
    setPaymentAmountState,
    bonusAmountState,
    setBonusAmountState,
    commissionAmountState,
    setCommissionAmountState,
    attainmentState,
    setAttainmentState,
    currencyTypeState,
    setCurrencyTypeState,
    selectDepartment,
    setSelectDepartment,
    managerState,
    setManagerState,
    changeDescription,
    setChangeDescription,
    expectedHoursState,
    setExpectedHoursState,
    resetFormState,
    createNewScenario,
    savingPosition,
    setSavingPosition,
    numberToCreate,
    setNumberToCreate,
  };
};

export default usePositionFormState;
