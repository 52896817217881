import { TableData } from "~/components/Table";
import parseCurrencyToNumber from "./parseCurrencyToNumber";

export default (
  data: TableData[] | undefined,
): {
  "ID Number"?: string | number;
  Name?: string;
  "Job Title"?: string;
  Department?: string;
  Modification?: string;
  "Effective Date"?: string;
  Impact?: number;
}[] => {
  if (!data) {
    return [];
  }
  return data.map((row) => ({
    "ID Number":
      row.values[0].value === "-" ? undefined : (row.values[0].value as string),
    Name:
      row.values[1].value === "-" ? undefined : (row.values[1].value as string),
    "Job Title":
      row.values[2].value === "-" ? undefined : (row.values[2].value as string),
    Department:
      row.exportLabel === "-" ? undefined : (row.exportLabel as string),
    Modification:
      row.values[4].value === "-" ? undefined : (row.values[4].value as string),
    "Effective Date":
      row.values[5].value === "-" ? undefined : (row.values[5].value as string),
    Impact:
      row.values[6].value === "-"
        ? undefined
        : parseCurrencyToNumber(row.values[6].value as string),
  }));
};
