import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import request from "~/utils/request";
import useQueryParams from "~/utils/hooks/useQueryParams";
import toast from "react-hot-toast";
import logos from "~/assets/logos";
import OAuthAuthorization from "./OAuthAuthorization";

interface OauthApplication {
  name: string;
  callbackUrl: string;
}
interface OauthApplicationResponse {
  data: {
    data: OauthApplication;
  };
  status: number;
}
const OAuthAuthorizationContainer = (): React.ReactNode => {
  const navigate = useNavigate();
  const [queryParams] = useQueryParams();
  const [oauthApplication, setOathApplication] = useState<OauthApplication>();

  const clientId = useMemo(() => queryParams.get("clientId"), [queryParams]);

  let logo = "";
  switch (clientId) {
    case "209f306e-ec4b-4f56-9b8a-58f939ad49b2":
      logo = logos.gsheets;
      break;

    case "a14815a1-fd1b-4b7f-8d74-c79c6aa4d81b":
      logo = logos.excel;
      break;

    default:
      break;
  }

  useEffect(() => {
    const fetchOauthApplication = async (): Promise<void> => {
      try {
        const response = (await request({
          url: `/oauth2/applications/${clientId}`,
          method: "GET",
        })) as OauthApplicationResponse;

        if (response.status === 200) {
          setOathApplication(response.data.data);
        }
      } catch (error) {
        toast.error("An error occurred");
      }
    };
    fetchOauthApplication();
  }, [clientId]);

  const handleAccept = async (): Promise<void> => {
    try {
      if (clientId) {
        const response = await request({
          url: "/oauth2/authorize",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            clientId,
            scopes: ["reports"],
            callbackUrl: queryParams.get("callbackUrl"),
          },
        });

        if (response.status === 201 && response.data.data.code) {
          const callbackUrl =
            oauthApplication?.callbackUrl ?? queryParams.get("callbackUrl");
          window.location.href = `${callbackUrl}?code=${response.data.data.code}`;
        } else {
          toast.error("Unable to authorize");
        }
      }
    } catch (error) {
      toast.error("An error occurred");
    }
  };

  const handleDecline = () => {
    const callbackUrl =
      oauthApplication?.callbackUrl ?? queryParams.get("callbackUrl");
    if (callbackUrl) {
      window.location.href = callbackUrl;
    } else {
      navigate("/dashboard");
    }
  };

  return (
    <OAuthAuthorization
      handleDecline={handleDecline}
      handleAccept={handleAccept}
      clientName={`${oauthApplication?.name}™`}
      logo={logo}
    />
  );
};

export default OAuthAuthorizationContainer;
