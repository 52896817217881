import { useInput } from "~/components/Input";
import React, { ReactNode, useEffect, useRef } from "react";
import ReactCurrencyInputField from "react-currency-input-field";
import formatCurrency from "~/utils/formatCurrency";
import { v4 } from "uuid";

export interface ISelectedCellState {
  department: string;
  value: string | number | boolean | React.ReactNode;
  rowIndex: number;
  ref: React.MutableRefObject<HTMLElement | undefined>;
}
export interface ICellValue {
  value: string | number | boolean | ReactNode;
  onClickText?: () => void;
  editable?: boolean;
  onClickCell?: () => void;
  onDoubleClickCell?: () => void;
  tdClassName?: string;
  metadata?: {
    departmentUuid?: string;
    effectiveAt?: Date;
    substring?: string | number;
  };
}

interface IProps {
  id: string;
  cell: ICellValue;
  editMode?: boolean;
  edits?: Record<
    string,
    { amount: number; departmentUuid: string; effectiveAt: Date }
  >;
  setEdits?: React.Dispatch<
    React.SetStateAction<
      Record<
        string,
        { amount: number; departmentUuid: string; effectiveAt: Date }
      >
    >
  >;
  cellIndex: number;
  tableDataClassName?: string | string[];
  columnAlignment?: (
    | "center"
    | "left"
    | "right"
    | "justify"
    | "char"
    | undefined
  )[];
  autoSpacing?: boolean;
  width?: number | string;
  selectedCell?: ISelectedCellState;
  setSelectedCell?: React.Dispatch<
    React.SetStateAction<ISelectedCellState | undefined>
  >;
  totalColumns: number;
  rowLabel: string;
  headerLabel: string;
  children?: ReactNode;
}

const ClickableCell = ({
  cell,
  alignment,
}: {
  cell: ICellValue;
  alignment: string;
}) => {
  const textAlignClass = alignment === "right" ? "text-right" : "text-left";

  return (
    <div className="w-full overflow-hidden whitespace-nowrap text-ellipsis">
      <button
        type="button"
        onClick={() => {
          if (!cell.onClickText) return;
          cell.onClickText();
        }}
        className={`block overflow-hidden whitespace-nowrap text-ellipsis hover:underline underline-offset-4 hover:text-green-500 cursor-pointer w-full ${textAlignClass}`}
      >
        {cell.value}
      </button>
    </div>
  );
};

const CellInput = ({
  cell,
  edits,
  setEdits,
  cellIndex,
  editMode,
  isSelected,
  setSelectedCell,
}: {
  cell: ICellValue;
  edits: Record<
    string,
    { amount: number; departmentUuid: string; effectiveAt: Date }
  >;
  setEdits: React.Dispatch<
    React.SetStateAction<
      Record<
        string,
        { amount: number; departmentUuid: string; effectiveAt: Date }
      >
    >
  >;
  cellIndex?: string;
  editMode: boolean;
  isSelected?: boolean;
  setSelectedCell?: () => void;
}) => {
  const [cellInputState, setCellInputState] = useInput({
    validation: /^([0-9]\d*)?$/,
  });
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (cellInputState.value) {
      setEdits({
        ...edits,
        [cellIndex as string]: {
          amount: Number(cellInputState.value) * 100,
          departmentUuid: cell.metadata?.departmentUuid as string,
          effectiveAt: cell.metadata?.effectiveAt as Date,
        },
      });
    } else {
      setEdits((prevState) => {
        const newState = { ...prevState };
        delete newState[cellIndex as string];
        return newState;
      });
    }
  }, [cellInputState.value, editMode]);

  useEffect(() => {
    if (isSelected && inputRef.current && editMode) {
      // Focus on the input field if the cell is selected
      inputRef.current.focus();
    }
  }, [isSelected, editMode]);

  useEffect(() => {
    if (cellIndex && edits[cellIndex]) {
      setCellInputState((prevState) => ({
        ...prevState,
        value: (edits[cellIndex].amount / 100).toString(),
      }));
    } else {
      setCellInputState((prevState) => ({
        ...prevState,
        value: "",
      }));
    }
  }, [cellIndex, editMode]);

  const label = cell.value === "-" && editMode ? null : cell.value;

  return (
    <>
      <div
        className={
          editMode && cell.value === "-"
            ? "max-h-0"
            : editMode
              ? "max-h-[24px]"
              : "max-h-[18px]"
        }
      >
        <span
          className={`text-${
            editMode ? "2xs text-neutral-200" : "sm"
          } py-0 ease-linear`}
          data-testid={`${cellIndex}-cell-label`}
        >
          {label !== ""
            ? formatCurrency(label as string | number, true)
            : label}
        </span>
      </div>
      <div
        className={`ease-linear overflow-hidden ${
          editMode ? "max-h-[24px]" : "max-h-0"
        }`}
      >
        {/* 
        Right now this is only being used for manual adjustments and thus a currency input
        Couldn't get Input component to follow desired styling 
      */}
        <ReactCurrencyInputField
          className={`w-full h-[24px] border-transparent focus:border-transparent focus-visible:ring-white !p-0 ${
            isSelected ? "" : "text-right"
          }`}
          align={`${isSelected ? "left" : "right"}`}
          prefix={isSelected ? "" : "$"}
          onFocus={setSelectedCell}
          id={`${cellIndex}-input`}
          ref={inputRef}
          data-testid={`${cellIndex}-input`}
          name={`${cellIndex}-input`}
          placeholder=""
          value={cellInputState.value}
          autoComplete="off"
          onValueChange={(value) => {
            setCellInputState((prevState) => ({
              ...prevState,
              value: value as string,
            }));
          }}
          disabled={cellInputState.disabled}
          onBlur={() => {
            setCellInputState((prevState) => ({
              ...prevState,
              touched: true,
            }));
          }}
        />
      </div>
    </>
  );
};

const Cell = ({
  id,
  cell,
  editMode = false,
  edits,
  setEdits,
  cellIndex,
  tableDataClassName,
  columnAlignment,
  autoSpacing,
  width,
  selectedCell,
  setSelectedCell,
  totalColumns,
  rowLabel,
  headerLabel,
  children,
}: IProps) => {
  const tableCellRef = useRef();

  const getPaddingClass = ({
    index,
    totalColumns,
    basePadding = "py-4",
    cellValue,
  }: {
    index: number;
    totalColumns: number;
    basePadding?: string;
    cellValue?: string | number | boolean | ReactNode;
  }) => {
    const editModePadding =
      editMode && cell.editable && cellValue !== "" ? " !py-[2px]" : "";
    if (index === 0) return `pl-5 pr-2 ${basePadding}${editModePadding}`;
    if (index === totalColumns - 1)
      return `pl-2 pr-5 ${basePadding}${editModePadding}`;
    return `px-2.5 ${basePadding}${editModePadding}`;
  };

  return (
    <td
      ref={tableCellRef}
      data-testid={id}
      onDoubleClick={
        cell.onDoubleClickCell ? cell.onDoubleClickCell : undefined
      }
      key={
        /* eslint-disable-next-line react/no-array-index-key */
        `${id}-${cellIndex ? cellIndex : v4()}`
      }
      title={typeof cell.value === "string" ? cell.value : undefined}
      className={`ease-linear border-t border-b
      ${
        typeof cell.value === "string" &&
        "overflow-hidden text-ellipsis whitespace-nowrap"
      }
      ${
        selectedCell &&
        selectedCell.department === rowLabel &&
        selectedCell.rowIndex === cellIndex &&
        "border-green-400"
      } text-sm text-gray-800 ${
        cell.onClickCell
          ? " border hover:border-1 hover:border-green-400 cursor-pointer"
          : ""
      } ${
        autoSpacing
          ? getPaddingClass({
              index: cellIndex,
              totalColumns,
              basePadding: cell.metadata?.substring ? "!py-[2px]" : undefined,
              cellValue: cell.value,
            })
          : ""
      } ${
        Array.isArray(tableDataClassName)
          ? tableDataClassName[cellIndex]
          : tableDataClassName
      }${cell.tdClassName ? ` ${cell.tdClassName}` : ""}${
        width ? ` w-[${width}px]` : ""
      }`}
      align={columnAlignment?.[cellIndex] || "left"}
      onClick={() => {
        if (cell.onClickCell && setSelectedCell) {
          setSelectedCell({
            department: rowLabel,
            value: cell.value,
            rowIndex: cellIndex,
            ref: tableCellRef,
          });
          cell.onClickCell();
        }
      }}
    >
      <>
        {cell.editable && edits && setEdits && setSelectedCell ? (
          <CellInput
            cell={cell}
            edits={edits}
            setEdits={setEdits}
            cellIndex={`${rowLabel}-${headerLabel}`}
            editMode={editMode}
            isSelected={
              selectedCell &&
              selectedCell.department === rowLabel &&
              selectedCell.rowIndex === cellIndex
            }
            setSelectedCell={() => {
              setSelectedCell({
                department: rowLabel,
                value: cell.value,
                rowIndex: cellIndex,
                ref: tableCellRef,
              });
              if (cell.onClickCell) cell.onClickCell();
            }}
          />
        ) : cell.onClickText ? (
          <div className="flex flex-col items-center h-full w-full">
            <ClickableCell
              cell={cell}
              alignment={columnAlignment?.[cellIndex] || "left"}
            />
          </div>
        ) : (
          "value" in cell && cell.value
        )}
        {cell.metadata?.substring && !editMode && (
          <span className="text-2xs text-neutral-200">
            {cell.metadata.substring}
          </span>
        )}
        {children}
      </>
    </td>
  );
};

export default Cell;
