import React, { useEffect } from "react";
import request from "~/utils/request";
import Combobox from "~/components/Combobox/Combobox";
import { State as ComboboxState } from "~/components/Combobox/Combobox.types";
import useCombobox from "~/components/Combobox/useCombobox";
import useDebounce from "~/utils/hooks/useDebounce";

interface Props {
  className?: string;
  state: ComboboxState;
  setState: React.Dispatch<React.SetStateAction<ComboboxState>>;
}

interface HookProps {
  organizationUuid: string;
}

export const useEmployeeSearch = ({
  organizationUuid,
}: HookProps): [
  ComboboxState,
  React.Dispatch<React.SetStateAction<ComboboxState>>,
] => {
  const [employeeState, setEmployeeState] = useCombobox({
    options: [],
  });
  const debouncedQuery = useDebounce(employeeState.query, 300);

  useEffect(() => {
    const searchManager = async () => {
      const employees = await request({
        url: `/organizations/${organizationUuid}/employees/search`,
        method: "GET",
        params: {
          name: `${employeeState.query}`,
          isActive: true,
          hasPosition: false,
        },
      });
      if (
        employees.data.data &&
        employees.config.params.name === employeeState.query
      ) {
        setEmployeeState((prevState) => ({
          ...prevState,
          options: employees.data.data.map((employee: Types.Employee) => {
            const employeeOrganization = employee.groups?.find(
              (organization: { type: string }) =>
                organization.type === "DEPARTMENT",
            );

            return {
              value: employee.uuid,
              label: `${employee.firstName} ${employee.lastName}${
                employeeOrganization ? ` - ${employeeOrganization.name}` : ""
              }`,
            };
          }),
        }));
      }
    };
    if (employeeState.query.length > 0) {
      searchManager();
    } else {
      setEmployeeState({
        ...employeeState,
        options: [],
      });
    }
  }, [debouncedQuery]);

  return [employeeState, setEmployeeState];
};
const EmployeeSearch = ({ state, setState, className }: Props) => (
  <Combobox
    className={className}
    id="select-employee-combobox"
    placeholder="Search for an employee"
    state={state}
    setState={setState}
    errorMessage="Please select an employee"
    emptyStateMessage="No employees found that don't currently have a position assigned to them"
  />
);

export default EmployeeSearch;
