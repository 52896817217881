import React, { useEffect } from "react";
import Select from "~/components/Select";
import { SelectState } from "~/components/Select/Select.types";

interface Props {
  departmentOptions: SelectState;
  setDepartmentOptions: React.Dispatch<React.SetStateAction<SelectState>>;
  className?: string;
  listBoxClassName?: string;
  label?: string;
  placeholder?: string;
  allowSelectAll?: boolean;
  disabled?: boolean;
}

const SelectDepartment = ({
  departmentOptions,
  setDepartmentOptions,
  className = "",
  listBoxClassName = "",
  label,
  placeholder,
  allowSelectAll = false,
  disabled = false,
}: Props): React.ReactNode => {
  useEffect(() => {
    setDepartmentOptions((prev) => {
      if (
        allowSelectAll &&
        prev.options.some((option) => option.label === "All Departments")
      ) {
        return prev;
      } else if (allowSelectAll) {
        return {
          ...prev,
          selected:
            prev.selected?.label && prev.selected.value
              ? prev.selected
              : {
                  label: "All Departments",
                  value: null,
                },
          options: [
            {
              label: "All Departments",
              value: null,
            },
            ...prev.options,
          ],
        };
      } else {
        return {
          ...prev,
          options: prev.options.filter(
            (option) => option.label !== "All Departments",
          ),
        };
      }
    });
  }, [allowSelectAll, setDepartmentOptions]);

  return (
    <Select
      id="department-select"
      label={label}
      state={departmentOptions}
      setState={setDepartmentOptions}
      className={className}
      placeholder={placeholder}
      disabled={disabled}
      listBoxClassName={listBoxClassName}
    />
  );
};

export default SelectDepartment;
