import { isSameDay, isBefore, isAfter, startOfDay } from "date-fns";

export default ({
  comparisonDate,
  dateForBefore,
  dateForAfter,
}: {
  comparisonDate: Date;
  dateForBefore: Date;
  dateForAfter: Date;
}): boolean => {
  return (
    isSameDay(startOfDay(comparisonDate), startOfDay(dateForBefore)) ||
    isSameDay(startOfDay(comparisonDate), startOfDay(dateForAfter)) ||
    (isAfter(startOfDay(comparisonDate), startOfDay(dateForBefore)) &&
      isBefore(startOfDay(comparisonDate), startOfDay(dateForAfter)))
  );
};
