import { Filter } from "~/components/SearchFilters/useSearchFilters";
import Button from "~/components/Button";
import { FilterType } from "~/components/SearchFilters/index";
import Checkbox from "~/components/Checkbox";
import { useState } from "react";
import { EmploymentTypes } from "~/utils/enums";
import Typography from "~/components/Typography";

interface Props {
  activeFilters: Filter[];
  onSave: (filter: Filter[]) => void;
  onClose: () => void;
}

const EmploymentTypeFilter = ({ activeFilters, onSave, onClose }: Props) => {
  const existingEmploymentTypeFilter = activeFilters.find(
    (filter) => filter.id === FilterType.EMPLOYMENT_TYPE,
  );

  const initialCheckboxStates = {
    FULL_TIME:
      existingEmploymentTypeFilter?.queryParam?.employmentType?.includes(
        "FULL_TIME",
      ) ?? false,
    PART_TIME:
      existingEmploymentTypeFilter?.queryParam?.employmentType?.includes(
        "PART_TIME",
      ) ?? false,
    INTERN:
      existingEmploymentTypeFilter?.queryParam?.employmentType?.includes(
        "INTERN",
      ) ?? false,
  };

  const [checkboxStates, setCheckboxStates] = useState(initialCheckboxStates);

  const attemptSave = () => {
    const selectedEmploymentTypes = Object.keys(checkboxStates).filter(
      (key) => checkboxStates[key as keyof typeof checkboxStates],
    );
    if (selectedEmploymentTypes.length > 0) {
      const label =
        selectedEmploymentTypes.length === Object.keys(checkboxStates).length
          ? "All Employment Types"
          : selectedEmploymentTypes
              .map(
                (selectedType) =>
                  EmploymentTypes[selectedType as keyof typeof EmploymentTypes],
              )
              .join(", ");

      const newFilter: Filter = {
        id: FilterType.EMPLOYMENT_TYPE,
        label,
        queryParam: {
          employmentType: selectedEmploymentTypes,
        },
      };

      let updatedFilters: Filter[] = [];
      if (existingEmploymentTypeFilter) {
        updatedFilters = activeFilters.map((filter) => {
          if (filter.id === FilterType.EMPLOYMENT_TYPE) {
            return newFilter;
          }
          return filter;
        });
      } else {
        updatedFilters = [...activeFilters, newFilter];
      }

      onSave(updatedFilters);
      onClose();
    }
  };

  return (
    <div className="text-neutral-900 text-left my-5 flex flex-col gap-2">
      <div className="flex items-center gap-2">
        <Checkbox
          id="fulltime-checkbox"
          checked={checkboxStates.FULL_TIME}
          toggleValue={() =>
            setCheckboxStates({
              ...checkboxStates,
              FULL_TIME: !checkboxStates.FULL_TIME,
            })
          }
          className="cursor-pointer w-4 h-4"
        />
        <Typography>Full Time</Typography>
      </div>
      <div className="flex items-center gap-2">
        <Checkbox
          id="parttime-checkbox"
          checked={checkboxStates.PART_TIME}
          toggleValue={() =>
            setCheckboxStates({
              ...checkboxStates,
              PART_TIME: !checkboxStates.PART_TIME,
            })
          }
          className="cursor-pointer w-4 h-4"
        />
        <Typography>Part Time</Typography>
      </div>
      <div className="flex items-center gap-2">
        <Checkbox
          id="intern-checkbox"
          checked={checkboxStates.INTERN}
          toggleValue={() =>
            setCheckboxStates({
              ...checkboxStates,
              INTERN: !checkboxStates.INTERN,
            })
          }
          className="cursor-pointer w-4 h-4"
        />
        <Typography>Intern</Typography>
      </div>
      <div className="flex justify-end gap-3 my-3">
        <Button
          fill="outline"
          className="text-red border-red !w-auto"
          id="filter-cancel"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button id="filter-save" className="!w-auto" onClick={attemptSave}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default EmploymentTypeFilter;
