import React, { useState } from "react";
import { RadioInputState } from "./RadioInput.types";

const useRadioInput = ({
  options,
  selected,
}: Partial<RadioInputState>): [
  value: RadioInputState,
  setState: React.Dispatch<React.SetStateAction<RadioInputState>>,
  resetState: () => void,
] => {
  let valid;
  if (Array.isArray(selected)) {
    valid =
      selected.every((selectedOption) =>
        options?.some((option) => option.value === selectedOption.value),
      ) || false;
  } else {
    valid =
      options?.some((option) => option.value === selected?.value) || false;
  }

  let selectedValue = selected;
  if (!selectedValue) {
    selectedValue = undefined;
  }

  const initialState: RadioInputState = {
    options: options || [],
    selected: selectedValue,
    valid,
    touched: false,
    pristine: true,
  };
  const [radioInputState, setRadioInputState] = useState(initialState);
  const resetInput = () => setRadioInputState(initialState);
  return [radioInputState, setRadioInputState, resetInput];
};

export default useRadioInput;
