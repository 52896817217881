import Modal from "~/components/Modal";
import React from "react";
import Button from "~/components/Button";
import Typography from "~/components/Typography";
import parallelLogo from "~/assets/parallelLogoSVG.svg";

interface Props {
  isOpen: boolean;
  setModal: () => void;
}

const FirstTimeLogin = ({ isOpen, setModal }: Props): React.ReactNode => (
  <Modal isOpen={isOpen} size="sm">
    <div
      data-testid="first-time-login-modal"
      className="flex flex-col gap-4 justify-center items-center w-full my-8"
    >
      <div className="h-36 w-36 rounded-full bg-green-15 flex justify-center items-center mx-auto mb-4">
        <img src={parallelLogo} alt="Parallel Logo" className="w-[66px]" />
      </div>
      <Typography size="lg" weight="bold" className="text-center">
        Welcome to Parallel
      </Typography>
      <Typography size="sm" color="secondary" className="text-center">
        Everything to do with headcount budgeting lives here!
      </Typography>
      <Typography size="sm" color="secondary" className="text-center">
        Feel free to explore the software at your own pace and reach out if you
        have any questions.
      </Typography>
      <Button
        id="continue-to-parallel"
        onClick={setModal}
        className="!w-fit mt-8"
      >
        Continue
      </Button>
    </div>
  </Modal>
);

export default FirstTimeLogin;
