import Input from "~/components/Input/Input";
import React from "react";
import useInput from "~/components/Input/useInput";

interface Props {
  id: string;
  state: Types.InputState;
  setState: React.Dispatch<React.SetStateAction<Types.InputState>>;
  type?:
    | "text"
    | "password"
    | "currency"
    | "percentage"
    | "textarea"
    | "search";
  placeholder?: string;
  className?: string;
  label?: string;
  disabled?: boolean;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  prepend?: React.ReactNode;
  onBlur?: () => void;
  required?: boolean;
}

const InputWrapper = ({
  id,
  type = "text",
  state,
  setState,
  className = "",
  label: labelValue = "",
  placeholder = "",
  onKeyDown = () => {},
  prepend,
  onBlur,
  required = false,
}: Props) => {
  const onChange = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      value: value || "",
      pristine: false,
      touched: value === "" ? true : prevState.touched,
      valid: state.validation.test(value),
    }));
  };
  const onInputBlur = () => {
    setState({
      ...state,
      touched: true,
    });
    if (onBlur) onBlur();
  };

  const showError = state.touched && !state.pristine && !state.valid;

  return (
    <Input
      id={id}
      className={className}
      state={state}
      type={type}
      placeholder={placeholder}
      label={labelValue}
      onBlur={onInputBlur}
      onChange={onChange}
      onKeyDown={onKeyDown}
      showError={showError}
      prepend={prepend}
      required={required}
    />
  );
};

export { useInput };
export default InputWrapper;
