import Modal from "~/components/Modal";
import React from "react";
import Button from "~/components/Button";
import Input from "~/components/Input";
import Divider from "~/components/Divider";
import Typography from "~/components/Typography";
import RadioInput from "~/components/RadioInput";
import { RadioInputState } from "~/components/RadioInput/RadioInput.types";
import { Transition } from "@headlessui/react";
import InputState = Types.InputState;
import { SelectMultipleState } from "~/components/SelectMultiple/SelectMultiple.types";
import SelectMultipleDepartments from "~/components/SelectMultipleDepartments";

interface Props {
  isOpen: boolean;
  setModal: () => void;
  createInvitation: () => void;
  userName: InputState;
  setUserName: React.Dispatch<React.SetStateAction<InputState>>;
  userEmail: InputState;
  setUserEmail: React.Dispatch<React.SetStateAction<InputState>>;
  role: RadioInputState;
  setRole: React.Dispatch<React.SetStateAction<RadioInputState>>;
  resetFormState: () => void;
  showDepartmentAccess: boolean;
  departmentAccessList: SelectMultipleState;
  setDepartmentAccessList: React.Dispatch<
    React.SetStateAction<SelectMultipleState>
  >;
}

const UserPermissions = ({
  isOpen,
  setModal,
  createInvitation,
  userName,
  userEmail,
  setUserName,
  setUserEmail,
  role,
  setRole,
  resetFormState,
  showDepartmentAccess,
  departmentAccessList,
  setDepartmentAccessList,
}: Props): React.ReactNode => (
  <Modal title="User Information" isOpen={isOpen} size="lg">
    <div
      data-testid="add-new-user-modal"
      className="flex flex-col gap-4 w-full"
    >
      <Input
        id="user-name-input"
        label="Name"
        state={userName}
        setState={setUserName}
        placeholder="Name"
      />
      <Input
        id="user-email-input"
        label="Email"
        state={userEmail}
        setState={setUserEmail}
        placeholder="Email"
      />
      <Divider />
      <Typography tag="h3" size="sm" weight="semibold">
        Role
      </Typography>
      <RadioInput
        id="userRole"
        state={role}
        setState={setRole}
        className="text-sm"
      />
      <Transition
        show={showDepartmentAccess}
        enter="transition ease-in duration-200"
        enterFrom="opacity-10"
        enterTo="opacity-100"
        leave="transition ease-out duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-10"
      >
        <div>
          <SelectMultipleDepartments
            departmentOptions={departmentAccessList}
            setDepartmentOptions={setDepartmentAccessList}
            label="Department Access"
            className="pl-6"
          />
        </div>
      </Transition>
      <div className="flex flex-row justify-between">
        <Button
          onClick={() => {
            setModal();
            resetFormState();
          }}
          fill="outline"
          className="!w-fit mt-2"
        >
          Cancel
        </Button>
        <Button
          id="attempt-create-new-user"
          onClick={createInvitation}
          className="!w-fit mt-2"
          disabled={
            !userName.value ||
            !userEmail.value ||
            !role.selected?.value ||
            (role.selected.value === "user" && !departmentAccessList.selected)
          }
        >
          Submit
        </Button>
      </div>
    </div>
  </Modal>
);

export default UserPermissions;
