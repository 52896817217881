import { IDrilldownDetailsData } from "~/components/Drawer/DrilldownDrawer/useDrilldownDrawer";
import { formatInTimeZone } from "date-fns-tz";

const transformDataForExportUsingReduce = (
  unformattedData: IDrilldownDetailsData[],
) =>
  unformattedData.reduce(
    (acc, drilldownDetailsData) => {
      const tableTitle = drilldownDetailsData.drilldownTitle;

      // Iterate over each drilldownDetailsData.drilldownData (which is IDrilldownDetails[])
      const detailEntries = drilldownDetailsData.drilldownData.flatMap(
        (detail) => {
          let dateString;

          if (
            drilldownDetailsData.drilldownHeaders?.includes("START DATE") ||
            drilldownDetailsData.drilldownHeaders?.includes("END DATE")
          ) {
            dateString = detail.positions[0].effectiveAt;
          }
          return {
            "Employee Number": detail.employeeNumber ?? undefined,
            "Employee Name": detail.employeeName ?? undefined,
            "Position Title": detail.positions[0].title,
            Department: detail.positions[0].departmentName ?? undefined,
            "Start/End Date": dateString,
          };
        },
      );

      return [...acc, { tableTitle: tableTitle, data: [...detailEntries] }];
    },
    [] as {
      tableTitle?: string;
      data: {
        "Employee Number": string | undefined;
        "Employee Name": string | undefined;
        "Position Title": string;
        Department: string | undefined;
        "Start/End Date": string | undefined;
      }[];
    }[],
  );

const formatBridgeDrilldownForExport = ({
  data,
}: {
  data: IDrilldownDetailsData[];
}): React.ReactNode[] => {
  const transformedData = transformDataForExportUsingReduce(data);
  const csvContent: React.ReactNode[][] = [];

  transformedData.forEach((table) => {
    if (table.tableTitle && table.tableTitle !== "") {
      csvContent.push([`${table.tableTitle}`]);
    }
    if (table.data.length > 0 && table.data[0]) {
      csvContent.push(Object.keys(table.data[0]));
      table.data.forEach((row) => {
        csvContent.push(
          Object.keys(row).map((key) => {
            const value = row[key as keyof typeof row];
            if (
              key === "Start/End Date" &&
              typeof value === "string" &&
              value.includes("-")
            ) {
              try {
                return formatInTimeZone(new Date(value), "UTC", "MM/dd/yyyy");
              } catch (error) {
                console.error("Error formatting date: ", error);
                return value;
              }
            } else {
              return value;
            }
          }),
        );
      });
    } else {
      csvContent.push(["No data available"]);
    }
    csvContent.push([]);
  });

  return csvContent;
};

export default formatBridgeDrilldownForExport;
