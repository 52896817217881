import useInput from "~/components/Input/useInput";
import { State } from "~/store";
import { emailValidator, fullNameValidator } from "~/utils/validators";
import useQueryParams from "~/utils/hooks/useQueryParams";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import request from "~/utils/request";
import zod from "zod";
import toast from "react-hot-toast";
import SignUp from "./SignUp";

const UserAlreadyCreatedErrorSchema = zod.object({
  message: zod.union([
    zod.literal("User already exists with this email"),
    zod.literal("Invitation already accepted"),
  ]),
  data: zod.string(),
});

const SignUpContainer = (): React.ReactNode => {
  const navigate = useNavigate();
  const [queryParams] = useQueryParams();
  const invitationId = queryParams.get("invitationId");
  const { isLoggedIn } = useSelector((state: State) => state.auth);
  const { uuid: userUuid } = useSelector((state: State) => state.user);
  const [name, setName] = useInput({
    validation: fullNameValidator,
    errorMessage: "Please enter a first and last name",
  });
  const [email, setEmail] = useInput({
    validation: emailValidator,
    errorMessage: "Please enter a valid email",
  });
  const [companyName, setCompanyName] = useInput({
    validation: /.{2,}/,
    errorMessage: "Please enter a valid company name",
  });
  const hasAttemptedInvitationCreation = useRef(false);

  const attemptCreateUserFromInvite = async (invitationUuid: string) => {
    if (hasAttemptedInvitationCreation.current) return;
    hasAttemptedInvitationCreation.current = true;
    const createUserResponse = await request({
      url: "/users",
      method: "POST",
      body: {
        invitationUuid,
      },
    });

    if (createUserResponse.status === 400) {
      const parseResult = UserAlreadyCreatedErrorSchema.safeParse(
        createUserResponse.data.errors[0],
      );
      if (parseResult.success) {
        toast.error("User Already Exists");
        navigate("/auth/login");
        return;
      }
    }

    if (createUserResponse.status === 201) {
      const { email, otp } = createUserResponse.data.data;
      navigate(
        `/auth/login?otp=${otp}&emailValue=${encodeURIComponent(
          email,
        )}&firstLogin=true`,
      );
    }
  };

  useEffect(() => {
    if (!invitationId || (isLoggedIn && userUuid)) return;
    attemptCreateUserFromInvite(invitationId);
  }, [invitationId, isLoggedIn, userUuid]);

  const attemptSignUp = async () => {
    if (email.valid && name.valid && companyName.valid) {
      const createUserResponse = await request({
        url: "/users",
        method: "POST",
        body: {
          name: name.value,
          email: email.value,
          organization: { name: companyName.value },
        },
      });

      if (createUserResponse.status === 201) {
        navigate("/auth/login");
      }
    } else {
      setEmail({ ...email, pristine: false, touched: true });
      setCompanyName({ ...companyName, pristine: false, touched: true });
      setName({ ...name, pristine: false, touched: true });
    }
  };

  if (isLoggedIn && userUuid) return <Navigate to="/onboarding" replace />;
  return (
    <div className="w-full flex flex-col items-center">
      {invitationId ? null : (
        <SignUp
          name={name}
          setName={setName}
          email={email}
          setEmail={setEmail}
          companyName={companyName}
          setCompanyName={setCompanyName}
          signUp={attemptSignUp}
          navigate={navigate}
        />
      )}
    </div>
  );
};

export default SignUpContainer;
