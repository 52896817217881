import React, { useState } from "react";
import date from "~/utils/dates/date";
import Typography from "~/components/Typography";
import request from "~/utils/request";
import Button from "~/components/Button";
import { State } from "~/store";
import { useSelector } from "react-redux";
import permissionsCheck from "~/utils/permissionsCheck";
import ChangeListItem from "./ChangeListItem";
import { Position, IPositionHistoryVersion } from "../headcount.types";
import ForecastOptions from "./forecastOptionsPopover";
import ConfirmPrompt from "~/components/ConfirmPrompt";

interface Props {
  id?: string;
  positionData: Position;
  organizationUuid: string;
  setPositionVersionInEditModeEffectiveAt?: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  fetchPositionData?: () => Promise<void>;
  version: IPositionHistoryVersion;
}

const PositionVersionCard = ({
  id,
  positionData,
  organizationUuid,
  fetchPositionData,
  setPositionVersionInEditModeEffectiveAt,
  version,
}: Props): React.ReactNode => {
  const {
    permissions: { role, departmentAccessList },
  } = useSelector((state: State) => state.user);
  const [displayDeleteTerminationModal, setDisplayDeleteTerminationModal] =
    useState(false);

  const deleteVersion = async (
    versionUuid: string,
    callback: () => void,
  ): Promise<void> => {
    const deleteVersionResponse = await request({
      url: `/organizations/${organizationUuid}/positions/${positionData.current.positionUuid}`,
      params: {
        versionUuid,
        effectiveAt: version.effectiveAt,
      },
      method: "DELETE",
    });

    if (deleteVersionResponse.status < 400) {
      callback();
    }
  };

  const changeReason = ((): string => {
    // Check if the current version is the first version in the history
    const isFirstVersion = version.uuid === positionData.history[0].uuid;

    // Custom logic for specific change reasons
    const isActiveChange = version.changes.find(
      (change) => change.label === "Position Active",
    );
    const employeeUuidChange = version.changes.find(
      (change) => change.label === "Employee",
    );

    // Check for Termination and Backfilled conditions
    if (isActiveChange) {
      const wasActive = isActiveChange.changedFrom === "Active";
      const isActive = isActiveChange.changedTo === "Active";
      if (!wasActive && isActive) {
        return "Backfill";
      }
      if (employeeUuidChange) {
        const wasAssigned = employeeUuidChange.changedFrom !== null;
        const isAssigned = employeeUuidChange.changedTo !== null;
        if (wasActive && !isActive && wasAssigned && !isAssigned) {
          return "Termination";
        }
        if (!wasActive && isActive && !wasAssigned && isAssigned) {
          return "Backfilled";
        }
      }

      if (version.changes.length === 1) {
        return "Position Filled";
      }
    }

    // If there is only one change, use that as the change reason
    if (version.changes.length === 1) {
      return version.changes[0].label;
    }

    if (!!positionData.currentEmployee?.uuid && isFirstVersion) {
      return "Hire Date";
    }

    // If there are no changes and it's not the first version, use 'No Changes'
    if (!version.changes.length && !isFirstVersion) {
      return "No Changes";
    }

    if (isFirstVersion) {
      return "Position Created";
    }

    return "Multiple Changes";
  })();

  const changeList = version.changes.map((change) => (
    <ChangeListItem
      key={version.uuid}
      label={change.label}
      changedFrom={change.changedFrom}
      changedTo={change.changedTo}
      versionUuid={version.uuid}
      currentCurrency={positionData.current.currency}
    />
  ));

  const isInitialForecast =
    !positionData.history[0].isActual &&
    version.uuid === positionData.history[0].uuid;

  return (
    <div
      data-testid={id}
      className={`bg-white flex flex-col gap-6 rounded-xl p-6 border border-neutral-50 ${
        date(version.effectiveAt).startOf("day").isBefore(date()) &&
        !version.isActual
          ? "border border-red-400"
          : ""
      }`}
    >
      <ConfirmPrompt
        isOpen={displayDeleteTerminationModal}
        onClose={() => setDisplayDeleteTerminationModal(false)}
        title="Future forecasts will also be deleted"
        message="By deleting a forecasted term all forecasts after the termination, including backfills and other modifications, will also be deleted. Are you sure?"
        confirmButtonText="Confirm, Delete Forecasts"
        onConfirm={() => {
          if (fetchPositionData) deleteVersion(version.uuid, fetchPositionData);
          setDisplayDeleteTerminationModal(false);
        }}
        modalSize="md"
      />
      <div className="flex flex-row justify-between">
        <div className="flex flex-row w-full gap-4">
          <div className="flex flex-col w-1/3 max-w-[200px]">
            <Typography
              id={`change-reason-${version.uuid}`}
              size="sm"
              weight="semibold"
            >
              {changeReason}
            </Typography>
            {date(version.effectiveAt).startOf("day").isBefore(date()) &&
            !version.isActual &&
            !!setPositionVersionInEditModeEffectiveAt ? (
              <Button
                fill="destructiveClear"
                onClick={() =>
                  setPositionVersionInEditModeEffectiveAt(version.effectiveAt)
                }
                className="!w-fit !p-0 text-red-400 hover:text-red-600"
              >
                {date(version.effectiveAt).format("MM/DD/YYYY")}
              </Button>
            ) : (
              <Typography id={`change-effective-date-${version.uuid}`}>
                {date(version.effectiveAt).format("MM/DD/YYYY")}
              </Typography>
            )}
          </div>
          <div className="flex gap-2 h-fit w-7/12">
            <Typography
              id={`version-change-description-${version.uuid}`}
              tag="p"
              color="empty"
              className="border-r pr-2"
            >
              {version.changeDescription}
            </Typography>
            <Typography tag="p" color="empty">
              {`${
                // If the
                !version.changedBy ? "Changed" : version.changedBy
              } on ${date(version.changedOn).format("MM/DD/YYYY")}`}
            </Typography>
          </div>
        </div>
        {!version.isActual &&
          !isInitialForecast &&
          !!setPositionVersionInEditModeEffectiveAt &&
          !!fetchPositionData &&
          permissionsCheck(role, departmentAccessList) && (
            <div className="flex w-1/12 justify-end">
              <ForecastOptions
                deleteFunction={() => {
                  if (changeReason === "Termination" && !version.isActual) {
                    setDisplayDeleteTerminationModal((prev) => !prev);
                  } else {
                    deleteVersion(version.uuid, fetchPositionData);
                  }
                }}
                editFunction={() =>
                  setPositionVersionInEditModeEffectiveAt(version.effectiveAt)
                }
              />
            </div>
          )}
      </div>
      <div className="flex flex-col gap-4 w-full">{changeList}</div>
    </div>
  );
};

export default PositionVersionCard;
