import Modal from "~/components/Modal";
import React from "react";
import Button from "~/components/Button";
import Typography from "~/components/Typography";
import { TaskInterface } from "~/components/Tasks";
import Input from "~/components/Input";
import date from "~/utils/dates/date";
import InputState = Types.InputState;

interface Props {
  isOpen: boolean;
  submitFunction: () => void;
  backFunction: () => void;
  reason: InputState;
  setReason: React.Dispatch<React.SetStateAction<InputState>>;
  isAdmin?: boolean;
  task?: TaskInterface;
  rejectReason?: string | null;
}

const RejectRequest = ({
  isOpen,
  submitFunction,
  backFunction,
  reason,
  setReason,
  isAdmin,
  task,
  rejectReason,
}: Props): React.ReactNode => (
  <Modal
    title={`${isAdmin ? "Reject Request" : "Response"}`}
    isOpen={isOpen}
    size="xs"
  >
    <div
      data-testid="reject-request-modal"
      className="w-full flex flex-col gap-4"
    >
      <div className="flex flex-col gap-4">
        {isAdmin ? (
          <Input
            id="rejection-reasoning"
            label="Reason"
            state={reason}
            setState={setReason}
            className="h-20"
            type="textarea"
          />
        ) : (
          <div>
            <div className="flex flex-row gap-4">
              <div className="flex flex-col">
                <Typography size="xs">Responder</Typography>
                <Typography size="sm">{task?.completedBy}</Typography>
              </div>
              <div className="flex flex-col">
                <Typography size="xs">Date</Typography>
                <Typography size="sm">
                  {date(task?.completedAt ?? undefined).format("MM/DD/YYYY")}
                </Typography>
              </div>
            </div>
            <div className="flex flex-col">
              <Typography size="xs" className="italic mt-2" color="secondary">
                {`"${rejectReason}"`}
              </Typography>
            </div>
          </div>
        )}
      </div>
      {isAdmin ? (
        <div className="w-full flex flex-row justify-between">
          <Button onClick={backFunction} fill="clear" className="!w-fit !px-0">
            Back
          </Button>
          <Button
            id="submit-reject-request"
            onClick={submitFunction}
            className="!w-fit"
          >
            Send
          </Button>
        </div>
      ) : (
        <div className="w-full flex flex-row justify-between">
          <Button onClick={backFunction} fill="clear" className="!w-fit !px-0">
            Close
          </Button>
          <Button
            id="dismiss-rejected-position-request"
            onClick={submitFunction}
            className="!w-fit"
          >
            Dismiss
          </Button>
        </div>
      )}
    </div>
  </Modal>
);

export default RejectRequest;
