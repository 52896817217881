import React, { useEffect } from "react";
import Select, { useSelect } from "~/components/Select";
import PeriodPicker from "~/components/PeriodPicker";
import { IPeriodPickerState } from "~/components/PeriodPicker/usePeriodPicker";

interface Props {
  state: IPeriodPickerState;
  setState: React.Dispatch<React.SetStateAction<IPeriodPickerState>>;
}

const DateRange = ({ state, setState }: Props): React.ReactNode => {
  const PERIODS: Record<string, string> = {
    month: "Monthly",
    quarter: "Quarterly",
    year: "Yearly",
  };
  const [selectMode, setSelectMode] = useSelect({
    options: [
      {
        label: "Monthly",
        value: "month",
      },
      {
        label: "Quarterly",
        value: "quarter",
      },
      {
        label: "Yearly",
        value: "year",
      },
    ],
    selected: {
      label: PERIODS[state.mode],
      value: state.mode,
    },
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      mode: selectMode.selected?.value as "month" | "quarter" | "year",
    }));
  }, [selectMode, setState]);

  return (
    <div className="relative border-l pl-5 flex flex-row gap-4">
      <Select
        id="select-date-range"
        className="!w-[130px]"
        state={selectMode}
        placeholder="Select Date Range"
        setState={setSelectMode}
        disabled={false}
      />
      <PeriodPicker state={state} setState={setState} />
    </div>
  );
};

export default DateRange;
