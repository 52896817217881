import React from "react";
import Input from "~/components/Input";
import { SelectState } from "~/components/Select/Select.types";
import CurrencyInput from "../../PositionForm/CurrencyInput";
import InputState = Types.InputState;

const ManuallyTrackedDataForm = ({
  selectedPaymentType,
  expectedHoursState,
  setExpectedHoursState,
  bonusAmountState,
  setBonusAmountState,
  commissionAmountState,
  setCommissionAmountState,
  attainmentState,
  setAttainmentState,
  currencyTypeState,
  setCurrencyTypeState,
}: {
  selectedPaymentType: SelectState;
  expectedHoursState: InputState;
  setExpectedHoursState: React.Dispatch<React.SetStateAction<InputState>>;
  bonusAmountState: InputState;
  setBonusAmountState: React.Dispatch<React.SetStateAction<InputState>>;
  commissionAmountState: InputState;
  setCommissionAmountState: React.Dispatch<React.SetStateAction<InputState>>;
  attainmentState: InputState;
  setAttainmentState: React.Dispatch<React.SetStateAction<InputState>>;
  currencyTypeState: SelectState;
  setCurrencyTypeState: React.Dispatch<React.SetStateAction<SelectState>>;
}): React.ReactNode => (
  <div className="w-full flex flex-col gap-3">
    {!Array.isArray(selectedPaymentType.selected) &&
      selectedPaymentType.selected?.value === "HOURLY" && (
        <Input
          id="expectedHours"
          type="text"
          label="Expected Hours Per Week"
          state={expectedHoursState}
          setState={setExpectedHoursState}
          placeholder="Expected hours"
        />
      )}
    <CurrencyInput
      paymentAmountState={bonusAmountState}
      currencyTypeState={currencyTypeState}
      setPaymentAmountState={setBonusAmountState}
      setCurrencyTypeState={setCurrencyTypeState}
      label="Bonus"
      id="bonus"
      hideCurrencyType
    />
    <CurrencyInput
      paymentAmountState={commissionAmountState}
      currencyTypeState={currencyTypeState}
      setPaymentAmountState={setCommissionAmountState}
      setCurrencyTypeState={setCurrencyTypeState}
      label="Commission"
      id="commission"
      hideCurrencyType
    />
    <Input
      label="Attainment"
      id="attainment-percentage"
      state={attainmentState}
      setState={setAttainmentState}
      type="percentage"
    />
  </div>
);

export default ManuallyTrackedDataForm;
