import { EnhancedStore } from "@reduxjs/toolkit";

export default (store: EnhancedStore): Promise<unknown> => {
  if (!store.getState()._persist.rehydrated) {
    let timeoutId: NodeJS.Timeout;
    return new Promise((resolve) => {
      timeoutId = setTimeout(() => {
        throw new Error("Store rehydration timed out");
      }, 5000);

      window.addEventListener(
        "reduxPersistRehydrated",
        () => {
          clearTimeout(timeoutId);
          resolve;
        },
        {
          once: true,
        },
      );
    });
  }
  return Promise.resolve();
};
