import React, { useEffect, useState } from "react";
import Modal from "~/components/Modal";
import { useInput } from "~/components/Input";
import { useSelector } from "react-redux";
import { State } from "~/store";
import SelectFileStep from "./SelectFileStep";
import MappingStep from "./MappingStep";
import SummaryStep, { IPositionsImportedDetails } from "./SummaryStep";

interface Props {
  isOpen: boolean;
  integrationUuid?: string;
  onClose: () => void;
}
const ModalContainer = ({ isOpen, integrationUuid, onClose }: Props) => {
  const organizationUuid = useSelector(
    (state: State) => state.organization.uuid,
  );
  const [uploadStage, setUploadStage] = useState("upload");
  const [hrisName, setHrisName, resetHrisName] = useInput({
    validation: /.+/,
    errorMessage: "Data source is required",
  });

  const [parsedFileData, setParsedFileData] = useState<{
    headers: string[];
    data: Record<string, unknown>[];
  }>();

  const [positionsImportedDetails, setPositionsImportedDetails] = useState<
    IPositionsImportedDetails | undefined
  >();

  useEffect(() => {
    if (!isOpen) {
      setUploadStage("upload");
      resetHrisName();
      setParsedFileData(undefined);
      setPositionsImportedDetails(undefined);
    }
  }, [isOpen]);

  const closeModal = () => {
    onClose();
  };

  const modalTitle = (() => {
    if (uploadStage === "summary") return "Success";
    if (uploadStage === "mapping") return "Map Columns";
    return "Upload File";
  })();

  let modalSize: "xxl" | "md" | "lg" = "xxl";
  if (uploadStage === "summary") modalSize = "md";
  if (uploadStage === "upload") modalSize = "lg";

  return (
    <Modal
      title={modalTitle}
      size={modalSize}
      isOpen={isOpen}
      onClose={closeModal}
    >
      {uploadStage === "upload" && (
        <SelectFileStep
          hrisName={hrisName}
          setHrisName={setHrisName}
          organizationUuid={organizationUuid}
          setPositionsImportedDetails={setPositionsImportedDetails}
          integrationUuid={integrationUuid}
          parsedFileData={parsedFileData}
          setParsedFileData={setParsedFileData}
          onNext={(nextStep?: string) => setUploadStage(nextStep ?? "mapping")}
          onCancel={closeModal}
        />
      )}
      {uploadStage === "mapping" && parsedFileData && (
        <MappingStep
          hrisName={hrisName.value}
          parsedFileData={parsedFileData}
          setPositionsImportedDetails={setPositionsImportedDetails}
          onBack={() => setUploadStage("upload")}
          onNext={() => setUploadStage("summary")}
          integrationUuid={integrationUuid}
        />
      )}
      {uploadStage === "summary" && positionsImportedDetails && (
        <SummaryStep
          positionsImportedDetails={positionsImportedDetails}
          onClose={closeModal}
        />
      )}
    </Modal>
  );
};

export default ModalContainer;
